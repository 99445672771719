import type { MarketViewSettings } from "$root/api/api-gen";
import { MarketViewSettingsActiveMarketViewTypesEnum } from "$root/api/api-gen";
import { typedUrlForRoute } from "$root/components/PlatformRouter/RoutesDef";
import CreateMarketViewCustomIcon from "$root/components/glyphs/CreateMarketViewCustomIcon";
import CreateMarketViewPositioningIconV2 from "$root/components/glyphs/CreateMarketViewPositioningIconV2";
import CreateMarketViewRegimeIconV2 from "$root/components/glyphs/CreateMarketViewRegimeIconV2";
import { Dialog } from "@mdotm/mdotui/components";
import type { SpawnResult } from "@mdotm/mdotui/react-extensions";
import { adaptAnimatedNodeProvider, spawn } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { Link } from "react-router-dom";

type MarketViewCreationDialogProps = {
	show: boolean;
	onClose?(): void;
	marketViewSetting?: MarketViewSettings;
	onSubmit(): void;
};

function MarketViewCreationDialog(props: MarketViewCreationDialogProps) {
	const { marketViewSetting } = props;
	return (
		<Dialog
			show={props.show}
			size={
				marketViewSetting?.activeMarketViewTypes?.length === 3
					? "large"
					: marketViewSetting?.activeMarketViewTypes?.length === 2
					  ? "medium"
					  : "small"
			}
			onClose={props.onClose}
		>
			<div className="flex gap-4 mx-auto">
				{marketViewSetting?.activeMarketViewTypes?.includes(
					MarketViewSettingsActiveMarketViewTypesEnum.ExpectedReturnsVolatility,
				) && (
					<Link
						to={typedUrlForRoute("MarketViewWorkSpace/New", {
							type: MarketViewSettingsActiveMarketViewTypesEnum.ExpectedReturnsVolatility,
						})}
						className={`hover:bg-[color:${themeCSSVars.palette_N50}] hover:rounded-md p-4 flex-1`}
						data-qualifier="PortfolioStudio/MarketViewList/NewMarketView/ExpectedReturns"
						onClick={() => props.onSubmit()}
					>
						<div className="w-[112px] h-[115px] overflow-hidden mx-auto">
							<CreateMarketViewRegimeIconV2 style={{ width: 117, height: 120 }} viewBox={{ width: 117, height: 120 }} />
						</div>
						<p className="text-lg font-semibold text-center">Expected returns</p>
						<p className="text-center">Set your market view regimes</p>
					</Link>
				)}
				{marketViewSetting?.activeMarketViewTypes?.includes(
					MarketViewSettingsActiveMarketViewTypesEnum.PositioningIndicators,
				) && (
					<Link
						to={typedUrlForRoute("MarketViewWorkSpace/New", {
							type: MarketViewSettingsActiveMarketViewTypesEnum.PositioningIndicators,
						})}
						className={`hover:bg-[color:${themeCSSVars.palette_N50}] hover:rounded-md p-4 flex-1`}
						data-qualifier="PortfolioStudio/MarketViewList/NewMarketView/UnderOverWeight"
						onClick={() => props.onSubmit()}
					>
						<CreateMarketViewPositioningIconV2 classList="mx-auto" />
						<p className="text-lg font-semibold text-center">Under / over weight</p>
						<p className="text-center">Set your market view positioning</p>
					</Link>
				)}
				{marketViewSetting?.activeMarketViewTypes?.includes(MarketViewSettingsActiveMarketViewTypesEnum.Custom) && (
					<Link
						to={typedUrlForRoute("MarketViewWorkSpace/New", {
							type: marketViewSetting.customMarketViewType!,
							isCustom: "true",
						})}
						className={`hover:bg-[color:${themeCSSVars.palette_N50}] hover:rounded-md p-4 flex-1`}
						data-qualifier="PortfolioStudio/MarketViewList/NewMarketView/Custom"
						onClick={() => props.onSubmit()}
					>
						<CreateMarketViewCustomIcon classList="mx-auto" />
						<p className="text-lg font-semibold text-center">{marketViewSetting.customMarketViewName ?? "Custom"}</p>
						<p className="text-center">Set your custom market view</p>
					</Link>
				)}
			</div>
		</Dialog>
	);
}

type spawnMarketViewCreationDialogProps = Omit<MarketViewCreationDialogProps, "onClose" | "show" | "onSubmit">;
export function spawnMarketViewCreationDialog(params: spawnMarketViewCreationDialogProps): SpawnResult<void> {
	return spawn<void>(
		adaptAnimatedNodeProvider(({ resolve, show }) => (
			<MarketViewCreationDialog {...params} show={show} onClose={() => resolve()} onSubmit={() => resolve()} />
		)),
	);
}
