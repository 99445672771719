import type { FactorsDto, InvestmentFactorsResponse } from "$root/api/api-gen";
import { Card } from "$root/components/EvolvedPrint/components/Card";
import {
	TinyTableDataCell,
	TinyTableHeadCell,
	tinyTableHeadCellFontSize,
} from "$root/components/EvolvedPrint/components/table/tiny-table";
import type { PrintableProps } from "$root/components/EvolvedPrint/configuration";
import { useLocaleFormatters } from "$root/localization/hooks";
import type {
	ReportTemplateItemMap,
	ReportTemplateVariant,
} from "$root/pages/PortfolioStudioSettings/ReportEditor/report-latest";
import { BarGraphPCSvg } from "$root/ui-lib/charts";
import {
	customObjectEntriesFn,
	customObjectValuesFn,
	getGraphMarkers2,
	roundCustomByStep,
} from "$root/utils/experimental";
import type { TableColumn } from "@mdotm/mdotui/components";
import { BaseTable, Text } from "@mdotm/mdotui/components";
import { overrideClassName } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { builtInSort } from "@mdotm/mdotui/utils";
import { useMemo } from "react";
import { useReportTranslation } from "../translation";

type ReducedResultProps = {
	[key: string]: {
		[key in keyof InvestmentFactorsResponse]?: FactorsDto;
	} & { groupKey: string };
};

type GetExposureFactorsSplittableProps = {
	current?: FactorsDto;
	proposal?: FactorsDto;
	benchmark?: FactorsDto;
	groupKey: string;
};

type GraphLimits = {
	maxValue: number;
	minValue: number;
};

type graphColumnMarkers = {
	value: number;
	label: string;
};

export type ExposureFactorsData = {
	investmentFactors: InvestmentFactorsResponse;
	variant: ReportTemplateVariant;
};

export function getExposureFactorsProps(
	data: ExposureFactorsData,
	filterSignificant: boolean,
): {
	list: GetExposureFactorsSplittableProps[];
	graphLimits: GraphLimits;
	graphColumnMarkers: graphColumnMarkers[];
} {
	const processedFactorList = customObjectValuesFn(
		customObjectEntriesFn(data.investmentFactors).reduce<ReducedResultProps>((acc, [key, factorList]) => {
			factorList?.forEach((factor) => {
				if (factor.key === undefined) {
					acc["undefinedKey"] = {
						...acc["undefinedKey"],
						[key]: factor,
						groupKey: "undefinedKey",
					};
				} else {
					acc[factor.key] = {
						...acc[factor.key],
						[key]: factor,
						groupKey: factor.key,
					};
				}
			});
			return acc;
		}, {}),
	);

	const filteredFactorList = !filterSignificant
		? processedFactorList
		: processedFactorList.filter(({ benchmark, current, proposal }) =>
				data.variant === "proposal"
					? [benchmark?.value ?? 0, current?.value ?? 0, proposal?.value ?? 0].some((e) => Math.abs(e) >= 0.01)
					: [benchmark?.value ?? 0, current?.value ?? 0].some((e) => Math.abs(e) >= 0.01),
		  );

	const limits = filteredFactorList.reduce(
		(a, c) => {
			return {
				maxValue: Math.max(a.maxValue, c.benchmark?.value ?? 0, c.proposal?.value ?? 0, c.current?.value ?? 0),
				minValue: Math.min(a.minValue, c.benchmark?.value ?? 0, c.proposal?.value ?? 0, c.current?.value ?? 0),
			};
		},
		{ maxValue: 0, minValue: 0 },
	);
	const sortedList = filteredFactorList.sort(
		(a, b) => builtInSort(Math.abs(a.current?.value ?? 0), Math.abs(b.current?.value ?? 0)) * -1,
	);
	const graphLimits = {
		maxValue: roundCustomByStep(limits.maxValue, 0.5),
		minValue: roundCustomByStep(limits.minValue, 0.5),
	};

	const graphColumnMarkers = getGraphMarkers2({
		min: graphLimits.minValue,
		max: graphLimits.maxValue,
		nOfMarkers: 10,
		suffix: "%",
	});

	return { list: sortedList, graphColumnMarkers, graphLimits };
}

export function ExposureFactors({
	variant,
	list,
	graphColumnMarkers,
	graphLimits,
	config,
}: PrintableProps<
	{
		variant: ReportTemplateVariant;
		data: ExposureFactorsData;
		graphLimits: GraphLimits;
		graphColumnMarkers: graphColumnMarkers[];
		config: ReportTemplateItemMap["factorExposure"];
	},
	GetExposureFactorsSplittableProps
>): JSX.Element {
	const { benchmark: showBenchmark } = config;
	const { t } = useReportTranslation();
	const { formatNumber } = useLocaleFormatters();
	const factorsMetrics = t("REPORT_BUILDER.FACTORS_EXPOSURE.FACTORS", { returnObjects: true });
	type x = keyof typeof factorsMetrics;

	const columns = useMemo<Array<TableColumn<GetExposureFactorsSplittableProps>>>(
		() =>
			variant === "proposal"
				? [
						{
							name: "label",
							minWidth: 200,
							header: (props) => (
								<TinyTableHeadCell {...props}>{t("REPORT_BUILDER.FACTORS_EXPOSURE.TABLE.FACTORS")}</TinyTableHeadCell>
							),
							content: ({ current }, props) => (
								<TinyTableDataCell {...props}>
									{current?.key
										? t(`REPORT_BUILDER.FACTORS_EXPOSURE.FACTORS.${current.key as keyof typeof factorsMetrics}`)
										: current?.label}
								</TinyTableDataCell>
							),
						},
						{
							name: "current",
							width: 74,
							header: (props) => (
								<TinyTableHeadCell {...props}>{t("REPORT_BUILDER.FACTORS_EXPOSURE.TABLE.CURRENT")}</TinyTableHeadCell>
							),
							cellClassList: "tabular-nums",
							align: "end",
							content: ({ current }, props) => (
								<TinyTableDataCell {...props}>{formatNumber(current?.value)}</TinyTableDataCell>
							),
						},
						{
							name: "proposal",
							width: 74,
							header: (props) => (
								<TinyTableHeadCell {...props}>{t("REPORT_BUILDER.FACTORS_EXPOSURE.TABLE.PROPOSAL")}</TinyTableHeadCell>
							),
							cellClassList: "tabular-nums",
							align: "end",
							content: ({ proposal }, props) => (
								<TinyTableDataCell {...props}>{formatNumber(proposal?.value)}</TinyTableDataCell>
							),
						},
						{
							name: "benchmark",
							width: 74,
							header: (props) => (
								<TinyTableHeadCell {...props}>{t("REPORT_BUILDER.FACTORS_EXPOSURE.TABLE.BENCHMARK")}</TinyTableHeadCell>
							),
							cellClassList: "tabular-nums",
							align: "end",
							content: ({ benchmark }, props) => (
								<TinyTableDataCell {...props}>{formatNumber(benchmark?.value)}</TinyTableDataCell>
							),
							hidden: !showBenchmark,
						},
						{
							name: "graph",
							width: 288,
							header: (props) => (
								<div
									style={{ ...props.style, minHeight: 31 }}
									className={overrideClassName(props.classList, "flex items-center justify-between grow")}
								>
									{graphColumnMarkers.map((m, i) => (
										<Text
											as="div"
											type="Body/S/BOLD-UPPERCASE"
											style={{ fontSize: tinyTableHeadCellFontSize }}
											color={themeCSSVars.palette_N500}
											key={`marker-${i}`}
										>
											{m.label}
										</Text>
									))}
								</div>
							),
							content: ({ benchmark, current, proposal }, props) => (
								<div
									style={{ ...props.style, minHeight: 31 }}
									className={overrideClassName(props.classList, "flex grow")}
								>
									<BarGraphPCSvg
										classList="w-full"
										options={{
											animated: false,
											resize: true,
											marksLabels: false,
											markerStep: 0.5,
											scale: { max: graphLimits.maxValue, min: graphLimits.minValue },
											bars: {
												height: (31 - 4 * 2) / 3,
												gap: 0,
											},
											vPadding: 4,
										}}
										data={[
											{ value: current?.value ?? 0, color: current?.relevant ? "#005C8B" : "red" },
											{ value: proposal?.value ?? 0, color: proposal?.relevant ? "#00AEEF" : "red" },
											{ value: benchmark?.value ?? 0, color: benchmark?.relevant ? "#C6ACD9" : "red" },
										]}
									/>
								</div>
							),
						},
				  ]
				: [
						{
							name: "label",
							minWidth: 200,
							header: (props) => (
								<TinyTableHeadCell {...props}>{t("REPORT_BUILDER.FACTORS_EXPOSURE.TABLE.FACTORS")}</TinyTableHeadCell>
							),
							content: ({ current }, props) => (
								<TinyTableDataCell {...props}>
									{current?.key
										? t(`REPORT_BUILDER.FACTORS_EXPOSURE.FACTORS.${current.key as keyof typeof factorsMetrics}`)
										: current?.label}
								</TinyTableDataCell>
							),
						},
						{
							name: "current",
							width: 74,
							header: (props) => (
								<TinyTableHeadCell {...props}>{t("REPORT_BUILDER.FACTORS_EXPOSURE.TABLE.CURRENT")}</TinyTableHeadCell>
							),
							cellClassList: "tabular-nums",
							align: "end",
							content: ({ current }, props) => (
								<TinyTableDataCell {...props}>{formatNumber(current?.value)}</TinyTableDataCell>
							),
						},
						{
							name: "benchmark",
							width: 74,
							header: (props) => (
								<TinyTableHeadCell {...props}>{t("REPORT_BUILDER.FACTORS_EXPOSURE.TABLE.BENCHMARK")}</TinyTableHeadCell>
							),
							cellClassList: "tabular-nums",
							align: "end",
							content: ({ benchmark }, props) => (
								<TinyTableDataCell {...props}>{formatNumber(benchmark?.value)}</TinyTableDataCell>
							),
							hidden: !showBenchmark,
						},
						{
							name: "graph",
							width: 288,
							header: (props) => (
								<div
									style={{ ...props.style, minHeight: 31 }}
									className={overrideClassName(props.classList, "flex items-center justify-between grow")}
								>
									{graphColumnMarkers.map((m, i) => (
										<Text
											as="div"
											type="Body/S/BOLD-UPPERCASE"
											style={{ fontSize: tinyTableHeadCellFontSize }}
											color={themeCSSVars.palette_N500}
											key={`marker-${i}`}
										>
											{m.label}
										</Text>
									))}
								</div>
							),
							content: ({ benchmark, current }, props) => (
								<div
									style={{ ...props.style, minHeight: 31 }}
									className={overrideClassName(props.classList, "flex grow")}
								>
									<BarGraphPCSvg
										classList="w-full"
										options={{
											animated: false,
											resize: true,
											marksLabels: false,
											markerStep: 0.5,
											scale: { max: graphLimits.maxValue, min: graphLimits.minValue },
											bars: {
												height: (31 - 8 * 2) / 2,
												gap: 0,
											},
											vPadding: 8,
										}}
										data={
											showBenchmark
												? [
														{ value: current?.value ?? 0, color: current?.relevant ? "#005C8B" : "red" },
														{ value: benchmark?.value ?? 0, color: benchmark?.relevant ? "#A0A7B6" : "red" },
												  ]
												: [{ value: current?.value ?? 0, color: current?.relevant ? "#005C8B" : "red" }]
										}
									/>
								</div>
							),
						},
				  ],
		[variant, t, showBenchmark, formatNumber, graphColumnMarkers, graphLimits.maxValue, graphLimits.minValue],
	);

	if (list.length === 0) {
		return <></>;
	}

	return (
		<Card title={t("REPORT_BUILDER.FACTORS_EXPOSURE.TITLE")}>
			<BaseTable
				disableVirtualScroll
				palette="uniform"
				columns={columns}
				noDataText={t("COMPOSITION.NO_COMPOSITION")}
				rows={list}
			/>
		</Card>
	);
}

export default ExposureFactors;
