import { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import GraphLegend from "$root/components/GraphLegend";
import Highcharts from "$root/utils/chart/highcharts-with-modules";
import type { Chart, XAxisPlotLinesOptions } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import ColoredRectangle from "$root/components/icons/ColoredRectangle";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { useWidgetOptions } from "$root/widgets-architecture/layout/WidgetsMapper/context";
import type { ContextContent } from "$root/utils/react-extra";
import { withContext } from "$root/utils/react-extra";
import { RegionContext } from "$root/widgets-architecture/contexts/region";
import { useApiGen } from "$root/api/hooks";
import type { HmmRegion, RegimeCounter, SectorsBreakDown } from "$root/api/api-gen";
import { HmmControllerApiFactory } from "$root/api/api-gen";
import type { TseriesSanitaized } from "$root/utils/sectorUtils";
import { DateFormatter, generatePlotLinesFromBands, processSectors } from "$root/utils/sectorUtils";
import { addDays, differenceInDays, format } from "date-fns";
import { Range } from "immutable";
import { stableEmptyObject } from "@mdotm/mdotui/utils";
import { IconWalls } from "$root/components/IconWall";
import { customObjectEntriesFn } from "$root/utils/experimental";
import { hasAccess } from "$root/components/AuthorizationGuard";
import { Button, Dialog, DialogFooter, DialogHeader, Option, Select } from "@mdotm/mdotui/components";
import { useUserValue } from "$root/functional-areas/user";

type TCustomizedChart = {
	xTimestamps: number[];
	bands: TseriesSanitaized[];
	series: number[][];
	yCategories: TyCategories[];
	colorCategories: { name: string; color: string }[];
	RetrieveRegimeCounter: RegimeCounter;
	lines: XAxisPlotLinesOptions[];
	region: HmmRegion;
};

type TyCategories =
	| "COMMUNICATION_SERVICES"
	| "ENERGY"
	| "CONSUMER_DISCRETIONARY"
	| "UTILITIES"
	| "CONSUMER_STAPLES"
	| "HEALTHCARE"
	| "INDUSTRIALS"
	| "INFORMATION_TECHNOLOGY"
	| "FINANCIALS"
	| "MATERIALS"
	| "HEALTH_CARE"
	| "REAL_ESTATE";

const CustomizedChart = forwardRef<{ chart: Chart }, TCustomizedChart>(function CustomizedChartRef(
	{ bands, xTimestamps, yCategories, colorCategories, lines, region },
	ref,
) {
	const chartRef = useRef<HighchartsReact.RefObject>(null);
	const { t } = useTranslation();

	const options = useMemo(() => {
		const minDate = xTimestamps[0];
		const maxDate = xTimestamps[xTimestamps.length - 1];

		return {
			chart: {
				style: {
					fontFamily: "Gotham,sans-serif",
				},
				panning: {
					enabled: false,
				},
			},
			credits: {
				enabled: false,
			},
			legend: {
				enabled: false,
			},
			title: {
				text: undefined,
			},
			subtitle: {
				text: undefined,
			},
			xAxis: [
				{
					type: "datetime",
					min: minDate,
					max: maxDate,
					endOnTick: false,
					startOnTick: false,
					plotBands: bands,
					plotLines: lines,
					minTickInterval: 1000 * 60 * 60 * 24,
					minRange: 1000 * 60 * 60 * 24 * 7,
				},
			],
			yAxis: {
				opposite: false,
				title: {
					enabled: false,
				},
				categories: yCategories.reverse().map((category) =>
					t(`EQ_SECTOR.${category}`, {
						region: t(`GEOGRAPHY.${region}`),
					}),
				),
				min: 0,
				max: yCategories.length - 1,
				gridLine: 1,
				gridLineColor: "black",
			},
			boost: {
				debug: {
					timeRendering: true,
				},
				useGPUTranslations: true,
				seriesThreshold: 1,
			},
			tooltip: {
				enabled: false,
			},
			plotOptions: {
				series: {
					showInNavigator: true,
					label: {
						connectorAllowed: false,
					},
					lineWidth: 0,
					opacity: 0,
				},
			},
			exporting: {
				enabled: true,
				chartOptions: {
					title: {
						text: "Sector regime breakdown",
					},
					subtitle: {
						text: `Data Exported in ${new Date().getUTCFullYear()}-${
							new Date().getUTCMonth() + 1
						}-${new Date().getUTCDate()}`,
					},
					chart: {
						margin: 40,
						marginTop: 80,
						marginLeft: 80,
						marginBottom: 80,
					},
					scrollbar: {
						enabled: false,
					},
				},
				buttons: {
					contextButton: {
						menuItems: ["downloadJPEG", "downloadPDF"],
					},
				},
				allowHTML: true,
				printMaxWidth: 1080,
				filename: "MDotM_Regime_Breakdown",
				sourceHeight: 600,
				sourceWidth: 1440,
			},
			series: [
				{
					data: xTimestamps.map((el) => el),
					pointStart: minDate,
					pointInterval: 1000 * 60 * 60 * 24,
				},
			],
		};
	}, [bands, xTimestamps, yCategories, lines, region, t]);

	return (
		<div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
			<div
				style={{
					borderRadius: 4,
					borderColor: "#eeeef1",
					borderWidth: 2,
					borderStyle: "solid",
					flex: 1,
					display: "flex",
					flexDirection: "column",
				}}
			>
				<div style={{ flex: 1 }}>
					<HighchartsReact
						containerProps={{ style: { height: "100%" } }}
						highcharts={Highcharts}
						constructorType="stockChart"
						options={options}
						ref={chartRef}
					/>
				</div>
				<div style={{ flex: 0 }}>
					<GraphLegend style={{ position: "static", padding: "1rem 0", margin: 0 }}>
						{colorCategories.map(({ name, color }) => (
							<div key={color} className="legend-container return-analysis light more-space book">
								<ColoredRectangle variant="vertical" color={color} />
								{name}
							</div>
						))}
					</GraphLegend>
				</div>
			</div>
		</div>
	);
});

// utility function to get the exact sectors based on date
type RegimeDateStandard = { day: string; month: string; year: string };
const testMap = {
	"-1": "uncertain",
	"1": "low risk",
	"2": "mid risk",
	"3": "high risk",
} as Record<string, string>;

function getPeriod(props: { startDate: RegimeDateStandard | string }, sectorsBreakdown?: SectorsBreakDown) {
	if (sectorsBreakdown === undefined) {
		return {};
	}

	const startDate =
		typeof props.startDate === "string"
			? props.startDate
			: `${props.startDate.year}${props.startDate.month}${props.startDate.day}`;

	const mapDate = new Map(
		customObjectEntriesFn(sectorsBreakdown).flatMap(([sector, timeSeries]) => {
			return customObjectEntriesFn(timeSeries).map(([time, regime]) => [`${time}-${sector}`, regime]);
		}),
	);

	function generateLabel(value?: string) {
		return value ? `${value}, (${testMap[value] ?? ""})` : "value not found";
	}

	return {
		COMMUNICATION_SERVICES: generateLabel(mapDate.get(startDate + "-" + "communicationsServices")),
		CONSUMER_DISCRETIONARY: generateLabel(mapDate.get(startDate + "-" + "consumerDiscretionary")),
		CONSUMER_STAPLES: generateLabel(mapDate.get(startDate + "-" + "consumerStaples")),
		ENERGY: generateLabel(mapDate.get(startDate + "-" + "energy")),
		FINANCIALS: generateLabel(mapDate.get(startDate + "-" + "financials")),
		HEALTHCARE: generateLabel(mapDate.get(startDate + "-" + "healthCare")),
		INDUSTRIALS: generateLabel(mapDate.get(startDate + "-" + "industrials")),
		INFORMATION_TECHNOLOGY: generateLabel(mapDate.get(startDate + "-" + "informationTechnology")),
		MATERIALS: generateLabel(mapDate.get(startDate + "-" + "materials")),
		UTILITIES: generateLabel(mapDate.get(startDate + "-" + "utilities")),
	};
}

const RegimeBreakdownBySectorBlock = (props: ContextContent<typeof RegionContext>) => {
	const { t } = useTranslation();
	const chartRef = useRef<{ chart: Chart } | null>(null);
	const region = props.region;
	const hmmApi = useApiGen(HmmControllerApiFactory);
	const user = useUserValue();
	const [showUtility, setShowUtility] = useState(false);
	const [timeSeries, setTimeSeries] = useState<Array<Option<string> & { group?: string }>>([]);

	function fromObjToArray<T>(obj: { [x: string]: T }) {
		const dataArray = Object.keys(obj).map((key) => {
			return obj[key];
		});
		return dataArray;
	}

	const processSectorsBreakdown = (SectorsBreakdown: SectorsBreakDown) => {
		const splittedSector = { ...SectorsBreakdown };
		const communicationsServicesKeys = Object.keys(splittedSector.communicationsServices ?? stableEmptyObject);
		const startDate = communicationsServicesKeys[0];
		const endDateString = communicationsServicesKeys[communicationsServicesKeys.length - 1];
		const endDate = new Date(DateFormatter(endDateString));

		const difference = differenceInDays(new Date(), endDate);
		const daysToAdd = difference > 7 ? 7 : difference === 0 ? 1 : difference;
		const newLastDate = addDays(endDate, daysToAdd);
		const newLastDateFormatted = format(newLastDate, "yyyyMMdd");

		if (startDate.substring(4, startDate.length) !== "0101") {
			const newInitialDate = String(Number(startDate.substring(0, 4))).concat("0101");
			const updateSector = (sectorName: keyof SectorsBreakDown) => {
				splittedSector[sectorName] = {
					...{ [newInitialDate]: 0 },
					...splittedSector[sectorName],
					...{
						[newLastDateFormatted]: Object.values(splittedSector[sectorName] ?? stableEmptyObject).at(-1) as number,
					},
				};
			};

			(Object.keys(SectorsBreakdown) as Array<keyof SectorsBreakDown>).forEach(updateSector);
		}

		return splittedSector;
	};

	const { isLoading, isError, isFetching, data } = useQueryNoRefetch(["queryHmmRegimeBreakdown", region], {
		queryFn: async () => {
			const { data: SectorsBreakdown } = await hmmApi.retrieveSectorsBreakDown(region);
			const { data: RetrieveRegimeCounter } = await hmmApi.retrieveRegimeCounter(region);

			const processedRegime = processSectorsBreakdown(SectorsBreakdown);
			const xTimestampsArray = Object.keys(processedRegime.communicationsServices ?? stableEmptyObject);

			const minTimestamp = DateFormatter(xTimestampsArray[0]);
			const maxTimestamp = DateFormatter(xTimestampsArray[xTimestampsArray.length - 1]);
			const dateDelta = maxTimestamp - minTimestamp + 1;
			const daysDelta = Math.ceil(dateDelta / (1000 * 3600 * 24));
			const xTimestampsGenerated = Range(0, daysDelta)
				.map((_, i) => minTimestamp + i * 24 * 60 * 60 * 1000)
				.toArray();
			const communicationsServices = fromObjToArray(processedRegime.communicationsServices ?? {});
			const consumerDiscretionary = fromObjToArray(processedRegime.consumerDiscretionary ?? {});
			const consumerStaples = fromObjToArray(processedRegime.consumerStaples ?? {});
			const energy = fromObjToArray(processedRegime.energy ?? {});
			const financials = fromObjToArray(processedRegime.financials ?? {});
			const healthCare = fromObjToArray(processedRegime.healthCare ?? {});
			const industrials = fromObjToArray(processedRegime.industrials ?? {});
			const informationTechnology = fromObjToArray(processedRegime.informationTechnology ?? {});
			const materials = fromObjToArray(processedRegime.materials ?? {});
			const utilities = fromObjToArray(processedRegime.utilities ?? {});
			// Create Data Matrix
			const PointMatrix: number[][] = [];
			xTimestampsArray.forEach((_val, index) => {
				PointMatrix.push([
					communicationsServices[index],
					consumerDiscretionary[index],
					consumerStaples[index],
					energy[index],
					financials[index],
					healthCare[index],
					industrials[index],
					informationTechnology[index],
					materials[index],
					utilities[index],
				]);
			});

			return {
				RetrieveRegimeCounter,
				xTimestamps: xTimestampsGenerated,
				PointMatrix,
				rawXTimestamps: xTimestampsArray,
				SectorsBreakdown,
			};
		},
	});

	const { PointMatrix, xTimestamps, rawXTimestamps, RetrieveRegimeCounter, SectorsBreakdown } = data ?? {};

	const bands = useMemo(
		() => PointMatrix && rawXTimestamps && processSectors(PointMatrix ?? [], rawXTimestamps ?? [""]),
		[PointMatrix, rawXTimestamps],
	);

	const lines = useMemo(() => {
		if (bands) {
			const startDate = bands[0].from;
			const lastDate = bands[bands.length - 1].to;
			return generatePlotLinesFromBands(startDate, lastDate);
		}
	}, [bands]);

	useWidgetOptions(
		() => ({
			isDraggable: false,
			actionHeader: <></>,
			title: t("REGIME_BREAKDOWN_BY_SECTOR.title"),
		}),
		[t],
	);

	return (
		<>
			<div className="flex space-x-2 items-start">
				<div style={{ paddingBottom: "1rem" }}>{t("REGIME_BREAKDOWN_BY_SECTOR.description")}</div>
				{hasAccess(user, { requiredRoles: ["ROOT"] }) && (
					<Button
						disabled={isLoading || isError}
						palette="secondary"
						classList="mb-2"
						size="small"
						onClick={() => {
							setTimeSeries(
								Object.keys(SectorsBreakdown?.informationTechnology ?? {})
									.map((x) => {
										const year = x.substring(0, 4);
										const month = x.substring(4, 6);
										const day = x.substring(6, 8);
										return { label: `${year} ${month} ${day}`, value: x, group: year };
									})
									.reverse(),
							);
							setShowUtility(true);
						}}
					>
						Get regime
					</Button>
				)}
			</div>
			{isError ? (
				<IconWalls.ErrorData />
			) : isLoading || isFetching ? (
				<IconWalls.LoadingData />
			) : (
				<CustomizedChart
					ref={chartRef}
					bands={bands ?? []}
					lines={lines ?? []}
					series={PointMatrix ?? []}
					xTimestamps={xTimestamps ?? []}
					RetrieveRegimeCounter={RetrieveRegimeCounter ?? {}}
					yCategories={[
						"COMMUNICATION_SERVICES",
						"CONSUMER_DISCRETIONARY",
						"CONSUMER_STAPLES",
						"ENERGY",
						"FINANCIALS",
						"HEALTHCARE",
						"INDUSTRIALS",
						"INFORMATION_TECHNOLOGY",
						"MATERIALS",
						"UTILITIES",
					]}
					region={region}
					colorCategories={[
						{ name: t("REGIME_BREAKDOWN_BY_SECTOR.uncertain_legend"), color: "#FCFCFC" },
						{ name: t("REGIME_BREAKDOWN_BY_SECTOR.growth_legend"), color: "#94D0C4" },
						{ name: t("REGIME_BREAKDOWN_BY_SECTOR.lateral_phase_legend"), color: "#FFD966" },
						{ name: t("REGIME_BREAKDOWN_BY_SECTOR.high_stress_legend"), color: "#F28C79" },
					]}
				/>
			)}
			<RegimeDialog
				onClose={() => setShowUtility(false)}
				show={showUtility}
				timeSeries={timeSeries}
				sectors={SectorsBreakdown}
			/>
		</>
	);
};

function RegimeDialog(props: {
	show: boolean;
	onClose: () => void;
	timeSeries: Array<Option<string>>;
	sectors?: SectorsBreakDown;
}) {
	const [selectedTime, setSelectedTime] = useState("");
	const [regime, setRegime] = useState<any>();

	return (
		<Dialog
			header="Utility"
			show={props.show}
			size="large"
			onClose={props.onClose}
			footer={<DialogFooter primaryAction={<Button onClick={props.onClose}>Close</Button>} />}
		>
			<div className="max-h-[500px] overflow-auto">
				<Select
					value={selectedTime}
					options={props.timeSeries}
					enableSearch
					onChange={(time) => {
						if (time) {
							setSelectedTime(time);
							setRegime(getPeriod({ startDate: time }, props.sectors));
						}
					}}
					classList="mb-4 w-full"
				/>
				<code className="block whitespace-pre overflow-x-scroll">{JSON.stringify(regime, null, 4)}</code>
			</div>
		</Dialog>
	);
}

export default withContext(RegionContext)(RegimeBreakdownBySectorBlock);
