import { Trans, useTranslation } from "react-i18next";
import { pxToRem } from "$root/utils/pxToRem";
import { useQueryNoRefetch } from "$root/utils/react-query";
import Highcharts from "$root/utils/chart/highcharts-with-modules";
import HighchartsReact from "highcharts-react-official";
import type { Chart } from "highcharts";
import { forwardRef, useMemo } from "react";
import { useWidgetOptions } from "$root/widgets-architecture/layout/WidgetsMapper/context";
import { useApiGen } from "$root/api/hooks";
import type { RegimeBySector } from "$root/api/api-gen";
import { HmmControllerApiFactory } from "$root/api/api-gen";
import type { ContextContent } from "$root/utils/react-extra";
import { withContext } from "$root/utils/react-extra";
import { RegionContext } from "$root/widgets-architecture/contexts/region";
import { groupBy, groupMap } from "$root/utils/collections";
import Color from "color";
import { objectCoalesce } from "$root/utils/objects";
import styled from "styled-components";
import { IconWalls } from "$root/components/IconWall";

const phaseValueMap = {
	[-1]: "uncertain",
	1: "growth",
	2: "lateral",
	3: "highStress",
} as const;

type MarketRegimes = (typeof phaseValueMap)[keyof typeof phaseValueMap];

type HistogramChartProps = {
	series: {
		name: MarketRegimes;
		quantity: number;
	}[];
	groups: {
		uncertain: (keyof RegimeBySector)[];
		growth: (keyof RegimeBySector)[];
		lateral: (keyof RegimeBySector)[];
		highStress: (keyof RegimeBySector)[];
	};
};

const colorByPhase: Record<MarketRegimes, string> = {
	growth: "#4CB09C",
	lateral: "#FFBF00",
	highStress: "#FF1D23",
	uncertain: "#8C8EA8",
};

const LegendList = styled("div")<{ coloumcount: number }>(({ coloumcount }) => ({
	columnCount: coloumcount,
}));

const LegendItem = styled("span")<{ bulletcolor: string }>(({ bulletcolor }) => ({
	height: pxToRem(24),
	fontSize: pxToRem(11),
	display: "flex",
	alignItems: "center",
	"::before": {
		content: `"\u2022"`,
		color: bulletcolor,
		fontSize: pxToRem(40),
		marginRight: pxToRem(8),
	},
}));

const HistogramChart = forwardRef<{ chart: Chart }, HistogramChartProps>(function HistogramChartInner(
	{ series, groups },
	ref,
) {
	const { t } = useTranslation();

	const titleByPhase = useMemo(
		() =>
			Object.fromEntries(
				Object.values(phaseValueMap).map((phase) => [phase, t(`REGIME_COUNTER.OPTIONS.${phase}`)]),
			) as Record<MarketRegimes, string>,
		[t],
	);

	const countLegendaColumns = useMemo(() => {
		const legendaSum = series.reduce((acc, { name }) => {
			return (acc += groups[name].length);
		}, 0);
		return Math.round(legendaSum / 5);
	}, [series, groups]);

	const sortedLegendaRegime = useMemo(() => {
		return series.reduce((acc: Array<{ selectorRegime: keyof RegimeBySector; groupName: MarketRegimes }>, { name }) => {
			return [
				...acc,
				...groups[name].sort().map((regime: keyof RegimeBySector) => ({ selectorRegime: regime, groupName: name })),
			];
		}, []);
	}, [series, groups]);

	const countersOptions = useMemo(() => {
		return {
			chart: {
				type: "bar",
				style: {
					fontFamily: "Gotham,sans-serif",
				},
				height: 220,
			},
			credits: {
				enabled: false,
			},
			panning: false,
			title: { text: undefined },
			subtitle: { text: undefined },
			tooltip: {
				enabled: false,
			},
			legend: {
				enabled: false,
			},
			xAxis: {
				type: "category",
				categories: series.map(({ name }) => name),
				title: {
					text: "",
				},
				visible: true,
				labels: {
					enabled: true,
					align: "left",
					reserveSpace: true,
					useHTML: true,
					formatter: ({ value }: { value: MarketRegimes }) => {
						return `<span style="color: ${colorByPhase[value]}; font-weight: bold">${titleByPhase[value]}</span>`;
					},
				},
			},
			yAxis: {
				min: 0,
				title: {
					text: "",
				},
				visible: true,
				labels: { enabled: false },
				gridLineColor: Color("#D1D2DC").alpha(0.6).toString(),
				gridLineWidth: 2,
				tickLength: 0,
				tickInterval: 1,
				//gridZIndex: 4,
				gridLineDashStyle: "ShortDash",
			},
			plotOptions: {
				bar: {
					pointWidth: undefined,
					borderWidth: 0,
					dataLabels: {
						enabled: true,
						color: "#616161",
					},
					colors: series.map(({ name }) => Color(colorByPhase[name]).alpha(0.5).toString()),
				},
				series: {
					groupPadding: 0,
					pointPadding: 0.2,
				},
			},
			series: [
				{
					colorByPoint: true,
					data: series.map(({ quantity }) => quantity),
				},
			],
			exporting: {
				enabled: false,
			},
		};
	}, [series, titleByPhase]);

	return (
		<div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
			<div
				style={{
					borderRadius: 4,
					borderColor: "#eeeef1",
					borderWidth: 1,
					borderStyle: "solid",
					flex: 1,
					marginBottom: pxToRem(12),
				}}
			>
				<HighchartsReact
					containerProps={{ style: { height: "100%" } }}
					highcharts={Highcharts}
					constructorType="chart"
					options={countersOptions}
					ref={ref as any}
				/>
			</div>
			<div className="flex-1">
				<LegendList coloumcount={countLegendaColumns}>
					{sortedLegendaRegime.map(({ groupName, selectorRegime }) => (
						<LegendItem key={selectorRegime} className={groupName} bulletcolor={colorByPhase[groupName]}>
							{t(`REGIME_COUNTER.SECTORS.${selectorRegime}`)}
						</LegendItem>
					))}
				</LegendList>
			</div>
		</div>
	);
});

const CurrentRegimeCounterBlock = (props: ContextContent<typeof RegionContext>) => {
	const { t } = useTranslation();
	const region = props.region;

	const hmmApi = useApiGen(HmmControllerApiFactory);

	const {
		isLoading,
		isError,
		isFetching,
		data: hmmRegimeData,
	} = useQueryNoRefetch(["queryHmmRegimeCounter", region], {
		queryFn: async () => {
			const { data } = await hmmApi.retrieveSectorsWithRegime(region);

			const sectorsByGroup = groupMap(
				objectCoalesce(
					Object.values(phaseValueMap),
					groupBy(Object.entries(data) as [keyof RegimeBySector, -1 | 1 | 2 | 3][], ([, phase]) => {
						return phaseValueMap[phase];
					}),
					() => [],
				),
				([sector]) => sector,
			);

			return {
				phaseCounters: (Object.entries(sectorsByGroup) as [MarketRegimes, (keyof RegimeBySector)[]][]).map(
					([group, sectors]) => ({
						name: group,
						quantity: sectors.length,
					}),
				),
				sectorsByGroup,
			};
		},
	});

	useWidgetOptions(
		() => ({
			title: t("REGIME_COUNTER.TITLE"),
			isDraggable: false,
			subTitle: <Trans i18nKey="REGIME_COUNTER.DESCRIPTION" />,
			actionHeader: <></>,
		}),
		[t],
	);

	return (
		<>
			{isError ? (
				<IconWalls.ErrorData />
			) : isLoading || isFetching ? (
				<IconWalls.LoadingData />
			) : (
				<>
					<div style={{ marginTop: pxToRem(12), height: "100%", display: "flex", flexDirection: "column" }}>
						<div style={{ flex: 1 }}>
							<div style={{ height: "100%" }}>
								{/* <div style={{ height: "100%", background: "yellow" }}></div> */}
								<HistogramChart series={hmmRegimeData.phaseCounters} groups={hmmRegimeData.sectorsByGroup} />
							</div>
						</div>
						{/* <div style={{ flex: 1, paddingTop: "1rem" }}>
							<Grid container spacing={2}>
								<Grid xs={6} item>
									<Section hmmRegimeData={hmmRegimeData} type="growth" />
									<Section hmmRegimeData={hmmRegimeData} type="highStress" />
								</Grid>
								<Grid xs={6} item>
									<Section hmmRegimeData={hmmRegimeData} type="lateral" />
									<Section hmmRegimeData={hmmRegimeData} type="uncertain" />
								</Grid>
							</Grid>
						</div> */}
					</div>
				</>
			)}
		</>
	);
};

export default withContext(RegionContext)(CurrentRegimeCounterBlock);
