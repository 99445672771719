import { stripUndefined } from "$root/utils/objects";
import { useUnsafeUpdatedRef } from "@mdotm/mdotui/react-extensions";
import type { CSSProperties } from "react";
import { useRef, useEffect, useCallback, useContext } from "react";
import { TransientNotificationContext } from "./components/slots";
import type { SnackMessageProps } from "./components/snack";

/**
 * One of the possible variants for the message.
 * - "success" should be used for dismissible, informative message that will automatically hide (e.g. entity successfully created);
 * - "info" should be used for dismissible, persistent message (e.g. general information about the current displayed content);
 * - "warning" should be used for non-dismissible, blocking message that will persist until the user takes an action
 * to solve a specific issue (e.g. request to confirm email);
 * - "error" should be used for non-dismissible feedback message about an action the user performed but that resulted
 * in an error (e.g. failed login).
 */
export type TransientNotificationVariant = "success" | "warning" | "error" | "info";

export type UpdateTransientNotificationHandlesParams = {
	/**
	 * One of the possible variants for the message.
	 * - "success" should be used for dismissible, informative message that will automatically hide (e.g. entity successfully created);
	 * - "info" should be used for dismissible, persistent message (e.g. general information about the current displayed content);
	 * - "warning" should be used for non-dismissible, blocking message that will persist until the user takes an action
	 * to solve a specific issue (e.g. request to confirm email);
	 * - "error" should be used for non-dismissible feedback message about an action the user performed but that resulted
	 * in an error (e.g. failed login).
	 */
	variant: TransientNotificationVariant;
	/**
	 * Message content, either direct or through a render prop that will receive a dismiss callback.
	 */
	children: React.ReactNode | ((dismiss: () => void) => React.ReactNode);
	/**
	 * Additional styles to forward to the notification component.
	 */
	style?: CSSProperties;
	/**
	 * Additional classes to forward to the notification component.
	 */
	className?: string;
	/**
	 * Whether the notification should present an "X" to let the user close it.
	 * @default true
	 */
	dismissible?: boolean;
	/**
	 * A react node or a render prop that determines the content on the right
	 * of the notification, used to show buttons or other interactive elements.
	 */
	action?: React.ReactNode | ((dismiss: () => void) => React.ReactNode);
	onClose?(): void;
};

export type ShowTransientNotificationHandlesParams = UpdateTransientNotificationHandlesParams & {
	/**
	 * If set, automatically hide the message after the specified duration (in milliseconds), otherwise
	 * the message will persist until dismissed by the user or programmatically.
	 * @default {after: 5000}
	 */
	autoHide?: { after: number } | null;
	/**
	 * Customize the way a notification is shown in the app:
	 * - `"overlay"` will hide content behind the notification, without occupying space in the document;
	 * - `"in-page"` will not hide content behind the notification, taking occupying in the document.
	 *
	 * @default "overlay"
	 */
	location?: "overlay" | "in-page";
};

export type UseTransientNotificationParams = ShowTransientNotificationHandlesParams & {
	/**
	 * If true and the trigger is also true on the first render cycle, show the notification.
	 * If false, the initial trigger value is saved, which means no rising nor falling
	 * edge is detected.
	 *
	 * @default true
	 */
	pullDown?: boolean;
	/**
	 * Like an electronic signal, when this property goes from
	 * false to true (rising edge) or from true to false (falling edge)
	 * the message is shown or hidden.
	 *
	 * For reference, a rising edge followed by a falling edge:
	 * ```
	 *   ┌──┐
	 * ──┘  ╵
	 * ```
	 */
	trigger: boolean;
	/**
	 * Whether to automatically close the notification when the components that uses this hook is unmounted.
	 * This option cannot be changed after the first render cycle.
	 * @default true
	 */
	closeOnUnmount?: boolean;
};

export type TransientNotificationData = {
	props: SnackMessageProps;
	location: "in-page" | "overlay";
	animationStatus: "showing" | "hiding";
	onClose?(): void;
};

export const animationDuration = 350;

export type TransientNotificationHandles = {
	/**
	 * Display a (new) notification.
	 *
	 *  @param params {@link ShowTransientNotificationHandlesParams}.
	 * @return the notification ID that can then be used to close or update the notification.
	 */
	showNotification(params: ShowTransientNotificationHandlesParams): number;
	/**
	 * Close a notification.
	 *
	 * @param id the ID returned by {@link TransientNotificationHandles.showNotification}.
	 */
	closeNotification(id: number): void;
	/**
	 * Update the notification content.
	 *
	 * @param id the ID returned by {@link TransientNotificationHandles.showNotification}
	 * @param params {@link UpdateTransientNotificationHandlesParams}.
	 */
	updateNotification(id: number, params: UpdateTransientNotificationHandlesParams): void;
};

/**
 * Use this hook to imperatively control a notification using methods such as show, close, and update.
 */
export function useTransientNotificationHandles(): TransientNotificationHandles {
	const ctx = useContext(TransientNotificationContext);

	const hideAndRemove = useCallback(
		(id: number) => {
			if (!ctx.io) {
				return;
			}
			const { getNotifications, setNotifications } = ctx.io;
			const notificationCollection = getNotifications();
			const notificationToDismiss = notificationCollection.notifications[id];
			if (!notificationToDismiss || notificationToDismiss.animationStatus === "hiding") {
				return;
			}
			setNotifications({
				...notificationCollection,
				notifications: {
					...notificationCollection.notifications,
					[id]: {
						...notificationToDismiss,
						animationStatus: "hiding",
					},
				},
			});
			setTimeout(() => {
				const delayedNotificationCollection = getNotifications();
				setNotifications(
					stripUndefined({
						...delayedNotificationCollection,
						notifications: { ...delayedNotificationCollection.notifications, [id]: undefined },
					}),
				);
				notificationToDismiss.onClose?.();
			}, animationDuration);
		},
		[ctx],
	);

	const showNotification = useCallback(
		(params: ShowTransientNotificationHandlesParams) => {
			if (!ctx.io) {
				return -1;
			}
			const { getNotifications, setNotifications } = ctx.io;
			const previousNotifications = getNotifications();
			const currentId = previousNotifications.lastId + 1;
			const lastId = currentId;
			if (params.autoHide !== null) {
				setTimeout(() => hideAndRemove(currentId), (params.autoHide ?? { after: 5000 }).after);
			}
			setNotifications({
				lastId,
				notifications: {
					...previousNotifications.notifications,
					[currentId]: {
						props: {
							children: params.children,
							dismiss: () => hideAndRemove(currentId),
							variant: params.variant,
							action: params.action,
							className: params.className,
							dismissible: params.dismissible,
							style: params.style,
						},
						location: params.location ?? "overlay",
						animationStatus: "showing",
						onClose: params.onClose,
					},
				},
			});
			return currentId;
		},
		[ctx, hideAndRemove],
	);
	const updateNotification = useCallback(
		(id: number, params: UpdateTransientNotificationHandlesParams) => {
			if (!ctx.io) {
				return;
			}
			const { getNotifications, setNotifications } = ctx.io;
			const previousNotifications = getNotifications();
			const notificationToUpdate = previousNotifications.notifications[id];
			if (!notificationToUpdate) {
				return;
			}
			setNotifications({
				...previousNotifications,
				notifications: {
					...previousNotifications.notifications,
					[id]: {
						...notificationToUpdate,
						props: {
							...notificationToUpdate.props,
							children: params.children,
							dismiss: () => hideAndRemove(id),
							variant: params.variant,
							action: params.action,
							className: params.className,
							dismissible: params.dismissible,
							style: params.style,
						},
						onClose: params.onClose,
					},
				},
			});
		},
		[ctx, hideAndRemove],
	);

	return { showNotification, closeNotification: hideAndRemove, updateNotification };
}

/**
 * Render a transient notification using a snackbar UI element.
 * The notification will appear once the specified `trigger` property
 * goes from `false` to `true` and hidden when it goes from `true` to `false`.
 *
 * If you want to skip the first notification and show it only when the trigger wait for the next falling and rising
 * edge, you can set `pullDown` to false.
 * See {@link UseTransientNotificationParams.pullDown} for more details.
 *
 * @param params {@link UseTransientNotificationParams}
 */
export function useTransientNotification(params: UseTransientNotificationParams): void {
	const previousTriggerStateRef = useRef(params.pullDown ?? true ? false : params.trigger);
	const ctx = useContext(TransientNotificationContext);
	const { showNotification, closeNotification, updateNotification } = useTransientNotificationHandles();

	const idRef = useRef<number | null>(null);

	const paramsRef = useUnsafeUpdatedRef(params);
	useEffect(() => {
		if (!ctx.io) {
			return;
		}
		if (!previousTriggerStateRef.current && params.trigger) {
			idRef.current = showNotification(paramsRef.current);
		} else if (previousTriggerStateRef.current && !params.trigger && idRef.current !== null) {
			closeNotification(idRef.current);
			idRef.current = null;
		}
		previousTriggerStateRef.current = params.trigger;
	}, [params.trigger, closeNotification, showNotification, paramsRef, ctx.io]);

	const updateRef = useUnsafeUpdatedRef(updateNotification);
	useEffect(() => {
		if (idRef.current !== null) {
			updateRef.current(idRef.current, params);
		}
	}, [params, updateRef]);

	const hideAndRemoveRef = useUnsafeUpdatedRef(closeNotification);
	useEffect(() => {
		return () => {
			// eslint-disable-next-line react-hooks/exhaustive-deps
			if ((paramsRef.current.closeOnUnmount ?? true) && idRef.current !== null) {
				// eslint-disable-next-line react-hooks/exhaustive-deps
				hideAndRemoveRef.current(idRef.current);
			}
		};
	}, [hideAndRemoveRef, paramsRef]);
}
