import { FormController } from "$root/third-party-integrations/react-hook-form";
import { valueByPath } from "$root/utils/objects";
import type { StylableProps, CheckboxProps } from "@mdotm/mdotui/components";
import { Checkbox, FormField } from "@mdotm/mdotui/components";
import type { ReactNode } from "react";
import type { Control, FieldValues, FormState, Path } from "react-hook-form";

export type CheckboxFieldProps<T extends FieldValues> = StylableProps & {
	control: Control<T, any>;
	formState: FormState<T>;
	name: Path<T>;
	label?: ReactNode;
	children?: ReactNode;
} & Omit<CheckboxProps, "checked">;

export function CheckboxField<T extends FieldValues>({
	label,
	name,
	formState,
	control,
	classList,
	style,
	children,
	...forward
}: CheckboxFieldProps<T>): JSX.Element {
	return (
		<FormField
			classList={classList}
			style={style}
			label={label}
			error={(valueByPath(formState.errors, name) as { message?: string })?.message}
		>
			{(fieldProps) => (
				<FormController
					control={control}
					name={name}
					render={({ field: { ref, value, onChange, ...controllerProps } }) => (
						<Checkbox
							innerRef={ref}
							onChange={onChange}
							id={fieldProps.id}
							{...controllerProps}
							{...forward}
							// switchType="box"
							checked={value}
						>
							{children}
						</Checkbox>
					)}
				/>
			)}
		</FormField>
	);
}
