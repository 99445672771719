import { NullableNumberField } from "./NullableNumberField";
import { PasswordField } from "./PasswordField";
import { SelectField } from "./SelectField";
import { TextField } from "./TextField";
import { AutocompleteField } from "./AutocompleteField";
import { CheckboxField } from "./CheckboxField";
import { TextAreaField } from "./TextAreaField";
import { CustomField } from "./CustomField";
import { PercentageField } from "./PercentageField";

export const FormFields = {
	NullableNumber: NullableNumberField,
	Percentage: PercentageField,
	Password: PasswordField,
	Select: SelectField,
	Text: TextField,
	Autocomplete: AutocompleteField,
	Checkbox: CheckboxField,
	TextArea: TextAreaField,
	Custom: CustomField,
};
