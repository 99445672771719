import type { ZodNullable, ZodNumber, ZodOptional, ZodString, ZodType } from "zod";
import { z } from "zod";
import type { WithTranslation } from "react-i18next";

const messages = {
	minMaxPercentage: "Select a value between 0 and 100",
};
export const zExt = {
	percentage: (): ZodNumber =>
		z
			.number({ invalid_type_error: messages.minMaxPercentage })
			.min(0, { message: messages.minMaxPercentage })
			.max(100, { message: messages.minMaxPercentage }),
	maybe<T extends ZodType>(x: T): ZodNullable<ZodOptional<T>> {
		return x.optional().nullable();
	},
	nonEmptyString(t: WithTranslation["t"], minLength = 1): ZodString {
		return z.string({ required_error: t("REQUIRED_FIELD") }).min(minLength, t("REQUIRED_FIELD"));
	},
};
