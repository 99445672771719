import type {
	AssetClass,
	ConstrainedTicker,
	ConstraintRelation,
	ConstraintValidity,
	Currencies,
	InvestableUniverseSaveRequest,
	InvestableUniverseSelectionStrategy,
	InvestmentBenchmark,
	InvestmentBenchmarkDTO,
	InvestmentReferenceDTO,
	MainInfoSaveRequest,
	MainInfoSaveRequestMandateTypeEnum,
	MarketScenario,
	PartialInvestableUniverseTicker,
	RichTicker,
	RiskConstraintType,
	TargetVolatility,
} from "$root/api/api-gen";
import type { CommonStepProps, StepMetadata } from "$root/components/MultiStepFormV2/MultiStepFormV2";
import { defaultInitialStepMetadata } from "$root/components/MultiStepFormV2/utils";
import type { MutableRefObject } from "react";
import { type BaseSyntheticEvent, useEffect } from "react";
import type { EditPortfolioAreaType, MultiStepPortfolioContext } from "./EditPortfolio";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import type { SetterOrUpdater } from "$root/utils/functions";

export const editPortfolioV4OrderedSteps = [
	"mainInfo",
	"investableUniverse",
	"portfolioConstraints",
	"riskConstraints",
	"portfolioStrategy",
	"marketView",
] as const;
export type EditPortfolioV4StepName = (typeof editPortfolioV4OrderedSteps)[number];
type customTargetVolatility = Omit<TargetVolatility, "selectedValue"> & { selectedValue?: number | null };
export const defaultEditPortfolioV4StepData = {
	mainInfo: {
		baseCurrency: "EUR" as Currencies,
		name: "",
		primaryBenchmark: undefined as undefined | InvestmentBenchmarkDTO,
		mandateType: undefined as undefined | MainInfoSaveRequestMandateTypeEnum,
		investmentUuid: undefined as undefined | string,
		investmentReference: undefined as undefined | InvestmentReferenceDTO,
		targetVolatility: undefined as undefined | customTargetVolatility,
	} satisfies Omit<MainInfoSaveRequest, "targetVolatility"> & {
		targetVolatility?: customTargetVolatility;
	},
	investableUniverse: {
		investableUniverseSelectionStrategy: undefined as InvestableUniverseSelectionStrategy | undefined,
		universeIdentifier: undefined as string | undefined,
		universeName: "",
		universeComposition: [] as PartialInvestableUniverseTicker[],
	} satisfies Omit<InvestableUniverseSaveRequest | PartialInvestableUniverseTicker[], "investmentUuid">,
	portfolioConstraints: { list: [] as EditablePortfolioConstraint[] },
	riskConstraints: { list: [] as EditableRiskConstraint[] },
	portfolioStrategy: { list: [] as EditablePortfolioStrategy[] },
	marketView: {
		scenarioIdentifier: undefined as string | undefined,
		scenarioData: undefined as MarketScenario | undefined,
		custom: undefined as boolean | undefined,
		name: undefined as string | undefined,
	},
} satisfies Record<EditPortfolioV4StepName, unknown>;

export const editPortfolioV4InitialStepsMetadataByMode: Record<
	EditPortfolioAreaType,
	Record<EditPortfolioV4StepName, StepMetadata>
> = {
	create: {
		mainInfo: {
			...defaultInitialStepMetadata(),
			name: "Main info",
			optional: false,
		},
		investableUniverse: {
			...defaultInitialStepMetadata(),
			name: "Investable Universe",
			optional: false,
		},
		portfolioConstraints: {
			...defaultInitialStepMetadata(),
			name: "Portfolio Constraints",
		},
		riskConstraints: {
			...defaultInitialStepMetadata(),
			name: "Risk Constraints",
		},
		portfolioStrategy: {
			...defaultInitialStepMetadata(),
			name: "Operative Constraints",
		},
		marketView: {
			...defaultInitialStepMetadata(),
			name: "Market View",
			optional: false,
		},
	},
	enhance: {
		mainInfo: {
			...defaultInitialStepMetadata(),
			name: "Main info",
			visible: true,
		},
		investableUniverse: {
			...defaultInitialStepMetadata(),
			name: "Investable Universe",
			optional: false,
		},
		portfolioConstraints: {
			...defaultInitialStepMetadata(),
			name: "Portfolio Constraints",
		},
		riskConstraints: {
			...defaultInitialStepMetadata(),
			name: "Risk Constraints",
		},
		portfolioStrategy: {
			...defaultInitialStepMetadata(),
			name: "Operative Constraints",
		},
		marketView: {
			...defaultInitialStepMetadata(),
			name: "Market View",
			optional: false,
		},
	},
	"settings-current": {
		mainInfo: {
			...defaultInitialStepMetadata(),
			name: "Main info",
		},
		investableUniverse: {
			...defaultInitialStepMetadata(),
			name: "Investable Universe",
		},
		portfolioConstraints: {
			...defaultInitialStepMetadata(),
			name: "Portfolio Constraints",
		},
		riskConstraints: {
			...defaultInitialStepMetadata(),
			name: "Risk Constraints",
		},
		portfolioStrategy: {
			...defaultInitialStepMetadata(),
			name: "Operative Constraints",
		},
		marketView: {
			...defaultInitialStepMetadata(),
			name: "Market View",
		},
	},
	"settings-enhanced": {
		mainInfo: {
			...defaultInitialStepMetadata(),
			name: "Main info",
		},
		investableUniverse: {
			...defaultInitialStepMetadata(),
			name: "Investable Universe",
		},
		portfolioConstraints: {
			...defaultInitialStepMetadata(),
			name: "Portfolio Constraints",
		},
		riskConstraints: {
			...defaultInitialStepMetadata(),
			name: "Risk Constraints",
		},
		portfolioStrategy: {
			...defaultInitialStepMetadata(),
			name: "Operative Constraints",
		},
		marketView: {
			...defaultInitialStepMetadata(),
			name: "Market View",
		},
	},
	draft: {
		mainInfo: {
			...defaultInitialStepMetadata(),
			name: "Main info",
			optional: false,
		},
		investableUniverse: {
			...defaultInitialStepMetadata(),
			name: "Investable Universe",
			optional: false,
		},
		portfolioConstraints: {
			...defaultInitialStepMetadata(),
			name: "Portfolio Constraints",
		},
		riskConstraints: {
			...defaultInitialStepMetadata(),
			name: "Risk Constraints",
		},
		portfolioStrategy: {
			...defaultInitialStepMetadata(),
			name: "Operative Constraints",
		},
		marketView: {
			...defaultInitialStepMetadata(),
			name: "Market View",
			optional: false,
		},
	},
};

export type EditPortfolioV4StepPayloadMap = {
	[K in keyof typeof defaultEditPortfolioV4StepData]: (typeof defaultEditPortfolioV4StepData)[K];
};

export const enumInstrumentWithouthScorePolicy = {
	Exclude: "EXCLUDE",
	IncludeWithZero: "INCLUDE_WITH_ZERO_AS_SCORE",
} as const;

export type InstrumentWithouthScorePolicy =
	(typeof enumInstrumentWithouthScorePolicy)[keyof typeof enumInstrumentWithouthScorePolicy];

export type CustomConstrainedTicker = ConstrainedTicker & { deleted?: boolean };
// #region PortfolioConstraints
export type PortfolioConstraintTypePayloadMap = {
	assetClassConstraints: {
		minWeight: number | null;
		maxWeight: number | null;
		identifier: string | null;
		relation: ConstraintRelation;
		assetClassesIdentifiers: Array<AssetClass>;
		target: boolean;
		suggestedMaxWeight: number | null;
		suggestedMinWeight: number | null;
		validity: ConstraintValidity;
	};
	instrumentConstraints: {
		identifier: string | null;
		tickerIdentifiers: Array<CustomConstrainedTicker>; //TODO: add delete property
		target: boolean;
		relation: ConstraintRelation;
		minWeight: number | null;
		maxWeight: number | null;
		suggestedMaxWeight: number | null;
		suggestedMinWeight: number | null;
		validity: ConstraintValidity;
	};
	currencyConstraints: {
		identifier: string | null;
		currencies: Array<Currencies>;
		target: boolean;
		relation: ConstraintRelation;
		minWeight: number | null;
		maxWeight: number | null;
		suggestedMaxWeight: number | null;
		suggestedMinWeight: number | null;
		validity: ConstraintValidity;
	};
	tagConstraints: {
		identifier: string | null;
		tagLabel: string;
		minWeight: number | null;
		maxWeight: number | null;
		target: boolean;
		relation: ConstraintRelation;
		tagIdentifiers: Array<string>;
		suggestedMaxWeight: number | null;
		suggestedMinWeight: number | null;
		validity: ConstraintValidity;
	};
	instrumentMaxWeightConstraint: {
		identifier: string | null;
		target: boolean;
		weight: number | null;
		suggestedMaxWeight: number | null;
		suggestedMinWeight: number | null;
		validity: ConstraintValidity;
	};
	scoresConstraint: {
		identifier: string | null;
		scoreIdentifiers: string[] | null;
		target: boolean;
		instrumentWithouthScorePolicy: InstrumentWithouthScorePolicy;
		relation: ConstraintRelation;
		minWeight: number | null;
		maxWeight: number | null;
		suggestedMaxWeight: number | null;
		suggestedMinWeight: number | null;
		validity: ConstraintValidity;
	};
	lockInstrumentGroup: {
		identifier: string | null;
		target: boolean;
		tickerIdentifiers: Array<CustomConstrainedTicker>;
		relation: ConstraintRelation;
		validity: ConstraintValidity;
	};
	forEachConstraint: {
		identifier: string | null;
		tickerIdentifiers: Array<CustomConstrainedTicker>;
		target: boolean;
		relation: ConstraintRelation;
		minWeight: number | null;
		maxWeight: number | null;
		suggestedMaxWeight?: number | null;
		suggestedMinWeight?: number | null;
		validity: ConstraintValidity;
	};
};

export type CommonConstraintData = { id: string; readonly: boolean };

export type EditableConstraintHelper<
	ConstraintTypePayloadMap extends Record<string, unknown>,
	K extends keyof ConstraintTypePayloadMap,
	ExtraConstraintData extends Record<string, unknown> | void = void,
> = ExtraConstraintData extends void
	? {
			[KNested in keyof ConstraintTypePayloadMap]: CommonConstraintData & {
				type: KNested;
			} & ConstraintTypePayloadMap[KNested];
	  }[K]
	: {
			[KNested in keyof ConstraintTypePayloadMap]: CommonConstraintData &
				ExtraConstraintData & {
					type: KNested;
				} & ConstraintTypePayloadMap[KNested];
	  }[K];

export type EditablePortfolioConstraintHelper<K extends keyof PortfolioConstraintTypePayloadMap> =
	EditableConstraintHelper<PortfolioConstraintTypePayloadMap, K, { id: string }>;

export type EditablePortfolioConstraint = EditableConstraintHelper<
	PortfolioConstraintTypePayloadMap,
	keyof PortfolioConstraintTypePayloadMap
>;
// #endregion

// #region RiskConstraints
export type RiskConstraintTypePayloadMap = {
	var: {
		subType:
			| typeof RiskConstraintType.HistoricalVar951Y
			| typeof RiskConstraintType.HistoricalVar952Y
			| typeof RiskConstraintType.HistoricalVar953Y
			| typeof RiskConstraintType.ParametricVar951Y
			| typeof RiskConstraintType.ParametricVar952Y
			| typeof RiskConstraintType.ParametricVar953Y
			| typeof RiskConstraintType.HistoricalVar9751Y
			| typeof RiskConstraintType.HistoricalVar9752Y
			| typeof RiskConstraintType.HistoricalVar9753Y
			| typeof RiskConstraintType.ParametricVar9751Y
			| typeof RiskConstraintType.ParametricVar9752Y
			| typeof RiskConstraintType.ParametricVar9753Y
			| typeof RiskConstraintType.HistoricalVar991Y
			| typeof RiskConstraintType.HistoricalVar992Y
			| typeof RiskConstraintType.HistoricalVar993Y
			| typeof RiskConstraintType.ParametricVar991Y
			| typeof RiskConstraintType.ParametricVar992Y
			| typeof RiskConstraintType.ParametricVar993Y
			| null;
		suggestedMinValue: number | null;
		suggestedMaxValue: number | null;
		suggestedValue: number | null;
		value: number | null;
		target: boolean;
		validity: ConstraintValidity;
	};
	volatility: {
		subType:
			| typeof RiskConstraintType.Volatility6M
			| typeof RiskConstraintType.Volatility1Y
			| typeof RiskConstraintType.Volatility3Y
			| typeof RiskConstraintType.Volatility2Y
			| null;
		value: number | null;
		suggestedMinValue: number | null;
		suggestedMaxValue: number | null;
		suggestedValue: number | null;
		target: boolean;
		validity: ConstraintValidity;
	};
	maxTrackingError: {
		investmentReference: InvestmentReferenceDTO | null;
		value: number | null;
		validity: ConstraintValidity;
		suggestedMinValue: number | null;
		suggestedMaxValue: number | null;
		suggestedValue: number | null;
		target: boolean;
	};
};

export type EditableRiskConstraintHelper<K extends keyof RiskConstraintTypePayloadMap> = EditableConstraintHelper<
	RiskConstraintTypePayloadMap,
	K
>;

export type EditableRiskConstraint = EditableConstraintHelper<
	RiskConstraintTypePayloadMap,
	keyof RiskConstraintTypePayloadMap
>;
// #endregion

// #region PortfolioStrategy
export type PortfolioStrategyTypePayloadMap = {
	targetMaxTurnover: {
		suggestedMinValue: number | null;
		suggestedMaxValue: number | null;
		suggestedValue: number | null;
		selectedValue: number | null;
		validity: ConstraintValidity;
	};
	targetMinOperationWeight: {
		suggestedMinValue: number | null;
		suggestedMaxValue: number | null;
		suggestedValue: number | null;
		selectedValue: number | null;
		validity: ConstraintValidity;
	};
	targetTransactionCostsInBps: {
		suggestedMinValue: number | null;
		suggestedMaxValue: number | null;
		suggestedValue: number | null;
		selectedValue: number | null;
		validity: ConstraintValidity;
	};
	minNumberOfInstruments: {
		suggestedMinValue: number | null;
		suggestedMaxValue: number | null;
		suggestedValue: number | null;
		selectedValue: number | null;
		validity: ConstraintValidity;
	};
	maxNumberOfInstruments: {
		suggestedMinValue: number | null;
		suggestedMaxValue: number | null;
		suggestedValue: number | null;
		selectedValue: number | null;
		validity: ConstraintValidity;
	};
	instrumentMinWeight: {
		suggestedMinValue: number | null;
		suggestedMaxValue: number | null;
		suggestedValue: number | null;
		selectedValue: number | null;
		validity: ConstraintValidity;
	};
	instrumentRoundingWeight: {
		suggestedMinValue: number | null;
		suggestedMaxValue: number | null;
		suggestedValue: number | null;
		selectedValue: number | null;
		validity: ConstraintValidity;
	}
};

export type EditablePortfolioStrategyHelper<K extends keyof PortfolioStrategyTypePayloadMap> = EditableConstraintHelper<
	PortfolioStrategyTypePayloadMap,
	K
>;

export type EditablePortfolioStrategy = EditableConstraintHelper<
	PortfolioStrategyTypePayloadMap,
	keyof PortfolioStrategyTypePayloadMap
>;
// #endregion

export type StepPropsFor<T extends EditPortfolioV4StepName> = CommonStepProps<
	typeof defaultEditPortfolioV4StepData,
	T,
	MultiStepPortfolioContext
>;

function handleSubmitToCustomSubmitHandler<T>({
	baseSubmitFn,
	handleSubmit,
	onInvalid,
}: {
	baseSubmitFn: (values: T) => MaybePromise<void>;
	onInvalid?: (err: any) => void;
	handleSubmit: (
		baseSubmitFn: (values: T) => MaybePromise<void>,
		onInvalid: (err: any) => void,
	) => (e?: BaseSyntheticEvent) => Promise<void>;
}): (e?: BaseSyntheticEvent) => Promise<void> {
	return (e) =>
		new Promise<void>((resolve, reject) => {
			handleSubmit(baseSubmitFn, (errors) => {
				onInvalid?.(errors);
				reject(errors);
			})(e).then(resolve, reject);
		});
}

export function useHandleSubmitToCustomSubmitHandlerInContext<T>({
	baseSubmitFn,
	handleSubmit,
	onInvalid,
	stepName,
	context,
}: {
	baseSubmitFn: (values: T) => MaybePromise<void>;
	onInvalid?: (err: any) => void;
	handleSubmit: (
		baseSubmitFn: (values: T) => MaybePromise<void>,
		onInvalid: (err: any) => void,
	) => (e?: BaseSyntheticEvent) => Promise<void>;
	stepName: EditPortfolioV4StepName;
	context: MultiStepPortfolioContext;
}): (e?: BaseSyntheticEvent) => Promise<void> {
	const onSubmitAsync = handleSubmitToCustomSubmitHandler({ baseSubmitFn, handleSubmit, onInvalid });
	useEffect(() => {
		context.submittersRef.current[stepName] = onSubmitAsync;
	});
	return onSubmitAsync;
}

export function makeUnsavedDataHandler<TStepNames extends string>(params: {
	submittersRef: MutableRefObject<Partial<Record<TStepNames, () => Promise<void>>>>;
	currentStep: TStepNames;
	setStepsMetadata: SetterOrUpdater<Record<TStepNames, StepMetadata>>;
}): () => Promise<"stay" | "continue"> {
	return async () => {
		if (params.submittersRef.current[params.currentStep]) {
			params.setStepsMetadata((prev) => ({
				...prev,
				[params.currentStep]: {
					...prev[params.currentStep],
					// TODO: test for race conditions
					persisting: true,
					completed: false, // should be set by the following await during regular response processing
				} satisfies StepMetadata,
			}));
			try {
				await params.submittersRef.current[params.currentStep]!();
				params.setStepsMetadata((prev) => ({
					...prev,
					[params.currentStep]: {
						...prev[params.currentStep],
						// TODO: test for race conditions
						persisting: false,
					} satisfies StepMetadata,
				}));
			} catch (err) {
				params.setStepsMetadata((prev) => ({
					...prev,
					[params.currentStep]: {
						...prev[params.currentStep],
						// TODO: test for race conditions
						persisting: false,
						completed: false, // if the validation failed, the completed flag will definitely be false
					} satisfies StepMetadata,
				}));
				console.warn("could not save the current step", err);
				return "stay";
			}
		} else {
			console.warn("no submitter found for the current step", { currentStep: params.currentStep });
		}
		return "continue" as const;
	};
}
