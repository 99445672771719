import { useTranslation } from "react-i18next";
import type { FormatDate, FormatTime, FormatNumber, ParseDate } from "$root/localization/formatters";
import {
	formatDate as unhookedFormatDate,
	formatDateTime as unhookedFormatDateTime,
	formatTime as unhookedFormatTime,
	formatNumber as unhookedFormatNumber,
	parseDate as unhookedParseDate,
} from "$root/localization/formatters";
import { useMemo } from "react";

export function useLocaleFormatters(): {
	locale: string;
	parseDate: ParseDate;
	formatDate: FormatDate;
	formatTime: FormatTime;
	formatDateTime: FormatDate;
	formatNumber: FormatNumber;
} {
	const {
		i18n: { language },
	} = useTranslation();
	const formatters = useMemo(() => {
		const parseDate: ParseDate = (localizedStr, locale) => {
			return unhookedParseDate(localizedStr, locale ?? language);
		};
		const formatDate: FormatDate = (date, opts) => {
			return unhookedFormatDate(date, { locale: language, ...opts });
		};

		const formatTime: FormatTime = (date, opts) => {
			return unhookedFormatTime(date, { locale: language, ...opts });
		};

		const formatDateTime: FormatDate = (date, opts) => {
			return unhookedFormatDateTime(date, { locale: language, ...opts });
		};

		const formatNumber: FormatNumber = (n, decimalPlacesOrOptions) => {
			if (typeof decimalPlacesOrOptions === "number") {
				return unhookedFormatNumber(n, {
					locale: language,
					minDecimalPlaces: decimalPlacesOrOptions,
					maxDecimalPlaces: decimalPlacesOrOptions,
				});
			}
			return unhookedFormatNumber(n, { locale: language, ...decimalPlacesOrOptions });
		};
		return { locale: language, parseDate, formatDate, formatTime, formatDateTime, formatNumber };
	}, [language]);

	return formatters;
}
