import type { PortfolioMetric, PortfolioMetricPreference, PortfolioMetricTypeEnum } from "$root/api/api-gen";
import { InvestmentReportsControllerApiFactory, PortfolioStudioPreferencesApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { axiosExtract } from "$root/third-party-integrations/axios";
import type { ContextContent } from "$root/utils/react-extra";
import { withContext } from "$root/utils/react-extra";
import { PortfolioContext } from "$root/widgets-architecture/contexts/portfolio";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { PortfolioMetricsBase } from "../PortfolioMetrics/PortfolioMetricsBase";
import { WidgetStatus, portfolioWidgetMissingDataReason } from "$root/pages/PortfolioDetails/PortfolioWidgetStatus";
import { Text } from "@mdotm/mdotui/components";
import { qualifier } from "$root/utils/qualifiers";

const PerformanceMetricsBlock = (props: ContextContent<typeof PortfolioContext>) => {
	const uuid = props.portfolio?.uuid;
	const benchmarkId = props.selectedBenchmark;
	const { t } = useTranslation();
	const reportInvestmentApi = useApiGen(InvestmentReportsControllerApiFactory);
	const portfolioStudioPreferences = useApiGen(PortfolioStudioPreferencesApiFactory);

	const isCalculating = useMemo(
		() =>
			(props.portfolio?.action === "UPLOAD" || props.portfolio?.action === "CREATION") &&
			props.portfolio?.status === "CALCULATING",
		[props.portfolio?.action, props.portfolio?.status],
	);

	return (
		<PortfolioMetricsBase
			hasBenchmark={Boolean(benchmarkId)}
			title={
				<Text
					type="Body/XL/Bold"
					title={t("PERFORMANCE_METRICS.TITLE")}
					classList="truncate"
					data-qualifier={qualifier.widgets.portfolioPerformanceMetrics.name}
				>
					{t("PERFORMANCE_METRICS.TITLE")}
				</Text>
			}
			widgetTooltip={t("EXPOSURE.TOOLTIP")}
			noDataText={t("PERFORMANCE_METRICS.NO_DATA")}
			queryKey={[
				"Performance H Metrics",
				props.portfolio?.uuid,
				benchmarkId,
				props.portfolio?.status,
				props.reportExcutionCounter,
			]} // idk
			saveHandler={async (data) => {
				const portfolioMetricPreferences: Array<PortfolioMetricPreference> = data.map((x) => ({
					enabled: x.visible,
					metricType: x.type!,
				}));

				await axiosExtract(
					portfolioStudioPreferences.setPortfolioMetricsOrderingPreferences({ portfolioMetricPreferences }),
				);
			}}
			metricsProvider={async () => {
				const preferences = await axiosExtract(portfolioStudioPreferences.getPortfolioMetricsOrderingPreferences());
				const metrics = await axiosExtract(reportInvestmentApi.getPortfolioMetrics(uuid!, benchmarkId!, preferences));
				if (!preferences?.portfolioMetricPreferences || !metrics.portfolioMetrics || isCalculating) {
					return {
						data: undefined,
						widgetStatus: portfolioWidgetMissingDataReason(props.portfolio!, "PerformanceMetricsBlock"),
					};
				}

				const mapMetrics = metrics.portfolioMetrics?.reduce<{ [key in PortfolioMetricTypeEnum]?: PortfolioMetric }>(
					(acc, el) => {
						if (!el.type) {
							return acc;
						}

						acc[el.type] = el;
						return acc;
					},
					{},
				);

				const provider = preferences.portfolioMetricPreferences.flatMap((preference) => {
					if (!preference.metricType) {
						return [];
					}
					return [
						{
							label: t(`PERFORMANCE_METRICS.METRICS.FULL.${preference.metricType}`) ?? "-",
							name: t(`PERFORMANCE_METRICS.METRICS.FULL.${preference.metricType}`) ?? "-",
							type: preference.metricType,
							visible: preference.enabled ?? false,
							current: mapMetrics?.[preference.metricType]?.value ?? 0,
							benchmark: mapMetrics?.[preference.metricType]?.benchmarkValue ?? 0,
						},
					];
				});

				return {
					data: provider,
					widgetStatus: WidgetStatus.READY as const,
				};
			}}
		/>
	);
};

export default withContext(PortfolioContext)(PerformanceMetricsBlock);
