import type {
	InvestmentListEntry,
	MarketViewListEntry,
	MarketViewSettings,
	UserMarketViewColumnPreference,
} from "$root/api/api-gen";
import { typedUrlForRoute } from "$root/components/PlatformRouter/RoutesDef";
import useInvestmentHorizonSetup from "$root/components/Portfolio/common_hooks/useInvestmentHorizonSetup";
import UserShareIcon from "$root/functional-areas/acl/UserShareIcon";
import { aclToUsersListEntries } from "$root/functional-areas/acl/checkers/all";
import { sharingWithColumn } from "$root/functional-areas/acl/table-columns";
import { useUserValue } from "$root/functional-areas/user";
import { useLocaleFormatters } from "$root/localization/hooks";
import type { TableColumn } from "@mdotm/mdotui/components";
import { ActionText, Icon } from "@mdotm/mdotui/components";
import { overrideClassName } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { builtInSortFnFor } from "@mdotm/mdotui/utils";
import { differenceInDays } from "date-fns";
import { t } from "i18next";
import { useMemo } from "react";
import { match } from "ts-pattern";

export function useMarketViewColumn(
	columnsPreferences?: UserMarketViewColumnPreference[],
	marketViewSetting?: MarketViewSettings,
): TableColumn<MarketViewListEntry>[] | null {
	const user = useUserValue();
	const formatters = useLocaleFormatters();
	const investmentHorizonSetup = useInvestmentHorizonSetup();
	const { formatDate } = formatters;

	const columns = useMemo(
		() =>
			columnsPreferences
				? columnsPreferences.map(
						(columnMeta) =>
							({
								header: columnMeta.preferencesType ? t(`TABLE.HEADERS.${columnMeta.preferencesType}`) : "-",
								hidden: columnMeta.enabled === false,
								content: () => null,
								...match(columnMeta)
									.with(
										{ preferencesType: "NAME" },
										(): Omit<TableColumn<MarketViewListEntry>, "header"> => ({
											content: ({ uuid, name, type, custom, richAcl, creationDate }, props) => (
												<div style={props.style} className={overrideClassName(props.classList, "items-center flex")}>
													<ActionText
														classList="line-clamp-2 mr-1"
														color={themeCSSVars.palette_N900}
														href={typedUrlForRoute("MarketViewWorkSpace", {
															action: "view",
															uuid: uuid!,
															type: type!,
															isCustom: String(Boolean(custom)),
														})}
														title={name}
														data-qualifier="PortfolioStudio/MarketViewList/Table/Column(Name)"
													>
														{!name ? `Market view ${formatDate(creationDate)}` : name}
													</ActionText>
													<UserShareIcon
														entity="MARKET_VIEW"
														userId={user.id}
														permission={richAcl?.currentUserPermissions}
														color={themeCSSVars.palette_N300}
														acl={richAcl?.acl}
													/>
												</div>
											),
											sortFn: builtInSortFnFor("name"),
											name: "name",
											width: 320,
										}),
									)
									.with(
										{ preferencesType: "SHARING_VIEWS" },
										(): Omit<TableColumn<MarketViewListEntry>, "header"> =>
											sharingWithColumn({
												users: ({ richAcl }) => aclToUsersListEntries("marketView", richAcl?.acl ?? []),
											}),
									)
									.with(
										{ preferencesType: "TYPE" },
										(): Omit<TableColumn<MarketViewListEntry>, "header"> => ({
											content: (row) => {
												if (row.custom) {
													return marketViewSetting?.customMarketViewName ?? "-";
												}

												return row.type ? String(t(row.type)) : "-";
											},
											sortFn: (a, b) => {
												if (a.custom) {
													return 1;
												}

												return (a.type ?? "") > (b.type ?? "") ? 1 : -1;
											},
											name: "type",
											minWidth: 128,
										}),
									)
									.with(
										{ preferencesType: "CREATION" },
										(): Omit<TableColumn<MarketViewListEntry>, "header"> => ({
											content: ({ creationDate }) => {
												const sanitizedCreationTime = creationDate ? formatDate(new Date(creationDate)) : "-";
												return sanitizedCreationTime;
											},
											sortFn: ({ creationDate: a }, { creationDate: b }) => {
												const rowA = new Date(a ?? "").getTime();
												const rowB = new Date(b ?? "").getTime();
												return rowA > rowB ? 1 : rowA < rowB ? -1 : 0;
											},
											name: "creation",
											cellClassList: "tabular-nums",
											align: "end",
											minWidth: 128,
										}),
									)
									.with(
										{ preferencesType: "FORECAST_HORIZON" },
										(): Omit<TableColumn<MarketViewListEntry>, "header"> => ({
											content: ({ forecastHorizon }) => {
												const forecastHorizonTypography =
													investmentHorizonSetup.getInvestmentHorizonTypographyFromNewApiValue(
														"multiAsset",
														String(forecastHorizon),
													);
												return forecastHorizonTypography;
											},
											sortFn: builtInSortFnFor("forecastHorizon"),
											name: "forecastHorizon",
											minWidth: 128,
										}),
									)
									.with(
										{ preferencesType: "EXPIRATION" },
										(): Omit<TableColumn<MarketViewListEntry>, "header"> => ({
											content: (row, props) => {
												if (!row.endDate) {
													return "-";
												}
												const expirationDate = new Date(row.endDate);
												if (differenceInDays(new Date(), expirationDate) > 0) {
													return (
														<div
															style={props.style}
															className={overrideClassName(`flex items-center`, props.classList)}
														>
															<div
																className={`bg-[${themeCSSVars.palette_W50}] flex items-center gap-2 py-1 px-2 text-[${themeCSSVars.palette_W500}] rounded-full`}
															>
																<Icon icon="Icon-full-alert" color={themeCSSVars.palette_W500} size={16} />
																<p className="font-semibold">{formatDate(expirationDate)}</p>
															</div>
														</div>
													);
												}

												return formatDate(expirationDate);
											},
											sortFn: ({ endDate: a }, { endDate: b }) => {
												const rowA = new Date(a ?? "").getTime();
												const rowB = new Date(b ?? "").getTime();
												return rowA > rowB ? 1 : rowA < rowB ? -1 : 0;
											},
											name: "endDate",
											minWidth: 128,
										}),
									)
									.otherwise(() => ({})),
							}) as unknown as TableColumn<InvestmentListEntry>,
				  )
				: null,
		[columnsPreferences, formatDate, investmentHorizonSetup, marketViewSetting?.customMarketViewName, user.id],
	);

	return columns;
}
