import type { RichAccessControl } from "$root/api/api-gen";
import { MarketViewControllerApiFactory } from "$root/api/api-gen";
import { reportPlatformError } from "$root/api/error-reporting";
import { getApiGen } from "$root/api/factory";
import type { EntityManagementActions } from "$root/components/spawnable/entity-management/actions";
import { spawnDeleteDialog } from "$root/components/spawnable/entity-management/delete-dialog";
import { spawnDuplicateDialog } from "$root/components/spawnable/entity-management/duplicate-dialog";
import { spawnRenameDialog } from "$root/components/spawnable/entity-management/rename-dialog";
import { platformToast } from "$root/notification-system/toast";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { t } from "i18next";
import { aclByArea } from "../acl/checkers/all";
import { useUserValue } from "../user";
import { useMemo } from "react";

export function useMarketViewEntityManagementActions(
	marketView:
		| {
				name?: string;
				uuid?: string;
				richAcl?: { acl?: RichAccessControl[] };
		  }
		| null
		| undefined,
	callbacks?: {
		onRename?(uuid: string): void;
		onDelete?(uuid: string): void;
		onDuplicate?(uuid: string): void;
	},
): Partial<Omit<EntityManagementActions, "createAsync">> {
	const user = useUserValue();
	return useMemo(
		() => (!marketView ? {} : marketViewEntityManagementActions(user, marketView, callbacks)),
		[callbacks, marketView, user],
	);
}

export function marketViewEntityManagementActions(
	user: { id: string },
	marketView: {
		name?: string;
		uuid?: string;
		richAcl?: { acl?: RichAccessControl[] };
	},
	callbacks?: {
		onRename?(uuid: string): void;
		onDelete?(uuid: string): void;
		onDuplicate?(uuid: string): void;
	},
): Partial<Omit<EntityManagementActions, "createAsync">> {
	const api = () => getApiGen(MarketViewControllerApiFactory); // accessor for lazy evaluation
	return {
		deleteAsync: !aclByArea.marketView.canDelete(user.id, marketView?.richAcl?.acl ?? [])
			? undefined
			: () =>
					spawnDeleteDialog({
						entityName: marketView?.name ?? "",
						entityType: "market view",
						onDeleteAsync: async () => {
							try {
								await api().deleteMarketView(marketView.uuid ?? "");

								// trackMixPanelEvent("MarketView", {
								// 	Type: "Delete",
								// 	ID: uuid,
								// 	Name: name,
								// });

								platformToast({
									children: t("MODAL.MARKET_DELETED_TITLE_SUCCESS", { name: marketView?.name ?? "" }),
									severity: "success",
									icon: "Portfolio",
								});
								callbacks?.onDelete?.(marketView.uuid ?? "");
							} catch (err) {
								reportPlatformError(err, "ERROR", "market-views", `delete market view "${marketView.uuid}"`);
								platformToast({
									children: t("SOMETHING_WENT_WRONG"),
									severity: "error",
									icon: "Portfolio",
								});
								throw err;
							}
						},
					}),
		duplicateAsync: () =>
			spawnDuplicateDialog({
				entityType: "marketView",
				originalName: marketView?.name ?? "",
				checkIfNameIsAvailable: (name, opts) => axiosExtract(api().isMarketViewNameAvailable(name, opts)),
				onSubmitAsync: async ({ param: name }) => {
					try {
						const uuid = (await axiosExtract(api().duplicateMarketView(marketView.uuid ?? "", name))).uuid ?? "";

						platformToast({
							children: t("MODAL.MARKET_DUPLICATE_TITLE_SUCCESS", { name }),
							severity: "success",
							icon: "Portfolio",
						});
						callbacks?.onDuplicate?.(uuid);

						return uuid;
					} catch (err) {
						reportPlatformError(err, "ERROR", "market-views", `duplicate market view "${marketView.uuid}"`);
						platformToast({
							children: t("SOMETHING_WENT_WRONG"),
							severity: "error",
							icon: "Portfolio",
						});
						throw err;
					}
				},
			}),
		renameAsync: !aclByArea.marketView.canRename(user.id, marketView?.richAcl?.acl ?? [])
			? undefined
			: () =>
					spawnRenameDialog({
						entityType: "marketView",
						currentName: marketView?.name ?? "",
						checkIfNameIsAvailable: (name, opts) => axiosExtract(api().isMarketViewNameAvailable(name, opts)),
						onSubmitAsync: async ({ param: name }) => {
							try {
								await api().renameMarketView(marketView.uuid ?? "", name);

								platformToast({
									children: t("MODAL.MARKET_RENAME_TITLE_SUCCESS", { name }),
									severity: "success",
									icon: "Portfolio",
								});
								callbacks?.onRename?.(name);

								return name;
							} catch (err) {
								reportPlatformError(err, "ERROR", "market-views", `rename market view "${marketView.uuid}"`);
								platformToast({
									children: t("SOMETHING_WENT_WRONG"),
									severity: "error",
									icon: "Portfolio",
								});
								throw err;
							}
						},
					}),
	};
}
