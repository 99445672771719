import { Icon, ProgressBar, Text } from "@mdotm/mdotui/components";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { SphereAIAvatar } from "../icons/SphereAIAvatar";
import { useEffect, useState } from "react";
import { sleep, unpromisify } from "@mdotm/mdotui/utils";
import { noop } from "@mdotm/mdotui/utils";
import { Switch, useLayoutTick, useUnsafeUpdatedRef } from "@mdotm/mdotui/react-extensions";
import { useLocaleFormatters } from "$root/localization/hooks";
import { Range } from "immutable";
import { AbortError } from "$root/utils/promise";

class CompleteStepAbortError extends AbortError {}

async function* fakeProgressProvider(opts: {
	signal?: AbortSignal;
	expCoeff?: number;
	deltaX?: number;
	sleepMs: number;
}) {
	let stop = opts.signal?.aborted ?? false;
	const abortListener = () => (stop = true);
	opts.signal?.addEventListener("abort", abortListener, { once: true });
	const expCoeff = opts.expCoeff ?? 1;
	const deltaX = opts.deltaX ?? 1;
	let x = 0;
	while (!stop) {
		yield 1 - Math.exp(-x * expCoeff);
		await sleep(opts.sleepMs);
		x += deltaX;
	}
	opts.signal?.removeEventListener("abort", abortListener);
	yield 1; // finally return 1
}

const steps = ["recovering", "analysing", "preparing"] as const;
type Step = (typeof steps)[number];

// eslint-disable-next-line react/no-unused-prop-types
export function ThinkingBoxV2(props: { signal?: AbortSignal; onDone?(): void; scrollToBottom?(): void }): JSX.Element {
	const [state, setState] = useState<
		Array<{
			name: Step;
		}>
	>([]);
	const [progress, setProgress] = useState(0);
	const propsRef = useUnsafeUpdatedRef(props);

	const layoutTick = useLayoutTick();

	useEffect(() => {
		setState([]);
		let latestLocalAbortController: AbortController | undefined = undefined;
		const abortListener = () => latestLocalAbortController?.abort(propsRef.current.signal?.reason);
		propsRef.current.signal?.addEventListener("abort", abortListener, { once: true });
		unpromisify(async () => {
			for (let i = 0; i < steps.length; i++) {
				const step = steps[i];
				setState((s) => [
					...s,
					{
						name: step,
					},
				]);
				await layoutTick();
				propsRef.current.scrollToBottom?.();
				const localAbortController = new AbortController();
				latestLocalAbortController = localAbortController;

				if (propsRef.current.signal?.aborted) {
					localAbortController.abort(new CompleteStepAbortError());
				}

				if (i !== steps.length - 1) {
					sleep(3000).then(() => {
						localAbortController.abort(new CompleteStepAbortError());
					}, noop);
				}
				for await (const p of fakeProgressProvider({
					signal: localAbortController.signal,
					deltaX: i !== steps.length - 1 ? 0.3 : 0.02,
					expCoeff: i !== steps.length - 1 ? 1 : 0.4,
					sleepMs: 400,
				})) {
					setProgress(p);
				}
				await sleep(400);
			}
			propsRef.current.signal?.removeEventListener("abort", abortListener);
			propsRef.current.onDone?.();
		})();
		return () => {
			// eslint-disable-next-line react-hooks/exhaustive-deps
			latestLocalAbortController?.abort(new AbortError("unmount"));
		};
	}, [layoutTick, propsRef]);

	const { formatNumber } = useLocaleFormatters();
	return (
		<div
			className={`bg-[${themeCSSVars.palette_N20}] px-8 flex flex-col min-w-0 items-center py-4 border-y border-y-[${themeCSSVars.palette_N100}]`}
		>
			{state.map((step, i) => (
				<div key={step.name} className="w-full py-0.5">
					<div className="flex justify-between">
						<div className="flex items-start min-w-0 w-full">
							<SphereAIAvatar
								classList={{
									invisible: i !== 0,
								}}
								animated={i === 0}
								style={{ width: 24 }}
							/>
							<div className="ml-3 pt-0.5 flex-1">
								<Text
									type="Body/L/Book"
									color={i !== state.length - 1 ? themeCSSVars.palette_N700 : themeCSSVars.palette_N400}
								>
									<Switch
										case={step.name}
										match={{
											recovering: () => "Recovering data",
											analysing: () => "Analysing data",
											preparing: () => "Preparing and formatting your response",
										}}
									/>
								</Text>
								{i === state.length - 1 && (
									<div className="min-w-0 w-full mt-2">
										<ProgressBar accentColor={themeCSSVars.palette_graph_B400} classList="w-full" value={progress} />
									</div>
								)}
							</div>
						</div>
						<Text
							as="div"
							type="Body/L/Book"
							color={i !== state.length - 1 ? themeCSSVars.palette_N700 : themeCSSVars.palette_N400}
						>
							{i !== state.length - 1 ? (
								<Icon
									icon="Outline"
									size={16}
									color={i !== state.length - 1 ? themeCSSVars.palette_N700 : themeCSSVars.palette_N400}
								/>
							) : (
								`${formatNumber(progress * 100, 0)}%`
							)}
						</Text>
					</div>
				</div>
			))}
		</div>
	);
}

export function ThinkingBox(): JSX.Element {
	return (
		<div
			className={`bg-[${themeCSSVars.palette_N20}] px-8 flex items-center py-4 border-y border-y-[${themeCSSVars.palette_N100}]`}
		>
			<SphereAIAvatar animated style={{ width: 24 }} />
			<Text type="Body/L/Book" color={themeCSSVars.palette_N700} classList="ml-3">
				Sphere AI is thinking
				{Range(0, 3).map((i) => (
					<span
						key={i}
						className="animate-ping"
						style={{
							animationDelay: `${i * 100}ms`,
						}}
					>
						.
					</span>
				))}
			</Text>
		</div>
	);
}
