import {
	BucketControllerApiFactory,
	CustomerControllerV3ApiFactory,
	UserControllerApiFactory,
} from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import MainBlock from "$root/components/EvolvedPrint/configuration";
import useExtractReportsData from "$root/components/EvolvedPrint/configuration/hooks/useExtractReports";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { AsyncButton, CircularProgressBar } from "@mdotm/mdotui/components";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { lazy, useMemo, useState } from "react";
import { useParams } from "react-router";
import "./print.scss";
import { platformToast } from "$root/notification-system/toast";
import { CustomReportEditorContext, initCustomEditorContextData } from "./context";
import { parallelize } from "$root/utils/promise";
import { spawnYesNoDialog } from "$root/components/spawnable/yes-no-dialog";
import { useTranslation } from "react-i18next";
import type { Languages } from "$root/localization/i18n";

const Theme = lazy(() => import("../../components/EvolvedPrint/layout/portrait"));

const CustomReportEditor = (): JSX.Element => {
	const { customerId } = useParams<{ customerId: string }>();
	const customerApi = useApiGen(CustomerControllerV3ApiFactory);
	const userApi = useApiGen(UserControllerApiFactory);
	const bucketApi = useApiGen(BucketControllerApiFactory);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const { i18n } = useTranslation();

	const { data: customerList } = useQueryNoRefetch(["queryCustomerData"], {
		enabled: customerId !== undefined,
		queryFn: () => axiosExtract(userApi.listAllCustomer()),
	});

	async function maybeUploadToBucket(file: File | null | undefined) {
		if (!file) {
			return undefined;
		}
		return (await axiosExtract(bucketApi.uploadFile(file)))!;
	}

	const [contextContent, setContextContent] = useState(initCustomEditorContextData);

	const {
		state: { data: fetchedData, isLoading },
		refetch,
	} = useExtractReportsData({ template: "mocked", language: i18n.language as Languages });

	const data = useMemo(
		() =>
			fetchedData &&
			({
				...fetchedData,
				payload: {
					...fetchedData.payload,
					report: {
						...fetchedData.payload.report,
						title: "PORTFOLIO DETAILS",
						subtitle: "NAME REFERENCE",
						footer: {
							...fetchedData.payload.report.footer,
							description: contextContent.texts.footerDescription ?? fetchedData.payload.report.footer?.description,
						},
						address: contextContent.texts.address ?? fetchedData.payload.report.address,
						disclaimer: contextContent.texts.disclaimer ?? fetchedData.payload.report.disclaimer,
						website: contextContent.texts.website ?? fetchedData.payload.report.website,
						email: contextContent.texts.email ?? fetchedData.payload.report.email,
					},
				},
			} as typeof fetchedData),
		[
			contextContent.texts.address,
			contextContent.texts.disclaimer,
			contextContent.texts.email,
			contextContent.texts.footerDescription,
			contextContent.texts.website,
			fetchedData,
		],
	);

	const [forceRemount, setForceRemount] = useState(0);
	async function resetCustomFields() {
		const doReset = await spawnYesNoDialog();
		if (doReset) {
			setContextContent(initCustomEditorContextData());
			setForceRemount((n) => n + 1);
		}
	}

	async function onSubmit() {
		try {
			setIsSubmitting(true);
			// TODO: remove from here
			const customer = customerList?.find((x) => x.id === customerId);
			if (!customer) {
				alert("unable to find a valid customer");
				throw new Error("unable to find a valid customer");
			}

			const [
				backgroundImage = customer.backgroundImage,
				clientLogo = customer.clientLogo,
				footerImage = customer.footerImage,
			] = await parallelize(
				[
					() => maybeUploadToBucket(contextContent.files.backgroundImage),
					() => maybeUploadToBucket(contextContent.files.clientLogo),
					() => maybeUploadToBucket(contextContent.files.footerImage),
				],
				{ concurrency: 2 },
			);

			const {
				footerDescription = customer.footerDescription,
				disclaimer = customer.disclaimer,
				address = customer.address,
				email = customer.email,
				website = customer.website,
			} = contextContent.texts;

			await customerApi.updateCustomer({
				backgroundImage,
				clientLogo,
				footerImage,
				disclaimer,
				email,
				website,
				address,
				footerDescription,
				enabled: customer.enabled,
				id: customer.id,
				marketViewSettings: customer.marketViewSettings,
				maxPortfolios: customer.maxPortfolios,
				maxUsers: customer.maxUsers,
				name: customer.name,
				theme: customer.theme,
				tokenDuration: customer.tokenDuration,
				importData: {
					enabled: customer.importEnabled,
					importServiceType: customer.importServiceType,
					password: customer.importPassword,
					pollingFrequencyInMinutes: customer.importPollingFrequencyInMinutes,
					port: customer.port,
					url: customer.importUrl,
					username: customer.importUsername,
				},
			});
			await refetch();
			setForceRemount((n) => n + 1);
			window.close();
		} catch (error) {
			console.log(error);
			platformToast({
				icon: "Icon-full-error",
				children: "Si è verificato un errore",
				severity: "error",
			});
		} finally {
			setIsSubmitting(false);
		}
	}

	if (!data || isLoading || isSubmitting) {
		return (
			<Theme>
				<div data-size="A4portrait" className="page_brake">
					<div className="h-full flex justify-center items-center">
						<CircularProgressBar value="indeterminate" />
					</div>
				</div>
			</Theme>
		);
	}

	return (
		<CustomReportEditorContext.Provider
			key={forceRemount}
			value={{
				value: contextContent,
				set: setContextContent,
				update: setContextContent,
			}}
		>
			<Theme>
				<section className="indicators">
					<div data-size="A4Template">
						<MainBlock {...data} layout="portrait" />
					</div>
				</section>

				<div className="fixed bottom-8 left-14 z-50">
					<AsyncButton
						classList="rounded-full h-12 w-12 shadow-md active:scale-90 transition-transform font-semibold text-white hover:text-slate-800"
						style={{ backgroundColor: themeCSSVars.palette_D200 }}
						unstyled
						onClickAsync={resetCustomFields}
					>
						Reset
					</AsyncButton>
				</div>

				<div className="fixed bottom-8 right-14 z-50">
					<AsyncButton
						classList="rounded-full h-12 w-12 shadow-md active:scale-90 transition-transform font-semibold text-white hover:text-slate-800"
						style={{ backgroundColor: themeCSSVars.palette_P500 }}
						unstyled
						onClickAsync={onSubmit}
					>
						Save
					</AsyncButton>
				</div>
			</Theme>
		</CustomReportEditorContext.Provider>
	);
};

export default CustomReportEditor;
