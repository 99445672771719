import type { PlatformErrorAreas } from "$root/api/error-reporting";
import type { PageDownloadMenuAction } from "./PageDownloadMenu";
import { PageDownloadMenu } from "./PageDownloadMenu";
import type { PageMoreActionsMenuAction } from "./PageMoreActionsMenu";
import { PageMoreActionsMenu } from "./PageMoreActionsMenu";

export function PageDownloadAndMoreActionsMenu(props: {
	downloadActions?: Array<PageDownloadMenuAction | null | undefined | false>;
	moreActions?: Array<PageMoreActionsMenuAction | null | undefined | false>;
	area: PlatformErrorAreas;
}): JSX.Element {
	return (
		<div className="flex items-center gap-3">
			{Boolean(props.downloadActions?.length) && (
				<PageDownloadMenu area={props.area} actions={props.downloadActions!} />
			)}
			{Boolean(props.moreActions?.length) && <PageMoreActionsMenu area={props.area} actions={props.moreActions!} />}
		</div>
	);
}
