import type { PageWidgetsConfiguration } from "$root/api/api-gen";
import { hasAccess } from "$root/components/AuthorizationGuard";
import type { IUser } from "$root/functional-areas/user";
import type { WidgetMapperProps } from "$root/widgets-architecture/layout/WidgetsMapper";

export type EnhancedPageWidgetsConfiguration = Omit<NonNullable<PageWidgetsConfiguration>, "configuration"> & {
	configuration: {
		x: number;
		y: number;
		w: number;
		h: number;
		minH: number;
		maxH: number;
		minW: number;
		maxW: number;
		i: WidgetMapperProps["widgetName"];
		hidden?: boolean;
		guard?: (params: { user: IUser }) => boolean;
	}[];
};

function widgetsGridWithType<T extends Record<string, EnhancedPageWidgetsConfiguration>>(map: {
	[K in keyof T]: T[K];
}): { [K in keyof T]: T[K] & { page: string; hidden?: boolean } } {
	return Object.fromEntries(Object.entries(map).map(([key, value]) => [key, { ...value, page: key }])) as {
		[K in keyof T]: T[K] & { page: string; hidden?: boolean };
	};
}

export const defaultWidgetsGridConfig = widgetsGridWithType({
	UNIVERSE_DETAILS: {
		version: "1",
		configuration: [],
	},
	PORTFOLIO_DETAILS: {
		version: "68",
		configuration: [
			{ i: "PortfolioPerformance", x: 0, y: 0, w: 2, h: 2, minH: 2, maxH: 2, minW: 2, maxW: 2 },
			{ i: "PortfolioMonitoringBlock", x: 2, y: 0, w: 1, h: 2, minH: 2, maxH: 2, minW: 1, maxW: 1 },
			{ i: "PerformanceMetricsBlock", x: 0, y: 2, w: 1, h: 2, minH: 2, maxH: 2, minW: 1, maxW: 1 },
			{ i: "ExanteMetricsBlock", x: 1, y: 2, w: 1, h: 2, minH: 2, maxH: 2, minW: 1, maxW: 1 },
			{ i: "PortfolioCommentaryMDBlock", x: 2, y: 2, w: 1, h: 2, minH: 2, maxH: 2, minW: 1, maxW: 1 },
			{ i: "ExposureEvolve", x: 0, y: 4, w: 2, h: 2, minH: 2, maxH: 2, minW: 2, maxW: 2 },
			{ i: "PortfolioHistoryBlock", x: 2, y: 4, w: 1, h: 2, minH: 2, maxH: 2, minW: 1, maxW: 1 },
			// { i: "Composition", x: 0, y: 6, w: 3, h: 2, minH: 2, maxH: 2, minW: 3, maxW: 3, },
			{ i: "Attribution", x: 0, y: 6, w: 3, h: 2, minH: 2, maxH: 2, minW: 3, maxW: 3 },
			{ i: "ContributionVolatility", x: 0, y: 8, w: 3, h: 2, minH: 2, maxH: 2, minW: 3, maxW: 3 },
			{ i: "ExanteContributionVolatility", x: 0, y: 10, w: 3, h: 2, minH: 2, maxH: 2, minW: 3, maxW: 3 },
			{ i: "PortfolioFactorsExposure", x: 0, y: 12, w: 3, h: 2, minH: 2, maxH: 2, minW: 3, maxW: 3 },
		],
	},
	PORTFOLIO_ENHANCEMENT: {
		version: "51",
		configuration: [
			{ i: "ExposureCompare", x: 0, y: 0, w: 2, h: 2, minH: 2, maxH: 2, minW: 2, maxW: 2 },
			{ i: "PortfolioCommentaryMDBlock", x: 2, y: 0, w: 1, h: 2, minH: 2, maxH: 2, minW: 1, maxW: 1 },
			{ i: "ExanteContributionVolatility", x: 0, y: 2, w: 2, h: 2, minH: 2, maxH: 2, minW: 2, maxW: 2 },
			{ i: "ExanteMetricsBlock", x: 2, y: 2, w: 1, h: 2, minH: 2, maxH: 2, minW: 1, maxW: 1 },
			// { i: "Composition", x: 0, y: 4, w: 3, h: 2, minH: 2, maxH: 2, minW: 3, maxW: 3 },
			{ i: "PortfolioFactorsExposure", x: 0, y: 4, w: 2, h: 2, minH: 2, maxH: 2, minW: 2, maxW: 2 },
			{ i: "PortfolioMonitoringBlock", x: 2, y: 4, w: 1, h: 2, minH: 2, maxH: 2, minW: 1, maxW: 1 },
		],
	},
	PORTFOLIO_REFERENCE: {
		version: "30",
		configuration: [
			{ i: "ExposureEvolve", x: 0, y: 0, w: 3, h: 2, minH: 2, maxH: 2, minW: 3, maxW: 3 },
			{ i: "Composition", x: 0, y: 2, w: 3, h: 2, minH: 2, maxH: 2, minW: 3, maxW: 3 },
		],
	},
	ASSET_CLASS: {
		version: "28",
		configuration: [
			{ i: "ACROutlookExpected", x: 0, y: 0, w: 1, h: 2, minH: 2, maxH: 2, minW: 1, maxW: 1 },
			{ i: "ReturnAnalysis", x: 1, y: 0, w: 2, h: 2, minH: 2, maxH: 2, minW: 2, maxW: 2 },
			// { i: "CommentaryMDBlock", x: 0, y: 2, w: 1, h: 2, minH: 2, maxH: 2, minW: 1, maxW: 1 },
			{ i: "FactorsExposure", x: 0, y: 2, w: 2, h: 2, minH: 2, maxH: 2, minW: 2, maxW: 2 },
		],
	},
	DASHBOARD: {
		version: "34",
		configuration: [
			{ i: "AssetClassOverviewBlock", x: 0, y: 0, w: 4, h: 0.73, minH: 0.73, maxH: 0.73, minW: 4, maxW: 4 },
			{ i: "OutlookBlock", x: 0, y: 0.73, w: 4, h: 2, minH: 2, maxH: 2, minW: 2, maxW: 2 },
			{
				i: "PortfolioOverview",
				x: 0,
				y: 2.73,
				w: 2,
				h: 2,
				minH: 1,
				maxH: 2,
				minW: 1,
				maxW: 2,
				guard: (params) =>
					hasAccess(params.user, {
						requiredService: "INVESTMENTS",
					}),
			},
			{ i: "CommentaryMDBlock", x: 4, y: 2.73, w: 1, h: 2, minH: 1, maxH: 2, minW: 1, maxW: 1 },
		],
	},
	OUTLOOK: {
		version: "23",
		configuration: [
			{ i: "Positioning", x: 0, y: 0, w: 3, h: 2, minH: 2, maxH: 2, minW: 3, maxW: 3 },
			{ i: "CommentaryMDBlock", x: 2, y: 2, w: 1, h: 2, minH: 2, maxH: 2, minW: 1, maxW: 1 },
			{ i: "Forecast", x: 0, y: 2, w: 2, h: 2, minH: 2, maxH: 2, minW: 2, maxW: 2 },
		],
	},
	HMM: {
		version: "24",
		configuration: [
			{ i: "MarketRegimeAnalysisBlock", x: 0, y: 0, w: 1, h: 2, minH: 2, maxH: 2, minW: 1, maxW: 1 },
			{ i: "ScenarioDescriptionBlock", x: 2, y: 0, w: 2, h: 2, minH: 2, maxH: 2, minW: 2, maxW: 2 },
			{ i: "PastMarketRegimesBlock", x: 0, y: 2, w: 3, h: 2, minH: 2, maxH: 2, minW: 3, maxW: 3 },
			{ i: "EvolutionOfRegimeProbabilitiesBlock", x: 0, y: 4, w: 3, h: 2, minH: 2, maxH: 2, minW: 3, maxW: 3 },
			{ i: "RegimeTransitionMatrix2WBlock", x: 0, y: 6, w: 1, h: 2, minH: 2, maxH: 2, minW: 1, maxW: 1 },
			{ i: "RegimeTransitionMatrix1MBlock", x: 1, y: 6, w: 1, h: 2, minH: 2, maxH: 2, minW: 1, maxW: 1 },
			{ i: "RegimeTransitionMatrix3MBlock", x: 2, y: 6, w: 1, h: 2, minH: 2, maxH: 2, minW: 1, maxW: 1 },
			{ i: "RegimeBreakdownBySectorBlock", x: 0, y: 8, w: 3, h: 3, minH: 3, maxH: 3, minW: 3, maxW: 3 },
			{ i: "CurrentRegimeCounterBlock", x: 0, y: 11, w: 1, h: 2, minH: 2, maxH: 2, minW: 1, maxW: 1 },
		],
	},
});
