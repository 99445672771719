// TODO: move all business logic to functional-areas/reports

import type { ReportTemplate as ReportTemplateV1 } from "./version/report-v1";
import { migrateV1ToV2, type ReportTemplate as ReportTemplateV2 } from "./version/report-v2";
import { migrateV2ToV3, type ReportTemplate as ReportTemplateV3 } from "./version/report-v3";
import { migrateV3ToV4, type ReportTemplate as ReportTemplateV4 } from "./version/report-v4";
import { migrateV4ToV5, type ReportTemplate as ReportTemplateLatest } from "./version/report-v5";

export * from "./version/report-v5";

const migrationFns: Record<number, (reportTemplate: any) => any> = {
	2: migrateV1ToV2,
	3: migrateV2ToV3,
	4: migrateV3ToV4,
	5: migrateV4ToV5,
};

export const latestVersion = Number(Object.keys(migrationFns).at(-1)!);
export type ReportTemplateUnion =
	| ReportTemplateV1
	| ReportTemplateV2
	| ReportTemplateV3
	| ReportTemplateV4
	| ReportTemplateLatest;

export function migrateToLatest(reportTemplate: ReportTemplateUnion): ReportTemplateLatest {
	let currentReportTemplate = reportTemplate;
	for (let v = reportTemplate.data.version + 1; v <= latestVersion; v++) {
		currentReportTemplate = migrationFns[v](currentReportTemplate);
	}

	return currentReportTemplate as ReportTemplateLatest;
}
