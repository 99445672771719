import type {
	Currencies,
	InvestmentBenchmarkDTO,
	InvestmentCreationConfigurationControllerV4Api,
	InvestmentDraftConfigurationControllerV4Api,
	InvestmentEnhancementConfigurationControllerV4Api,
	InvestmentReferenceDTO,
	TargetVolatility,
} from "$root/api/api-gen";
import {
	InvestmentControllerV4ApiFactory,
	InvestmentCreationConfigurationControllerV4ApiFactory,
	InvestmentDraftConfigurationControllerV4ApiFactory,
	InvestmentEnhancementConfigurationControllerV4ApiFactory,
	InvestmentsStaticConfigurationControllerApiFactory,
	MainInfoSaveRequestMandateTypeEnum,
} from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { DataDisplayOverlay } from "$root/components/DataDisplayOverlay";
import { ReactQueryWrapperBase } from "$root/components/ReactQueryWrapper";
import { useDebouncedNameUniquenessChecker } from "$root/functional-areas/named-entities/uniqueness";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { trackMixPanelEvent } from "$root/third-party-integrations/initMixPanel";
import { FormFields } from "$root/ui-lib/form/FormFields";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { zodResolver } from "@hookform/resolvers/zod";
import type { Option } from "@mdotm/mdotui/components";
import { AutoTooltip, Banner, CircularProgressBar, FormField, Icon, Label, Text } from "@mdotm/mdotui/components";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";
import { z } from "zod";
import type { EditPortfolioV4Props } from "../EditPortfolio";
import { StepBase } from "../StepBase";
import { assocStatusToEntity } from "../constraints/const";
import { useStepSync } from "../hooks";
import { makeStepToRequestMapper, responseToStepMapper } from "../requests";
import {
	useHandleSubmitToCustomSubmitHandlerInContext,
	type EditPortfolioV4StepPayloadMap,
	type StepPropsFor,
} from "../shared";

export function getMainInfoStepData(
	createApi: Omit<InvestmentCreationConfigurationControllerV4Api, "basePath" | "axios">,
	enhanceApi: Omit<InvestmentEnhancementConfigurationControllerV4Api, "basePath" | "axios">,
	draftApi: Omit<InvestmentDraftConfigurationControllerV4Api, "basePath" | "axios">,
	area: EditPortfolioV4Props["area"],
): Promise<EditPortfolioV4StepPayloadMap["mainInfo"]> {
	return (
		!area.portfolioUid
			? createApi.getCreationConfigurationMainInfo()
			: area.name === "draft"
			  ? draftApi.getDraftConfigurationMainInfo(area.portfolioUid)
			  : enhanceApi.getEnhancementConfigurationMainInfo(area.portfolioUid)
	).then(({ data }) => responseToStepMapper.mainInfo(data, area));
}

const MAX_TARGET_VOLATILITY = 50;
const MIN_TARGET_VOLATILITY = 0;

export function Step01_MainInfo({
	context,
	stepMetadata,
	stepData,
	onStepDataChange,
	onStepError,
	toggleDirty,
}: StepPropsFor<"mainInfo">): JSX.Element {
	const { saveHandlers, area } = context;
	const { t } = useTranslation();

	const edit = area.editable && area.edit;
	const createInvestmentApi = useApiGen(InvestmentCreationConfigurationControllerV4ApiFactory);
	const draftInvestmentApi = useApiGen(InvestmentDraftConfigurationControllerV4ApiFactory);
	const investmentApi = useApiGen(InvestmentControllerV4ApiFactory);
	const enhanceInvestmentApi = useApiGen(InvestmentEnhancementConfigurationControllerV4ApiFactory);
	const staticController = useApiGen(InvestmentsStaticConfigurationControllerApiFactory);

	const isEditProcessing = useMemo(() => stepMetadata.processing && edit, [edit, stepMetadata.processing]);

	const { checkingNameUniqueness, checkIfNameIsAvailable } = useDebouncedNameUniquenessChecker({
		isNameAvailableApi: (name, opts) => axiosExtract(investmentApi.isInvestmentNameAvailable(name, opts)),
		currentName: stepData.name,
	});

	const { control, formState, handleSubmit, reset, watch } = useForm({
		defaultValues: stepData,
		resolver: zodResolver(
			z.object({
				baseCurrency: z.string(),
				name: z
					.string()
					.min(1, "Please provide a valid name")
					.refine((name) => checkIfNameIsAvailable(name), {
						message: "Name not available",
					}),
				primaryBenchmark: z
					.object(
						{
							benchmarkIdentifier: z.string(),
							benchmarkType: z.string(),
							available: z.boolean(),
							volatility: z.number(),
							status: z.string(),
							benchmarkName: z.string(),
						} satisfies Record<keyof InvestmentBenchmarkDTO, unknown>,
						{ invalid_type_error: "Please select a valid benchmark" },
					)
					.required()
					.passthrough(),
				mandateType: z.nativeEnum(MainInfoSaveRequestMandateTypeEnum).optional(),
				investmentReference: z
					.object({
						name: z.string().nullable(),
						type: z.any(),
						identifier: z.string(),
						available: z.boolean().nullable(),
						volatility: z.number().nullable(),
						status: z.string().nullable(),
					} satisfies Record<keyof InvestmentReferenceDTO, unknown>)
					.optional()
					.nullable(),
				investmentUuid: z.string().optional(),
				targetVolatility: z.object({
					selectedValue: z
						.number({
							required_error: "Please insert a valid number",
							invalid_type_error: "Please insert a valid number",
						})
						.min(MIN_TARGET_VOLATILITY, "Please provide a valid number higher or equal 0")
						.max(MAX_TARGET_VOLATILITY, "Please provide a number lower or equal 50")
						.nullable(),
					suggestedMaxValue: z.number().optional(),
					suggestedMinValue: z.number().optional(),
					suggestedValue: z.number().optional(),
					validity: z.any(),
				} satisfies Record<keyof TargetVolatility, unknown>),
			} satisfies Record<keyof typeof stepData, unknown>),
		),
	});

	useStepSync({ reset, toggleDirty, isDirty: formState.isDirty, stepData });
	const stepToRequestMapper = useMemo(() => makeStepToRequestMapper(area.portfolioUid), [area.portfolioUid]);

	const selectableQuery = useQueryNoRefetch({
		queryKey: ["mainInfoCurrencies", "mainInfoBenchmarks"],
		queryFn: async () => {
			const selectable = await match(context)
				.with({ area: { name: "enhance" } }, (x) =>
					axiosExtract(enhanceInvestmentApi.getEnhancementConfigurationSelectableMainInfo(x.area.portfolioUid)),
				)
				.with({ area: { name: "settings-enhanced" } }, ({ area: x }) =>
					axiosExtract(staticController.getStaticConfigurationSelectableMainInfo(x.portfolioUid)),
				)
				.with({ area: { name: "settings-current" } }, ({ area: x }) =>
					axiosExtract(staticController.getStaticConfigurationSelectableMainInfo(x.portfolioUid)),
				)
				.with({ area: { name: "create" } }, () =>
					axiosExtract(createInvestmentApi.getCreationConfigurationSelectableMainInfo()),
				)
				.with({ area: { name: "draft" } }, (x) =>
					axiosExtract(draftInvestmentApi.getDraftConfigurationSelectableMainInfo(x.area.portfolioUid)),
				)
				.exhaustive();

			const currencies: Option<Currencies>[] = (selectable.availableCurrencies ?? []).map((currency) => ({
				label: currency ?? "",
				value: currency,
			}));

			const benchmarks: Option<InvestmentBenchmarkDTO>[] = (selectable.availablePrimaryBenchmarks ?? []).map((el) => ({
				label: assocStatusToEntity(el.benchmarkName, el.status),
				value: el,
				disabled: el.available === false,
				group: t(`INVESTMENT_REFERENCE_CATEGORIES.${el.benchmarkType!}`),
			}));

			const references: Option<InvestmentReferenceDTO>[] = (selectable.availableInvestmentReferences ?? []).map(
				(el) => ({
					label: assocStatusToEntity(el.name, el.status),
					value: el,
					group: t(`INVESTMENT_REFERENCE_CATEGORIES.${el.type!}`),
					disabled: !el.available,
				}),
			);

			return { currencies, benchmarks, references };
		},
	});

	const observedPrimaryBenchmark = watch("primaryBenchmark");
	const observedTargetVolatility = watch("targetVolatility");
	const observedInvestmentReference = watch("investmentReference");

	const onSubmitAsync = useHandleSubmitToCustomSubmitHandlerInContext({
		context,
		stepName: "mainInfo",
		handleSubmit,
		baseSubmitFn: async (values) => {
			try {
				onStepDataChange(await saveHandlers.mainInfo(values), {
					skipMetadataUpdate: true,
					persist: true,
				});
				trackMixPanelEvent("Portfolio-Draft", {
					Type: area.portfolioUid ? "enhance" : "creation",
					Step: "main-info",
					Action: "save",
					Name: values.name,
					BaseCurrency: values.baseCurrency,
					BenchmarkIdentifier: values.primaryBenchmark?.benchmarkIdentifier,
					BenchmarkType: values.primaryBenchmark?.benchmarkType,
				});
			} catch (err) {
				onStepError();
				console.error("cannot save MainInfo step", err);
				throw err;
			}
		},
	});

	return (
		<StepBase optional={stepMetadata.optional} title="Main info" onSubmitAsync={() => onSubmitAsync()}>
			<DataDisplayOverlay
				dataProvider={() => ({
					formData: watch(),
					requestBody: stepToRequestMapper.mainInfo(watch()),
				})}
				dataSource="Main Info"
			/>

			{
				//#region Portfolio Name
			}
			{!isEditProcessing && edit && area.editable && area.name !== "enhance" ? (
				<FormFields.Text
					classList="mb-4 max-w-[470px]"
					control={control}
					formState={formState}
					name="name"
					label="Portfolio name"
					placeholder="Name"
					data-qualifier="portfolioWizard/mainInfo/portfolioName"
					rightContent={
						checkingNameUniqueness ? <CircularProgressBar classList="w-3" value="indeterminate" /> : undefined
					}
				/>
			) : (
				<FormField label="Portfolio name" classList="mb-4 max-w-[470px]">
					{watch("name") || "-"}
				</FormField>
			)}
			{
				//#endregion
			}

			{
				//#region Currency
			}
			{!isEditProcessing && (area.name === "create" || area.name === "draft") && area.editable ? (
				<ReactQueryWrapperBase
					query={selectableQuery}
					loadingFallback={<CircularProgressBar style={{ width: "1rem" }} value="indeterminate" />}
				>
					{({ currencies }) => (
						<FormFields.Select
							data-qualifier="portfolioWizard/mainInfo/currency/options"
							innerRef={(e) => e?.setAttribute("data-qualifier", "portfolioWizard/mainInfo/currency")}
							control={control}
							formState={formState}
							name="baseCurrency"
							classList="mb-4 max-w-[470px]"
							label="Base currency"
							i18n={{ triggerPlaceholder: () => t("PORTFOLIOS.PORTFOLIO_CURRENCY_PLACEHOLDER") }}
							style={{ width: "100%" }}
							options={currencies}
							enableSearch
						/>
					)}
				</ReactQueryWrapperBase>
			) : (
				<FormField label="Base currency" classList="mb-4 max-w-[470px]">
					{watch("baseCurrency") || "-"}
				</FormField>
			)}
			{
				//#endregion
			}

			{
				//#region Comparative benchmark
			}
			{!isEditProcessing && edit && area.editable && area.name !== "enhance" ? (
				<ReactQueryWrapperBase
					query={selectableQuery}
					loadingFallback={<CircularProgressBar style={{ width: "1rem" }} value="indeterminate" />}
				>
					{({ benchmarks }) => (
						<FormFields.Select
							enableSearch
							data-qualifier="portfolioWizard/mainInfo/benchmark/options"
							innerRef={(e) => e?.setAttribute("data-qualifier", "portfolioWizard/mainInfo/benchmark")}
							control={control}
							formState={formState}
							name="primaryBenchmark"
							classList="mb-4 max-w-[470px]"
							label="Comparative benchmark"
							options={benchmarks}
							i18n={{
								triggerPlaceholder: () => t("PORTFOLIOS.PORTFOLIO_BENCHMARK_PLACEHOLDER"),
							}}
							style={{ width: "100%" }}
						/>
					)}
				</ReactQueryWrapperBase>
			) : (
				<FormField label="Benchmark" classList="mb-4 max-w-[470px]">
					{observedPrimaryBenchmark?.benchmarkName}
				</FormField>
			)}

			{
				//#endregion
			}

			{
				//#region Reference
			}
			{!isEditProcessing && edit && area.editable ? (
				<ReactQueryWrapperBase
					query={selectableQuery}
					loadingFallback={<CircularProgressBar style={{ width: "1rem" }} value="indeterminate" />}
				>
					{({ references }) => (
						<div className="relative mb-4 max-w-[470px]">
							<FormFields.Select
								enableSearch
								data-qualifier="portfolioWizard/mainInfo/reference/options"
								innerRef={(e) => e?.setAttribute("data-qualifier", "portfolioWizard/mainInfo/reference")}
								control={control}
								formState={formState}
								name="investmentReference"
								label={({ htmlFor }) => (
									<div className="flex space-x-2 items-center w-fit mb-px">
										<Label htmlFor={htmlFor}>Reference</Label>{" "}
										<AutoTooltip
											position="top"
											severity="info"
											trigger={({ innerRef }) => (
												<Icon icon="info" size={14} color={themeCSSVars.palette_S600} innerRef={innerRef} />
											)}
										>
											The reference can be used to represent either the neutral positioning of your portfolio or to
											apply a Tracking Error constraint in the Risk Constraints section below.
										</AutoTooltip>
									</div>
								)}
								i18n={{ triggerPlaceholder: () => t("PORTFOLIOS.PORTFOLIO_REFERENCE_PLACEHOLDER") }}
								style={{ width: "100%" }}
								options={references}
								unselectOnMatch
							/>

							<div className="absolute right-0 top-0">
								<Text type="Body/M/Book" as="p" classList="text-right">
									Optional
								</Text>
							</div>
						</div>
					)}
				</ReactQueryWrapperBase>
			) : (
				<FormField label="Reference" classList="mb-4 max-w-[470px]">
					{observedInvestmentReference?.name || "-"}
				</FormField>
			)}
			{
				//#endregion
			}

			{
				//#region Target Volatility
			}
			<ReactQueryWrapperBase
				query={selectableQuery}
				loadingFallback={<CircularProgressBar style={{ width: "1rem" }} value="indeterminate" />}
			>
				{() => {
					const benchmarkVol = observedPrimaryBenchmark?.volatility;
					const referenceVol = observedInvestmentReference?.volatility;

					return (
						<div className="max-w-[470px] overflow-hidden mb-4">
							{!isEditProcessing && edit && area.editable ? (
								<div>
									<div className="flex space-x-2 items-end w-full mb-2">
										<FormFields.NullableNumber
											control={control}
											data-qualifier="portfolioWizard/mainInfo/targetVolatility"
											name="targetVolatility.selectedValue"
											label={({ htmlFor }) => (
												<Label htmlFor={htmlFor} classList="mb-px">
													Target volatility
												</Label>
											)}
											formState={formState}
											classList="max-w-[110px]"
											inputAppearance={{ style: { textAlign: "right" } }}
											rightContent={<Icon icon="Percentile" size={14} />}
											hideBanner
										/>
										<div className="flex-1 flex flex-col justify-end w-[calc(470px-110px-8px)] relative h-[51px]">
											<div className="absolute right-0 top-0">
												<Text type="Body/M/Book" as="p" classList="text-right">
													Optional
												</Text>
											</div>

											<Text type="Body/M/Book" as="div" classList="truncate pr-4 h-fit">
												{benchmarkVol ? `Benchmark volatility: ${benchmarkVol}%` : ""}
											</Text>
											<Text type="Body/M/Book" as="div" classList="truncate pr-4 h-fit !leading-none">
												{referenceVol ? `Reference volatility: ${referenceVol}%` : ""}
											</Text>
										</div>
									</div>
									{formState.errors.targetVolatility?.selectedValue?.message && (
										<Banner severity="error">{formState.errors.targetVolatility?.selectedValue?.message}</Banner>
									)}
								</div>
							) : (
								<FormField label="Target Volatility" classList="max-w-[470px]">
									<Text as="p" type="Body/M/Book">
										Value:{"\xa0"}
										<Text as="span" type="Body/S/Bold">
											{observedTargetVolatility?.selectedValue ? `${observedTargetVolatility?.selectedValue}%` : "-"}
										</Text>
									</Text>
									{benchmarkVol || referenceVol ? (
										<>
											({benchmarkVol ? `Benchmark volatility: ${benchmarkVol}%${referenceVol ? "\xa0" : ""}` : ""}
											{referenceVol ? `Reference volatility: ${referenceVol}%` : ""})
										</>
									) : (
										""
									)}
								</FormField>
							)}
						</div>
					);
				}}
			</ReactQueryWrapperBase>
			{
				//#endregion
			}

			{
				//#region Commentary Type
			}

			{
				//#endregion
			}
		</StepBase>
	);
}
