import type {
	EntityManagementBaseDialogCommon,
	EntityManagementBaseDialogProps,
	SpawnEntityManagementBaseDialogParams,
} from "./_base-dialog";
import { EntityManagementBaseDialog, spawnEntityManagementBaseDialog } from "./_base-dialog";

export type RenameDialogCommon<ID> = EntityManagementBaseDialogCommon<ID> & { currentName: string };
export type RenameDialogProps<ID> = EntityManagementBaseDialogProps<ID> & { currentName: string };
export type SpawnRenameDialogParams<ID> = SpawnEntityManagementBaseDialogParams<ID> & { currentName: string };

function withDefaults<ID, T extends SpawnRenameDialogParams<ID> | RenameDialogProps<ID>>(
	params: T,
): EntityManagementBaseDialogCommon<ID> & T {
	return {
		header: params.header ?? (params.entityType ? `Rename ${params.entityType}` : "Rename"),
		inputLabel: params.inputLabel ?? `Rename "${params.currentName}" to...`,
		size: "large",
		...params,
	};
}

export function spawnRenameDialog<ID>(params: SpawnRenameDialogParams<ID>): Promise<ID> {
	return spawnEntityManagementBaseDialog(withDefaults(params));
}

export function RenameDialog<ID>(props: RenameDialogProps<ID>): JSX.Element {
	return <EntityManagementBaseDialog {...withDefaults(props)} />;
}
