import type { InvestmentExportConverterType } from "$root/api/api-gen";
import { IntegrationsControllerApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { downloadContentDisposition } from "$root/utils/files";
import { parallelize } from "$root/utils/promise";

const useCompositionDownload = () => {
	const integrationsApi = useApiGen(IntegrationsControllerApiFactory);

	async function downloadInvestmentConversion(
		format: InvestmentExportConverterType,
		uuid: string,
		enhancement?: boolean,
		persist?: boolean,
	) {
		const composition = await axiosExtract(integrationsApi.exportInvestment(uuid, enhancement ?? false));
		const response = await integrationsApi.convertTo(format, [composition], persist, {
			responseType: "blob",
		});
		downloadContentDisposition(response);
	}

	async function scheduleMultiInvestmentsConversion(
		format: InvestmentExportConverterType,
		list: Array<{ uuid: string; enhancement?: boolean }>,
		persist: boolean = true,
	) {
		const exportInvestmentCalls = list.map(
			(investment) => () =>
				axiosExtract(integrationsApi.exportInvestment(investment.uuid, investment.enhancement ?? false)),
		);
		const compositions = await parallelize(exportInvestmentCalls, { concurrency: 3 });
		await integrationsApi.convertTo(format, compositions, persist);
	}

	return { downloadInvestmentConversion, scheduleMultiInvestmentsConversion };
};

export default useCompositionDownload;
