import type { InvestmentStatuses, RichAccessControl } from "$root/api/api-gen";
import { ReferenceUniversesControllerApiFactory } from "$root/api/api-gen";
import { reportPlatformError } from "$root/api/error-reporting";
import { getApiGen } from "$root/api/factory";
import type { EntityManagementActions } from "$root/components/spawnable/entity-management/actions";
import { spawnDeleteDialog } from "$root/components/spawnable/entity-management/delete-dialog";
import { spawnDuplicateDialog } from "$root/components/spawnable/entity-management/duplicate-dialog";
import { spawnRenameDialog } from "$root/components/spawnable/entity-management/rename-dialog";
import { platformToast } from "$root/notification-system/toast";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { t } from "i18next";
import { aclByArea } from "../acl/checkers/all";
import { useUserValue } from "../user";
import { useMemo } from "react";
import { trackMixPanelEvent } from "$root/third-party-integrations/initMixPanel";

export function useUniverseEntityManagementActions(
	universe:
		| {
				name?: string;
				uuid?: string;
				richAcl?: { acl?: RichAccessControl[] };
				referralInvestments?: unknown[];
				status?: InvestmentStatuses;
		  }
		| null
		| undefined,
	callbacks?: {
		onRename?(uuid: string): void;
		onDelete?(uuid: string): void;
		onDuplicate?(uuid: string): void;
	},
): Partial<Omit<EntityManagementActions, "createAsync">> {
	const user = useUserValue();
	return useMemo(
		() => (!universe ? {} : universeEntityManagementActions(user, universe, callbacks)),
		[callbacks, universe, user],
	);
}

export function universeEntityManagementActions(
	user: { id: string },
	universe: {
		name?: string;
		uuid?: string;
		richAcl?: { acl?: RichAccessControl[] };
		referralInvestments?: unknown[];
		status?: InvestmentStatuses;
	},
	callbacks?: {
		onRename?(uuid: string): void;
		onDelete?(uuid: string): void;
		onDuplicate?(uuid: string): void;
	},
): Partial<Omit<EntityManagementActions, "createAsync">> {
	const api = () => getApiGen(ReferenceUniversesControllerApiFactory); // accessor for lazy evaluation
	return {
		deleteAsync:
			!aclByArea.universe.canDelete(user.id, universe?.richAcl?.acl ?? []) ||
			(universe?.referralInvestments ?? []).length > 0 ||
			universe?.status === "RETRIEVING_DATA"
				? undefined
				: () =>
						spawnDeleteDialog({
							entityName: universe?.name ?? "",
							entityType: "universe",
							onDeleteAsync: async () => {
								try {
									await api().deleteUniverse(universe.uuid ?? "");

									trackMixPanelEvent("Universe", {
										Type: "Delete",
										ID: universe.uuid,
										Name: universe.name,
									});

									platformToast({
										children: t("UNIVERSE.DELETE_OK_MESSAGE", { name: universe.name }),
										severity: "success",
										icon: "Portfolio",
									});
									callbacks?.onDelete?.(universe.uuid ?? "");
								} catch (err) {
									reportPlatformError(err, "ERROR", "universe", `delete universe "${universe.uuid}"`);
									platformToast({
										children: t("SOMETHING_WENT_WRONG"),
										severity: "error",
										icon: "Portfolio",
									});
									throw err;
								}
							},
						}),
		duplicateAsync:
			!universe.status || invalidStatus.includes(universe.status)
				? undefined
				: () =>
						spawnDuplicateDialog({
							entityType: "universe",
							originalName: universe?.name ?? "",
							checkIfNameIsAvailable: (name, opts) => axiosExtract(api().isUniverseNameAvailable(name, opts)),
							onSubmitAsync: async ({ param: name }) => {
								try {
									const uuid = (await axiosExtract(api().duplicateUniverse(universe.uuid ?? "", name))).uuid ?? "";

									platformToast({
										children: t("UNIVERSE.DUPLICATE_OK_MESSAGE", { name }),
										severity: "success",
										icon: "Portfolio",
									});
									callbacks?.onDuplicate?.(uuid);

									return uuid;
								} catch (err) {
									reportPlatformError(err, "ERROR", "universe", `duplicate universe "${universe.uuid}"`);
									platformToast({
										children: t("SOMETHING_WENT_WRONG"),
										severity: "error",
										icon: "Portfolio",
									});
									throw err;
								}
							},
						}),
		renameAsync: !aclByArea.universe.canRename(user.id, universe?.richAcl?.acl ?? [])
			? undefined
			: () =>
					spawnRenameDialog({
						entityType: "universe",
						currentName: universe?.name ?? "",
						checkIfNameIsAvailable: (name, opts) => axiosExtract(api().isUniverseNameAvailable(name, opts)),
						onSubmitAsync: async ({ param: name }) => {
							try {
								await api().renameUniverse(universe.uuid ?? "", name);

								platformToast({
									children: t("UNIVERSE.RENAME_OK_MESSAGE", { name }),
									severity: "success",
									icon: "Portfolio",
								});
								callbacks?.onRename?.(name);

								return name;
							} catch (err) {
								reportPlatformError(err, "ERROR", "universe", `rename universe "${universe.uuid}"`);
								platformToast({
									children: t("SOMETHING_WENT_WRONG"),
									severity: "error",
									icon: "Portfolio",
								});
								throw err;
							}
						},
					}),
	};
}

const invalidStatus: Array<InvestmentStatuses> = ["REVIEW", "CALCULATING", "RETRIEVING_DATA", "PROPOSAL_READY"];
