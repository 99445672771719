import { formatDate } from "$root/localization/formatters";
import type {
	EntityManagementBaseDialogCommon,
	EntityManagementBaseDialogProps,
	SpawnEntityManagementBaseDialogParams,
} from "./_base-dialog";
import { EntityManagementBaseDialog, spawnEntityManagementBaseDialog } from "./_base-dialog";

export type DuplicateDialogCommon<ID = string> = Omit<EntityManagementBaseDialogCommon<ID>, "currentName"> & {
	originalName: string;
};
export type DuplicateDialogProps<ID = string> = Omit<EntityManagementBaseDialogProps<ID>, "currentName"> & {
	originalName: string;
};
export type SpawnDuplicateDialogParams<ID = string> = Omit<SpawnEntityManagementBaseDialogParams<ID>, "currentName"> & {
	originalName: string;
};

function withDefaults<ID, T extends SpawnDuplicateDialogParams<ID> | DuplicateDialogProps<ID>>(
	params: T,
): EntityManagementBaseDialogCommon<ID> & T {
	return {
		header: params.header ?? (params.entityType ? `Duplicate ${params.entityType}` : "Duplicate"),
		inputLabel: params.inputLabel ?? "Duplicate name",
		suggestedName: `${params.originalName.trim()} (Copy ${formatDate(Date.now())})`,
		size: "large",
		...params,
	};
}

export function spawnDuplicateDialog<ID>(params: SpawnDuplicateDialogParams<ID>): Promise<ID> {
	return spawnEntityManagementBaseDialog(withDefaults(params));
}

export function DuplicateDialog<ID>(props: DuplicateDialogProps<ID>): JSX.Element {
	return <EntityManagementBaseDialog {...withDefaults(props)} />;
}
