import type { RichAcl } from "$root/api/api-gen";
import type { TableColumn } from "@mdotm/mdotui/components";
import type { UserListEntryProps } from "./UsersList";
import { UsersList } from "./UsersList";
import type { MaybeFn } from "@mdotm/mdotui/utils";
import { maybeCall } from "@mdotm/mdotui/utils";

export function sharingWithColumn<TRow extends { richAcl?: RichAcl }>(params: {
	users: MaybeFn<UserListEntryProps[], [TRow]>;
}): TableColumn<TRow, "sharingViews"> {
	return {
		content: (row, props) => {
			return <UsersList {...props} users={maybeCall(params.users, row)} visibleSlots={3} size="small" />;
		},
		sortFn: (a, b) => (a.richAcl?.acl?.length ?? 0) - (b.richAcl?.acl?.length ?? 0),
		name: "sharingViews",
		header: "Sharing with",
		width: 192,
	};
}
