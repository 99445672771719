import { InvestmentExportControllerApiFactory, InvestmentReportsControllerApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import GraphLegend from "$root/components/GraphLegend";
import ColoredRectangle from "$root/components/icons/ColoredRectangle";
import DashedRectangle from "$root/components/icons/DashedRectangle";
import { useLocaleFormatters } from "$root/localization/hooks";
import {
	PortfolioQueryWidgetBase,
	WidgetStatus,
	portfolioWidgetMissingDataReason,
} from "$root/pages/PortfolioDetails/PortfolioWidgetStatus";
import { PaletteColors } from "$root/styles/themePalette";
import { trackMixPanelEvent } from "$root/third-party-integrations/initMixPanel";
import Highcharts from "$root/utils/chart/highcharts-with-modules";
import { downloadContentDisposition } from "$root/utils/files";
import type { ContextContent } from "$root/utils/react-extra";
import { withContext } from "$root/utils/react-extra";
import { useDebounced, useUnsafeUpdatedRef } from "@mdotm/mdotui/react-extensions";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { useWidgetOptions } from "$root/widgets-architecture/layout/WidgetsMapper/context";
import { DropdownMenu, DropdownMenuActionButton, Icon, Text } from "@mdotm/mdotui/components";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import HighchartsReact from "highcharts-react-official";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { PortfolioContext } from "../contexts/portfolio";
import { InfoTooltip } from "../layout/WidgetsMapper/InfoTooltip";
import { qualifier } from "$root/utils/qualifiers";
import equal from "fast-deep-equal";
import BigNumber from "bignumber.js";

type RealizedReturn = { dateInMilliseconds: number; return: number; performance: number };
type PerformanceSeries = { portfolioPerformance: Array<RealizedReturn>; benchmarkPerformance: Array<RealizedReturn> };
type DateRange = { min: number; max: number };

type NormaliseReturnEntry = RealizedReturn & { normalise?: boolean };
/**
 * flag item in the series as items to normalise
 * @param series
 * @param dateRange
 * @returns
 */
function flagItemSerieAsNomalise(series: Array<RealizedReturn>, dateRange?: DateRange): Array<NormaliseReturnEntry> {
	if (!dateRange) {
		return series;
	}

	const firstSelecteDateIndex = series.findIndex((item) => item.dateInMilliseconds >= dateRange.min);
	const lastSelecteDateIndex = series.findLastIndex((item) => item.dateInMilliseconds >= dateRange.max);
	return series.map((s, i) => ({ ...s, normalise: i >= firstSelecteDateIndex && i <= lastSelecteDateIndex }));
}

/**
 * normalize series
 * @param inceptionSerie
 * @param serie
 * @returns
 */
function normalizePerformanceSeries(serie: NormaliseReturnEntry, inceptionSerie?: NormaliseReturnEntry) {
	if (!serie.normalise || !inceptionSerie) {
		return serie.return;
	}

	const normalisedPerformance = serie.performance / inceptionSerie.performance - 1;
	const percentageNormalisedPerformance = normalisedPerformance * 100;

	return BigNumber(percentageNormalisedPerformance).decimalPlaces(2).toNumber();
}

const CustomizedChart: React.FC<{
	performanceSeries: PerformanceSeries;
}> = ({ performanceSeries }) => {
	const { t } = useTranslation();
	const colorCategories = useMemo(
		() => [
			{ name: t("PORTFOLIO_PERFORMANCE.CURRENT_PERF"), color: "#00AEEF", Component: ColoredRectangle },
			{ name: t("PORTFOLIO_PERFORMANCE.BENCHMARK"), color: "#8C8EA8", Component: DashedRectangle },
		],
		[t],
	);

	const dateRange = useMemo(() => {
		const sortedPerformanceSeries = {
			portfolioPerformance: Array.from(
				performanceSeries.portfolioPerformance.sort((a, b) => a.dateInMilliseconds - b.dateInMilliseconds),
			),
			benchmarkPerformance: Array.from(
				performanceSeries.benchmarkPerformance.sort((a, b) => a.dateInMilliseconds - b.dateInMilliseconds),
			),
		};

		const { benchmarkPerformance, portfolioPerformance } = sortedPerformanceSeries;
		portfolioPerformance[0].return = portfolioPerformance[0].return === 0 ? 0.00001 : portfolioPerformance[0].return;
		benchmarkPerformance[0].return = benchmarkPerformance[0].return === 0 ? 0.00001 : benchmarkPerformance[0].return;

		const minDate = Math.min(portfolioPerformance[0].dateInMilliseconds, benchmarkPerformance[0].dateInMilliseconds);
		const maxDate = Math.max(
			portfolioPerformance[portfolioPerformance.length - 1].dateInMilliseconds,
			benchmarkPerformance[benchmarkPerformance.length - 1].dateInMilliseconds,
		);
		return { min: minDate, max: maxDate };
	}, [performanceSeries]);

	const { formatDate, formatNumber } = useLocaleFormatters();
	const [userDateRange, setUserDateRange] = useState<DateRange>(dateRange);
	const debounceDateRangeUpdate = useDebounced(
		(newRange: DateRange) => {
			setUserDateRange(newRange);
		},
		{
			debounceInterval: 300,
		},
	);

	const series = useMemo(() => {
		const isDateRangeEqual = equal(userDateRange, dateRange);
		const sortedPerformance = performanceSeries.portfolioPerformance.sort(
			(a, b) => a.dateInMilliseconds - b.dateInMilliseconds,
		);
		const sortedBenchmark = performanceSeries.benchmarkPerformance.sort(
			(a, b) => a.dateInMilliseconds - b.dateInMilliseconds,
		);

		const flaggedPerformance = flagItemSerieAsNomalise(sortedPerformance, isDateRangeEqual ? undefined : userDateRange);
		const firstFlaggedPerformance = flaggedPerformance.find((x) => x.normalise);

		const flaggedBenchmark = flagItemSerieAsNomalise(sortedBenchmark, isDateRangeEqual ? undefined : userDateRange);
		const firstFlaggedBenchmark = flaggedBenchmark.find((x) => x.normalise);

		const normalizedSeries = {
			portfolioPerformance: flaggedPerformance.map((s, _index) => ({
				x: s.dateInMilliseconds,
				y: isDateRangeEqual ? s.return : normalizePerformanceSeries(s, firstFlaggedPerformance),
			})),
			benchmarkPerformance: flaggedBenchmark.map((s, _index) => ({
				x: s.dateInMilliseconds,
				y: isDateRangeEqual ? s.return : normalizePerformanceSeries(s, firstFlaggedBenchmark),
			})),
		};

		return normalizedSeries;
	}, [dateRange, performanceSeries.benchmarkPerformance, performanceSeries.portfolioPerformance, userDateRange]);

	const options = useMemo<Highcharts.Options>(
		() => ({
			chart: {
				style: {
					fontFamily: "Gotham,sans-serif",
				},
				panning: {
					enabled: false,
				},
			},
			credits: {
				enabled: false,
			},
			legend: {
				enabled: false,
			},
			title: {
				text: undefined,
			},
			subtitle: {
				text: undefined,
			},
			xAxis: [
				{
					type: "datetime",
					min: dateRange.min,
					max: dateRange.max,
					endOnTick: false,
					startOnTick: false,
					minTickInterval: Math.min(dateRange.max + 86400000 - dateRange.min, 1000 * 60 * 60 * 24),
					minRange: Math.min(dateRange.max + 86400000 - dateRange.min, 1000 * 60 * 60 * 24 * 7),
					events: {
						afterSetExtremes(this, event) {
							if (event.userMax && event.userMin) {
								debounceDateRangeUpdate.invoke({
									max: event.userMax ?? event.max,
									min: event.userMin ?? event.min,
								});
							}
						},
					},
				},
			],
			yAxis: {
				opposite: false,
				gridLine: 1,
				gridLineColor: "#CCCCCC",
			},
			boost: {
				debug: {
					timeRendering: true,
				},
				useGPUTranslations: true,
				seriesThreshold: 1,
			},
			tooltip: {
				enabled: true,
				shared: false,
				useHTML: true,
				borderColor: "transparent",
				borderRadius: 5,
				backgroundColor: PaletteColors.WHITE,

				formatter() {
					const content = `<div style="min-width: 12.5rem">
							<div style="text-align: center;
								border-radius: 0.25rem;
								background-color: #eeeef1;
								color: #9699b3;
								font-family: Gotham, sans-serif;
								font-weight: bold;
								line-height: 1.25rem;
								padding: 0.2rem 0;">
								<div>${formatDate(this.x)}</div>
							</div>
							<table style="width: 100%">
								<tbody>${(this.points || [])
									.map(
										(point) => `
										<tr>
											<td style="border-bottom: solid 1px transparent; padding: 5px 8px 0 0">
											<span style="display: flex; flex-direction: row; align-items: center">
												<span style="display: inline-block; width: 10px; height: 10px; margin-right: 6px; border-radius: 100px; background-color: ${
													point.color
												}">
											</span>
											${point.series.name}</span>
											</td>
											<td
												style="text-align: right; font-weight: 500; border-bottom: solid 1px transparent; padding: 5px 0 0 0; color: black"
											>
												${formatNumber(point.point.y, 2)}%
											</td>
										</tr>
									`,
									)
									.reverse()
									.join("")}
								</tbody>
							</table>
						</div>`;
					return content;
				},
			},
			plotOptions: {
				series: {
					showInNavigator: true,
				},
			},
			exporting: {
				enabled: true,
				chartOptions: {
					title: {
						text: t("PORTFOLIO_PERFORMANCE.TITLE"),
					},
					subtitle: {
						text: `Data Exported in ${new Date().getUTCFullYear()}-${
							new Date().getUTCMonth() + 1
						}-${new Date().getUTCDate()}`,
					},
					chart: {
						margin: 40,
						marginTop: 80,
						marginLeft: 80,
						marginBottom: 80,
					},
					scrollbar: {
						enabled: false,
					},
				},
				buttons: {
					contextButton: {
						menuItems: ["downloadJPEG", "downloadPDF"],
					},
				},
				allowHTML: true,
				printMaxWidth: 1080,
				filename: "MDotM_Portfolio_Performance",
				sourceHeight: 600,
				sourceWidth: 1440,
			},
			series: [
				{
					data: series.portfolioPerformance,
					color: colorCategories[0].color,
					marker: {
						fillColor: colorCategories[0].color,
					},
					name: t("PORTFOLIO_PERFORMANCE.CURRENT_PERF"),
					type: "line",
				},
				{
					data: series.benchmarkPerformance,
					color: colorCategories[1].color,
					dashStyle: "Dash",
					marker: {
						fillColor: colorCategories[1].color,
					},
					name: t("PORTFOLIO_PERFORMANCE.BENCHMARK"),
					type: "line",
				},
			],
			time: {
				// timezoneOffset: new Date().getTimezoneOffset(),
				timezoneOffset: 12 * 60, // Method to Fix Backend Date Time
				useUTC: false,
			},
			rangeSelector: {
				buttons: [
					{
						type: "day",
						count: 30,
						text: "1m",
					},
					{
						type: "day",
						count: 90,
						text: "3m",
					},
					{
						type: "day",
						count: 180,
						text: "6m",
					},
					{
						type: "day",
						count: 360,
						text: "1y",
					},
					{
						type: "all",
						text: "All",
					},
				],
			},
		}),
		[
			colorCategories,
			dateRange.max,
			dateRange.min,
			debounceDateRangeUpdate,
			formatDate,
			formatNumber,
			series.benchmarkPerformance,
			series.portfolioPerformance,
			t,
		],
	);

	return (
		<>
			<div style={{ flex: 1 }}>
				<HighchartsReact
					containerProps={{ style: { height: "100%" } }}
					highcharts={Highcharts}
					constructorType="stockChart"
					options={options}
				/>
			</div>
			<div style={{ flex: 0 }}>
				<GraphLegend style={{ position: "static", padding: "1rem 1rem", margin: 0 }}>
					{colorCategories.map(({ name, color, Component }) => (
						<div key={color} className="legend-container light more-space book">
							<Component variant="vertical" color={color} />
							{name}
						</div>
					))}
				</GraphLegend>
			</div>
		</>
	);
};

const PortfolioPerformance = ({
	portfolio,
	reportsExecution,
	reportExcutionCounter,
	selectedBenchmark: benchmarkId,
}: ContextContent<typeof PortfolioContext>) => {
	const exportApi = useApiGen(InvestmentExportControllerApiFactory);
	const reportInvestmentApi = useApiGen(InvestmentReportsControllerApiFactory);

	const { t } = useTranslation();

	const performanceSeriesQueryFn = useCallback(async () => {
		if (!portfolio?.uuid) {
			throw new Error("missing portfolio");
		}
		const { data } = await reportInvestmentApi.getInvestmentPerformance(portfolio.uuid, benchmarkId ?? "");

		if (!data.current || !data.benchmark) {
			return {
				data: undefined,
				widgetStatus: portfolioWidgetMissingDataReason(portfolio!, "PortfolioPerformance"),
			};
		}

		return {
			data: {
				portfolioPerformance: data.current.map(
					([dateSeconds, x]): RealizedReturn => ({
						dateInMilliseconds: dateSeconds * 1000,
						return: x,
						performance: x * 0.01 + 1,
					}),
				),
				benchmarkPerformance: data.benchmark.map(
					([dateSeconds, x]): RealizedReturn => ({
						dateInMilliseconds: dateSeconds * 1000,
						return: x,
						performance: x * 0.01 + 1,
					}),
				),
			},
			widgetStatus: WidgetStatus.READY as const,
		};
	}, [benchmarkId, portfolio, reportInvestmentApi]);

	const exportPortfolioPerformance = async () => {
		const response = await exportApi.exportPerformance(portfolio!.uuid!, benchmarkId ?? "", { responseType: "blob" });
		downloadContentDisposition(response);

		trackMixPanelEvent("Portfolio", {
			Type: "Export",
			Area: `Performance`,
		});
	};

	const exportPortfolioPerformanceRef = useUnsafeUpdatedRef(exportPortfolioPerformance);

	// UseQuery
	const query = useQueryNoRefetch(["portfolio-performance", portfolio, benchmarkId, reportExcutionCounter], {
		enabled: Boolean(portfolio) && Boolean(benchmarkId),
		queryFn: performanceSeriesQueryFn,
	});

	useWidgetOptions(
		() => ({
			actionHeader: function Download() {
				return (
					<div style={{ display: "flex", flexDirection: "row" }} className="space-x-2">
						{query.data?.widgetStatus === WidgetStatus.READY && (
							<DropdownMenu
								trigger={({ innerRef, open, ...forward }) => (
									<button ref={innerRef} aria-expanded={open} type="button" {...forward}>
										<Icon icon="Dowload" color={themeCSSVars.MessageSeverity_success} size={20} />
									</button>
								)}
								actions={[
									({ onClose }) => (
										<DropdownMenuActionButton
											key="performance"
											icon="Dowload"
											onClickAsync={async () => {
												await exportPortfolioPerformanceRef.current();
												onClose();
											}}
										>
											{t("PORTFOLIO_PERFORMANCE.DOWNLOAD_TITLE")}
										</DropdownMenuActionButton>
									),
								]}
							/>
						)}
						<InfoTooltip>{t("PORTFOLIO_PERFORMANCE.TOOLTIP")}</InfoTooltip>
					</div>
				);
			},
			title: (
				<Text
					type="Body/XL/Bold"
					title={t("PORTFOLIO_PERFORMANCE.TITLE")}
					classList="truncate"
					data-qualifier={qualifier.widgets.portfolioPerformance.name}
				>
					{t("PORTFOLIO_PERFORMANCE.TITLE")}
				</Text>
			),
		}),
		[exportPortfolioPerformanceRef, query.data?.widgetStatus, t],
	);

	return (
		<PortfolioQueryWidgetBase query={query}>
			{(performanceSeries) => <CustomizedChart performanceSeries={performanceSeries} />}
		</PortfolioQueryWidgetBase>
	);
};

export default withContext(PortfolioContext)(PortfolioPerformance);
