import type {
	ForecastHorizons,
	InvestmentSummary,
	MarketScenario,
	PerformanceContributionEntry,
} from "$root/api/api-gen";
import {
	InvestmentEnhancementReportsControllerApiFactory,
	InvestmentReportsControllerApiFactory,
	MarketViewControllerApiFactory,
	PortfolioStudioPreferencesApiFactory,
	ScenarioAnalysisControllerApiFactory,
} from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { DataDisplayOverlay } from "$root/components/DataDisplayOverlay";
import type { MarketViewProps } from "$root/components/Portfolio/MarketView";
import ColoredRectangle from "$root/components/icons/ColoredRectangle";
import { builtInSortFnFor } from "$root/utils/collections";
import { useSize } from "$root/utils/react-dom-extra";
import type { ContextContent } from "$root/utils/react-extra";
import { withContext } from "$root/utils/react-extra";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { PortfolioContext } from "$root/widgets-architecture/contexts/portfolio";
import type { OrderBy, TableColumn } from "@mdotm/mdotui/components";
import {
	AutoSortHScrollTable,
	CircularProgressBar,
	defaultRowHeight,
	ProgressBar,
	Row,
	Text,
} from "@mdotm/mdotui/components";
import { memo, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	applyDeltaToSelectedMarketView,
	removeDeltaFromSelectedMarketView,
	round2Dec,
	roundCustom,
} from "../../../components/Portfolio/MarketView/utilsV2";
import { BarGraph } from "./BarGraph";
import { MarketViewProbabilities } from "$root/functional-areas/market-view/MarketViewProbabilities";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { useLocaleFormatters } from "$root/localization/hooks";
import { IconWalls } from "$root/components/IconWall";
import { ForEach, overrideClassList, useDebouncedMemo } from "@mdotm/mdotui/react-extensions";
import { useInstrumentColumnsTableV2 } from "$root/functional-areas/instruments/hooks";

type portfolioPerformanceColumnProps = {
	metrics: string;
	portfolio?: number;
	benchmark?: number;
	graph: string;
};

type portfolioPerformanceContributionColumnProps = {
	portfolioReturns: number;
	portfolioExposure: number;
	benchmarkReturns: number;
	assetClass: string;
};

type ScenarioCategoryProps = {
	value: string;
	label: string;
	group: "Sphere forecasts" | "My views" | "Historical scenarios" | "";
	scenarioType: MarketViewProps["scenario"];
	forecastHorizon?: ForecastHorizons;
	enhancable: boolean;
	custom?: boolean;
};

const EXPECTED_PTF_PERFORMANCE_LEGENDS = [
	{ name: "Portfolio", color: "#00AEEF" },
	{ name: "Benchmark", color: "#D1D2DC" },
];
const EXPECTED_PTF_PERFORMANCE_CONTRIBUTION_LEGENDS = [
	{ name: "Portfolio returns", color: "#00AEEF" },
	{ name: "Benchmark returns", color: "#D1D2DC" },
];

const ScenarioAnalysisEditableContentMemo = memo<{
	sphereScenario: MarketScenario;
	summary: InvestmentSummary;
	benchmarkId: string | null;
	performanceContributions: PerformanceContributionEntry[];
	enhanced: boolean;
}>(function ScenarioAnalysisEditableContent({ sphereScenario, summary, performanceContributions, enhanced }) {
	const [size, setSize] = useState<HTMLDivElement | null>(null);
	const portfolioContributionPerformanceRowSize = useSize(size)?.width;
	const marketViewApi = useApiGen(MarketViewControllerApiFactory);
	const scenarioApi = useApiGen(ScenarioAnalysisControllerApiFactory);
	const portfolioStudioPreferenceApi = useApiGen(PortfolioStudioPreferencesApiFactory);

	const showContributionGraph = useMemo(() => {
		if (!portfolioContributionPerformanceRowSize) {
			return true;
		}
		const percentOfGraphWidth = 20;
		const graphColumnWidth = (portfolioContributionPerformanceRowSize * percentOfGraphWidth) / 100;
		return graphColumnWidth > 110;
	}, [portfolioContributionPerformanceRowSize]);

	const { uuid } = summary ?? {};
	const { t } = useTranslation();
	const { formatNumber } = useLocaleFormatters();

	const [currentScenarioId, setCurrentScenarioId] = useState<string | null>("SPHERE_FORECAST_ONE_MONTH");
	const [originalScenario, setOriginalScenario] = useState<MarketScenario>(sphereScenario);
	const [marketScenario, setMarketScenario] = useState<MarketScenario>(sphereScenario);

	// Get Options List
	const { data: scenarioAnalysisList } = useQueryNoRefetch(["initScenarioEditable"], {
		enabled: Boolean(uuid),
		queryFn: async () => {
			const { data } = await scenarioApi.getScenarioList(uuid, enhanced);
			return data.scenarios;
		},
		onError: (err) => {
			console.error(err);
		},
		onSuccess: (_data) => {
			const defaultScenario = "SPHERE_FORECAST_ONE_MONTH";
			setCurrentScenarioId(defaultScenario);
		},
	});
	const selectedScenario = useMemo(
		() => scenarioAnalysisList?.find((x) => x.id === currentScenarioId),
		[currentScenarioId, scenarioAnalysisList],
	);

	const { data: marketViewSettings } = useQueryNoRefetch(["queryMarkseScenarioEditableTemplateConfig"], {
		queryFn: async () => ({
			setting: await axiosExtract(marketViewApi.getMarketViewUserSettings()),
			alias: await axiosExtract(portfolioStudioPreferenceApi.getMarketViewAssetClassAliases()),
		}),
	});

	const { value: queryKey } = useDebouncedMemo(
		() => [selectedScenario, marketScenario],
		[selectedScenario, marketScenario],
		{ debounceInterval: 300 },
	);

	const performanceQuery = useQueryNoRefetch(["queryMarkseScenarioEditablePerformance", queryKey], {
		keepPreviousData: true,
		enabled: selectedScenario != null,
		queryFn: () => {
			const { flexibleExpectedReturnsVolatility } = removeDeltaFromSelectedMarketView(
				marketScenario.flexibleExpectedReturnsVolatility!.assetClasses!,
				originalScenario.flexibleExpectedReturnsVolatility!.assetClasses!,
				marketScenario.regimeUserProbability!,
			);

			return axiosExtract(
				scenarioApi.getExpectedPerformance({
					enhancement: enhanced,
					expectedReturnsWithDelta: { assetClasses: flexibleExpectedReturnsVolatility } /* TODO: test */,
					investmentUuid: uuid,
					scenarioId: selectedScenario!.id,
				}),
			);
		},
	});

	const { data: performance } = performanceQuery;

	const portfolioContributionPerformanceRow = useMemo<Array<{
		portfolioExposure: number;
		portfolioReturns: number;
		benchmarkReturns: number;
		portfolioVolatility: number;
		benchmarkVolatility: number;
		assetClass: string;
	}> | null>(() => {
		if (selectedScenario == null) {
			return null;
		}
		return (
			performance?.expectedPerformanceAssetClassEntries?.map((x) => ({
				portfolioExposure: round2Dec(x.portfolioExposure!),
				portfolioReturns: round2Dec(x.portfolioReturns!),
				benchmarkReturns: round2Dec(x.benchmarkReturns!),
				portfolioVolatility: round2Dec(x.portfolioVolatility!),
				benchmarkVolatility: round2Dec(x.benchmarkVolatility!),
				assetClass: `${x.macroAssetClass} ${x.macroGeography} ${x.microAssetClass}`,
			})) ?? null
		);
	}, [performance?.expectedPerformanceAssetClassEntries, selectedScenario]);

	const { formatDate } = useLocaleFormatters();

	const scenarioOptions = useMemo(() => {
		if (!scenarioAnalysisList || !scenarioAnalysisList) {
			return [];
		}
		const composeAcc = (
			value: string,
			label: string,
			group: ScenarioCategoryProps["group"],
			scenarioType: ScenarioCategoryProps["scenarioType"],
			enhancable: boolean,
			forecastHorizon?: ForecastHorizons,
			custom?: boolean,
		) => ({ value, label, group, enhancable, forecastHorizon, scenarioType, custom });

		const { custom, historical, standard } = scenarioAnalysisList.reduce(
			(acc, el) => {
				const { historical: historicalAcc, standard: standardAcc, custom: customAcc } = acc;
				const { id, label, userCustomScenario, forecastHorizon } = el;

				if ((id ?? "").includes("CURRENT_PORTFOLIO") || (id ?? "").includes("SPHERE_FORECAST")) {
					const newLabel =
						id === "CURRENT_PORTFOLIO"
							? `Market view ${formatDate(marketScenario?.historicalInvestmentHorizon?.from)}`
							: label ?? "-";
					const currPtf = composeAcc(
						id ?? "-",
						newLabel,
						"",
						id === "CURRENT_PORTFOLIO" ? "custom" : "sphere",
						(id ?? "").includes("SPHERE_FORECAST"),
						forecastHorizon,
					);
					return { historical: historicalAcc, custom: customAcc, standard: [...standardAcc, currPtf] };
				}

				if (userCustomScenario) {
					return {
						standard: standardAcc,
						historical: historicalAcc,
						custom: [
							...customAcc,
							composeAcc(id ?? "-", label ?? "-", "My views", "custom", true, forecastHorizon, el.customMarketView),
						],
					};
				}

				return {
					standard: standardAcc,
					custom: customAcc,
					historical: [
						...historicalAcc,
						composeAcc(id ?? "-", label ?? "-", "Historical scenarios", "historical", false, forecastHorizon),
					],
				};
			},
			{
				historical: [] as Array<ScenarioCategoryProps>,
				standard: [] as Array<ScenarioCategoryProps>,
				custom: [] as Array<ScenarioCategoryProps>,
			},
		);

		const sortHistorycalRegimes = (a: ScenarioCategoryProps, b: ScenarioCategoryProps) => {
			const rgxHelper = (s: string) => {
				const rgx = new RegExp(/[0-9]{4}/);
				const matches = rgx.exec(s);
				return matches ? matches[0] : "";
			};

			const valueA = rgxHelper(a.label);
			const valueB = rgxHelper(b.label);

			if (valueA > valueB) {
				return 1;
			}
			if (valueA < valueB) {
				return -1;
			}
			return 0;
		};

		return standard.concat(custom.sort(builtInSortFnFor("value"))).concat(historical.sort(sortHistorycalRegimes));
	}, [formatDate, marketScenario?.historicalInvestmentHorizon?.from, scenarioAnalysisList]);

	// Totals PTF Performance
	const portfolioPerformanceRow = useMemo(() => {
		if (!performance) {
			return null;
		}
		return [
			{
				benchmark: performance.benchmarkTotalAnnualizedReturns,
				graph: "",
				metrics: "Annualised returns",
				portfolio: performance.totalAnnualizedReturns,
			},
			{
				benchmark: performance.benchmarkTotalAnnualizedVolatility,
				graph: "",
				metrics: "Annualised volatility",
				portfolio: performance.totalAnnualizedVolatility,
			},
		];
	}, [performance]);

	const derivedContributionGraphLimits = useMemo(() => {
		if (!portfolioContributionPerformanceRow || !portfolioPerformanceRow) {
			return null;
		}
		const portfolioAndBenchmarkCReturns = portfolioContributionPerformanceRow
			.map((el) => Math.abs(el.benchmarkReturns))
			.concat(portfolioContributionPerformanceRow.map((el) => Math.abs(el.portfolioReturns)));
		const MAX = roundCustom(Math.max(...portfolioAndBenchmarkCReturns));
		return {
			max: MAX,
			min: MAX * -1,
		};
	}, [portfolioContributionPerformanceRow, portfolioPerformanceRow]);

	const derivedPerformanceGraphLimits = useMemo(() => {
		if (!portfolioPerformanceRow) {
			return null;
		}
		const portfolioAndBenchmarkVolatilityReturns = portfolioPerformanceRow.map((el) => [
			Math.abs(el.benchmark ?? NaN),
			Math.abs(el.portfolio ?? NaN),
		]);
		const MAX = roundCustom(Math.max(...portfolioAndBenchmarkVolatilityReturns.flat()));
		return {
			max: MAX,
			min: MAX * -1,
		};
	}, [portfolioPerformanceRow]);

	// Highlight data that is related to the portfolio
	const highlightedAssetClass = useMemo(() => {
		const filteredAC = performanceContributions.filter((el) => (el.portfolioExposure ?? 0) > 0);
		const selectedAC = filteredAC.reduce((acc, curr) => {
			const newAC =
				curr.marketViewAssetClassLabel && curr.marketViewAssetClassLabel.length > 0
					? curr.marketViewAssetClassLabel.map((el) => el.tickerName!)
					: [];
			if (curr.geographicalLink) {
				newAC.push(curr.geographicalLink);
			}
			return [...acc, ...newAC];
		}, [] as string[]);
		return [...new Set(selectedAC)];
	}, [performanceContributions]);

	// TableColumns
	const portfolioPerformanceColumn = useMemo<TableColumn<portfolioPerformanceColumnProps>[]>(
		() => [
			{
				name: "metric",
				header: t("SCENARIO_ANALYSIS_EDITABLE.TABLES_FIELDS.METRIC"),
				content: ({ metrics }) => metrics,
				minWidth: 264,
				footer: (footerProps) => (
					<Row {...footerProps} gap={16} classList="py-4">
						{EXPECTED_PTF_PERFORMANCE_LEGENDS.map(({ name, color }) => (
							<div key={color} className="flex items-center gap-1">
								<ColoredRectangle variant="vertical" color={color} />
								{name}
							</div>
						))}
					</Row>
				),
			},
			{
				name: "portfolio",
				header: t("SCENARIO_ANALYSIS_EDITABLE.TABLES_FIELDS.PORTFOLIO"),
				cellClassList: "tabular-nums",
				content: ({ portfolio }) => `${formatNumber(portfolio)}%`,
				align: "end" as const,
				width: 144,
			},
			{
				name: "benchmark",
				header: t("SCENARIO_ANALYSIS_EDITABLE.TABLES_FIELDS.BENCHMARK"),
				cellClassList: "tabular-nums",
				content: ({ benchmark }) => `${formatNumber(benchmark)}%`,
				align: "end" as const,
				width: 144,
			},
			{
				name: "graph",
				width: 200,
				header: (headerProps) => {
					const metrics = [
						`${formatNumber(derivedPerformanceGraphLimits?.min, { minDecimalPlaces: 0, maxDecimalPlaces: 2 })}%`,
						"0",
						`${formatNumber(derivedPerformanceGraphLimits?.max, { minDecimalPlaces: 0, maxDecimalPlaces: 2 })}%`,
					];
					return (
						<Row {...headerProps} alignItems="center" justifyContent="space-between">
							<ForEach collection={metrics}>
								{({ item }) => <p className="text-[10px] text-[#667085] font-[400]">{item}</p>}
							</ForEach>
						</Row>
					);
				},
				content: ({ portfolio, benchmark }, cellProps) => {
					return (
						<Row
							justifyContent="center"
							{...cellProps}
							style={{ ...cellProps.style, height: defaultRowHeight }}
							classList={overrideClassList(cellProps.classList, "py-2")}
						>
							<BarGraph
								bars={[portfolio ?? 0, benchmark ?? 0]}
								scale={{
									min: derivedPerformanceGraphLimits?.min ?? 0,
									max: derivedPerformanceGraphLimits?.max ?? 0,
								}}
							/>
						</Row>
					);
				},
				hidden: !showContributionGraph,
				footer: (footerProps) => {
					const metrics = [
						`${formatNumber(derivedPerformanceGraphLimits?.min, { minDecimalPlaces: 0, maxDecimalPlaces: 2 })}%`,
						"0",
						`${formatNumber(derivedPerformanceGraphLimits?.max, { minDecimalPlaces: 0, maxDecimalPlaces: 2 })}%`,
					];
					return (
						<Row {...footerProps} alignItems="center" justifyContent="space-between">
							<ForEach collection={metrics}>
								{({ item }) => <p className="text-[10px] text-[#667085] font-[400]">{item}</p>}
							</ForEach>
						</Row>
					);
				},
			},
		],
		[derivedPerformanceGraphLimits?.max, derivedPerformanceGraphLimits?.min, formatNumber, showContributionGraph, t],
	);

	const instrumentColumns = useInstrumentColumnsTableV2();

	const portfolioPerformanceContributionColumn = useMemo<
		TableColumn<portfolioPerformanceContributionColumnProps>[]
	>(() => {
		// TODO: verify width with production!
		return [
			instrumentColumns.assetClass,
			{
				header: t("SCENARIO_ANALYSIS_EDITABLE.TABLES_FIELDS.PORTFOLIO_EXPOSURE"),
				cellClassList: "tabular-nums",
				content: ({ portfolioExposure }) => `${formatNumber(portfolioExposure)}%`,
				width: 144,
				// TODO relativeWidth: !showContributionGraph ? 2.0 : 1.5,
				sortFn: builtInSortFnFor("portfolioExposure"),
				name: "portfolioExposure",
				hiderOrderableIcon: false,
				align: "end",
			},
			{
				header: t("SCENARIO_ANALYSIS_EDITABLE.TABLES_FIELDS.PORTFOLIO_RETURNS"),
				cellClassList: "tabular-nums",
				content: ({ portfolioReturns }) => `${formatNumber(portfolioReturns)}%`,
				align: "end",
				width: 144,
				// TODO relativeWidth: !showContributionGraph ? 2.0 : 1.5,
				sortFn: builtInSortFnFor("portfolioReturns"),
				name: "portfolioReturns",
				hiderOrderableIcon: false,
			},
			{
				header: t("SCENARIO_ANALYSIS_EDITABLE.TABLES_FIELDS.BENCHMARK_RETURNS"),
				cellClassList: "tabular-nums",
				content: ({ benchmarkReturns }) => `${formatNumber(benchmarkReturns)}%`,
				align: "end",
				width: 144,
				// TODO relativeWidth: !showContributionGraph ? 2.0 : 1.5,
				sortFn: builtInSortFnFor("benchmarkReturns"),
				name: "benchmarkReturns",
				hiderOrderableIcon: false,
			},
			{
				name: "graph",
				width: 200,
				header: (headerProps) => {
					const metrics = [
						`${formatNumber(derivedContributionGraphLimits?.min, { minDecimalPlaces: 0, maxDecimalPlaces: 2 })}%`,
						"0",
						`${formatNumber(derivedContributionGraphLimits?.max, { minDecimalPlaces: 0, maxDecimalPlaces: 2 })}%`,
					];
					return (
						<Row {...headerProps} alignItems="center" justifyContent="space-between">
							<ForEach collection={metrics}>
								{({ item }) => <p className="text-[10px] text-[#667085] font-[400]">{item}</p>}
							</ForEach>
						</Row>
					);
				},
				content: ({ portfolioReturns, benchmarkReturns }, cellProps) => {
					return (
						<Row
							justifyContent="center"
							{...cellProps}
							style={{ ...cellProps.style, height: defaultRowHeight }}
							classList={overrideClassList(cellProps.classList, "py-2")}
						>
							<BarGraph
								bars={[portfolioReturns, benchmarkReturns]}
								scale={{
									min: derivedContributionGraphLimits?.min ?? 0,
									max: derivedContributionGraphLimits?.max ?? 0,
								}}
							/>
						</Row>
					);
				},
				// TODO relativeWidth: showContributionGraph ? 2.0 : 0,
				cellClassList: showContributionGraph ? "mx-auto grow" : "",
				hideColumn: !showContributionGraph,
				footer: (footerProps) => {
					const metrics = [
						`${formatNumber(derivedContributionGraphLimits?.min, { minDecimalPlaces: 0, maxDecimalPlaces: 2 })}%`,
						"0",
						`${formatNumber(derivedContributionGraphLimits?.max, { minDecimalPlaces: 0, maxDecimalPlaces: 2 })}%`,
					];
					return (
						<Row {...footerProps} alignItems="center" justifyContent="space-between">
							<ForEach collection={metrics}>
								{({ item }) => <p className="text-[10px] text-[#667085] font-[400]">{item}</p>}
							</ForEach>
						</Row>
					);
				},
			},
		];
	}, [
		instrumentColumns.assetClass,
		t,
		showContributionGraph,
		formatNumber,
		derivedContributionGraphLimits?.min,
		derivedContributionGraphLimits?.max,
	]);

	return (
		<>
			<div className="grid" style={{ gridTemplateColumns: "805px 1fr" }}>
				<div className="py-4 pr-4 overflow-hidden border-r-2 border-[#EFF0F3]">
					{/* Market Scenario */}
					<div className="flex justify-between w-full mb-4">
						<Text type="Title/S">{t("SCENARIO_ANALYSIS_EDITABLE.MARKET_SCENARIO.MARKET_SCENARIO_TITLE")}</Text>
					</div>
					<div className="mb-4">
						<p>{t("SCENARIO_ANALYSIS_EDITABLE.MARKET_SCENARIO.MARKET_SCENARIO_DESCRIPTION")}</p>
					</div>
					<MarketViewProbabilities
						classList="!px-0"
						mode={selectedScenario?.editable ? "edit" : "view"}
						forceSelectMode="edit"
						marketScenarioProvider={async (scenarioId) => {
							const loadedScenario = await axiosExtract(scenarioApi.loadScenario(scenarioId, uuid));
							setOriginalScenario(loadedScenario);
							return loadedScenario;
						}}
						marketScenario={marketScenario}
						onMarketScenarioChange={setMarketScenario}
						scenarioOptions={scenarioOptions}
						selectedScenarioId={currentScenarioId}
						onSelectedScenarioChange={setCurrentScenarioId}
						highlightedAssetClass={highlightedAssetClass}
						alias={marketViewSettings?.alias.assetClassAliases}
						hideCommentary
						hidePositioning
					/>
				</div>
				<div className="py-4 pl-4 min-w-0">
					<div className="mb-4 flex items-center gap-2">
						<Text type="Title/S">Expected portfolio performance</Text>
						{performanceQuery.isFetching && (
							<CircularProgressBar value="indeterminate" outerDiameter={20} innerDiameter={12} />
						)}
					</div>
					<div className="mb-12">
						{portfolioPerformanceRow && (
							<AutoSortHScrollTable
								disableVirtualScroll
								columns={portfolioPerformanceColumn}
								rows={portfolioPerformanceRow}
							/>
						)}
					</div>
					<div className="mb-12">
						<div className="mb-4 flex items-center gap-2">
							<Text type="Title/S"> Expected performance contribution by asset class</Text>
							{performanceQuery.isFetching && (
								<CircularProgressBar value="indeterminate" outerDiameter={20} innerDiameter={12} />
							)}
						</div>
						<div ref={setSize} className="relative">
							{portfolioContributionPerformanceRow && (
								<AutoSortHScrollTable
									disableVirtualScroll
									columns={portfolioPerformanceContributionColumn}
									rows={portfolioContributionPerformanceRow}
									orderBy={defaultPortfolioPerformanceContributionOrderBy}
								/>
							)}

							<div className="flex gap-4 py-4">
								{EXPECTED_PTF_PERFORMANCE_CONTRIBUTION_LEGENDS.map(({ name, color }) => (
									<div key={color} className="flex items-center gap-1">
										<ColoredRectangle variant="vertical" color={color} />
										{name}
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
				<DataDisplayOverlay dataSource="Edit MarketView" data={marketScenario} />
				<DataDisplayOverlay
					data={(function () {
						if (!marketScenario || !originalScenario) {
							return [];
						}
						return removeDeltaFromSelectedMarketView(
							marketScenario.flexibleExpectedReturnsVolatility!.assetClasses!,
							originalScenario.flexibleExpectedReturnsVolatility!.assetClasses!,
							marketScenario.regimeUserProbability!,
						);
					})()}
					dataSource="ReConverted MarketView"
				/>
			</div>
		</>
	);
});

const defaultPortfolioPerformanceContributionOrderBy: OrderBy[] = [
	{ columnName: "portfolioExposure", direction: "desc" },
];

const ScenarioAnalysisEditable = ({
	portfolio,
	enhanced,
	selectedBenchmark,
}: ContextContent<typeof PortfolioContext>) => {
	const reportInvestmentApi = useApiGen(InvestmentReportsControllerApiFactory);
	const reportEnhanceApi = useApiGen(InvestmentEnhancementReportsControllerApiFactory);

	const { uuid } = portfolio ?? {};
	const benchmarkId = selectedBenchmark;

	const { data: performanceContributions, isLoading: isLoadingPerformanceContributions } = useQueryNoRefetch(
		["performanceContributions", uuid, benchmarkId],
		{
			enabled: Boolean(uuid) && Boolean(benchmarkId),
			queryFn: async () => {
				const { data } = enhanced
					? await reportEnhanceApi.getExposureDecomposition1(uuid ?? "", benchmarkId ?? "")
					: await reportInvestmentApi.getExposureDecomposition(uuid ?? "", benchmarkId ?? "");
				return data;
			},
			onError: (err) => {
				console.error(err);
			},
		},
	);

	const scenarioApi = useApiGen(ScenarioAnalysisControllerApiFactory);

	const { data: sphereScenario, isLoading: isLoadingSphereScenario } = useQueryNoRefetch(["sphereScenario"], {
		enabled: Boolean(portfolio),
		queryFn: async () => {
			const marketView = await axiosExtract(scenarioApi.loadScenario("SPHERE_FORECAST_ONE_MONTH", uuid, enhanced));
			const regimes = {
				lower: marketView.regimeUserProbability?.a ?? 0,
				upper: (marketView.regimeUserProbability?.a ?? 0) + (marketView.regimeUserProbability?.b ?? 0),
			};
			return {
				...marketView,
				flexibleExpectedReturnsVolatility: {
					assetClasses: applyDeltaToSelectedMarketView(
						{
							a: regimes.lower,
							b: regimes.upper - regimes.lower,
							c: 100 - regimes.upper,
						},
						marketView.flexibleExpectedReturnsVolatility?.assetClasses,
					),
				},
			} satisfies MarketScenario;
		},
		onError: (err) => {
			console.error(err);
		},
	});

	return (
		<>
			{isLoadingSphereScenario || isLoadingPerformanceContributions ? (
				<ProgressBar value="indeterminate" />
			) : sphereScenario && performanceContributions && portfolio ? (
				<ScenarioAnalysisEditableContentMemo
					performanceContributions={performanceContributions}
					sphereScenario={sphereScenario}
					summary={portfolio}
					benchmarkId={benchmarkId}
					enhanced={enhanced}
				/>
			) : (
				<div className="h-[720px] bg-white w-full">
					<IconWalls.ErrorData />
				</div>
			)}
		</>
	);
};

export default withContext(PortfolioContext)(ScenarioAnalysisEditable);
