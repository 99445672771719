import type { ForecastHorizons, Regimes } from "$root/api/api-gen";
import type { IUser } from "$root/functional-areas/user";

let mixPanelConfig = {
	uid: null as null | string,
	impersonating: false,
	isInternal: false,
};

export function configMixPanel(config: Partial<typeof mixPanelConfig>): void {
	mixPanelConfig = { ...mixPanelConfig, ...config };
}

const initMixPanel = (token: string, debug: boolean, iubendaTag: string, bypassIubenda: boolean): void => {
	// Enabling the debug mode flag is useful during implementation,
	// but it's recommended you remove it for production
	const mixPanelScript = document.createElement("script");
	mixPanelScript.text = `(function(f,b){if(!b.__SV){var e,g,i,h;window.mixpanel=b;b._i=[];b.init=function(e,f,c){function g(a,d){var b=d.split(".");2==b.length&&(a=a[b[0]],d=b[1]);a[d]=function(){a.push([d].concat(Array.prototype.slice.call(arguments,0)))}}var a=b;"undefined"!==typeof c?a=b[c]=[]:c="mixpanel";a.people=a.people||[];a.toString=function(a){var d="mixpanel";"mixpanel"!==c&&(d+="."+c);a||(d+=" (stub)");return d};a.people.toString=function(){return a.toString(1)+".people (stub)"};i="disable time_event track track_pageview track_links track_forms track_with_groups add_group set_group remove_group register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking start_batch_senders people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user people.remove".split(" ");
	for(h=0;h<i.length;h++)g(a,i[h]);var j="set set_once union unset remove delete".split(" ");a.get_group=function(){function b(c){d[c]=function(){call2_args=arguments;call2=[c].concat(Array.prototype.slice.call(call2_args,0));a.push([e,call2])}}for(var d={},e=["get_group"].concat(Array.prototype.slice.call(arguments,0)),c=0;c<j.length;c++)b(j[c]);return d};b._i.push([e,f,c])};b.__SV=1.2;e=f.createElement("script");e.type="text/javascript";e.async=!0;e.src="undefined"!==typeof MIXPANEL_CUSTOM_LIB_URL?
	MIXPANEL_CUSTOM_LIB_URL:"file:"===f.location.protocol&&"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js".match(/^\\/\\//)?"https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js":"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js";g=f.getElementsByTagName("script")[0];g.parentNode.insertBefore(e,g)}})(document,window.mixpanel||[]);

	mixpanel.init('${token}', {
		debug: ${debug},
		api_host: "https://fletcher.mdotm.net",
	});`;

	if (!bypassIubenda) {
		mixPanelScript.type = "text/plain";
		mixPanelScript.setAttribute("class", "_iub_cs_activate-inline");
		mixPanelScript.setAttribute("data-iub-purposes", `${iubendaTag}`);
	} else {
		mixPanelScript.type = "application/javascript";
	}
	document.head.appendChild(mixPanelScript);
};

export const initMixPanelCUser = (user: IUser): void => {
	if (typeof window.mixpanel === "undefined") {
		return;
	}
	if (mixPanelConfig.impersonating || mixPanelConfig.isInternal) {
		window.mixpanel.opt_out_tracking();
		return;
	}
	if (user.signedIn) {
		window.mixpanel.identify(user.email);
		window.mixpanel.people.set({
			$name: user.email,
			$email: user.email,
			$distinct_id: user.email,
			Uid: user.email,
		});

		// TODO: is this condition reversed? it was written by someone who's not part of the team anymore
		if (window.mixpanel.has_opted_out_tracking()) {
			window.mixpanel.opt_in_tracking();
		}
	}
};

// TODO: normalize
export type MixPanelEventMap = {
	"Page View": { Page: string };
	// Mixpanel Checked Events - VRequest
	"Custom-reports": {
		Type: "Export";
		Mode: "XLS" | "PDF";
	};
	Portfolio: {
		Action?: "save";
		ID?: string;
		Name?: string;
	} & (
		| {
				Type: "Review" | "Delete" | "Create" | "Enhance" | "Reject" | "Accept" | "Settings";
		  }
		| {
				Type: "Upload";
				Currency: string;
				Benchmark: string;
		  }
		| {
				Type: "Update";
				Area: "Composition";
				Mode:
					| "enhance"
					| "optimize"
					| "normal"
					| "enhance Without Accept"
					| "optimize Without Accept"
					| "normal Without Accept";
		  }
		| {
				Type: "Export";
				Area:
					| "History composition"
					| "Performance"
					| "composition"
					| "trades"
					| "Exposure Compare"
					| "Exposure"
					| "Factors";
				Mode?: "XLS" | "PDF";
		  }
	);
	Universe: {
		Action?: "save";
		ID?: string;
		Name?: string;
	} & (
		| {
				Type: "Upload" | "Edit Composition" | "Download Composition" | "Review" | "Review Composition" | "Delete";
		  }
		| {
				Type: "Async Upload";
				estimatedTime: number;
		  }
	);
	Benchmark: {
		Type: "Creation" | "Export";
		Action?: "save";
		ID?: string;
	};
	MarketView: {
		Type: "Creation";
		Action?: "save";
		ID?: string;
	};
	ChatAI: {
		Type: "Question";
		Action?: "Select-suggested" | "send-Ask";
		Question?: string;
	};
	// Mixpanel Events - New Flows
	"Portfolio-Draft": {
		Type: "creation" | "enhance";
		Action?: "AI-Solve" | "save" | "reset";
		UID?: string;
	} & (
		| {
				Step: "global";
		  }
		| {
				Step: "main-info";
				Name?: string;
				BaseCurrency?: string;
				BenchmarkIdentifier?: string;
				BenchmarkType?: string;
		  }
		| {
				Step: "investable-universe";
				UniverseName?: string;
				UniverseIdentifier?: string;
		  }
		| {
				Step: "portfolio-constraints";
		  }
		| {
				Step: "risk-constraints";
		  }
		| {
				Step: "portfolio-strategy";
		  }
		| {
				Step: "market-view";
				ScenarioIdentifier?: string;
				RegimeUserProbability?: Regimes;
				RegimeDefaultProbability?: Regimes;
				ForecastHorizon?: ForecastHorizons;
		  }
	);
};

export function trackMixPanelEvent<K extends keyof MixPanelEventMap>(event: K, payload: MixPanelEventMap[K]): void {
	if (typeof window.mixpanel === "undefined") {
		return;
	}
	if (mixPanelConfig.impersonating || mixPanelConfig.isInternal) {
		return;
	}
	const defaults = {
		Domain: window.location.host,
		Page: window.location.pathname,
		Action: event,
		$distinct_id: mixPanelConfig.uid,
		Impersonate: mixPanelConfig.impersonating,
	};
	const mixpanelObj = {
		...defaults,
		...payload,
	};
	window.mixpanel.track(event, mixpanelObj);
}

export default initMixPanel;
