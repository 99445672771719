import { approxRound } from "@mdotm/mdotui/utils";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useMemo, useRef } from "react";

const WaterfallChart: React.FC<{
	dataSeries: {
		label: string;
		value: number;
		tooltipTitle?: string;
	}[];
	ordered?: boolean;
	needSum?: boolean;
	height?: string;
	animated?: boolean;
}> = ({ dataSeries, ordered = true, needSum = true, height = "100%", animated = true }) => {
	const chartRef = useRef<HighchartsReact.RefObject>(null);

	const derivedData = useMemo(() => {
		const data = ordered ? dataSeries.sort((a, b) => b.value - a.value) : dataSeries;
		const mappedData: Array<{
			name: string;
			tooltipTitle: string;
			y?: number;
			isSum?: boolean;
			color?: string;
		}> = data.map((el) => ({
			name: el.label,
			y: el.value,
			tooltipTitle: el.tooltipTitle ?? el.label,
		}));
		const dataSum = approxRound(
			data.reduce((acc, curr) => acc + curr.value, 0),
			2,
		);
		const composedData = needSum
			? mappedData.concat([
					{
						name: "Total",
						isSum: true,
						tooltipTitle: "Total",
						color: dataSum > 0 ? "#4CB09C" : "#E81E25",
					},
			  ])
			: mappedData;
		return composedData;
	}, [dataSeries, needSum, ordered]);

	const options = useMemo(() => {
		return {
			chart: {
				type: "waterfall",
				style: {
					fontFamily: "Gotham,sans-serif",
				},
			},
			credits: {
				enabled: false,
			},
			panning: false,
			title: { text: undefined },
			subtitle: { text: undefined },
			legend: {
				enabled: false,
			},
			xAxis: {
				type: "category",
				labels: {
					rotation: -90,
					useHTML: true,
				},
			},
			yAxis: {
				title: {
					text: "",
				},
				plotLines: [
					{
						color: "#000",
						width: 2,
						value: 0,
					},
				],
			},
			tooltip: {
				shared: true,
				useHTML: true,
				distance: 22,
				backgroundColor: "rgba(255,255,255,1)",
				borderColor: undefined,
				borderRadius: 4,
				borderWidth: 0,
				padding: 0,
				zIndex: 1,
				style: {
					color: "#656d78",
					fontSize: "12px",
					boxShadow: "0 2px 10px 0 rgba(47, 53, 65, 0.15)",
					backgroundColor: "rgba(255,255,255,1)",
				},
				formatter(this: { points: { key: string; point: { tooltipTitle: string } }[]; y: number }) {
					let tooltip_html =
						"<div class='DivTooltip' style='width: auto; min-width: 120px; background-color: #fff; padding: 8px; margin: 0px;'><div class='Tooltip-title' style='padding-left:4px; padding-right:4px;'>" +
						this.points[0].point.tooltipTitle +
						"</div>";
					tooltip_html += "<table>";
					tooltip_html +=
						'<tr><td style="border-bottom: solid 1px transparent;padding: 5px 8px 0 0">' +
						"Returns" +
						'</td><td style="text-align: right; font-weight: 500;border-bottom: solid 1px transparent;padding: 5px 0 0 0"> ' +
						this.y +
						"%" +
						"</td></tr>";
					tooltip_html += "</table></div>";
					return tooltip_html;
				},
			},
			series: [
				{
					upColor: "#B7DFD7",
					color: "#FFA5A7",
					data: derivedData,
					point: {
						events: {
							mouseOver(this: { y: number; graphic: { attr: (p: unknown) => void } }) {
								this.graphic.attr({
									fill: this.y >= 0 ? "#4CB09C" : "#E81E25",
								});
							},
						},
					},
					dataLabels: {
						enabled: true,
						formatter(this: { y: number }) {
							return this.y;
						},
						style: {
							fontWeight: "bold",
						},
					},
					pointPadding: 0,
				},
			],
			plotOptions: {
				series: {
					borderWidth: 0,
					animation: animated
						? undefined
						: {
								duration: 0,
						  },
				},
			},
			exporting: {
				enabled: false,
			},
		};
	}, [derivedData]);

	return (
		<>
			<div style={{ flex: 1 }}>
				<HighchartsReact
					containerProps={{ style: { height } }}
					highcharts={Highcharts}
					constructorType="chart"
					options={options}
					ref={chartRef}
				/>
			</div>
		</>
	);
};

export default WaterfallChart;
