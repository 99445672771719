import Color from "color";

export type GradientStop = {
	offset: number;
	color: string;
};

export type Gradient = [GradientStop & { offset: 0 }, ...GradientStop[], GradientStop & { offset: 1 }];

export function linearGradient(ratio: number, gradientStops: Array<GradientStop>): string {
	const stopIndex = gradientStops.findIndex(({ offset }) => offset > ratio);
	if (stopIndex === -1) {
		// assuming last color (ratio is >= 1)
		return gradientStops[gradientStops.length - 1].color;
	} else if (stopIndex === 0) {
		// assuming second color (ratio is <= 0)
		return gradientStops[0].color;
	}
	return Color(gradientStops[stopIndex - 1].color)
		.mix(
			Color(gradientStops[stopIndex].color),
			(ratio - gradientStops[stopIndex - 1].offset) /
				(gradientStops[stopIndex].offset - gradientStops[stopIndex - 1].offset),
		)
		.toString();
}

export function invertGradient(gradient: Gradient): Gradient {
	return gradient.map((_, i) => ({
		offset: gradient[i].offset,
		color: gradient[gradient.length - 1 - i].color,
	})) as Gradient;
}
