import type { FormatDate, FormatNumber } from "$root/localization/formatters";
import type { defaultWidgetsGridConfig } from "$root/widgets-architecture/layout/WidgetsGrid/default-grids";
import type { WidgetAlert } from "$root/widgets-architecture/layout/WidgetsMapper/context";
import { Trans, UseTranslationResponse } from "react-i18next";
import type { AssetClassType } from "./asset-class";
import type { ForecastHorizon } from "./horizon";
import type { MarketRegimeType } from "./market-regime";
import { addMonths } from "date-fns";
import type { Drivers } from "../outlook/useOutlookComposer";
import { ConstraintRelation, MonitoringMetricCategoryEnum } from "$root/api/api-gen";

const alertsFixableByOptimize: Array<keyof PortfolioAlertMap> = [
	"RiskConstraintAlertCheck",
	"AssetAllocationExposureConstraintsAlertCheck",
	"AssetAllocationExposureWarningAlertCheck",
];

const alertsFixableByEnhance: Array<keyof PortfolioAlertMap> = [
	"RiskConstraintAlertCheck",
	"AssetAllocationExposureConstraintsAlertCheck",
	"AssetAllocationExposureWarningAlertCheck",
	"MarketViewAlignmentAlertCheck",
	"MarketViewExpiredAlertCheck",
];

export type PortfolioWidget = (typeof defaultWidgetsGridConfig)[
	| "PORTFOLIO_DETAILS"
	| "PORTFOLIO_ENHANCEMENT"]["configuration"][number]["i"];

export const alertsByWidget: Partial<Record<PortfolioWidget, Array<keyof PortfolioAlertMap>>> = {
	ExposureCompare: ["AssetAllocationExposureWarningAlertCheck"],
	ExposureEvolve: [
		"AssetAllocationExposureConstraintsAlertCheck",
		"AssetAllocationExposureWarningAlertCheck",
		"TagWarningAlertCheck",
		"TagBreachedAlertCheck",
	],
	// Composition: ["InstrumentsChangesAlertCheck", "InstrumentsChangesChildAlertCheck"],
	// MarketViewDetails: ["MarketViewAlignmentAlertCheck", "MarketViewExpiredAlertCheck", "MarketScenariosAlertCheck"],
	// InvestmentSummary: ["RiskConstraintAlertCheck", "RiskConstraintWarningAlertCheck"],
	PortfolioMonitoringBlock: [
		"PortfolioBreachesAlertCheck",
		"PortfolioConstraintWarningAlertCheck",
		"ScoreWarningAlertCheck",
		"ScoreBreachedAlertCheck",
	],
};

export function isAutoFixable(alertType: keyof PortfolioAlertMap): boolean {
	return alertsFixableByOptimize.includes(alertType);
}

export function isFixableByEnhance(alertType: keyof PortfolioAlertMap): boolean {
	return alertsFixableByEnhance.includes(alertType);
}

export function widgetFromAlertType(alertType: keyof PortfolioAlertMap): PortfolioWidget | null {
	return (
		(Object.entries(alertsByWidget).find(([, alertTypes]) => alertTypes.includes(alertType))?.[0] as
			| PortfolioWidget
			| undefined) ?? null
	);
}

export function alertTypesFromWidgetName(widgetName: PortfolioWidget): Array<keyof PortfolioAlertMap> {
	return alertsByWidget[widgetName] ?? [];
}

export type PortfolioAlertMap = {
	RiskConstraintAlertCheck: {
		userSet: string;
		currentValue: string;
	};
	MarketViewExpiredAlertCheck: {
		enhanceDate: string;
		horizon: ForecastHorizon;
	};
	MarketScenariosAlertCheck: {
		marketScenario: string;
		sphereForecast: string;
		threshold: string;
		userSet: string;
	};
	AssetAllocationExposureConstraintsAlertCheck: {
		userSet: string;
		currentValue: string;
		assetClass: string;
	};
	AssetAllocationExposureWarningAlertCheck: {
		userSet: string;
		currentValue: string;
		assetClass: string;
	};
	MarketViewAlignmentAlertCheck: {
		sphereForecast: string;
		marketScenario: string;
		userSet: string;
		threshold: string;
	};
	InstrumentsChangesChildAlertCheck: {
		tickerId: number;
		isinOld: string;
		isinNew: string;
	};
	InstrumentsChangesAlertCheck: {
		tickerId: number;
		isinOld: string;
		isinNew: string;
	};
	TagBreachedAlertCheck: {
		tagName: string;
		currentValue: string;
		userSet: string;
	};
	TagWarningAlertCheck: {
		tagName: string;
		currentValue: string;
		userSet: string;
	};
	RiskConstraintWarningAlertCheck: {
		currentValue: string;
		userSet: string;
	};
	ScoreBreachedAlertCheck: {
		currentValue: string;
		scoreName: string;
		userSet: string;
	};
	ScoreWarningAlertCheck: {
		currentValue: string;
		scoreName: string;
		userSet: string;
	};
	PortfolioConstraintWarningAlertCheck: {
		drivers: Drivers[];
		category: MonitoringMetricCategoryEnum;
		constraintRelation: ConstraintRelation;
		entity: string;
		investmentName: string;
		currentValue: string;
		minWarningDelta: number;
		maxWarningDelta: number;
		maxDelta: number;
		minDelta: number;
		minThreshold: number;
		maxThreshold: number;
		target?: boolean;
	};
	PortfolioBreachesAlertCheck: {
		currentValue: string;
		threshold: string;
		drivers: Drivers[];
		category: string;
		constraintRelation: ConstraintRelation;
		entity: string;
		investmentName: string;
	};
};

const portfolioVariantByType: Record<keyof PortfolioAlertMap, WidgetAlert["variant"]> = {
	RiskConstraintAlertCheck: "warning",
	MarketViewExpiredAlertCheck: "warning",
	AssetAllocationExposureConstraintsAlertCheck: "warning",
	AssetAllocationExposureWarningAlertCheck: "warning",
	MarketViewAlignmentAlertCheck: "warning",
	InstrumentsChangesChildAlertCheck: "info",
	InstrumentsChangesAlertCheck: "info",
	TagBreachedAlertCheck: "warning",
	TagWarningAlertCheck: "warning",
	RiskConstraintWarningAlertCheck: "warning",
	ScoreBreachedAlertCheck: "warning",
	ScoreWarningAlertCheck: "warning",
	MarketScenariosAlertCheck: "warning",
	PortfolioConstraintWarningAlertCheck: "warning",
	PortfolioBreachesAlertCheck: "warning",
};

const regimeTranslation: Record<
	string,
	| "GrowthMarketViewAlignmentAlertCheck"
	| "LateralPhaseMarketViewAlignmentAlertCheck"
	| "HighStressMarketViewAlignmentAlertCheck"
> = {
	"High Stress": "HighStressMarketViewAlignmentAlertCheck",
	"Lateral Phase": "LateralPhaseMarketViewAlignmentAlertCheck",
	Growth: "GrowthMarketViewAlignmentAlertCheck",
};

export function getPortfolioAlertVariantByType(type: keyof PortfolioAlertMap): WidgetAlert["variant"] {
	return portfolioVariantByType[type];
}

export type PortfolioAlert = {
	[K in keyof PortfolioAlertMap]: { type: K; value: PortfolioAlertMap[K] & { identifier?: string } };
}[keyof PortfolioAlertMap];

const _renderAlertByType: {
	[K in keyof PortfolioAlertMap]: (
		ctx: {
			t: UseTranslationResponse<"en", undefined>["0"];
			formatNumber: FormatNumber;
			formatDate: FormatDate;
		},
		alert: { type: K; value: PortfolioAlertMap[K] },
	) => WidgetAlert & { shortTitle: WidgetAlert["shortTitle"] };
} = {
	RiskConstraintAlertCheck: (ctx, alert) => ({
		shortTitle: mapAlertShortTitle(ctx, alert),
		title: ctx.t(`PORTFOLIO_ALERTS.DESCRIPTION.${alert.type}.title`),
		content: ctx.t(`PORTFOLIO_ALERTS.DESCRIPTION.${alert.type}.description`, {
			userSet: ctx.formatNumber(alert.value.userSet),
			currentValue: ctx.formatNumber(alert.value.currentValue),
		}),
		variant: getPortfolioAlertVariantByType(alert.type),
	}),
	MarketViewExpiredAlertCheck: (ctx, alert) => ({
		shortTitle: mapAlertShortTitle(ctx, alert),
		title: ctx.t(`PORTFOLIO_ALERTS.DESCRIPTION.${alert.type}.title`),
		content: ctx.t(`PORTFOLIO_ALERTS.DESCRIPTION.${alert.type}.description`, {
			enhanceDate: (() => {
				const dateConversion: Record<ForecastHorizon, number> = {
					ONE_MONTH: 1,
					THREE_MONTHS: 3,
					SIX_MONTHS: 6,
					TWELVE_MONTHS: 12,
					EIGHTEEN_MONTHS: 18,
					TWENTY_FOUR_MONTHS: 24,
					THIRTY_SIX_MONTHS: 36,
				};
				const enhanceDate = addMonths(new Date(alert.value.enhanceDate), dateConversion[alert.value.horizon]);
				return ctx.formatDate(enhanceDate);
			})(),
			horizon:"",
			// ctx.t(`INVESTMENT_HORIZON.${alert.value.horizon}`)
		}),
		variant: getPortfolioAlertVariantByType(alert.type),
	}),
	AssetAllocationExposureConstraintsAlertCheck: (ctx, alert) => ({
		shortTitle: mapAlertShortTitle(ctx, alert),
		title: ctx.t(`PORTFOLIO_ALERTS.DESCRIPTION.${alert.type}.title`, {
			assetClass: ctx.t(`ASSET_CLASS_NAME.${alert.value.assetClass as AssetClassType}`),
		}),
		content: ctx.t(`PORTFOLIO_ALERTS.DESCRIPTION.${alert.type}.description`, {
			userSet: ctx.formatNumber(alert.value.userSet),
			currentValue: ctx.formatNumber(alert.value.currentValue),
		}),
		variant: getPortfolioAlertVariantByType(alert.type),
	}),
	AssetAllocationExposureWarningAlertCheck: (ctx, alert) => ({
		shortTitle: mapAlertShortTitle(ctx, alert),
		title: ctx.t(`PORTFOLIO_ALERTS.DESCRIPTION.${alert.type}.title`, {
			assetClass: ctx.t(`ASSET_CLASS_NAME.${alert.value.assetClass as AssetClassType}`),
		}),
		content: ctx.t(`PORTFOLIO_ALERTS.DESCRIPTION.${alert.type}.description`, {
			userSet: ctx.formatNumber(alert.value.userSet),
			currentValue: ctx.formatNumber(alert.value.currentValue),
		}),
		variant: getPortfolioAlertVariantByType(alert.type),
	}),
	MarketViewAlignmentAlertCheck: (ctx, alert) => ({
		shortTitle: mapAlertShortTitle(ctx, alert),
		title: ctx.t(`PORTFOLIO_ALERTS.DESCRIPTION.${alert.type}.title`, {
			userSet: ctx.formatNumber(alert.value.userSet),
			marketScenario: ctx.t(`MARKET_REGIME_TYPE.${alert.value.marketScenario as MarketRegimeType}`),
			threshold: ctx.formatNumber(alert.value.threshold),
		}),
		content: ctx.t(`PORTFOLIO_ALERTS.DESCRIPTION.${alert.type}.description`, {
			sphereForecast: ctx.formatNumber(alert.value.sphereForecast),
			marketScenario: ctx.t(`MARKET_REGIME_TYPE.${alert.value.marketScenario as MarketRegimeType}`),
			userSet: ctx.formatNumber(alert.value.userSet),
			threshold: ctx.formatNumber(alert.value.threshold),
		}),
		variant: getPortfolioAlertVariantByType(alert.type),
	}),
	MarketScenariosAlertCheck: (ctx, alert) => ({
		shortTitle: mapAlertShortTitle(ctx, alert),
		title: ctx.t(`PORTFOLIO_ALERTS.DESCRIPTION.${alert.type}.title`, {
			regime: ctx.t(`NEW_NOTIFICATIONS.NOTIFICATIONS.${regimeTranslation[alert.value.marketScenario]}`),
		}),
		content: ctx.t(`PORTFOLIO_ALERTS.DESCRIPTION.${alert.type}.description`, {
			regime: ctx.t(`NEW_NOTIFICATIONS.NOTIFICATIONS.${regimeTranslation[alert.value.marketScenario]}`),
			// sphereThreshold: alert.value.sphereForecast,
			// delta: Math.floor(Number(alert.value.sphereForecast) - Number(alert.value.userSet)),
			threshold: alert.value.threshold,
		}),
		variant: "warning",
	}),
	InstrumentsChangesChildAlertCheck: (ctx, alert) => ({
		shortTitle: mapAlertShortTitle(ctx, alert),
		title: ctx.t(`PORTFOLIO_ALERTS.DESCRIPTION.${alert.type}.title`),
		content: (
			<Trans
				t={ctx.t}
				i18nKey={`PORTFOLIO_ALERTS.DESCRIPTION.${alert.type}.description`}
				values={{ isinOld: alert.value.isinOld, isinNew: alert.value.isinNew }}
				components={{ b: <strong /> }}
			/>
		),
		variant: getPortfolioAlertVariantByType(alert.type),
	}),
	InstrumentsChangesAlertCheck: (ctx, alert) => ({
		shortTitle: mapAlertShortTitle(ctx, alert),
		title: ctx.t(`PORTFOLIO_ALERTS.DESCRIPTION.${alert.type}.title`),
		content: (
			<Trans
				t={ctx.t}
				i18nKey={`PORTFOLIO_ALERTS.DESCRIPTION.${alert.type}.description`}
				values={{ isinOld: alert.value.isinOld, isinNew: alert.value.isinNew }}
				components={{ b: <strong /> }}
			/>
		),
		variant: getPortfolioAlertVariantByType(alert.type),
	}),
	TagBreachedAlertCheck: (ctx, alert) => ({
		shortTitle: mapAlertShortTitle(ctx, alert),
		title: ctx.t(`PORTFOLIO_ALERTS.DESCRIPTION.${alert.type}.title`, { tagName: alert.value.tagName }),
		content: (
			<Trans
				t={ctx.t}
				i18nKey={`PORTFOLIO_ALERTS.DESCRIPTION.${alert.type}.description`}
				values={{
					tagName: alert.value.tagName,
					constraintValue: alert.value.userSet,
					current: alert.value.currentValue,
				}}
				components={{ b: <strong /> }}
			/>
		),
		variant: getPortfolioAlertVariantByType(alert.type),
	}),
	TagWarningAlertCheck: (ctx, alert) => ({
		shortTitle: mapAlertShortTitle(ctx, alert),
		title: ctx.t(`PORTFOLIO_ALERTS.DESCRIPTION.${alert.type}.title`, { tagName: alert.value.tagName }),
		content: (
			<Trans
				t={ctx.t}
				i18nKey={`PORTFOLIO_ALERTS.DESCRIPTION.${alert.type}.description`}
				values={{
					tagName: alert.value.tagName,
					constraintValue: alert.value.userSet,
					current: alert.value.currentValue,
				}}
				components={{ b: <strong /> }}
			/>
		),
		variant: getPortfolioAlertVariantByType(alert.type),
	}),
	RiskConstraintWarningAlertCheck: (ctx, alert) => ({
		shortTitle: mapAlertShortTitle(ctx, alert),
		title: ctx.t(`PORTFOLIO_ALERTS.DESCRIPTION.${alert.type}.title`),
		content: (
			<Trans
				t={ctx.t}
				i18nKey={`PORTFOLIO_ALERTS.DESCRIPTION.${alert.type}.description`}
				values={{
					constraintValue: alert.value.userSet,
					current: alert.value.currentValue,
				}}
				components={{ b: <strong /> }}
			/>
		),
		variant: getPortfolioAlertVariantByType(alert.type),
	}),
	ScoreBreachedAlertCheck: (ctx, alert) => ({
		shortTitle: mapAlertShortTitle(ctx, alert),
		title: ctx.t(`PORTFOLIO_ALERTS.DESCRIPTION.${alert.type}.title`, { score: alert.value.scoreName }),
		content: (
			<Trans
				t={ctx.t}
				i18nKey={`PORTFOLIO_ALERTS.DESCRIPTION.${alert.type}.description`}
				values={{
					tagName: alert.value.scoreName,
					constraintValue: alert.value.userSet,
					current: alert.value.currentValue,
				}}
				components={{ b: <strong /> }}
			/>
		),
		variant: getPortfolioAlertVariantByType(alert.type),
	}),
	ScoreWarningAlertCheck: (ctx, alert) => ({
		shortTitle: mapAlertShortTitle(ctx, alert),
		title: ctx.t(`PORTFOLIO_ALERTS.DESCRIPTION.${alert.type}.title`, { score: alert.value.scoreName }),
		content: (
			<Trans
				t={ctx.t}
				i18nKey={`PORTFOLIO_ALERTS.DESCRIPTION.${alert.type}.description`}
				values={{
					tagName: alert.value.scoreName,
					constraintValue: alert.value.userSet,
					current: alert.value.currentValue,
				}}
				components={{ b: <strong /> }}
			/>
		),
		variant: getPortfolioAlertVariantByType(alert.type),
	}),
	PortfolioConstraintWarningAlertCheck: (ctx, alert) => ({
		shortTitle: mapAlertShortTitle(ctx, alert),
		title: (
			<Trans
				t={ctx.t}
				i18nKey={`PORTFOLIO_ALERTS.DESCRIPTION.${alert.type}.title`}
				values={{
					category: (() => {
						const obj = ctx.t(`MONITORING_METRICS`, { returnObjects: true });
						return obj[alert.type as keyof typeof obj] ?? "-";
					})(),
				}}
			/>
		),
		content: (
			<Trans
				t={ctx.t}
				i18nKey={`PORTFOLIO_ALERTS.DESCRIPTION.${alert.type}.description`}
				values={{
					category: (() => {
						const obj = ctx.t(`MONITORING_METRICS`, { returnObjects: true });
						return obj[alert.type as keyof typeof obj] ?? "-";
					})(),
				}}
			/>
		),
		variant: getPortfolioAlertVariantByType(alert.type),
	}),
	PortfolioBreachesAlertCheck: (ctx, alert) => ({
		shortTitle: mapAlertShortTitle(ctx, alert),
		title: (
			<Trans
				t={ctx.t}
				i18nKey={`PORTFOLIO_ALERTS.DESCRIPTION.${alert.type}.title`}
				values={{
					category: (() => {
						const obj = ctx.t(`MONITORING_METRICS`, { returnObjects: true });
						return obj[alert.value.category as keyof typeof obj] ?? "-";
					})(),
				}}
			/>
		),
		content: (
			<Trans
				t={ctx.t}
				i18nKey={`PORTFOLIO_ALERTS.DESCRIPTION.${alert.type}.description`}
				values={{
					category: (() => {
						const obj = ctx.t(`MONITORING_METRICS`, { returnObjects: true });
						return obj[alert.value.category as keyof typeof obj] ?? "-";
					})(),
				}}
			/>
		),
		variant: getPortfolioAlertVariantByType(alert.type),
	}),
};

const renderAlertByType = _renderAlertByType as Record<
	keyof PortfolioAlertMap,
	(
		ctx: {
			t: UseTranslationResponse<"en", undefined>["0"];
			formatNumber: FormatNumber;
			formatDate: FormatDate;
		},
		alert: PortfolioAlert,
	) => WidgetAlert
>;

const _renderAlertShortTitleByType: {
	[K in keyof PortfolioAlertMap]: (
		ctx: {
			t: UseTranslationResponse<"en", undefined>["0"];
			formatNumber: FormatNumber;
			formatDate: FormatDate;
		},
		alert: { type: K; value: PortfolioAlertMap[K] },
	) => React.ReactNode;
} = {
	RiskConstraintAlertCheck: ({ t }, { type }) => t(`PORTFOLIO_ALERTS.DESCRIPTION.${type}.short_title`),
	MarketViewExpiredAlertCheck: ({ t }, { type }) => t(`PORTFOLIO_ALERTS.DESCRIPTION.${type}.short_title`),
	AssetAllocationExposureConstraintsAlertCheck: ({ t }, { type }) =>
		t(`PORTFOLIO_ALERTS.DESCRIPTION.${type}.short_title`),
	AssetAllocationExposureWarningAlertCheck: ({ t }, { type }) => t(`PORTFOLIO_ALERTS.DESCRIPTION.${type}.short_title`),
	MarketViewAlignmentAlertCheck: ({ t }, { type, value: { marketScenario } }) =>
		t(`PORTFOLIO_ALERTS.DESCRIPTION.${type}.short_title`, {
			marketScenario: t(`MARKET_REGIME_TYPE.${marketScenario as MarketRegimeType}`),
		}),
	InstrumentsChangesChildAlertCheck: ({ t }, { type }) => t(`PORTFOLIO_ALERTS.DESCRIPTION.${type}.short_title`),
	InstrumentsChangesAlertCheck: ({ t }, { type }) => t(`PORTFOLIO_ALERTS.DESCRIPTION.${type}.short_title`),
	TagBreachedAlertCheck: ({ t }, { type, value: { tagName } }) =>
		t(`PORTFOLIO_ALERTS.DESCRIPTION.${type}.short_title`, { tagName }),
	TagWarningAlertCheck: ({ t }, { type, value: { tagName } }) =>
		t(`PORTFOLIO_ALERTS.DESCRIPTION.${type}.short_title`, { tagName }),
	RiskConstraintWarningAlertCheck: ({ t }, { type }) => t(`PORTFOLIO_ALERTS.DESCRIPTION.${type}.short_title`),
	ScoreBreachedAlertCheck: ({ t }, { type, value: { scoreName } }) =>
		t(`PORTFOLIO_ALERTS.DESCRIPTION.${type}.short_title`, { scoreName }),
	ScoreWarningAlertCheck: ({ t }, { type, value: { scoreName } }) =>
		t(`PORTFOLIO_ALERTS.DESCRIPTION.${type}.short_title`, { scoreName }),
	MarketScenariosAlertCheck: ({ t }, { type, value }) =>
		t(`PORTFOLIO_ALERTS.DESCRIPTION.${type}.short_title`, {
			regime: t(`NEW_NOTIFICATIONS.NOTIFICATIONS.${regimeTranslation[value.marketScenario]}`),
		}),
	PortfolioConstraintWarningAlertCheck: ({ t }, { type, value }) =>
		t(`PORTFOLIO_ALERTS.DESCRIPTION.${type}.short_title`, {
			category: (() => {
				const obj = t(`MONITORING_METRICS`, { returnObjects: true });
				return obj[value.category as keyof typeof obj] ?? "-";
			})(),
		}),
	PortfolioBreachesAlertCheck: ({ t }, { type, value }) =>
		t(`PORTFOLIO_ALERTS.DESCRIPTION.${type}.short_title`, {
			category: (() => {
				const obj = t(`MONITORING_METRICS`, { returnObjects: true });
				return obj[value.category as keyof typeof obj] ?? "-";
			})(),
		}),
};

const renderAlertShortTitleByType = _renderAlertShortTitleByType as Record<
	keyof PortfolioAlertMap,
	(
		ctx: {
			t: UseTranslationResponse<"en", undefined>["0"];
			formatNumber: FormatNumber;
			formatDate: FormatDate;
		},
		alert: PortfolioAlert,
	) => React.ReactNode
>;

export function mapAlertShortTitle(
	ctx: {
		t: UseTranslationResponse<"en", undefined>["0"];
		formatNumber: FormatNumber;
		formatDate: FormatDate;
	},
	alert: PortfolioAlert,
): React.ReactNode {
	return renderAlertShortTitleByType[alert.type](ctx, alert);
}

export function mapAlert(
	ctx: {
		t: UseTranslationResponse<"en", undefined>["0"];
		formatNumber: FormatNumber;
		formatDate: FormatDate;
		requestOptimize?: () => void;
		requestEnhance?: () => void;
	},
	alert: PortfolioAlert,
): WidgetAlert {
	return renderAlertByType[alert.type](ctx, alert);
}

export function filterMapAlerts(
	ctx: {
		t: UseTranslationResponse<"en", undefined>["0"];
		formatNumber: FormatNumber;
		formatDate: FormatDate;
		requestOptimize?: () => void;
		requestEnhance?: () => void;
	},
	widgetName: PortfolioWidget,
	alerts: PortfolioAlert[],
	_opts?: { summary?: boolean },
): WidgetAlert[] {
	const types = alertTypesFromWidgetName(widgetName);
	const baseAlerts = alerts.filter(({ type }) => types.includes(type));
	const mappedAlerts = baseAlerts.map<WidgetAlert>((alert) => mapAlert(ctx, alert));
	const fixableByEnhancement = ctx.requestEnhance && baseAlerts.some(({ type }) => isFixableByEnhance(type));
	const autoFixable = ctx.requestOptimize && baseAlerts.some(({ type }) => isAutoFixable(type));
	const extraAlerts: WidgetAlert[] = [];
	if (fixableByEnhancement || autoFixable) {
		extraAlerts.push({
			variant: "info",
			title: ctx.t("PORTFOLIO_ALERTS.HOW_TO_SOLVE"),
			content: (
				<Trans
					t={ctx.t}
					i18nKey={
						fixableByEnhancement && autoFixable
							? "PORTFOLIO_ALERTS.HOW_TO_SOLVE_ENHANCE_AUTO"
							: fixableByEnhancement
							  ? "PORTFOLIO_ALERTS.HOW_TO_SOLVE_ENHANCE"
							  : "PORTFOLIO_ALERTS.HOW_TO_SOLVE_AUTO"
					}
					components={{
						"x-auto": <button type="button" onClick={ctx.requestOptimize} className="underline font-bold" />,
						"x-enhance": <button type="button" onClick={ctx.requestEnhance} className="underline font-bold" />,
					}}
				/>
			),
		});
	}
	return mappedAlerts.concat(extraAlerts);
}
