export class UnreachableError extends Error {
	constructor() {
		super("unreachable code");
	}
}

export class LocalizedError extends Error {
	constructor(
		public localizedMsg: string,
		opts?: { cause?: unknown; msg: string },
	) {
		super(opts?.msg, { cause: opts?.cause });
	}
}

export class UserCancelledError extends Error {
	constructor(msg?: string, opts?: { cause?: unknown }) {
		super(msg ?? "action cancelled by the user", opts);
	}
}
