import type { FieldKey, FilterRelation, FilterResponse, InvestmentListEntry } from "$root/api/api-gen";
import { dateWithoutTime } from "$root/utils/dates";
import { differenceInDays } from "date-fns";
import { NonUndefined } from "react-hook-form";
import { match } from "ts-pattern";

export function fieldKeyExtractor(fieldKey: FieldKey, investment: InvestmentListEntry) {
	return match(fieldKey)
		.with("CURRENT_ACTION", () => investment.action)
		.with("EFFICIENCY_RATIO_1M", () => investment.efficiencyRatio1M)
		.with("MAX_DRAWDOWN_1M", () => investment.maxDrawdown1M)
		.with("BENCHMARK_NAME", () => investment.benchmarkIdentifier)
		.with("SORTINO_1M", () => investment.sortino1M)
		.with("VOLATILITY_1M", () => investment.volatility1M)
		.with("COMMENTARY_TEMPLATE", () => investment.commentaryTemplateIdentifier)
		.with("INCEPTION_DATE", () => investment.creationTime)
		.with("CURRENCY_NAME", () => investment.currencyName)
		.with("LAST_STATUS_UPDATE", () => investment.modificationTime)
		.with("PERFORMANCE_1M", () => investment.performance1M)
		.with("REFERENCE_NAME", () => investment.referenceIdentifier)
		.with("AVERAGE_SCORE", () => investment.score)
		.with("STATUS", () => investment.status)
		.with("AUTO_SYNC", () => investment.syncDate === undefined)
		.with("UNIVERSE_NAME", () => investment.universeIdentifier)
		.with("NAME", () => investment.uuid)
		.with("MARKET_VIEW_NAME", () => investment.marketView?.scenarioIdentifier)
		.otherwise(() => undefined);
}

export function sanitaizeFilter(filter: FilterResponse): undefined | Required<FilterResponse> {
	if (filter.relation && filter.value && filter.value.length > 0 && filter.key && filter.type) {
		return {
			key: filter.key!,
			relation: filter.relation!,
			type: filter.type!,
			value: filter.value!,
		};
	}

	return undefined;
}

export function matchInvestentToFilter(filter: FilterResponse, investmentValue?: string): boolean {
	const { type } = filter;

	return match(type)
		.with(
			"FINITE_SET_PORTFOLIOS",
			"FINITE_SET_STATUSES",
			"FINITE_SET_ACTIONS",
			"FINITE_SET_BASE_CURRENCIES",
			"FINITE_SET_UNIVERSES",
			"FINITE_SET_BENCHMARKS",
			"FINITE_SET_REFERENCES",
			"FINITE_SET_MARKET_VIEWS",
			"FINITE_SET_COMMENTARY_TEMPLATES",
			"BOOLEAN",
			() => {
				const sanitaizedFilter = sanitaizeFilter(filter);
				return sanitaizedFilter ? Boolean(sanitaizedFilter.value.includes(investmentValue ?? "")) : true;
			},
		)
		.with("DATE", () => {
			const sanitaizedFilter = sanitaizeFilter(filter);
			if (!sanitaizedFilter) {
				return true;
			}

			const filterDate = sanitaizedFilter.value[0];
			const difference = investmentValue
				? -differenceInDays(dateWithoutTime(filterDate), dateWithoutTime(investmentValue))
				: undefined;

			return relationMatch(0, difference, sanitaizedFilter.relation);
		})
		.with("NUMERIC", "PERCENTAGE", () => {
			const sanitaizedFilter = sanitaizeFilter(filter);
			if (!sanitaizedFilter) {
				return true;
			}

			const filterValue = sanitaizedFilter.value[0];
			return relationMatch(
				Number(filterValue),
				investmentValue != null ? Number(investmentValue) : undefined,
				filter.relation,
			);
		})
		.with(undefined, () => true)
		.exhaustive();
}

function relationMatch(filterValue?: number, investmentValue?: number, relation?: FilterRelation) {
	return filterValue != null && investmentValue != null
		? match(relation)
				.with("EQUAL", () => investmentValue === filterValue)
				.with("GREATER_THAN", () => investmentValue > filterValue)
				.with("LOWER_THAN", () => investmentValue < filterValue)
				.with("NOT_EQUAL", () => investmentValue !== filterValue)
				.otherwise(() => false)
		: false;
}
