import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsStock from "highcharts/modules/stock";
import HighchartsHeatmap from "highcharts/modules/heatmap";
import HighchartsBoost from "highcharts/modules/boost";
// @ts-ignore
import HC_rounded from "highcharts-rounded-corners";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsExportData from "highcharts/modules/export-data";
import HighchartsExportDataOffline from "highcharts/modules/offline-exporting";
import HighchartsBellCurve from "highcharts/modules/histogram-bellcurve";
import HighchartsTreemap from "highcharts/modules/treemap";

HighchartsTreemap(Highcharts);
HighchartsBellCurve(Highcharts);
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);
HighchartsExportDataOffline(Highcharts);
HC_rounded(Highcharts);
HighchartsStock(Highcharts);
HighchartsHeatmap(Highcharts);
HighchartsMore(Highcharts);
HighchartsBoost(Highcharts);

// Ref: https://github.com/highcharts/highcharts/issues/15031#issuecomment-778148724
Highcharts.AST.allowedAttributes.push("data-text-type");

export default Highcharts;
