import { noop } from "@mdotm/mdotui/utils";
import { useUnsafeUpdatedRef } from "@mdotm/mdotui/react-extensions";
import { useEffect, useRef, useState } from "react";

const requestAnimationFrameImpl =
	typeof requestAnimationFrame !== "undefined"
		? {
				request: (cb: (time: number) => void) => requestAnimationFrame(cb),
				cancel: (id: number) => cancelAnimationFrame(id),
		  }
		: {
				request: (cb: (time: number) => void) =>
					setTimeout(() => cb(performance.now()), 1000 / 60) as unknown as number,
				cancel: (id: number) => clearTimeout(id),
		  };

function useAnimationFrame(callback: () => void, opts?: { pause?: boolean }): void {
	const callbackRef = useUnsafeUpdatedRef(callback);
	const [dummyState, setDummyState] = useState(0);

	const latestIdRef = useRef<null | number>(null);
	const pause = opts?.pause ?? false;

	useEffect(() => {
		noop(dummyState); // track
		if (pause) {
			return;
		}

		latestIdRef.current = requestAnimationFrameImpl.request(() => {
			callbackRef.current();
			setDummyState((n) => (n + 1) % Number.MAX_SAFE_INTEGER);
		});
	}, [callbackRef, dummyState, pause]);
	useEffect(() => {
		if (pause && latestIdRef.current !== null) {
			requestAnimationFrameImpl.cancel(latestIdRef.current);
			latestIdRef.current = null;
		}
	}, [pause]);
	useEffect(() => {
		return () => {
			if (latestIdRef.current !== null) {
				requestAnimationFrameImpl.cancel(latestIdRef.current);
				latestIdRef.current = null;
			}
		};
	});
}

export default useAnimationFrame;
