import type { InvestmentListEntry } from "$root/api/api-gen";
import { CustomerControllerV3ApiFactory, InvestmentControllerV4ApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import InfiniteLoader from "$root/components/InfiniteLoader";
import { useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import { useEventBus } from "$root/event-bus";
import { useUserValue } from "$root/functional-areas/user";
import { usePortfolioColumns } from "$root/pages/PortfoliosStudio/PortfolioList/columns";
import { PaletteColors } from "$root/styles/themePalette";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { qualifier } from "$root/utils/qualifiers";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { InfoTooltip } from "$root/widgets-architecture/layout/WidgetsMapper/InfoTooltip";
import { useWidgetOptions } from "$root/widgets-architecture/layout/WidgetsMapper/context";
import type { TableColumn } from "@mdotm/mdotui/components";
import { AutoSortHScrollTable, Button, Icon, Text } from "@mdotm/mdotui/components";
import { noop } from "@mdotm/mdotui/utils";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const NoData = (): JSX.Element => {
	const { t } = useTranslation();
	const { push } = useTypedNavigation();

	return (
		<div>
			{t("PORTFOLIOS.NO_PORTFOLIO_YET", { returnObjects: true }).map((tt) => (
				<div key={tt} style={{ color: PaletteColors.LIGHT_GREY }}>
					{tt}
				</div>
			))}
			<div className="flex flex-row gap-4 mt-3">
				<Button size="small" palette="primary" onClick={() => push("Portfolios/UploadPortfolioPage/Portfolio", {})}>
					<Icon icon="Upload" />
					&nbsp;
					{t("PORTFOLIOS.UPLOAD_PORTFOLIO").toLowerCase()}
				</Button>
				<Button size="small" palette="primary" onClick={() => push("Portfolios/CreatePortfolio", { uuid: "" })}>
					<Icon icon="Outline1" />
					&nbsp;
					{t("PORTFOLIOS.CREATE_PORTFOLIO").toLowerCase()}
				</Button>
			</div>
		</div>
	);
};

const PortfolioOverview = (): JSX.Element => {
	const investmentApi = useApiGen(InvestmentControllerV4ApiFactory);
	const { t } = useTranslation();
	const user = useUserValue();
	const customerV3Api = useApiGen(CustomerControllerV3ApiFactory);

	const {
		isFetching,
		refetch,
		data: portfolioList,
	} = useQueryNoRefetch(["queryPortfolioOverview"], {
		queryFn: async () => {
			const { data } = await investmentApi.getInvestmentList();

			return data;
		},
	});

	const { data: userSyncInfo } = useQueryNoRefetch(["queryUserSyncInfoDashboard"], {
		enabled: user.automaticImport,
		queryFn: () => axiosExtract(customerV3Api.users1()),
	});

	useWidgetOptions(
		() => ({
			title: (
				<Text
					type="Body/XL/Bold"
					title="Portfolio Overview"
					classList="truncate"
					data-qualifier={qualifier.widgets.portfolioListOverview.name}
				>
					Portfolio Overview
				</Text>
			),
			actionHeader: <InfoTooltip>{t("PORTFOLIO_ALIGNMENT_OUTLOOK.TOOLTIP")}</InfoTooltip>,
			isDraggable: false,
		}),
		[t],
	);

	useEventBus("investment-update", () => {
		refetch().catch(noop);
	});

	const portfolioColumns = usePortfolioColumns();

	const columns = useMemo<TableColumn<InvestmentListEntry>[]>(
		() => [
			portfolioColumns.NAME(),
			portfolioColumns.INCEPTION_DATE(),
			portfolioColumns.STATUS(),
			portfolioColumns.PERFORMANCE_1M(),
			portfolioColumns.BENCHMARK_PERFORMANCE_1M(),
			portfolioColumns.AUTO_SYNC({ userSyncInfo }),
			portfolioColumns.AVERAGE_SCORE(),
			portfolioColumns.WARNINGS(),
		],
		[portfolioColumns, userSyncInfo],
	);

	if (isFetching) {
		return <InfiniteLoader />;
	}

	if (!portfolioList) {
		throw new Error("failed to load the portfolio list");
	}

	if (portfolioList.length === 0) {
		return <NoData />;
	}

	return (
		<AutoSortHScrollTable
			key="portfolioListTable"
			columns={columns}
			rows={portfolioList}
			noDataText="No data available"
		/>
	);
};

export default PortfolioOverview;
