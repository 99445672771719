import type { TrackableAsyncFn } from "@mdotm/mdotui/headless";
import { spawnYesNoDialog } from "../yes-no-dialog";
import type { FormEvent, ReactNode } from "react";
import type { NodeOrFn } from "@mdotm/mdotui/react-extensions";
import { Text, type DialogBoxSize } from "@mdotm/mdotui/components";
import { UserCancelledError } from "$root/utils/errors";

export type DeleteDialogCommon = {
	children?: ReactNode;
	header?: string | ReactNode;
	yesButton?: string | NodeOrFn;
	onDeleteAsync: TrackableAsyncFn<void, FormEvent>;
	noButton?: string | NodeOrFn<{ onClick(): void }>;
	size?: DialogBoxSize;
	entityName: string;
	entityType?: string;
};

export type SpawnDeleteDialogParams = DeleteDialogCommon & {
	zIndex?: number;
};

export async function spawnDeleteDialog(params: SpawnDeleteDialogParams): Promise<void> {
	const shouldProceed = await spawnYesNoDialog({
		header: params.header ?? (params.entityType ? `Delete ${params.entityType}` : "Delete"),
		size: "large",
		children: (
			<>
				<Text type="Body/M/Book">Proceed with the deletion of </Text>
				<Text type="Body/M/Bold">{params.entityName.trim()}</Text>
				<Text type="Body/M/Book">?</Text>
			</>
		),
		onSubmitAsync: params.onDeleteAsync,
	});
	if (!shouldProceed) {
		throw new UserCancelledError();
	}
}
