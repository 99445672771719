import type { InvestmentListEntry } from "$root/api/api-gen";
import { EntityEditorControllerApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { hasAccess } from "$root/components/AuthorizationGuard";
import { useUserValue } from "$root/functional-areas/user";
import { useLocaleFormatters } from "$root/localization/hooks";
import type { UploadEntity } from "$root/pages/Portfolios/UploadPortfolioPage";
import { statusIconMap } from "$root/pages/PortfoliosStudio";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { useQueryNoRefetch } from "$root/utils/react-query";
import {
	AutoSortTable,
	AutoTooltip,
	Button,
	Checkbox,
	CircularProgressBar,
	Dialog,
	DialogFooter,
	Icon,
	Row,
	SubmitButton,
	TableDataCell,
	TextInput,
	TooltipContent,
	useSelectableTableColumn,
} from "@mdotm/mdotui/components";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import { useDebouncedSearch, useSearchable } from "@mdotm/mdotui/headless";
import type { NodeOrFn } from "@mdotm/mdotui/react-extensions";
import { generateUniqueDOMId, renderNodeOrFn, toClassListRecord } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { builtInSortFnFor } from "@mdotm/mdotui/utils";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

type CustomizedInvestmentEntry = InvestmentListEntry & { domId: string };

type AddPortfolioButtonProps = {
	uploadEntity: UploadEntity;
	selectedPortfolios: string[];
	renderCustomButton?: NodeOrFn<{ setShowDialog(show: boolean): void; rows: CustomizedInvestmentEntry[] }>;
	onConfirm?(portfolios: Array<CustomizedInvestmentEntry>): MaybePromise<void>;
	uuid?: string;
};

const AddPortfolioButton = (props: AddPortfolioButtonProps): JSX.Element => {
	const { selectedPortfolios, renderCustomButton, uploadEntity, uuid, onConfirm } = props;
	const [showDialog, setShowDialog] = useState(false);

	const formatters = useLocaleFormatters();
	const { formatDate } = formatters;
	const editorApi = useApiGen(EntityEditorControllerApiFactory);
	const { t } = useTranslation();
	const [significantValues, setSignificantValues] = useState(false);

	const queryComposition = useQueryNoRefetch(["queryComposition", "investment"], {
		queryFn: async () => {
			let portfolioList: InvestmentListEntry[] | undefined = undefined;
			if (uuid) {
				portfolioList = (await axiosExtract(editorApi.getEditorEditSelectablePortfolios(uuid, uploadEntity)))
					.selectablePortfolios;
			} else {
				portfolioList = (await axiosExtract(editorApi.getEditorNewSelectablePortfolios(uploadEntity)))
					.selectablePortfolios;
			}

			return {
				all: portfolioList?.map((x) => ({ ...x, domId: generateUniqueDOMId() })),
				significantOnly:
					portfolioList?.flatMap((x): CustomizedInvestmentEntry[] =>
						x.canUseAsMixedPortfolio ? [{ ...x, domId: generateUniqueDOMId() }] : [],
					) ?? [],
			};
		},
	});

	const { data, isLoading } = queryComposition;

	const list = useMemo(() => (significantValues ? data?.significantOnly : data?.all), [data, significantValues]);

	const { debouncedNormalizedQuery, query, setQuery } = useDebouncedSearch("", 10);
	const { filtered } = useSearchable({
		collection: list ?? [],
		query: debouncedNormalizedQuery,
		matchFn: (row, q) => {
			const stringifiedRow = JSON.stringify(row.name).toLowerCase();
			const words = q.toLowerCase().split(" ");
			return words.every((keys) => stringifiedRow.indexOf(keys) >= 0);
		},
	});

	const selectableRowIds = useMemo(() => {
		if (!list) {
			return [];
		}

		return list.flatMap(({ uuid, canUseAsMixedPortfolio }) => {
			if (selectedPortfolios.some((x) => x === uuid) === false && uuid && canUseAsMixedPortfolio) {
				return [uuid];
			}
			return [];
		});
	}, [list, selectedPortfolios]);

	const {
		column: checkBoxColumn,
		rowClassList,
		toggle,
		multiSelectCtx,
	} = useSelectableTableColumn({
		filteredRows: filtered,
		rows: list ?? [],
		selectBy: ({ uuid }) => uuid ?? "",
		selectableRowIds,
		preSelectedRowIds: selectedPortfolios,
	});

	function onClose() {
		multiSelectCtx.actions.reset();
		setShowDialog(false);
	}
	const user = useUserValue();
	// FIXME: virtual scroll height estimation workaround
	return (
		<>
			<Dialog
				size="xxlarge"
				noValidate
				show={showDialog}
				onClose={onClose}
				header="Select"
				footer={
					<DialogFooter
						neutralAction={
							<Button palette="tertiary" onClick={onClose}>
								Cancel
							</Button>
						}
						primaryAction={<SubmitButton>Confirm</SubmitButton>}
					/>
				}
				onSubmitAsync={async () => {
					const filteredComposition =
						list?.filter(
							(x) => x.uuid && !selectedPortfolios.includes(x.uuid ?? "") && multiSelectCtx.data.selection.has(x.uuid),
						) ?? [];

					await onConfirm?.(filteredComposition);
					onClose();
				}}
			>
				<div className="mb-4 flex">
					<TextInput placeholder="Search a portfolio" classList="max-w-[280px]" value={query} onChangeText={setQuery} />

					{hasAccess(user, { requiredRole: "ROOT" }) && (
						<Checkbox
							onChange={() => setSignificantValues((prev) => !prev)}
							checked={significantValues}
							switchPosition="start"
							switchType="switch"
							classList="ml-auto"
						>
							Significant values only
						</Checkbox>
					)}
				</div>
				<AutoSortTable
					rows={filtered}
					rowClassList={(row, rowIndex) => ({
						...toClassListRecord(rowClassList(row, rowIndex)),
						"opacity-60": !row.canUseAsMixedPortfolio,
					})}
					columns={[
						checkBoxColumn,
						{
							header: t("TABLE.HEADERS.NAME"),
							content: (row, cellProps) => {
								if (!row.canUseAsMixedPortfolio && row.currentlyContainsNestedPortfolios) {
									return (
										<AutoTooltip
											mode="hover"
											align="startToStart"
											position="top"
											overrideColor={themeCSSVars.palette_N200}
											trigger={({ innerRef }) => (
												<TableDataCell {...cellProps}>
													<span ref={innerRef}>{row.name}</span>
												</TableDataCell>
											)}
										>
											<TooltipContent>
												<p className="text-center">
													You cannot add portfolios that already include other portfolios in their metrics and
													performance calculation
												</p>
											</TooltipContent>
										</AutoTooltip>
									);
								}

								return row.name;
							},
							sortFn: builtInSortFnFor("name"),
							name: "name",
						},
						{
							header: t("TABLE.HEADERS.STATUS"),
							content: ({ status }, cellProps) => {
								const sanitizedStatus = status ? statusIconMap[status] : "-";
								if (sanitizedStatus === "-") {
									return <div>{sanitizedStatus}</div>;
								}
								return (
									<Row {...cellProps} title={sanitizedStatus.title} alignItems="center">
										<Icon
											classList="mr-1"
											icon={sanitizedStatus.icon}
											color={themeCSSVars.palette_N800} // changed
											size={sanitizedStatus.size}
										/>
										<div style={{ fontSize: 12, fontFamily: "Gotham Book", fontWeight: "300" }}>
											{sanitizedStatus.title}
										</div>
									</Row>
								);
							},
							sortFn: builtInSortFnFor("status"),
							name: "status",
						},
						{
							header: t("TABLE.HEADERS.LAST_STATUS_UPDATE"),
							content: ({ modificationTime }) => {
								const sanitizedModificationTime = modificationTime ? formatDate(new Date(modificationTime)) : "-";
								return sanitizedModificationTime;
							},
							sortFn: ({ modificationTime: a }, { modificationTime: b }) => {
								const rowA = new Date(a ?? "").getTime();
								const rowB = new Date(b ?? "").getTime();
								return rowA > rowB ? 1 : rowA < rowB ? -1 : 0;
							},
							name: "lastReportsUpdate",
						},
						{
							header: t("TABLE.HEADERS.UNIVERSE_NAME"),
							content: (row) => row.universeName,
							name: "universeName",
							sortFn: builtInSortFnFor("universeName"),
						},
					]}
					classList="max-h-[410px]"
					onRowClick={({ uuid }) => toggle(uuid ?? "")}
					noDataText={
						isLoading ? (
							<div className="h-80 w-full flex justify-center items-center relative">
								<CircularProgressBar value="indeterminate" />
							</div>
						) : (
							"no instruments available"
						)
					}
				/>
			</Dialog>
			{renderCustomButton ? (
				renderNodeOrFn(renderCustomButton, {
					setShowDialog,
					rows: list ?? [],
				})
			) : (
				<Button palette="secondary" size="small" onClick={() => setShowDialog(true)} classList="flex gap-2">
					<Icon icon="add-ptf" size={18} />
					add portfolios
				</Button>
			)}
		</>
	);
};

export default AddPortfolioButton;
