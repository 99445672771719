import Highcharts from "$root/utils/chart/highcharts-with-modules";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { PaletteColors } from "$root/styles/themePalette";
import "./style.scss";
import { useLocaleFormatters } from "$root/localization/hooks";
import HighchartsReact from "highcharts-react-official";
import { useMemo } from "react";
import { useUnsafeUpdatedRef } from "@mdotm/mdotui/react-extensions";
import { clamp } from "@mdotm/mdotui/utils";

export type ReturnAnalysisZoomChartProps = {
	data: {
		dates: number[];
		performance: number[];
		prev_dates: number[];
		prev_performance: number[];
	};
	bands: Array<{
		fromIdx: number;
		toIdx: number;
		color: string;
		value: number;
		regime: 1 | 2 | 3;
	}>;
	seriesName?: string;
	downloadLabel?: string;
};

const TYPE_DEF = {
	ASSET_CLASS_REGIME: {
		1: "GROWTH",
		2: "LATERAL_PHASE",
		3: "HIGH_STRESS",
	},
} as const;

function ReturnAnalysisZoomChart({
	data,
	bands,
	seriesName,
	downloadLabel,
}: ReturnAnalysisZoomChartProps): JSX.Element {
	const [chartObject, setChartObject] = useState<HighchartsReact.RefObject | null>(null);
	const chartRef = useUnsafeUpdatedRef(chartObject);
	const { t } = useTranslation();
	const { formatDate, formatNumber } = useLocaleFormatters();
	const tooltipsContainerRef = useRef<HTMLDivElement | null>(null);

	const options = useMemo(() => {
		const mapped = data.dates.map((date, index) => {
			return [date, data.performance[index]];
		});
		const mappedBands = bands.map((band, idx) => {
			let bn = {
				color: `${band.color}`,
				regime: `${band.regime}`,
				from: data.dates[band.fromIdx],
				to:
					data.dates[band.toIdx] || (typeof data.dates[band.fromIdx] !== "undefined" ? data.dates.at(-1) : Date.now()),
				gel_band_value: {
					...band,
				},
				className: "",
				events: {
					mouseover(event: unknown) {
						if (!("target" in (event as { target: HTMLElement }))) {
							return;
						}
						if (tooltipsContainerRef.current) {
							const tp = tooltipsContainerRef.current?.childNodes[idx] as HTMLElement;
							const parentRect = chartRef.current!.container.current!.getBoundingClientRect();
							const rect = (event as { target: HTMLElement }).target.getBoundingClientRect();

							if (tp) {
								tp.style.display = "unset";
								tp.style.left = `${rect.left - parentRect.left + rect.width / 2 - 100 + 16}px`;
							}
						}
					},
					mouseout() {
						if (tooltipsContainerRef.current) {
							const tp = tooltipsContainerRef.current?.childNodes[idx] as HTMLElement;
							if (tp) {
								tp.style.display = "none";
							}
						}
					},
				},
			};
			if (band.toIdx - band.fromIdx < 80) {
				bn = {
					...bn,
					className: `bigBand colorHover`,
				};
			} else {
				bn = {
					...bn,
					className: `colorHover`,
				};
			}
			return bn;
		});

		return {
			chart: {
				style: {
					fontFamily: "Gotham,sans-serif",
				},
				type: "line",
				spacingLeft: 0,
				zoomType: "x",
			},
			credits: {
				enabled: false,
			},
			xAxis: {
				type: "datetime",
				tickInterval: 1000 * 60 * 60 * 24 * 30 * 12,
				tickLength: 0,
				endOnTick: false,
				labels: {
					style: {
						textOverflow: "none",
					},
					allowOverlap: true,
					formatter(this: { value: number }) {
						const target = new Date(this.value);
						return target.getFullYear().toString();
					},
				},
				//min: new Date().getTime() - (7 * 1000 * 60 * 60 * 24 * 30 * 12),
				min: data.dates[0],
				max: Date.now(),
				plotBands: mappedBands,
				events: {
					afterSetExtremes(this: { update: (props: unknown) => void }, event: { min: number; max: number }) {
						const min = event.min;
						const max = event.max;
						const interval = max - min;
						const intervalDay = Math.floor(interval / 86400000);
						const intervalMonth = Math.floor(intervalDay / 30);
						const intervalYear = Math.floor(intervalMonth / 12);
						let newInterval = 1000 * 60 * 60 * 24 * 30 * 12;
						if (intervalYear <= 1) {
							newInterval = 1000 * 60 * 60 * 24 * 30;
							if (intervalMonth <= 1) {
								newInterval = 1000 * 60 * 60 * 24;
							}
						}
						this.update({
							tickInterval: newInterval,
							labels: {
								formatter(this: { value: number }) {
									const target = new Date(this.value);
									const dd = String(target.getDate()).padStart(2, "0");
									const mm = String(target.getMonth() + 1).padStart(2, "0");
									const yyyy = target.getFullYear().toString();
									let newTarget = yyyy;
									if (intervalYear <= 1) {
										newTarget = `${mm}-${yyyy}`;
										if (intervalMonth <= 1) {
											newTarget = `${dd}-${mm}-${yyyy}`;
										}
									}
									return newTarget;
								},
							},
						});
					},
				},
			},
			yAxis: {
				opposite: false,
				title: {
					text: undefined,
				},
				labels: {
					formatter(this: { value: number | undefined }) {
						return formatNumber((this.value ?? 0) as number, 2);
					},
				},
			},
			plotOptions: {
				line: {
					dataLabels: {
						enabled: false,
					},
					enableMouseTracking: true,
				},
				series: {
					stickyTracking: false,
				},
			},
			legend: {
				enabled: false,
			},
			title: { text: undefined },
			series: [
				{
					name: seriesName || "Performance",
					data: mapped,
					type: "line",
					lineWidth: 1,
					color: PaletteColors.AZURE,
				},
			],
			tooltip: {
				enabled: true,
				shared: false,
				useHTML: true,
				borderColor: "transparent",
				borderRadius: 5,
				backgroundColor: PaletteColors.WHITE,
				positioner(boxWidth: number, boxHeight: number, point: { plotX: number; plotY: number }) {
					if (!chartRef.current?.chart) {
						return undefined;
					}
					const candidateY = point.plotY - boxHeight - 20;
					return {
						x: clamp(point.plotX - boxWidth / 2, 0, chartRef.current.chart.plotWidth),
						y: candidateY < 0 ? point.plotY + 20 : candidateY,
					};
				},
				formatter(this: { x: number; y: number; points: [{ series: { name: string } }] }) {
					return `
						<table class="return-graph-tooltip">
							<tbody>
								<tr>
									<th colspan="2" class="title" style="background:${PaletteColors.AZURE};">${this.points[0].series.name}</th>
								</tr>
								<tr>
									<td class="left-text">${t("DATE")}</td>
									<td class="right-text"><b>${formatDate(this.x)}</b></td>
								</tr>
								<tr>
									<td class="left-text">${t("RETURN_ANALYSIS.RETURN_SINCE_INCEPTION")}</td>
									<td class="right-text"><b>${formatNumber(this.y, 3)}</b></td>
								</tr>
							</tbody>
						</table>
					`;
				},
			},
			responsive: {
				rules: [
					{
						condition: {
							maxWidth: 400,
						},
						chartOptions: {
							pane: {
								size: "100%",
							},
						},
					},
				],
			},
			exporting: {
				chartOptions: {
					title: {
						text: "Regime Analysis",
					},
					subtitle: {
						text: `Data Exported in ${new Date().getUTCFullYear()}-${
							new Date().getUTCMonth() + 1
						}-${new Date().getUTCDate()}`,
					},
					chart: {
						margin: 40,
						marginTop: 80,
						marginLeft: 80,
						marginBottom: 80,
						events: {
							load(this: {
								renderer: {
									text: (s: string, x: number, y: number) => { attr: (opts: unknown) => { add: () => void } };
								};
							}) {
								this.renderer
									.text(
										`<span style="color:${PaletteColors.AZURE}; font-size:24px;">&#8226;</span> ${seriesName} - ${downloadLabel}
									&nbsp;&nbsp;&nbsp;&nbsp;
									<span style="color:${mappedBands[0].color}; font-size:24px;">&#8226;</span> Historical periods comparable to the current market regime.
									`,
										140,
										375,
									)
									.attr({
										zIndex: 3,
									})
									.add();
							},
						},
					},
					scrollbar: {
						enabled: false,
					},
				},
				buttons: {
					contextButton: {
						menuItems: ["downloadJPEG", "downloadPDF"],
					},
				},
				allowHTML: true,
				printMaxWidth: 1080,
				filename: "MDotM_Regime_Analysis",
				sourceHeight: 400,
				sourceWidth: 1440,
			},
		};
	}, [bands, chartRef, data.dates, data.performance, downloadLabel, formatDate, formatNumber, seriesName, t]);

	const bandTooltips = useMemo(() => {
		if (!chartObject) {
			return null;
		}
		const xAxis = chartObject.chart.xAxis[0];
		const plotBandsDefs = (xAxis.userOptions.plotBands || []) as Array<
			Highcharts.XAxisPlotBandsOptions & { color: string; regime: 1 | 2 | 3; from: number; to: number }
		>;
		const plotBands = Array.from(
			document.querySelectorAll(`.returnAnalysis-graph-container .highcharts-plot-band`),
		).map((pb, idx) => ({
			element: pb,
			def: plotBandsDefs[idx],
		}));
		return (
			<>
				{plotBands.map((band) => {
					const from = formatDate(band.def.from);
					const to = formatDate(band.def.to);
					return (
						<div key={`${band.def.from}-${band.def.to}`} className="DivTooltip return-analysis">
							<div className="title w-full" style={{ backgroundColor: band.def.color }}>
								{t(`ASSET_CLASS_SUMMARY.UO_WEIGHT.CHIPS.${TYPE_DEF.ASSET_CLASS_REGIME[band.def.regime]}`)}
							</div>
							<table>
								<tbody>
									<tr>
										<td className="left-text">{t("START_DATE")}</td>
										<td className="right-text">{from}</td>
									</tr>
									<tr>
										<td className="left-text">{t("END_DATE")}</td>
										<td className="right-text">{to}</td>
									</tr>
								</tbody>
							</table>
						</div>
					);
				})}
			</>
		);
	}, [chartObject, formatDate, t]);

	return (
		<div
			className="returnAnalysis-graph-container"
			style={{ flex: 1, display: "flex", flexDirection: "column", minHeight: 0, paddingBottom: "1rem" }}
		>
			<div className="pt-1">
				<div className="capitalize text-[0.625rem] text-[#2f3541]">{t("FORECAST.RETURN")}</div>
			</div>
			<HighchartsReact
				containerProps={{ style: { flex: 1, minHeight: 0 } }}
				highcharts={Highcharts}
				constructorType="stockChart"
				options={options}
				ref={setChartObject}
			/>
			<div id="band-tooltips-container" ref={tooltipsContainerRef}>
				{bandTooltips}
			</div>
		</div>
	);
}

export default ReturnAnalysisZoomChart;
