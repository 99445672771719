import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import TrendChart from "./TrendChart";
import type { Chart } from "highcharts";
import { PaletteColors } from "$root/styles/themePalette";
import ScenarioTabs from "./ScenarioTabs";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { useWidgetOptions } from "$root/widgets-architecture/layout/WidgetsMapper/context";
import type { ContextContent } from "$root/utils/react-extra";
import { withContext } from "$root/utils/react-extra";
import { RegionContext } from "$root/widgets-architecture/contexts/region";
import { useApiGen } from "$root/api/hooks";
import { HmmControllerApiFactory } from "$root/api/api-gen";
import { useLocaleFormatters } from "$root/localization/hooks";
import { Text } from "@mdotm/mdotui/components";
import { IconWalls } from "$root/components/IconWall";

const ScenarioDescriptionBlock = (props: ContextContent<typeof RegionContext>) => {
	const { t } = useTranslation();
	const [serie, setSerie] = useState(0);
	const { region } = props;

	const fromObjToArray = (obj: { [x: string]: any }) => {
		const dataArray = Object.keys(obj).map((key) => {
			return obj[key];
		});
		return dataArray;
	};

	const hmmApi = useApiGen(HmmControllerApiFactory);

	const { formatNumber } = useLocaleFormatters();

	const { isLoading, isError, isFetching, data } = useQueryNoRefetch(["queryHmmScenarioDescription", region], {
		queryFn: async () => {
			const { data: ScenarioDescription } = await hmmApi.retrieveScenarioDescription(region);
			const ARDGrowSerie = fromObjToArray(ScenarioDescription.growth?.annualizedReturnDistribution?.data ?? []);
			const AVDGrowSerie = fromObjToArray(ScenarioDescription.growth?.annualizedVolatilityDistribution?.data ?? []);
			const ARDLateralSerie = fromObjToArray(
				ScenarioDescription.lateralPhase?.annualizedReturnDistribution?.data ?? [],
			);
			const AVDLateralSerie = fromObjToArray(
				ScenarioDescription.lateralPhase?.annualizedVolatilityDistribution?.data ?? [],
			);
			const ARDHighSerie = fromObjToArray(ScenarioDescription.highStress?.annualizedReturnDistribution?.data ?? []);
			const AVDHighSerie = fromObjToArray(ScenarioDescription.highStress?.annualizedVolatilityDistribution?.data ?? []);

			const Series = [
				{
					AVD: {
						name: "Annualized Volatility Distribution",
						data: AVDGrowSerie,
						color: PaletteColors.GREENY_BLUE,
						mean: (ScenarioDescription.growth?.annualizedVolatilityDistribution?.mean ?? 0) * 100,
						std: (ScenarioDescription.growth?.annualizedVolatilityDistribution?.standardDeviation ?? 0) * 100,
					},
					ARD: {
						name: "Annualized Return Distribution",
						data: ARDGrowSerie,
						color: PaletteColors.GREENY_BLUE,
						mean: (ScenarioDescription.growth?.annualizedReturnDistribution?.mean ?? 0) * 100,
						std: (ScenarioDescription.growth?.annualizedReturnDistribution?.standardDeviation ?? 0) * 100,
					},
				},
				{
					AVD: {
						name: "Annualized Volatility Distribution",
						data: AVDLateralSerie,
						color: PaletteColors.YELLOW,
						mean: (ScenarioDescription.lateralPhase?.annualizedVolatilityDistribution?.mean ?? 0) * 100,
						std: (ScenarioDescription.lateralPhase?.annualizedVolatilityDistribution?.standardDeviation ?? 0) * 100,
					},
					ARD: {
						name: "Annualized Return Distribution",
						data: ARDLateralSerie,
						color: PaletteColors.YELLOW,
						mean: (ScenarioDescription.lateralPhase?.annualizedReturnDistribution?.mean ?? 0) * 100,
						std: (ScenarioDescription.lateralPhase?.annualizedReturnDistribution?.standardDeviation ?? 0) * 100,
					},
				},
				{
					AVD: {
						name: "Annualized Volatility Distribution",
						data: AVDHighSerie,
						color: PaletteColors.NEON_RED,
						mean: (ScenarioDescription.highStress?.annualizedVolatilityDistribution?.mean ?? 0) * 100,
						std: (ScenarioDescription.highStress?.annualizedVolatilityDistribution?.standardDeviation ?? 0) * 100,
					},
					ARD: {
						name: "Annualized Return Distribution",
						data: ARDHighSerie,
						color: PaletteColors.NEON_RED,
						mean: (ScenarioDescription.highStress?.annualizedReturnDistribution?.mean ?? 0) * 100,
						std: (ScenarioDescription.highStress?.annualizedReturnDistribution?.standardDeviation ?? 0) * 100,
					},
				},
			];
			return Series;
		},
	});

	const changeSerie = (selection: number) => {
		setSerie(selection);
	};

	const chartRef = useRef<{ chart: Chart } | null>(null);

	useWidgetOptions(
		() => ({
			isDraggable: false,
			actionHeader: <></>,
			title: t("SCENARIO_DESCRIPTION.TITLE", { region: t(`GEOGRAPHY.${region}`) }),
		}),
		[t, region],
	);

	return (
		<>
			{isError ? (
				<IconWalls.ErrorData />
			) : isLoading || isFetching ? (
				<IconWalls.LoadingData />
			) : (
				<>
					<ScenarioTabs onTabChange={changeSerie} tabIndex={serie} />
					<div className="grid grid-cols-2">
						<div style={{ position: "relative", zIndex: 0 }}>
							<div style={{ position: "relative", zIndex: 0 }}>
								<Text as="div" type="Body/XL/Book" classList="mb-3">
									Annualized return distributions
								</Text>
								<TrendChart
									ref={chartRef}
									series={data[serie].ARD}
									xMin={-1}
									xMax={1}
									yMax={region === "JP" ? 250 : 200}
								/>
							</div>
							<div
								style={{
									marginTop: "1rem",
									padding: "0.5rem 1rem",
									backgroundColor: "white",
									position: "absolute",
									right: "1.5rem",
									top: 0,
									zIndex: 1,
									borderRadius: 15,
									overflow: "hidden",
								}}
							>
								<div style={{ marginBottom: "0.75rem" }}>
									<span style={{ fontWeight: "bold" }}>{t("MEAN")}</span>
									&nbsp;
									<span>{formatNumber(data[serie].ARD.mean)}%</span>
								</div>
								<div style={{ marginBottom: "0.75rem" }}>
									<span style={{ fontWeight: "bold" }}>{t("STANDARD_SHORT")}</span>
									&nbsp;
									<span>{formatNumber(data[serie].ARD.std)}%</span>
								</div>
							</div>
						</div>
						<div style={{ position: "relative", zIndex: 0 }}>
							<div style={{ position: "relative", zIndex: 0 }}>
								<Text as="div" type="Body/XL/Book" classList="mb-3">
									Annualized volatility distributions
								</Text>
								<TrendChart
									ref={chartRef}
									series={data[serie].AVD}
									xMin={0}
									xMax={1}
									yMax={region === "JP" ? 250 : 200}
								/>
							</div>
							<div
								style={{
									marginTop: "1rem",
									padding: "0.5rem 1rem",
									backgroundColor: "white",
									position: "absolute",
									right: "1.5rem",
									top: 0,
									zIndex: 1,
									borderRadius: 15,
									overflow: "hidden",
								}}
							>
								<div style={{ marginBottom: "0.75rem" }}>
									<span style={{ fontWeight: "bold" }}>{t("MEAN")}</span>
									&nbsp;
									<span>{formatNumber(data[serie].AVD.mean)}%</span>
								</div>
								<div style={{ marginBottom: "0.75rem" }}>
									<span style={{ fontWeight: "bold" }}>{t("STANDARD_SHORT")}</span>
									&nbsp;
									<span>{formatNumber(data[serie].AVD.std)}%</span>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
};

export default withContext(RegionContext)(ScenarioDescriptionBlock);
