import type {
	CommentaryTemplateModel,
	FieldKey,
	Filter,
	FilterResponse,
	InvestmentCommentaryDTO,
	InvestmentExportConverterType,
	InvestmentListEntry,
	InvestmentStatuses,
	InvestmentsReportTemplate,
	MetricResultEntry,
	PageDto,
	SyncInfoDto,
	UserPortfolioColumnOrdering,
	UserPortfolioColumnPreference,
	UserPortfolioColumnPreferencePreferencesTypeEnum,
} from "$root/api/api-gen";
import {
	CommentaryTemplateControllerApiFactory,
	IntegrationsControllerApiFactory,
	InvestmentBulkBulkStaticConfigurationControllerV1ApiFactory,
	InvestmentBulkEnhancementConfigurationControllerV4ApiFactory,
	InvestmentControllerV4ApiFactory,
	InvestmentsBulkAnalysisControllerApiFactory,
	InvestmentsReportTemplateControllerApiFactory,
	PdfControllerApiFactory,
	PortfolioStudioPreferencesApiFactory,
} from "$root/api/api-gen";
import { reportPlatformError } from "$root/api/error-reporting";
import { useApiGen } from "$root/api/hooks";
import { withCache } from "$root/caching/with-cache";
import { hasAccess } from "$root/components/AuthorizationGuard";
import { typedUrlForRoute, useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import { spawnYesNoDialog } from "$root/components/spawnable/yes-no-dialog";
import type { ColumnMetadata } from "$root/components/tables-extra/CustomizeColumns";
import { spawnCustomizeColumnsDialog } from "$root/components/tables-extra/CustomizeColumns";
import { aclByArea, roleByArea } from "$root/functional-areas/acl/checkers/all";
import { validateACLPermissions } from "$root/functional-areas/acl/checkers/shared";
import { useUpdateAccessListControlDialog } from "$root/functional-areas/acl/hook/useUpdateAccessListControlDialog";
import type { CompareDataItem } from "$root/functional-areas/compare-portfolio/CompareOverlay";
import { CompareOverlay, type CompareOverlayProps } from "$root/functional-areas/compare-portfolio/CompareOverlay";
import { PortfolioStudioSettingTabEnum } from "$root/functional-areas/portfolio-studio-settings";
import { portfolioEntityManagementActions } from "$root/functional-areas/portfolio/entity-management";
import { defaultPortfolioTemplates, isDefaultReportTemplate } from "$root/functional-areas/reports/default-templates";
import { useUserValue } from "$root/functional-areas/user";
import useCompositionDownload from "$root/hooks/useCompositionDownload";
import { useLocaleFormatters } from "$root/localization/hooks";
import { platformToast } from "$root/notification-system/toast";
import { PortfolioDetailsTabs } from "$root/pages/PortfolioDetails";
import type { ReportTemplateVariant } from "$root/pages/PortfolioStudioSettings/ReportEditor/report-latest";
import { axiosExtract } from "$root/third-party-integrations/axios";
import type { MetricComparisonChartProps } from "$root/ui-lib/charts/MetricComparisonChart";
import { MetricComparisonChart } from "$root/ui-lib/charts/MetricComparisonChart";
import { actionsColumn } from "$root/ui-lib/interactive-collections/common-table-actions";
import { parallelize } from "$root/utils/promise";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { textSearchMatchFns } from "$root/utils/strings";
import type {
	ActionOrActionWithGroup,
	BatchAction,
	DropdownMenuProps,
	Option,
	OrderBy,
	TableColumn,
} from "@mdotm/mdotui/components";
import {
	BaseHScrollTable,
	BatchActions,
	Button,
	ButtonGroup,
	Checkbox,
	Icon,
	ProgressBar,
	contextMenuHandler,
	defaultBatchActionsI18n,
	useMergeI18n,
	useSelectableTableColumn,
	useSortedRows,
} from "@mdotm/mdotui/components";
import { type MultiSelectCtx, useMultiSelect } from "@mdotm/mdotui/headless";
import {
	generateUniqueDOMId,
	toClassListRecord,
	useDebounced,
	useDebouncedMemo,
	useUpdatedRef,
} from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { builtInSort, debugValue, noop, nullary, stableEmptyArray, unpromisify } from "@mdotm/mdotui/utils";
import type { QueryObserverBaseResult } from "@tanstack/query-core";
import { format } from "date-fns";
import type { HighchartsReactRefObject } from "highcharts-react-official";
import { Map, Set } from "immutable";
import type { ForwardedRef } from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";
import type { DefaultSelectionProps } from "$root/functional-areas/portfolio-studio/dialogs/CompareDialog";
import { spawnCompareDialogProps } from "$root/functional-areas/portfolio-studio/dialogs/CompareDialog";
import type {
	PortfolioInsightPanelHandles,
	PortfolioInsightPanelPreset,
} from "$root/functional-areas/portfolio-studio/InsightPanel";
import PortfolioInsightPanel from "$root/functional-areas/portfolio-studio/InsightPanel";
import type { TemplateChooserSubmitParams } from "$root/functional-areas/portfolio-studio/dialogs/TemplateChooserDialog";
import {
	type PortfolioTemplateChooserDialogProps,
	spawnPortfolioTemplateChooser,
} from "$root/functional-areas/portfolio-studio/dialogs/TemplateChooserDialog";
import { multipleEntityDelete, notifyUser } from "../common";
import { usePortfolioStudioTableSettings } from "../portfolio-studio-table-settings";
import { usePortfolioColumn } from "./columns";
import { fieldKeyExtractor, matchInvestentToFilter } from "$root/functional-areas/portfolio-studio/util";
import {
	PortfolioListViewMode,
	usePortfolioStudioState,
} from "$root/functional-areas/portfolio-studio/PortfolioStudioStore";
import { useDeepEqualEffect } from "$root/utils/react-extra";
import env from "$root/env";
import { downloadBlob } from "$root/utils/files";
import { START_DATE_CREATE_PROPOSAL_COMMENTARY } from "$root/utils/const";
import { diffrenceISODate } from "$root/utils/dates";

type PortfolioListProps = {
	portfolios: Array<InvestmentListEntry>;
	columnsPreferences?: UserPortfolioColumnPreference[];
	userSyncInfo?: SyncInfoDto | undefined;
	refetch: {
		investments: QueryObserverBaseResult<InvestmentListEntry[]>["refetch"];
		columnsPreferences: QueryObserverBaseResult<UserPortfolioColumnOrdering>["refetch"];
	};
	isVisible: boolean;
	portfolioStudioOuterDiv: HTMLDivElement | null;
};

function generateCompareItem(portfolio: InvestmentListEntry, enhanced: boolean): CompareDataItem {
	return {
		id: generateUniqueDOMId(),
		composition:
			(enhanced ? portfolio?.macroAssetClassExposureEnhanced : portfolio?.macroAssetClassExposure)?.map((x) => ({
				quality: x.firstQualityLevel,
				weight: x.weight,
			})) ?? [],
		numberOfInstrument: portfolio.nofInstruments ?? 0,
		portfolioName: portfolio.name ?? "-",
		uuid: portfolio.uuid,
		enhanced,
		note: portfolio.lastActionNote,
		action: portfolio.action,
	};
}

function convertFilterResponseToFilter(filterResponse: Array<FilterResponse>): Array<Filter> {
	return filterResponse
		.filter((x) => x.key && x.value && x.relation)
		.map((x) => ({ fieldKey: x.key, relation: x.relation, value: x.value }));
}

const metricToMeasurementUnit: Partial<Record<FieldKey, string>> = {
	PERFORMANCE_SINCE_INCEPTION: "%",
	PERFORMANCE_YTD: "%",
	PERFORMANCE_1Y: "%",
	PERFORMANCE_6M: "%",
	PERFORMANCE_3M: "%",
	PERFORMANCE_1M: "%",
	SORTINO_SINCE_INCEPTION: "%",
	SORTINO_YTD: "%",
	SORTINO_1Y: "%",
	SORTINO_6M: "%",
	SORTINO_3M: "%",
	SORTINO_1M: "%",
	MAX_DRAWDOWN_SINCE_INCEPTION: "%",
	MAX_DRAWDOWN_YTD: "%",
	MAX_DRAWDOWN_1Y: "%",
	MAX_DRAWDOWN_6M: "%",
	MAX_DRAWDOWN_3M: "%",
	MAX_DRAWDOWN_1M: "%",
	VOLATILITY_SINCE_INCEPTION: "%",
	VOLATILITY_YTD: "%",
	VOLATILITY_1Y: "%",
	VOLATILITY_6M: "%",
	VOLATILITY_3M: "%",
	VOLATILITY_1M: "%",
	EFFICIENCY_RATIO_SINCE_INCEPTION: "%",
	EFFICIENCY_RATIO_YTD: "%",
	EFFICIENCY_RATIO_1Y: "%",
	EFFICIENCY_RATIO_6M: "%",
	EFFICIENCY_RATIO_3M: "%",
	EFFICIENCY_RATIO_1M: "%",
	EX_ANTE_RETURN_3Y: "%",
	EX_ANTE_EFFICIENCY_RATIO_3Y: "%",
	EX_ANTE_MAX_DRAWDOWN_3Y: "%",
	EX_ANTE_DIVERSIFICATION_RATIO_3Y: "%",
	TRACKING_ERROR_3Y: "%",
	PARAMETRIC_VAR_95_1Y: "%",
	PARAMETRIC_VAR_975_1Y: "%",
	PARAMETRIC_VAR_99_1Y: "%",
	PARAMETRIC_VAR_95_2Y: "%",
	PARAMETRIC_VAR_975_2Y: "%",
	PARAMETRIC_VAR_99_2Y: "%",
	PARAMETRIC_VAR_95_3Y: "%",
	PARAMETRIC_VAR_975_3Y: "%",
	PARAMETRIC_VAR_99_3Y: "%",
	HISTORICAL_VAR_95_1Y: "%",
	HISTORICAL_VAR_975_1Y: "%",
	HISTORICAL_VAR_99_1Y: "%",
	HISTORICAL_VAR_95_2Y: "%",
	HISTORICAL_VAR_975_2Y: "%",
	HISTORICAL_VAR_99_2Y: "%",
	HISTORICAL_VAR_95_3Y: "%",
	HISTORICAL_VAR_975_3Y: "%",
	HISTORICAL_VAR_99_3Y: "%",
	EX_ANTE_VOLATILITY_1Y: "%",
	EX_ANTE_VOLATILITY_2Y: "%",
	EX_ANTE_VOLATILITY_3Y: "%",
	EX_ANTE_VOLATILITY_6M: "%",
	EXPOSURE_MACRO_EQUITY: "%",
	EXPOSURE_MACRO_FIXED_INCOME: "%",
	EXPOSURE_MACRO_COMMODITIES: "%",
	EXPOSURE_MACRO_ALTERNATIVE: "%",
	EXPOSURE_MACRO_MONEY_MARKET: "%",
};
// TODO:make the chart always zoom to 100% when ranges of ptf increase
function PortfolioInsight(props: PortfolioListProps): JSX.Element {
	const { portfolios, refetch } = props;

	const rowSelection = useMultiSelect<string>();
	const { portfolioStudioStore, setPortfolioStudioStore } = usePortfolioStudioState();

	const viewMode = portfolioStudioStore.viewMode ?? "MIXED";
	function setViewMode(mode: PortfolioListViewMode) {
		setPortfolioStudioStore((ps) => ({ ...ps, viewMode: mode }));
	}

	const [insightPanelPreset, setInsightPanelPreset] = useState<PortfolioInsightPanelPreset | undefined>(undefined);
	const [comparedInvestments, setComparedInvestments] = useState<CompareOverlayProps["compareData"]>([]);
	const portfolioInsightHandleRef = useRef<PortfolioInsightPanelHandles | null>(null);
	const chartRef = useRef<HighchartsReactRefObject | null>(null);

	const { value: rowsWithoutSearch } = useDebouncedMemo(
		() => {
			if (!insightPanelPreset?.filters) {
				return portfolios;
			}

			const filters = Array.from(insightPanelPreset.filters.values());
			return portfolios.filter((investment) =>
				filters.every((filter) => {
					const valueToMatch = fieldKeyExtractor(filter.key!, investment);
					return matchInvestentToFilter(filter, valueToMatch ? String(valueToMatch) : undefined);
				}),
			);
		},
		[insightPanelPreset?.filters, portfolios],
		{ debounceInterval: 500 },
	);

	const rowsWithoutSearchMap = useMemo(
		() => Map(rowsWithoutSearch.flatMap((x) => (x.uuid ? [[x.uuid, x]] : []))),
		[rowsWithoutSearch],
	);

	const { value: rows } = useDebouncedMemo(
		() => {
			const filteredInvestments = insightPanelPreset?.search
				? rowsWithoutSearch.filter((investment) =>
						textSearchMatchFns.keyword(investment.name ?? "-", insightPanelPreset.search),
				  )
				: rowsWithoutSearch;

			return Map(filteredInvestments.flatMap((x) => (x.uuid ? [[x.uuid, x]] : [])));
		},
		[insightPanelPreset?.search, rowsWithoutSearch],
		{ debounceInterval: 500 },
	);

	useEffect(() => {
		noop(rows); // track
		if (chartRef.current) {
			chartRef.current.chart.zoomOut();
		}
	}, [rows]);

	useDeepEqualEffect(() => {
		noop(insightPanelPreset?.filters); // track
		rowSelection.actions.setSelection(
			rowSelection.data.selection.intersect(rowsWithoutSearch.map((x) => x.uuid ?? "")),
		);
	}, [insightPanelPreset?.filters, rowSelection.actions, rowSelection.data.selection, rowsWithoutSearch]);

	const investmentApi = useApiGen(InvestmentControllerV4ApiFactory);
	const investmentsReportTemplateApi = useApiGen(InvestmentsReportTemplateControllerApiFactory);
	const pdfControllerApi = useApiGen(PdfControllerApiFactory);
	const integrationsApi = useApiGen(IntegrationsControllerApiFactory);
	const commentaryTemplateApi = useApiGen(CommentaryTemplateControllerApiFactory);
	const bulkEnhancementApi = useApiGen(InvestmentBulkEnhancementConfigurationControllerV4ApiFactory);
	const investmentsBulkAnalysisController = useApiGen(InvestmentsBulkAnalysisControllerApiFactory);
	const investmentBulkBulkStaticConfigurationV1 = useApiGen(
		InvestmentBulkBulkStaticConfigurationControllerV1ApiFactory,
	);

	const portfolioStudioPreferencesApi = useApiGen(PortfolioStudioPreferencesApiFactory);

	const { scheduleMultiInvestmentsConversion, downloadInvestmentConversion } = useCompositionDownload();
	const { push } = useTypedNavigation();
	const allI18n = useMergeI18n({ fallback: defaultBatchActionsI18n });

	const { invoke: reflowChart } = useDebounced(
		() => {
			const chart = chartRef.current?.chart;
			if (chart) {
				chart.reflow();
			}
		},
		{ debounceInterval: 300 },
	);

	const onExpandChange = useCallback(() => {
		setPortfolioStudioStore((ps) => ({ ...ps, isightPanel: { open: !ps.isightPanel?.open } }));
		reflowChart();
	}, [reflowChart, setPortfolioStudioStore]);

	const onCheckIfNameIsAvailable = useCallback(
		(name: string) => axiosExtract(investmentsBulkAnalysisController.isPresetNameAvailable(name)),
		[investmentsBulkAnalysisController],
	);

	const onDownloadReportBuilderTemplates = useCallback(
		async (pages: PageDto[]) => {
			try {
				if (pages.length === 1) {
					const [page] = pages;
					const pdf = await axiosExtract(
						pdfControllerApi.generateCustomReportPdf(`${env.appOrigin}${page.url}`, "TPLPortraitNoMargin", page.name!, {
							responseType: "blob",
						}),
					);
					downloadBlob(pdf as Blob, {
						fileName: page.name ?? "untitled",
					});
					return;
				}

				await pdfControllerApi.generateCustomReportsPdf({
					pages,
					templateType: "TPLPortraitNoMargin",
				});

				platformToast({
					children: "Sphere has taken over your request", //TODO: translate
					severity: "info",
					icon: "Portfolio",
				});
			} catch (error) {
				reportPlatformError(error, "ERROR", "portfolio", "unable to generate the bulk download");
				platformToast({
					children: "Something went wrong while generating the report",
					severity: "error",
					icon: "Portfolio",
				});
			} finally {
				rowSelection.actions.reset();
			}
		},
		[rowSelection, pdfControllerApi],
	);

	const onDownloadComposition = useCallback(
		async (converter: InvestmentExportConverterType, investments: Array<{ uuid: string; enhancement?: boolean }>) => {
			try {
				if (converter === "EASIM_TEMPLATE_CONVERTER") {
					if (investments.length > 1) {
						const compositions = await parallelize(
							investments.map(
								({ uuid, enhancement }) =>
									() =>
										axiosExtract(integrationsApi.exportInvestment(uuid, enhancement)),
							),
						);

						await axiosExtract(integrationsApi.convertTo(converter, compositions, true));
						platformToast({
							children: "Sphere has taken over your request",
							severity: "info",
							icon: "Dowload",
						});
						return;
					}
					const { enhancement, uuid } = investments[0];
					const composition = await axiosExtract(integrationsApi.exportInvestment(uuid, enhancement));
					await axiosExtract(integrationsApi.convertTo(converter, [composition], true));
					platformToast({
						children: "Sphere has taken over your request",
						severity: "info",
						icon: "Dowload",
					});
					return;
				}

				if (investments.length > 1) {
					await scheduleMultiInvestmentsConversion(converter, investments);
					platformToast({
						children: "Sphere has taken over your request",
						severity: "info",
						icon: "Dowload",
					});
					return;
				}
				const { enhancement, uuid } = investments[0];
				await downloadInvestmentConversion(
					converter,
					uuid,
					enhancement,
					// converter === InvestmentExportConverterType.EasimTemplateConverter,
				);
			} catch (error) {
				platformToast({
					children: "Unable to export the selected investments",
					icon: "Portfolio",
					severity: "error",
				});
				throw error;
			}
		},
		[downloadInvestmentConversion, integrationsApi, scheduleMultiInvestmentsConversion],
	);

	const onCompareInvestments = useCallback(
		(portfolio: InvestmentListEntry, enhanced?: boolean) =>
			setComparedInvestments((prev) => {
				const portfoliooAlreadyCompared = prev.find((x) => x.uuid === portfolio.uuid && x.enhanced === enhanced);
				if (portfoliooAlreadyCompared) {
					return prev.filter((x) => x.id !== portfoliooAlreadyCompared.id);
				}

				return [...prev, generateCompareItem(portfolio, enhanced ?? false)];
			}),
		[],
	);

	const onCompareMultipleInvestments = useCallback(
		(selected?: Map<string, DefaultSelectionProps>) => {
			setComparedInvestments((prev) => {
				const selectedPortfolios = Set<string>([
					...prev.map((x) => x.uuid ?? ""),
					...rowSelection.data.selection.toArray(),
				]);

				return selectedPortfolios.toArray().flatMap((uuid) => {
					const investment = rowsWithoutSearchMap?.get(uuid);
					if (!investment) {
						return [];
					}

					const preferences = selected?.get(uuid);
					const existingPortfolio = prev.filter((portfolio) => portfolio.uuid === uuid);
					const currentPortfolio = generateCompareItem(investment, false);
					const enhancedPortfolio = generateCompareItem(investment, true);
					if (existingPortfolio.length === 0) {
						return preferences === undefined
							? [currentPortfolio]
							: [
									...(preferences.current ? [currentPortfolio] : []),
									...(preferences.enhance ? [enhancedPortfolio] : []),
							  ];
					}

					return preferences === undefined
						? existingPortfolio
						: [
								...(preferences.current ? [existingPortfolio.find((x) => !x.enhanced) ?? currentPortfolio] : []),
								...(preferences.enhance ? [existingPortfolio.find((x) => x.enhanced) ?? enhancedPortfolio] : []),
						  ];
				});
			});
		},
		[rowSelection.data.selection, rowsWithoutSearchMap],
	);

	const onGenerateCommentary = useCallback(
		async (investments: Array<InvestmentCommentaryDTO>) => {
			await investmentApi.createCommentaryBulkFromTemplate(investments);
		},
		[investmentApi],
	);

	const onCreateProposal = useCallback(
		async (investments: Set<string>) => {
			try {
				const { uuid } = await axiosExtract(bulkEnhancementApi.createDraftBulkEnhancement(investments.toArray()));
				push("Portfolios/EditPortfolioMulti", { proposalUuid: uuid ?? "" });
			} catch (error) {
				reportPlatformError(error, "ERROR", "portfolio", "try to create bulk enhancement");
				platformToast({
					children: "Unable to create a proposal",
					severity: "error",
					icon: "Dowload",
				});
				throw error;
			}
		},
		[bulkEnhancementApi, push],
	);

	const onEditPortfolioSettings = useCallback(
		async (investments: Set<string>) => {
			try {
				const { uuid } = await axiosExtract(
					investmentBulkBulkStaticConfigurationV1.createDraftConfiguration(investments.toArray()),
				);
				push("Portfolios/SettingsPortfolioMulti", { bulkUid: uuid ?? "" });
			} catch (error) {
				reportPlatformError(error, "ERROR", "portfolio", "try to create bulk enhancement");
				platformToast({
					children: "Unable to edit settings in bulk",
					severity: "error",
					icon: "Dowload",
				});
				throw error;
			}
		},
		[investmentBulkBulkStaticConfigurationV1, push],
	);

	const onApplyFilters = useCallback(
		(investments: Set<string>) => {
			portfolioInsightHandleRef.current?.setManualSelection(investments.toArray());
			rowSelection.actions.reset();
		},
		[rowSelection.actions],
	);

	const onSubmitColumnPreferences = useCallback(
		async (data: ColumnMetadata<UserPortfolioColumnPreferencePreferencesTypeEnum>[]) => {
			try {
				const payload = {
					userPortfolioColumnPreferences: data.map((preference) => ({
						enabled: preference.visible,
						preferencesType: preference.id,
					})),
				} satisfies UserPortfolioColumnOrdering;
				await portfolioStudioPreferencesApi.setUserPortfolioColumnMetricsOrderingPreferences(payload);
				await refetch.columnsPreferences({ throwOnError: true });
				platformToast({
					children: "Table Successfully Edited.",
					severity: "success",
					icon: "Settings",
				});
			} catch (error) {
				platformToast({
					children: "Failed to update table",
					severity: "error",
					icon: "Settings",
				});
				throw error;
			}
		},
		[portfolioStudioPreferencesApi, refetch],
	);

	const metricResultQuery = useQueryNoRefetch({
		queryKey: [
			"metricResult",
			insightPanelPreset?.metric?.key,
			insightPanelPreset?.metric?.referenceEntity?.identifier,
			insightPanelPreset?.metric?.referenceType,
			insightPanelPreset?.uuid,
		],
		cacheTime: 3600 * 1000,
		queryFn: () => {
			const metric = insightPanelPreset?.metric;
			if (!metric) {
				return null;
			}
			return axiosExtract(
				investmentsBulkAnalysisController.getMetricResult({
					key: metric.key,
					ordering: metric.ordering,
					referenceEntity: metric.referenceType === "DELTA_RELATIVE" ? metric.referenceEntity : undefined,
					referenceType: metric.referenceType,
				}),
			);
		},
	});

	const { portfolioListOrderByName, setPortfolioListOrderByName } = usePortfolioStudioTableSettings();
	useEffect(() => {
		if (insightPanelPreset?.metric?.key && insightPanelPreset?.metric?.ordering !== "UNSORTED") {
			setPortfolioListOrderByName([
				{
					columnName: insightPanelPreset?.metric?.key,
					direction: insightPanelPreset?.metric?.ordering === "ASC" ? "asc" : "desc",
				},
			]);
		}
	}, [insightPanelPreset?.metric?.key, insightPanelPreset?.metric?.ordering, setPortfolioListOrderByName]);

	const { metricResultEntries, ...metricStats } = metricResultQuery.data ?? {};
	const metricResultsById = useMemo(
		() => Map(metricResultEntries?.map((entry) => [entry.uuid!, entry]) ?? []),
		[metricResultEntries],
	);

	const onChangeOrder = useCallback(
		(newOrderBy: OrderBy[]) => {
			setPortfolioListOrderByName(newOrderBy);
			const insightNewOrder = newOrderBy.at(0)?.columnName === insightPanelPreset?.metric?.key;
			const order = newOrderBy.at(0)?.direction;
			portfolioInsightHandleRef.current?.setOrder(!insightNewOrder ? "UNSORTED" : order === "asc" ? "ASC" : "DESC");
		},
		[insightPanelPreset?.metric?.key, setPortfolioListOrderByName],
	);

	const allVisibleColumns =
		usePortfolioColumn(comparedInvestments, onCompareInvestments, props.columnsPreferences, props.userSyncInfo) ??
		(stableEmptyArray as TableColumn<InvestmentListEntry>[]);

	const { t } = useTranslation();
	const { formatNumber } = useLocaleFormatters();
	const { key: metricKey } = insightPanelPreset?.metric ?? {};
	const metricColumn = useMemo<TableColumn<InvestmentListEntry> | undefined>(
		() =>
			metricKey
				? {
						name: metricKey,
						content: (row) =>
							metricResultsById.get(row.uuid!)?.value != null
								? `${formatNumber(metricResultsById.get(row.uuid!)?.value)}${metricToMeasurementUnit[metricKey] ?? ""}`
								: t("NOT_AVAILABLE_DATA"),
						header: t(`TABLE.HEADERS.${metricKey}`),
						sortFn: (a, b) =>
							builtInSort(
								metricResultsById.get(a.uuid!)?.value ?? -Infinity,
								metricResultsById.get(b.uuid!)?.value ?? -Infinity,
							),
						cellClassList: "tabular-nums",
						width: 168,
				  }
				: undefined,
		[metricKey, t, formatNumber, metricResultsById],
	);

	const visibleColumns = useMemo<Array<TableColumn<InvestmentListEntry>>>(() => {
		if (!metricKey || !props.isVisible || !metricColumn) {
			return allVisibleColumns;
		}
		const [first, ...others] = allVisibleColumns.filter((x) => x.name !== metricKey);
		console.assert(first != null, "no visible column");
		return [first, metricColumn, ...others];
	}, [allVisibleColumns, metricKey, metricColumn, props.isVisible]);

	const sortedRows = useSortedRows({
		rows: Array.from(rows.values()),
		columns: visibleColumns,
		orderByArr: portfolioListOrderByName,
	});

	const onSaveAsNewPreset = useCallback(
		async (currentPreset: PortfolioInsightPanelPreset, name: string) => {
			try {
				const { search, metric, filters, uuid, ...params } = currentPreset;
				const { keyMetricThreshold, ...reset } = metric ?? {};
				const keyMetricThresholdValues = keyMetricThreshold?.first();
				await axiosExtract(
					investmentsBulkAnalysisController.savePreset({
						...params,
						name,
						standard: false,
						favourite: false,
						filters: convertFilterResponseToFilter(Array.from(filters?.values() ?? [])),
						metric: {
							...reset,
							thresholdEntity: keyMetricThresholdValues?.thresholdEntity,
							thresholdType: keyMetricThresholdValues?.thresholdType,
							thresholdValue: keyMetricThresholdValues?.thresholdValue,
						},
					}),
				);
			} catch (error) {
				platformToast({
					children: "Something went wrong please try later",
					icon: "Portfolio",
					severity: "error",
				});
				reportPlatformError(error, "ERROR", "portfolio", "try to save preset as new");
				throw error;
			}
		},
		[investmentsBulkAnalysisController],
	);

	const onSavePreset = useCallback(
		async (currentPreset: PortfolioInsightPanelPreset) => {
			try {
				const { search, metric, filters, favorite, ...params } = currentPreset;
				const { keyMetricThreshold, ...reset } = metric ?? {};
				const keyMetricThresholdValues = keyMetricThreshold?.first();
				await axiosExtract(
					investmentsBulkAnalysisController.updatePreset({
						...params,
						favourite: favorite,
						filters: convertFilterResponseToFilter(Array.from(filters?.values() ?? [])),
						metric: {
							...reset,
							thresholdEntity: keyMetricThresholdValues?.thresholdEntity,
							thresholdType: keyMetricThresholdValues?.thresholdType,
							thresholdValue: keyMetricThresholdValues?.thresholdValue,
						},
					}),
				);
			} catch (error) {
				platformToast({
					children: "Something went wrong please try later",
					icon: "Portfolio",
					severity: "error",
				});
				reportPlatformError(error, "ERROR", "portfolio", "try to save preset");
				throw error;
			}
		},
		[investmentsBulkAnalysisController],
	);

	return (
		<>
			<div className="flex relative z-0 min-h-[calc(100dvh_-_235px)] max-h-[calc(100dvh_-_245px)]">
				<PortfolioInsightPanel
					expand={portfolioStudioStore.isightPanel?.open ?? false}
					onExpandChange={onExpandChange}
					handlesRef={portfolioInsightHandleRef}
					onCheckIfNameIsAvailable={onCheckIfNameIsAvailable}
					onChangePreset={setInsightPanelPreset}
					presetsProvider={() => axiosExtract(investmentsBulkAnalysisController.getPresets())}
					onChangeFavorite={async (uuid) => {
						await axiosExtract(investmentsBulkAnalysisController.updateFavorite(uuid));
					}}
					thresholdForEntityProvider={withCache(
						async (metric) => {
							const { thresholdValue } = await axiosExtract(
								investmentsBulkAnalysisController.getThreshold({
									metricThresholdEntity: metric.keyMetricThreshold?.first()?.thresholdEntity,
									metricKey: metric.key,
									metricReferenceEntity: metric.referenceEntity,
									metricReferenceType: metric.referenceType,
								}),
							);
							return thresholdValue;
						},
						{
							cacheKey: (metric) =>
								[
									"portfolioListThreshold",
									metric.keyMetricThreshold?.first()?.thresholdEntity?.identifier,
									metric.key,
									metric.referenceEntity?.identifier,
									metric.referenceType,
									metric.referenceEntity,
								].join("-"),
							cacheTime: 3600 * 1000,
						},
					)}
					selectableProvider={() => axiosExtract(investmentsBulkAnalysisController.getAvailbaleFields())}
					onSave={onSavePreset}
					onSaveAsNew={onSaveAsNewPreset}
				/>
				<div
					className="rounded-l-none rounded-md bg-white px-4 py-6 w-full overflow-hidden"
					// style={{ width: expand ? `calc(100% - ${extendedTabWidth}px)` : `calc(100% - ${smallTabWidth}px)` }}
				>
					<div className="h-full overflow-y-auto">
						<div className="flex justify-between items-center mb-2">
							<div>
								{allI18n.selectionCounter({
									selected: rowSelection.data.selection.size,
									total: rowsWithoutSearch.length,
								})}
							</div>
							<ButtonGroup>
								<Button
									palette="tertiary"
									classList={{
										"!border-y-2 !border-l-2 !border-slate-200 !rounded-l-lg": true,
										[`!bg-[color:${themeCSSVars.palette_P100}]`]: viewMode === PortfolioListViewMode.mixed,
									}}
									onClick={() => setViewMode(PortfolioListViewMode.mixed)}
								>
									<Icon
										icon="toolchart-tablegraph"
										color={viewMode === PortfolioListViewMode.mixed ? themeCSSVars.palette_P700 : undefined}
									/>
								</Button>
								<Button
									palette="tertiary"
									classList={{
										"!border-2 !border-slate-200": true,
										[`!bg-[color:${themeCSSVars.palette_P100}]`]: viewMode === PortfolioListViewMode.chart,
									}}
									onClick={() => setViewMode(PortfolioListViewMode.chart)}
								>
									<Icon
										icon="toolchart-cart"
										color={viewMode === PortfolioListViewMode.chart ? themeCSSVars.palette_P700 : undefined}
									/>
								</Button>
								<Button
									palette="tertiary"
									classList={{
										"!border-y-2 !border-r-2 !border-slate-200 !rounded-r-lg": true,
										[`!bg-[color:${themeCSSVars.palette_P100}]`]: viewMode === PortfolioListViewMode.table,
									}}
									onClick={() => setViewMode(PortfolioListViewMode.table)}
								>
									<Icon
										icon="toolchart-table"
										color={viewMode === PortfolioListViewMode.table ? themeCSSVars.palette_P700 : undefined}
									/>
								</Button>
							</ButtonGroup>
						</div>

						<div className="w-full h-[calc(100%_-_46px)] min-h-[576px] flex flex-col">
							{props.isVisible &&
								match(viewMode)
									.with("TABLE", () => (
										<PortfolioTable
											rowsWithoutSearch={rowsWithoutSearch}
											ctx={props}
											sortedRows={sortedRows}
											rowSelection={rowSelection}
											visibleColumns={visibleColumns}
											portfolioListOrderByName={portfolioListOrderByName}
											setPortfolioListOrderByName={onChangeOrder}
											metricColumn={insightPanelPreset?.metric?.key}
											onSubmitColumnPreferences={onSubmitColumnPreferences}
											isMetricResultLoading={metricResultQuery.isLoading}
										/>
									))
									.with("CHART", () => (
										<Chart
											chartRef={chartRef}
											sortedRows={sortedRows}
											thresholdValue={insightPanelPreset?.metric?.keyMetricThreshold?.first()?.thresholdValue}
											multiSelectCtx={rowSelection}
											enhancedMetricById={metricResultsById}
											onThresholdChange={portfolioInsightHandleRef.current?.setThreshold}
											title={insightPanelPreset?.metric?.key}
											stats={metricStats}
											chartHeight="full"
											unit={
												insightPanelPreset?.metric?.key
													? metricToMeasurementUnit[insightPanelPreset?.metric?.key]
													: undefined
											}
										/>
									))
									.with("MIXED", () => (
										<InsightPanel
											rowsWithoutSearch={rowsWithoutSearch}
											ctx={props}
											sortedRows={sortedRows}
											chartRef={chartRef}
											rowSelection={rowSelection}
											visibleColumns={visibleColumns}
											portfolioListOrderByName={portfolioListOrderByName}
											setPortfolioListOrderByName={onChangeOrder}
											metricColumn={insightPanelPreset?.metric?.key}
											thresholdValue={insightPanelPreset?.metric?.keyMetricThreshold?.first()?.thresholdValue}
											enhancedMetricById={metricResultsById}
											onThresholdChange={portfolioInsightHandleRef.current?.setThreshold}
											title={insightPanelPreset?.metric?.key}
											onSubmitColumnPreferences={onSubmitColumnPreferences}
											stats={metricStats}
											unit={
												insightPanelPreset?.metric?.key
													? metricToMeasurementUnit[insightPanelPreset?.metric?.key]
													: undefined
											}
											chartHeight="full"
											isMetricResultLoading={metricResultQuery.isLoading}
										/>
									))
									.otherwise(() => <></>)}
						</div>
					</div>
				</div>
			</div>

			<PotfolioToolbar
				ctx={props}
				selectableRows={rowsWithoutSearch.length}
				rowSelection={rowSelection}
				rows={rowsWithoutSearchMap}
				reportBuilderTemplatesProvider={() =>
					axiosExtract(investmentsReportTemplateApi.listInvestmentReportTemplates())
				}
				commentaryTemplatesProvider={() => axiosExtract(commentaryTemplateApi.getTemplateList())}
				onDownloadReportBuilderTemplates={onDownloadReportBuilderTemplates}
				onDownloadComposition={onDownloadComposition}
				comparedInvestments={comparedInvestments}
				onCompareMultipleInvestments={onCompareMultipleInvestments}
				onGenerateCommentary={onGenerateCommentary}
				onCreateProposal={onCreateProposal}
				onEditPortfolioSettings={onEditPortfolioSettings}
				onApplyFilters={onApplyFilters}
			/>

			<CompareOverlay
				show={comparedInvestments.length > 0}
				onClose={() => setComparedInvestments([])}
				onRemove={(id) => setComparedInvestments((v) => v.filter((x) => x.id !== id))}
				compareData={comparedInvestments}
			/>
		</>
	);
}

function generateReportBuilderTemplateName(pageMetadata: TemplateChooserSubmitParams, templateName: string) {
	const { name, choice } = pageMetadata;
	return `${name}_${templateName}_${format(new Date(), "MMddyyyy")}${choice === "current" ? "" : "proposal_ready"}`;
}

function generateReportBuilderTemplateUrl(
	metadata: Array<TemplateChooserSubmitParams>,
	template: InvestmentsReportTemplate,
) {
	return metadata.map(
		({ choice, uuid, name }): PageDto => ({
			url: typedUrlForRoute("Login", {
				from: typedUrlForRoute("Report", {
					templateId: isDefaultReportTemplate(template) ? template.id : template.uuid!,
					objectId: uuid!,
					variant: (choice === "current" ? "current" : "proposal") satisfies ReportTemplateVariant,
				}),
			}),
			name: generateReportBuilderTemplateName({ choice, uuid, name }, template.templateName ?? "-"),
		}),
	);
}

function aggregateTemplateChooserInvestments(
	uuids: string[],
	investments: Map<string, InvestmentListEntry>,
): PortfolioTemplateChooserDialogProps["investments"] {
	return uuids.flatMap((uuid) => {
		const portfolio = investments.get(uuid);

		return portfolio
			? [
					{
						name: portfolio.name,
						uuid: portfolio.uuid!,
						status: portfolio.status,
					},
			  ]
			: [];
	});
}

type MinimumPortfolioInfo = {
	uuid: string;
	enhancement?: boolean;
};

function PotfolioToolbar(props: {
	ctx: PortfolioListProps;
	rowSelection: MultiSelectCtx<string>;
	rows: Map<string, InvestmentListEntry>;
	selectableRows: number;
	comparedInvestments: Array<CompareDataItem>;
	onCompareMultipleInvestments(selectedInvestments: Map<string, DefaultSelectionProps>): void;
	reportBuilderTemplatesProvider(): Promise<Array<InvestmentsReportTemplate>>;
	commentaryTemplatesProvider(): Promise<Array<CommentaryTemplateModel>>;
	onDownloadReportBuilderTemplates(pages: Array<PageDto>): Promise<void>;
	onDownloadComposition(
		converter: InvestmentExportConverterType,
		investments: Array<MinimumPortfolioInfo>,
	): Promise<void>;
	onGenerateCommentary(investments: Array<InvestmentCommentaryDTO>): Promise<void>;
	onCreateProposal(investments: Set<string>): Promise<void>;
	onEditPortfolioSettings(investments: Set<string>): Promise<void>;
	onApplyFilters(investments: Set<string>): void;
}) {
	const {
		ctx,
		rowSelection,
		rows,
		onDownloadComposition,
		onDownloadReportBuilderTemplates,
		onCompareMultipleInvestments,
		onGenerateCommentary,
		onCreateProposal,
		onEditPortfolioSettings,
		onApplyFilters,
		comparedInvestments,
	} = props;

	const { t } = useTranslation();
	const user = useUserValue();
	const { push } = useTypedNavigation();

	const handler = useUpdatedRef({
		push,
		onDownloadComposition,
		onDownloadReportBuilderTemplates,
		onCompareMultipleInvestments,
		onGenerateCommentary,
		onCreateProposal,
		onEditPortfolioSettings,
		onApplyFilters,
	});

	const queryReportBuilderTemplates = useQueryNoRefetch(["queryReportBuilderTemplates"], {
		queryFn: () => props.reportBuilderTemplatesProvider(),
	});

	const reportBuilderTemplates = useMemo(() => {
		const reportBuilderTemplatesResponse = queryReportBuilderTemplates.data;
		if (!reportBuilderTemplatesResponse) {
			return [];
		}
		const defaults = [
			...defaultPortfolioTemplates.map((defaultTemplate) => ({
				...defaultTemplate,
				visible: true,
			})),
		];
		return [
			...defaults.filter((x) => !reportBuilderTemplatesResponse.find((y) => x.templateName === y.templateName)),
			...reportBuilderTemplatesResponse.filter((x) => x.visible),
		];
	}, [queryReportBuilderTemplates]);

	const queryCommentaryTemplates = useQueryNoRefetch(["queryCommentaryTemplates"], {
		queryFn: () => props.commentaryTemplatesProvider(),
	});

	const commentaryTemplateOptions = useMemo<Array<Option<string>>>(() => {
		const commentaryTemplates = queryCommentaryTemplates.data;
		if (!commentaryTemplates) {
			return [];
		}
		return commentaryTemplates.flatMap((x) =>
			x.visible && x.uuid && x.name ? [{ label: x.name, value: x.uuid }] : [],
		);
	}, [queryCommentaryTemplates]);

	const selection = useMemo(() => rowSelection.data.selection, [rowSelection]);

	const isPortfolioInUnconsistentStatus = useMemo(
		() =>
			selection.some(
				(uuid) =>
					rows.get(uuid)?.status === "ERROR" ||
					rows.get(uuid)?.status === "CALCULATING" ||
					rows.get(uuid)?.status === "REVIEW" ||
					rows.get(uuid)?.status === "RETRIEVING_DATA" ||
					rows.get(uuid)?.status === "DRAFT",
			),
		[rows, selection],
	);

	const canUserCreateBulkProposal = useMemo(
		() =>
			selection.every((uuid) => {
				const investment = rows.get(uuid);
				if (!investment) {
					return false;
				}

				return (
					Boolean(investment.canBulkEnhance) &&
					aclByArea.portfolio.canCreateProposal(user.id, investment.richAcl?.acl ?? [])
				);
			}),
		[selection, rows, user.id],
	);

	const canUserEditPortfolioSettings = useMemo(() => {
		return selection.every((uuid) => {
			const investment = rows.get(uuid);
			if (!investment) {
				return false;
			}

			return (
				aclByArea.portfolio.canEditSettings(user.id, investment.richAcl?.acl ?? []) &&
				investment.status !== "PROPOSAL_READY"
			);
		});
	}, [selection, rows, user.id]);

	const canUserMergeInvestments = useMemo(
		() => selection.every((uuid) => Boolean(rows.get(uuid)?.canUseAsMixedPortfolio)),
		[rows, selection],
	);

	const actions = useMemo<Array<BatchAction<"DOWNLOAD" | "">>>(
		() => [
			{
				label: "Apply selection to filter",
				icon: "Filter",
				onClick: () => handler.current.onApplyFilters(selection),
				"data-qualifier": "PortfolioStudio/PortfolioList/BatchAction(ApplyFilter)",
			},

			// delete
			{
				label: "Delete",
				icon: "Delete",
				onClickAsync: async () => {
					await spawnYesNoDialog({
						header: t("PORTFOLIOS.MODAL.BULK_DELETE.TITLE"),
						yesButton: t("BUTTON.DELETE"),
						noButton: t("BUTTON.CANCEL"),
						children: t("PORTFOLIOS.MODAL.BULK_DELETE.DESCRIPTION", { size: selection.size }),
						onSubmitAsync: async () => {
							const items = selection.toArray().flatMap((uuid) => {
								const portfolio = rows.get(uuid);
								return portfolio ? [portfolio] : [];
							});
							const response = await multipleEntityDelete({ area: "Portfolios", items });
							await ctx.refetch.investments({ throwOnError: true });
							rowSelection.actions.reset();
							await notifyUser({ t, succeded: response.succeded, area: "Portfolios" });
						},
					});
				},
				disabled: selection.some((uuid) => {
					const portfolio = rows?.get(uuid);

					return (
						aclByArea.portfolio.canDelete(user.id, portfolio?.richAcl?.acl ?? []) === false ||
						(portfolio?.usagesAsNestedPortfolios?.length ?? 0) > 0
					);
				}),
				tooltip: {
					children: selection.some(
						(uuid) => aclByArea.portfolio.canDelete(user.id, rows?.get(uuid)?.richAcl?.acl ?? []) === false,
					)
						? "You don't have the permission to delete this portfolios"
						: "Available only for portfolios not included in a universe or in a portfolio's composition",
					overrideColor: themeCSSVars.palette_N300,
				},
				"data-qualifier": "PortfolioStudio/PortfolioList/BatchAction(Delete)",
			},

			// download
			{
				label: "Download",
				icon: "Dowload",
				...(isPortfolioInUnconsistentStatus
					? {
							onClick: noop,
							disabled: true,
							tooltip: {
								children: "Available only for portfolios in ready, proposal ready or accepted status",
								overrideColor: themeCSSVars.palette_N300,
							},
					  }
					: {
							dropdown: {
								actions: [
									...reportBuilderTemplates.map(
										(template) =>
											({
												group: { id: "DOWNLOAD" },
												icon: "pdf",
												label: (template.templateName ?? "").concat(" (pdf)"),
												onClickAsync: async () => {
													const proposalInList = selection.some((uuid) => rows.get(uuid)?.status === "PROPOSAL_READY");
													if (proposalInList) {
														const investments = aggregateTemplateChooserInvestments(selection.toArray(), rows);

														await spawnPortfolioTemplateChooser({
															investments,
															onSubmitAsync: async (userTemplatePreferences) => {
																const urlsToDownload = generateReportBuilderTemplateUrl(
																	userTemplatePreferences,
																	template,
																);
																await handler.current.onDownloadReportBuilderTemplates(urlsToDownload);
															},
														});
														return;
													}

													const urlsToDownload = generateReportBuilderTemplateUrl(
														selection
															.toArray()
															.map((uuid) => ({ choice: "current", uuid, name: rows.get(uuid)?.name })),
														template,
													);
													await handler.current.onDownloadReportBuilderTemplates(urlsToDownload);
												},
											}) satisfies ActionOrActionWithGroup<"DOWNLOAD">,
									),

									...(hasAccess(user, { requiredService: "EXPORT" })
										? (user.exportFormats ?? []).map(
												(format) =>
													({
														group: { id: "DOWNLOAD" },
														icon: "xls",
														onClickAsync: async () => {
															const proposalInList = selection.some(
																(uuid) => rows.get(uuid)?.status === "PROPOSAL_READY",
															);
															if (proposalInList) {
																const investments = aggregateTemplateChooserInvestments(selection.toArray(), rows);
																spawnPortfolioTemplateChooser({
																	investments,
																	onSubmitAsync: async (data) => {
																		const investmentsToSubmit = data.map(({ uuid, choice }) => ({
																			uuid,
																			enhancement: choice === "enhance",
																		}));
																		await handler.current.onDownloadComposition(format, investmentsToSubmit);
																	},
																});
																return;
															}

															const investments = selection.toArray().map((uuid) => ({ uuid }));
															await handler.current.onDownloadComposition(format, investments);
														},
														label: `${t(`EXPORT.${format}`)} template`,
													}) satisfies ActionOrActionWithGroup<"DOWNLOAD">,
										  )
										: []),
									hasAccess(user, { requiredService: "INVESTMENTS_REPORT_TEMPLATE_EDITOR" })
										? {
												icon: "Settings",
												label: "Report customisation",
												onClick: () =>
													handler.current.push("PortfolioStudioSettings", {
														tab: PortfolioStudioSettingTabEnum.ReportCustomisation,
													}),
										  }
										: null,
								],
							},
					  }),
				"data-qualifier": "PortfolioStudio/PortfolioList/BatchAction(Download)",
			},

			// compare
			{
				label: "Compare",
				icon: "compare",
				disabled: isPortfolioInUnconsistentStatus,
				onClick: () => {
					const proposalInList = selection.some((uuid) => rows.get(uuid)?.status === "PROPOSAL_READY");
					if (proposalInList) {
						const investments = selection.toArray().flatMap((uuid) => {
							const investment = rows.get(uuid);
							return investment ? [investment] : [];
						});

						return spawnCompareDialogProps({
							investments,
							comparedInvestments,
							onSubmit: handler.current.onCompareMultipleInvestments,
						});
					}

					const investments = Map(
						selection
							.toArray()
							.map((uuid): [string, DefaultSelectionProps] => [uuid, { current: true, enhance: false }]),
					);

					handler.current.onCompareMultipleInvestments(investments);
				},
				tooltip: {
					children: "Available only for portfolios in ready, proposal ready or accepted status",
					overrideColor: themeCSSVars.palette_N300,
					disabled: isPortfolioInUnconsistentStatus === false,
				},
				"data-qualifier": "PortfolioStudio/PortfolioList/BatchAction(Compare)",
			},

			//create proposal
			{
				label: "Create proposal",
				icon: "Enhance",
				disabled: !canUserCreateBulkProposal,
				onClickAsync: async () => {
					if (selection.size === 1) {
						handler.current.push("Portfolios/EditPortfolio", { portfolioUid: rowSelection.data.selection.first() });
						return;
					}
					await handler.current.onCreateProposal(selection);
				},
				tooltip: {
					children: "Available only for portfolios with a universe and in ready or accepted status",
					overrideColor: themeCSSVars.palette_N300,
					disabled: canUserCreateBulkProposal,
				},
				"data-qualifier": "PortfolioStudio/PortfolioList/BatchAction(CreateProposal)",
			},

			//edit portfolio settings
			{
				label: "Edit market view",
				icon: "Settings",
				disabled: isPortfolioInUnconsistentStatus || !canUserEditPortfolioSettings,
				onClickAsync: async () => {
					if (selection.size === 1) {
						handler.current.push("PortfolioDetails", {
							portfolioUid: rowSelection.data.selection.first(),
							tab: PortfolioDetailsTabs.PORTFOLIO_STUDIO_SETTINGS,
						});
						return;
					}
					await handler.current.onEditPortfolioSettings(selection);
				},
				tooltip: {
					children: "Available only for portfolios in ready or accepted status",
					overrideColor: themeCSSVars.palette_N300,
					disabled: canUserEditPortfolioSettings,
				},
				"data-qualifier": "PortfolioStudio/PortfolioList/BatchAction(EditPortfolioSettings)",
			},

			// merge (aka: create new portfolio with editor)
			{
				label: "Merge",
				icon: "merge",
				disabled: !canUserMergeInvestments,
				onClick: () =>
					handler.current.push("Portfolios/UploadPortfolioPage/Portfolio", {
						uuids: selection.join(","),
					}),
				tooltip: {
					children: "Available only for not nested portfolios and in ready, proposal ready or accepted status",
					overrideColor: themeCSSVars.palette_N300,
					disabled: canUserMergeInvestments,
				},
				"data-qualifier": "PortfolioStudio/PortfolioList/BatchAction(MergeComposition)",
			},

			//generate commentary
			{
				label: "Generate commentary",
				icon: "sphere-ai",
				...(isPortfolioInUnconsistentStatus ||
				selection.some((id) => !aclByArea.portfolio.canEditComposition(user.id, rows?.get(id)?.richAcl?.acl ?? []))
					? {
							onClick: noop,
							disabled: true,
							tooltip: {
								children: "Available only for portfolios in ready, proposal ready or accepted status",
								overrideColor: themeCSSVars.palette_N300,
							},
					  }
					: {
							dropdown: {
								actions: commentaryTemplateOptions.map(
									(commentaryTemplate) =>
										({
											label: commentaryTemplate.label,
											onClickAsync: async () => {
												const proposalInListWithAvailableDateGeneration = selection.filter((uuid) => {
													const portfolio = rows.get(uuid);
													return (
														portfolio?.status === "PROPOSAL_READY" &&
														diffrenceISODate(portfolio?.modificationTime, START_DATE_CREATE_PROPOSAL_COMMENTARY) > 0
													);
												});
												if (proposalInListWithAvailableDateGeneration.size > 0) {
													const investments = aggregateTemplateChooserInvestments(selection.toArray(), rows);

													await spawnPortfolioTemplateChooser({
														investments,
														onSubmitAsync: async (userTemplatePreferences) => {
															await handler.current.onGenerateCommentary(
																userTemplatePreferences.map(({ uuid, choice }) => ({
																	investmentUUID: uuid,
																	templateUUID: commentaryTemplate.value,
																	proposal: choice === "enhance",
																})),
															);
														},
													});
													return;
												}

												await handler.current.onGenerateCommentary(
													selection.toArray().map((investmentUUID) => ({
														investmentUUID,
														templateUUID: commentaryTemplate.value,
													})),
												);
											},
										}) satisfies ActionOrActionWithGroup<"">,
								),
								listboxAppearance: { classList: "max-h-[312px]" },
							},
					  }),
				"data-qualifier": "PortfolioStudio/PortfolioList/BatchAction(GenerateCommentary)",
			},
		],
		[
			selection,
			isPortfolioInUnconsistentStatus,
			reportBuilderTemplates,
			user,
			canUserCreateBulkProposal,
			canUserEditPortfolioSettings,
			canUserMergeInvestments,
			commentaryTemplateOptions,
			t,
			ctx.refetch,
			rowSelection.actions,
			rowSelection.data.selection,
			rows,
			handler,
			comparedInvestments,
		],
	);

	return (
		ctx.portfolioStudioOuterDiv &&
		createPortal(
			ctx.isVisible && rowSelection.data.selection.size > 0 && (
				<BatchActions
					onUnselect={() => rowSelection.actions.reset()}
					classList={`sticky bottom-5 !bg-[color:${themeCSSVars.palette_N500}] h-11 px-2 rounded-md shadow-xl shadow-[color:${themeCSSVars.palette_N200}]`}
					selected={rowSelection.data.selection.size}
					total={props.selectableRows}
					palette="quaternary"
					actions={actions}
				/>
			),
			ctx.portfolioStudioOuterDiv,
		)
	);
}

const isPortfolioEnhancable = (status?: InvestmentStatuses) => status === "READY" || status === "ACCEPTED";
function InsightPanel({
	ctx,
	chartRef,
	rowSelection,
	portfolioListOrderByName,
	setPortfolioListOrderByName,
	sortedRows,
	visibleColumns,
	thresholdValue,
	metricColumn,
	enhancedMetricById,
	onThresholdChange,
	title,
	onSubmitColumnPreferences,
	stats,
	unit,
	chartHeight,
	rowsWithoutSearch,
	isMetricResultLoading,
}: {
	enhancedMetricById: Map<string, MetricResultEntry>;
	chartRef?: ForwardedRef<HighchartsReactRefObject>;
	thresholdValue?: number;
	onThresholdChange?(newThreshold: number): void;
	title?: FieldKey;
	stats?: { min?: number; max?: number; avg?: number };
	unit?: string;
	chartHeight?: MetricComparisonChartProps["chartHeight"];
	isMetricResultLoading: boolean;
} & PortfolioTableProps) {
	return (
		<>
			<Chart
				multiSelectCtx={rowSelection}
				thresholdValue={thresholdValue}
				sortedRows={sortedRows}
				chartRef={chartRef}
				enhancedMetricById={enhancedMetricById}
				onThresholdChange={onThresholdChange}
				title={title}
				stats={stats}
				unit={unit}
				chartHeight={chartHeight}
			/>
			<br />
			<div className="basis-1/2 shrink-0 grow-0 overflow-hidden">
				<PortfolioTable
					rowsWithoutSearch={rowsWithoutSearch}
					ctx={ctx}
					rowSelection={rowSelection}
					visibleColumns={visibleColumns}
					portfolioListOrderByName={portfolioListOrderByName}
					setPortfolioListOrderByName={setPortfolioListOrderByName}
					sortedRows={sortedRows}
					metricColumn={metricColumn}
					onSubmitColumnPreferences={onSubmitColumnPreferences}
					isMetricResultLoading={isMetricResultLoading}
				/>
			</div>
		</>
	);
}

type PortfolioTableProps = {
	ctx: PortfolioListProps;
	rowSelection: MultiSelectCtx<string>;
	visibleColumns: TableColumn<InvestmentListEntry, string>[];
	sortedRows: InvestmentListEntry[];
	portfolioListOrderByName: OrderBy[];
	setPortfolioListOrderByName: (newOrderBy: OrderBy[]) => void;
	onSubmitColumnPreferences: (
		payload: ColumnMetadata<UserPortfolioColumnPreferencePreferencesTypeEnum>[],
	) => Promise<void>;
	metricColumn?: string;
	rowsWithoutSearch: InvestmentListEntry[];
	isMetricResultLoading: boolean;
};

function PortfolioTable({
	ctx,
	rowSelection,
	visibleColumns,
	sortedRows,
	portfolioListOrderByName,
	setPortfolioListOrderByName,
	onSubmitColumnPreferences,
	metricColumn,
	rowsWithoutSearch,
	isMetricResultLoading,
}: PortfolioTableProps) {
	const { refetch, columnsPreferences } = ctx;

	const { aclSpawn } = useUpdateAccessListControlDialog("INVESTMENT");
	const { t } = useTranslation();
	const user = useUserValue();

	const { push } = useTypedNavigation();

	const customizableColumns = useMemo(
		() =>
			(columnsPreferences ?? []).map((c) => ({
				label: c.preferencesType ? t(`TABLE.HEADERS.${c.preferencesType}`) : "-",
				id: c.preferencesType!,
				visible: c.enabled ?? false,
				disabled: c.preferencesType === "NAME",
				hidden: c.preferencesType === "NAME",
			})),
		[columnsPreferences, t],
	);

	const {
		column: checkboxColumn,
		rowClassList,
		multiSelectCtx: tableMultiSelectCtx,
	} = useSelectableTableColumn({
		rows: sortedRows,
		selectBy: (p) => p.uuid!,
		multiSelectCtx: rowSelection,
		selectableRowIds: rowsWithoutSearch.map((x) => x.uuid ?? ""),
	});

	const getDropdownActions = useCallback(
		(portfolio: InvestmentListEntry): DropdownMenuProps<HTMLElement, "">["actions"] => {
			const isEnhanceable = isPortfolioEnhancable(portfolio.status);

			const entityManagementActions = portfolioEntityManagementActions(user, portfolio, {
				onRename: unpromisify(nullary(refetch.investments)),
				onDuplicate: unpromisify(nullary(refetch.investments)),
				onDelete() {
					refetch.investments().catch(noop);
					tableMultiSelectCtx.actions.remove(portfolio.uuid ?? "");
				},
			});

			return [
				aclByArea.portfolio.canCreateProposal(user.id, portfolio.richAcl?.acl ?? []) && {
					icon: isEnhanceable ? "Enhance" : "Enhance-Not-available",
					disabled: !isEnhanceable,
					onClick: () => push("Portfolios/EditPortfolio", { portfolioUid: portfolio.uuid! }),
					"data-qualifier": "PortfolioStudio/PortfolioList/DropdownMenu/DropdownItem(Enhance)",
					label: "Create proposal",
				},
				aclByArea.portfolio.canDelete(user.id, portfolio.richAcl?.acl ?? []) && {
					icon: "Delete",
					disabled: !entityManagementActions.deleteAsync,
					onClick: unpromisify(entityManagementActions.deleteAsync ?? noop),
					"data-qualifier": "PortfolioStudio/PortfolioList/DropdownMenu/DropdownItem(Delete)",
					label: "Delete",
				},
				{
					icon: "Content-Copy",
					disabled: !entityManagementActions.duplicateAsync,
					onClick: unpromisify(entityManagementActions.duplicateAsync ?? noop),
					"data-qualifier": "PortfolioStudio/PortfolioList/DropdownMenu/DropdownItem(Duplicate)",
					label: "Duplicate",
				},
				aclByArea.portfolio.canRename(user.id, portfolio.richAcl?.acl ?? []) && {
					icon: "Edit",
					onClick: unpromisify(entityManagementActions.renameAsync ?? noop),
					"data-qualifier": "PortfolioStudio/PortfolioList/DropdownMenu/DropdownItem(Rename)",
					label: "Rename",
				},
				validateACLPermissions(user.id, portfolio.richAcl?.acl ?? [], roleByArea.portfolio.EDITOR) && {
					icon: "share",
					onClickAsync: async () => {
						await aclSpawn(portfolio.name, portfolio.uuid, refetch.investments);
					},
					"data-qualifier": "PortfolioStudio/PortfolioList/DropdownMenu/DropdownItem(Share)",
					label: "Share",
				},
			];
		},
		[user, refetch, tableMultiSelectCtx.actions, push, aclSpawn],
	);

	const columns = useMemo(
		() =>
			visibleColumns
				? ([
						checkboxColumn,
						...visibleColumns,
						actionsColumn({
							onSettingsClick: () =>
								spawnCustomizeColumnsDialog({
									columns: customizableColumns,
									onSubmitAsync: onSubmitColumnPreferences,
								}),
							triggerProps: {
								"data-qualifier": "PortfolioStudio/PortfolioList/DropdownMenu",
							},
							dropdownActions: getDropdownActions,
						}),
				  ] satisfies TableColumn<InvestmentListEntry>[])
				: null,
		[visibleColumns, checkboxColumn, getDropdownActions, customizableColumns, onSubmitColumnPreferences],
	);

	if (isMetricResultLoading) {
		return <ProgressBar value="indeterminate" />;
	}

	if (!columns) {
		return <></>;
	}

	return (
		<>
			<BaseHScrollTable
				onRowClick={(row) => row.uuid && tableMultiSelectCtx.actions.toggle(row.uuid)}
				onRowContextMenu={(row, _index, e) => contextMenuHandler(e, getDropdownActions(row))}
				columns={columns}
				rows={sortedRows}
				orderBy={portfolioListOrderByName}
				onOrderByChange={setPortfolioListOrderByName}
				rowClassList={(row, rowIndex) => ({
					...toClassListRecord(rowClassList(row, rowIndex)),
					PortfolioListTableRow: true,
				})}
				rowStyle={({ uuid }) => {
					const selected = tableMultiSelectCtx.data.selection?.has(uuid!);
					return selected ? { backgroundColor: themeCSSVars.Table_highlightedRowBackgroundColor } : {};
				}}
				style={{ height: "100%" }}
				noDataText="No data available"
				pinnedColumns={[
					{ name: "name", side: "left" },
					...(metricColumn ? [{ name: metricColumn, side: "left" as const }] : []),
					{ name: "settings-action", side: "right" },
				]}
			/>
		</>
	);
}

function Chart(props: {
	chartRef?: ForwardedRef<HighchartsReactRefObject>;
	unit?: string;
	enhancedMetricById: Map<string, MetricResultEntry>;
	sortedRows: InvestmentListEntry[];
	multiSelectCtx: MultiSelectCtx<string>;
	thresholdValue?: number;
	onThresholdChange?(newThreshold: number): void;
	title?: FieldKey;
	stats?: { min?: number; max?: number; avg?: number };
	chartHeight?: MetricComparisonChartProps["chartHeight"];
}) {
	const [highlightProposal, setHighlightProposal] = useState(false);
	const selected = props.multiSelectCtx.data.selection.toSet();
	const setSelected = props.multiSelectCtx.actions.setSelection;
	const { title, sortedRows, thresholdValue, onThresholdChange, stats } = props;
	const { t } = useTranslation();

	const metricComparisonData = useMemo<MetricComparisonChartProps["data"]>(
		() =>
			sortedRows.map((investment) => ({
				id: investment.uuid!,
				name: investment.name ?? "-",
				metrics: [
					props.enhancedMetricById.get(investment.uuid!)?.value ?? null,
					props.enhancedMetricById.get(investment.uuid!)?.enhancementValue ?? null,
				],
			})),
		[sortedRows, props.enhancedMetricById],
	);

	return (
		<>
			<MetricComparisonChart
				actions={
					<Checkbox checked={highlightProposal} switchType="switch" onChange={setHighlightProposal}>
						Highlight proposal
					</Checkbox>
				}
				highlightSecondaryMetric={highlightProposal}
				chartTitle={title ? t(`METRIC_COMPARISON_CHART.${title}`) : "-"}
				mainMetricLabel="Current"
				secondaryMetricLabel="Proposal"
				unit={props.unit}
				min={stats?.min ?? 0}
				max={stats?.max ?? 0}
				avg={stats?.avg ?? 0}
				threshold={thresholdValue}
				thresholdDragStep={0.01}
				selectedIds={selected}
				onSelectionChange={setSelected}
				onThresholdChange={onThresholdChange}
				data={metricComparisonData}
				chartHeight={props.chartHeight}
				innerRef={props.chartRef}
			/>
		</>
	);
}

export default PortfolioInsight;
