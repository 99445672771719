import type { ClassList } from "@mdotm/mdotui/react-extensions";
import { toClassListRecord, toClassName } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";

export type SeparatorProps = {
	text?: string;
	align?: "center" | "start" | "end";
	color?: string;
	classList?: ClassList;
}

function Separator(props: SeparatorProps): JSX.Element {
	const { align = "center" } = props;
	return (
		<div
			className={toClassName({
				"relative flex py-2 items-center": true,
				[`text-[color:${themeCSSVars.palette_N200}]`]: true,
				...toClassListRecord(props.classList),
			})}
		>
			<div
				className={toClassName({
					[`border-t border-[color:${themeCSSVars.palette_N200}]`]: true,
					"flex-grow": align === "center" || align === "end",
					"basis-[15%]": align === "start",
				})}
			/>
			{props.text && <span className="flex-shrink mx-4 text-gray-400">{props.text}</span>}
			<div
				className={toClassName({
					[`border-t border-[color:${themeCSSVars.palette_N200}]`]: true,
					"flex-grow": align === "center" || align === "start",
					"basis-[15%]": align === "end",
				})}
			/>
		</div>
	);
}

export default Separator;
