export const ScatterPlotLimits = {
	EQUITYGEO: {
		RETURNS: [-0.3, 0.1, 0.5, 0.9],
		RETURNS_LIMITS: { min: -1, max: 1.6 },
		VOLATILITY: [10, 15, 20, 25],
	},
	EQUITYSECTORS: {
		RETURNS: [-0.3, 0.1, 0.5, 0.9],
		RETURNS_LIMITS: { min: -1, max: 1.6 },
		VOLATILITY: [10, 15, 20, 25],
	},
	COMMODITIES: {
		RETURNS: [-0.3, 0.1, 0.5, 0.9],
		RETURNS_LIMITS: { min: -1, max: 1.6 },
		VOLATILITY: [10, 15, 20, 25],
	},
	FIXED_INCOME: {
		RETURNS: [-0.1, 0.1, 0.3, 0.5],
		RETURNS_LIMITS: { min: -0.1, max: 0.9 },
		VOLATILITY: [2.5, 5, 7.5, 10],
	},
	FIXEDINCOME: {
		RETURNS: [-0.1, 0.1, 0.3, 0.5],
		RETURNS_LIMITS: { min: -0.1, max: 0.9 },
		VOLATILITY: [2.5, 5, 7.5, 10],
	},
};
