import { useLocaleFormatters } from "$root/localization/hooks";
import type { StylableProps } from "@mdotm/mdotui/components";
import { ComputedSizeContainer, Svg, Text } from "@mdotm/mdotui/components";
import { For, ForEach, toClassListRecord, toClassName } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import type { Vec2D } from "@mdotm/mdotui/utils";
import { isBetween, mapBetweenRanges } from "@mdotm/mdotui/utils";
import type { HTMLAttributes, SyntheticEvent } from "react";
import { thresholdsToIntervals } from "../../utils/math";

export const riskSectors: Array<RiskIndicatorSector> = [
	{ type: "VERY_HIGH", label: "Very high risk", color: "#AF1D25" },
	{ type: "HIGH", label: "High risk", color: "#FFBA8E" },
	{ type: "MEDIUM", label: "Medium risk", color: themeCSSVars.palette_A200 },
	{ type: "LOW", label: "Low risk", color: "#BAD8B5" },
	{ type: "VERY_LOW", label: "Very low risk", color: "#35786B" },
];

export const riskSectorsByType = Object.fromEntries(
	riskSectors.map((riskSector) => [riskSector.type, riskSector]),
) as Record<RiskIndicatorSector["type"], RiskIndicatorSector>;

export type RiskIndicatorSector = {
	type: "VERY_HIGH" | "HIGH" | "MEDIUM" | "LOW" | "VERY_LOW";
	/** Label shown when selected */
	label: string;
	/** Control the color.  */
	color: string;
};

export type RiskIndicatorProps = {
	selectedSectorIndex?: number | null;
	hoveringSectorIndex?: number | null;
	onSectorMouseEnter?(sector: RiskIndicatorSector, index: number, e: SyntheticEvent): void;
	onSectorMouseLeave?(sector: RiskIndicatorSector, index: number, e: SyntheticEvent): void;
	onSectorClick?(sector: RiskIndicatorSector, index: number, e: SyntheticEvent): void;
	probabilityDistanceThresholds: [number, number, number, number, number, number];
	currentProbabilityDistance: number;
} & StylableProps;

const marginH = 10;
const marginBottom = 30;
const marginRadial = 60;
const textMarginRadial = 10;
const selectedRadiusDelta = 15;
const sectorThickness = 30;
const markerLength = 12;
const markerMargin = 20;

export function RiskIndicator({
	hoveringSectorIndex,
	onSectorClick,
	onSectorMouseEnter,
	onSectorMouseLeave,
	selectedSectorIndex,
	classList,
	style,
	probabilityDistanceThresholds,
	currentProbabilityDistance,
}: RiskIndicatorProps): JSX.Element {
	const sectors = riskSectors;
	const { formatNumber } = useLocaleFormatters();
	const intervals = thresholdsToIntervals(probabilityDistanceThresholds);
	const currentScenarioSectorIndex = intervals.findIndex((x) => isBetween(currentProbabilityDistance, x.min, x.max));

	return (
		<ComputedSizeContainer
			style={style}
			classList={{
				"relative z-0": true,
				...toClassListRecord(classList),
			}}
		>
			{({ htmlEl: _htmlEl, ...rect }) => {
				const center = { x: rect.width / 2, y: rect.height - marginBottom };

				const radiusBase =
					Math.min(rect.width / 2 - marginH, rect.height - marginBottom) -
					sectorThickness / 2 -
					marginRadial -
					selectedRadiusDelta;
				const radiusSelected =
					Math.min(rect.width / 2 - marginH, rect.height - marginBottom) - sectorThickness / 2 - marginRadial;

				return (
					<>
						<div className="z-0 absolute bottom-0 inset-x-0 flex justify-center">
							<div className="flex flex-col items-center">
								<Text type="Title/L" as="div">
									{`${formatNumber(currentProbabilityDistance)}%`}
								</Text>
								<div>
									<Text type="Body/XL/Bold" as="div" classList="text-center">
										{riskSectors[selectedSectorIndex ?? currentScenarioSectorIndex].label}
									</Text>
									<div
										className="h-[3px] rounded-full -mx-2"
										style={{ backgroundColor: riskSectors[selectedSectorIndex ?? currentScenarioSectorIndex].color }}
									/>
								</div>
								<Text type="Body/M/Book">{selectedSectorIndex != null ? "Selected scenario" : "Current scenario"}</Text>
							</div>
						</div>
						<Svg viewBox={rect} classList="pointer-events-none relative z-10">
							{/* Line marks */}
							<For times={27}>
								{({ index }) => {
									const angle = mapBetweenRanges(index, 0, 26, 0, Math.PI) + Math.PI;

									return (
										<line
											stroke={themeCSSVars.palette_N200}
											strokeWidth={1}
											className="pointer-events-none"
											x1={center.x + Math.cos(angle) * (radiusBase + sectorThickness / 2 + markerMargin + markerLength)}
											y1={center.y + Math.sin(angle) * (radiusBase + sectorThickness / 2 + markerMargin + markerLength)}
											x2={center.x + Math.cos(angle) * (radiusBase + sectorThickness / 2 + markerMargin)}
											y2={center.y + Math.sin(angle) * (radiusBase + sectorThickness / 2 + markerMargin)}
										/>
									);
								}}
							</For>
							{/* Threshold values */}
							<ForEach collection={probabilityDistanceThresholds}>
								{({ item, index }) => {
									const angle =
										mapBetweenRanges(index, 0, probabilityDistanceThresholds.length - 1, 0, Math.PI) + Math.PI;

									return (
										<text
											data-text-type="Body/S/Book"
											fill={themeCSSVars.palette_N200}
											textAnchor={angle < Math.PI + Math.PI / 2 ? "end" : "start"}
											x={
												center.x +
												Math.cos(angle) *
													(radiusBase + sectorThickness / 2 + markerMargin + markerLength + textMarginRadial)
											}
											y={
												center.y +
												Math.sin(angle) *
													(radiusBase + sectorThickness / 2 + markerMargin + markerLength + textMarginRadial)
											}
										>
											{`${formatNumber(item)}%`}
										</text>
									);
								}}
							</ForEach>
							{/* Sectors */}
							<ForEach collection={sectors}>
								{({ item, index }) => {
									const startAngle =
										mapBetweenRanges(index, 0, sectors.length, 0, Math.PI) + Math.PI + (index > 0 ? Math.PI / 500 : 0);
									const endAngle =
										mapBetweenRanges(index + 1, 0, sectors.length, 0, Math.PI) +
										Math.PI -
										(index < sectors.length - 1 ? Math.PI / 500 : 0);

									const radiusCurrentAware = currentScenarioSectorIndex === index ? radiusSelected : radiusBase;

									const innerRadius = radiusCurrentAware - sectorThickness / 2;

									return (
										<>
											{/* Highlight */}
											<SVGSector
												fill={item.color}
												classList="transition-[d,opacity] pointer-events-none opacity-30"
												center={center}
												startAngle={startAngle}
												endAngle={endAngle}
												innerRadius={radiusBase - (hoveringSectorIndex === index ? sectorThickness : 0)}
												thickness={hoveringSectorIndex === index ? sectorThickness * 2 : 0}
											/>
											<SVGSector
												classList="transition-[d] pointer-events-none"
												fill={item.color}
												center={center}
												startAngle={startAngle}
												endAngle={endAngle}
												innerRadius={innerRadius}
												thickness={sectorThickness}
											/>
											{/* Interaction path */}
											<SVGSector
												onMouseEnter={(e) => onSectorMouseEnter?.(item, index, e)}
												onMouseLeave={(e) => onSectorMouseLeave?.(item, index, e)}
												onClick={(e) => onSectorClick?.(item, index, e)}
												fill="transparent"
												classList="pointer-events-auto"
												center={center}
												startAngle={startAngle}
												endAngle={endAngle}
												innerRadius={1}
												outerRadius={radiusSelected + sectorThickness / 2}
											/>
										</>
									);
								}}
							</ForEach>
							{/* Current indicator */}
							{(() => {
								const radiusCurrentAware =
									currentScenarioSectorIndex === currentScenarioSectorIndex ? radiusSelected : radiusBase;

								const angle =
									mapBetweenRanges(
										currentProbabilityDistance,
										intervals[currentScenarioSectorIndex].min,
										intervals[currentScenarioSectorIndex].max,
										0,
										Math.PI / sectors.length,
									) +
									Math.PI +
									(Math.PI / sectors.length) * currentScenarioSectorIndex +
									(currentScenarioSectorIndex > 0 ? Math.PI / 500 : 0);
								return (
									<circle
										className="transition-[cx,cy]"
										cx={center.x + Math.cos(angle) * (radiusCurrentAware - sectorThickness / 2)}
										cy={center.y + Math.sin(angle) * (radiusCurrentAware - sectorThickness / 2)}
										r={4}
										strokeWidth={2}
										stroke={themeCSSVars.palette_N700}
										fill={themeCSSVars.palette_N0}
									/>
								);
							})()}
						</Svg>
					</>
				);
			}}
		</ComputedSizeContainer>
	);
}

export function SVGSector(
	props: {
		fill: string;
		center: Vec2D;
		innerRadius: number;
		startAngle: number;
		endAngle: number;
	} & (
		| {
				thickness: number;
				outerRadius?: undefined;
		  }
		| {
				thickness?: undefined;
				outerRadius: number;
		  }
	) &
		StylableProps &
		Omit<HTMLAttributes<SVGPathElement>, "className">,
): JSX.Element {
	const {
		center,
		endAngle,
		fill,
		thickness,
		outerRadius: optionalOuterRadius,
		innerRadius,
		startAngle,
		classList,
		...forward
	} = props;
	const outerRadius = thickness !== undefined ? innerRadius + thickness : optionalOuterRadius;
	const isLargeAngle = endAngle - startAngle > Math.PI;

	return (
		<path
			fill={fill}
			className={toClassName(classList)}
			d={`
				M ${center.x + outerRadius * Math.cos(startAngle)} ${center.y + outerRadius * Math.sin(startAngle)}
				A ${outerRadius} ${outerRadius} 0 ${isLargeAngle ? 1 : 0} 1 ${center.x + outerRadius * Math.cos(endAngle)} ${
					center.y + outerRadius * Math.sin(endAngle)
				}
				L ${center.x + innerRadius * Math.cos(endAngle)} ${center.y + innerRadius * Math.sin(endAngle)}
				A ${innerRadius} ${innerRadius} 0 ${isLargeAngle ? 1 : 0} 0 ${center.x + innerRadius * Math.cos(startAngle)} ${
					center.y + innerRadius * Math.sin(startAngle)
				}`}
			{...forward}
		/>
	);
}
