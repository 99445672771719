import { DefaultTagLabels, type IndexTicker, type ReviewTicker } from "$root/api/api-gen";
import { hasAccess } from "$root/components/AuthorizationGuard";
import { CustomLabels } from "$root/components/CustomLabels";
import type { NonUndefined } from "$root/components/ReactQueryWrapper";
import { useLocaleFormatters } from "$root/localization/hooks";
import { commonBatchActions } from "$root/ui-lib/interactive-collections/common-batch-actions";
import { CommonItemActions } from "$root/ui-lib/interactive-collections/common-item-actions";
import colorGenerator from "$root/utils/chart/colorGenerator";
import { useUnsafeUpdatedRef } from "@mdotm/mdotui/react-extensions";
import type { BatchActionsProps, TableColumn } from "@mdotm/mdotui/components";
import {
	ActionText,
	AutoSortTable,
	Badge,
	BatchActions,
	Icon,
	NullableNumberInput,
	Row,
	TableDataCell,
	TextInput,
	colorBySeverity,
	useSelectableTableColumn,
} from "@mdotm/mdotui/components";
import { adaptAnimatedNodeProvider, spawn, toClassListRecord } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { builtInSortFnFor } from "@mdotm/mdotui/utils";
import type { Map } from "immutable";
import { Set } from "immutable";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { EditTagButton, TagButton } from "../instruments/edit-tags";
import {
	useInstrumentColumnsTableV2,
	useSearchableInstrumentTable as useSearchableInstrumentCollection,
} from "../instruments/hooks";
import { InstrumentClassificationDialog } from "../instruments/instrument-classification/InstrumentClassificationDialog";
import { useUserValue } from "../user";
import { typedUrlForRoute } from "$root/components/PlatformRouter/RoutesDef";
import { PortfolioDetailsTabs } from "$root/pages/PortfolioDetails";
import { InstrumentNameFormCell } from "../instruments/table-cells/InstrumentNameFormCell";

export type EditableProxiedInstrument = ReviewTicker & { linkedPortfolios?: number };
export type ProxiedInstrumentData = {
	currency: string;
	instrument: string;
	assetClass: string;
	microAssetClass: string;
	proxies: Array<IndexTicker>;
};

export type EditProxiedInstrumentSections = "universe" | "portfolio" | "benchmark" | "target_investment";

export type EditProxiedInstrumentTableProps = {
	disabled?: boolean;
	instrumentThatNeedProxy: EditableProxiedInstrument[];
	instrumentThatDontNeedProxy: EditableProxiedInstrument[];
	deleted: Set<string>;
	onDeletedChange(newDeleted: Set<string>): void;
	actions?: Array<"delete" | "edit">;
	mode?: "proxy" | "edit-proxy";
	proxied: Map<string, ProxiedInstrumentData>; // TODO: can we map the ticker of an unknown instrument to the ticker of the created proxy?
	onProxiedChange(newProxies: Map<string, ProxiedInstrumentData>): void;
	section?: EditProxiedInstrumentSections;
	tagged: Map<string, string>;
	onTagChange(newProxies: Map<string, string>): void;
	scored: Map<string, number | null>;
	onScoreChange(newScore: Map<string, number | null>): void;
	uuid?: string;
};

export function EditProxiedInstrumentTable({
	disabled,
	instrumentThatNeedProxy,
	instrumentThatDontNeedProxy,
	proxied,
	onProxiedChange,
	deleted,
	onDeletedChange,
	actions,
	mode = "proxy",
	section,
	tagged,
	onTagChange,
	scored,
	onScoreChange,
	uuid,
}: EditProxiedInstrumentTableProps): JSX.Element {
	const allInstruments = useMemo(
		() => [...instrumentThatNeedProxy, ...instrumentThatDontNeedProxy],
		[instrumentThatNeedProxy, instrumentThatDontNeedProxy],
	);

	const [tags, setTags] = useState(
		(() => {
			const defaultLabels = Object.values(DefaultTagLabels) as string[];
			const labelsIncomposition = allInstruments.filter((x) => x.tagLabel).map((x) => x.tagLabel!);
			return Array.from(Set(defaultLabels.concat(labelsIncomposition))).map((tag, i) => ({
				value: tag as string,
				color: colorGenerator(i),
				deletable: defaultLabels.includes(tag) === false,
			}));
		})(),
	);

	const instrumentColumns = useInstrumentColumnsTableV2();
	const { formatNumber } = useLocaleFormatters();
	const { t } = useTranslation();
	const user = useUserValue();

	const allMergedInstruments = useMemo(
		() =>
			allInstruments.map((originalInstrument) => ({
				...originalInstrument,
				currency: proxied.get(originalInstrument.ticker ?? "-")?.currency ?? originalInstrument.currency,
				instrument: proxied.get(originalInstrument.ticker ?? "-")?.instrument ?? originalInstrument.instrument,
				assetClass: proxied.get(originalInstrument.ticker ?? "-")?.assetClass ?? originalInstrument.assetClass,
				microAssetClass:
					proxied.get(originalInstrument.ticker ?? "-")?.microAssetClass ?? originalInstrument.microAssetClass,
			})),
		[allInstruments, proxied],
	);

	const { query, setQuery, filtered } = useSearchableInstrumentCollection(allMergedInstruments);

	const {
		column: checkboxColumn,
		multiSelectCtx,
		rowClassList,
		toggle,
	} = useSelectableTableColumn({
		selectBy: (row) => row.ticker ?? "",
		rows: allInstruments,
		filteredRows: filtered,
		mode: "checkbox",
		selectableRowIds: instrumentThatNeedProxy.map((row) => row.ticker ?? ""),
	});

	const refs = useUnsafeUpdatedRef({
		onDeletedChange,
		onProxiedChange,
		onTagChange,
		onScoreChange,
		tagged,
		proxied,
		deleted,
		tags,
		scored,
	});

	const columns = useMemo<Array<TableColumn<EditableProxiedInstrument>>>(() => {
		const cellClassList = ({ ticker }: EditableProxiedInstrument) => ({
			"line-through": deleted.has(ticker ?? "-"),
		});
		return [
			checkboxColumn,
			{
				...instrumentColumns.instrument(),
				content: (row, cellProps) => (
					<Row justifyContent="center" {...cellProps}>
						{row.proxyOverwriteType === "PORTFOLIO_MIXED" ? (
							<div className="flex flex-row items-center flex-nowrap w-full pr-4">
								<ActionText
									classList="inline-flex items-center gap-1"
									onClick={() =>
										window.open(
											typedUrlForRoute("PortfolioDetails", {
												portfolioUid: row.ticker ?? "",
												tab: PortfolioDetailsTabs.COMPOSITION,
											}),
											"_blank",
										)
									}
								>
									<span className="font-[weight:500] truncate items-center">{row.instrument ?? "-"}</span>
									<svg
										width="12"
										height="12"
										viewBox="0 0 12 12"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
										className="shrink-0"
									>
										<path
											d="M8 1.5H10.5V4"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M7 5L10.5 1.5"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M8.5 7.4375V9.625C8.5 10.1084 8.10844 10.5 7.625 10.5H2.375C1.89156 10.5 1.5 10.1084 1.5 9.625V4.375C1.5 3.89156 1.89156 3.5 2.375 3.5H4.5625"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</ActionText>
							</div>
						) : (
							<div className="relative z-0 flex-1 w-full flex justify-start items-center">
								<div className="truncate grow min-w-0">
									<InstrumentNameFormCell value={row.instrument} />
								</div>
								{row.proxyOverwriteType === "LIVE" &&
									mode === "proxy" && ( // TODO: just LIVE? How should we handle the other types? Same below
										<div>
											<Badge backgroundColor="#A5AEC0" color="white" classList="scale-[0.85]">
												Custom
											</Badge>
										</div>
									)}

								{row.delisted && (
									<div>
										<Badge backgroundColor="#A5AEC0" color="white" classList="scale-[0.85]">
											Delisted
										</Badge>
									</div>
								)}
							</div>
						)}
					</Row>
				),
				minWidth: 200,
				cellClassList,
			},
			{
				header: "Status",
				cellClassList,
				content: (row, cellProps) => (
					<TableDataCell {...cellProps}>
						{!row.needsCustomProxy || row.delisted === true ? (
							<span data-qualifier="EditProxiedInstrument/Table/Column(Status)/Success" />
						) : !proxied.has(row.ticker ?? "-") ? (
							<span data-qualifier="EditProxiedInstrument/Table/Column(Status)/Review">
								<Icon icon="Icon-full-alert" color={colorBySeverity.warning} size={20} />
							</span>
						) : (
							<span data-qualifier="EditProxiedInstrument/Table/Column(Status)/Success">
								<Icon icon="Icon-full-ok" color={colorBySeverity.success} size={20} />
							</span>
						)}
					</TableDataCell>
				),
				minWidth: 100,
				sortFn: builtInSortFnFor("needsCustomProxy"),
				name: "needsCustomProxy",
				hidden: mode === "edit-proxy",
			},
			{ ...instrumentColumns.alias, cellClassList },
			{ ...instrumentColumns.assetClass, cellClassList },
			{ ...instrumentColumns.microAssetClass, cellClassList },
			{
				header: "weight",
				content: (row) => `${formatNumber(row.weight)}%`,
				sortFn: builtInSortFnFor("weight"),
				name: "weight",
				hidden: mode === "edit-proxy" || section === "universe",
				minWidth: 114,
			},
			{
				name: "linked",
				minWidth: 114,
				hidden: !allInstruments.some(
					(row) => row.linkedPortfolios !== undefined && row.linkedPortfolios !== null && row.linkedPortfolios > 0,
				),
				header: "Linked portfolio",
				content: (row) => String(row.linkedPortfolios ?? 0),
				cellClassList: (row) => ({
					...toClassListRecord(cellClassList(row)),
					"tabular-nums": true,
				}),
				align: "end" as const,
			},
			{
				minWidth: 200,
				name: "tags",
				header: "Tags",
				content: ({ ticker }, cellProps) => {
					return (
						// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
						<Row {...cellProps} alignItems="center" onClick={(e) => e.stopPropagation()}>
							<TagButton
								options={tags}
								color={tags.find(({ value }) => value === tagged.get(ticker ?? "-"))?.color}
								value={tagged.get(ticker ?? "-") ?? null}
								onClick={(newTag, e) => {
									e?.stopPropagation();
									refs.current.onTagChange(tagged.set(ticker ?? "-", newTag));
								}}
								disabled={deleted.has(ticker ?? "-") || disabled}
								enableDebounce
							/>
						</Row>
					);
				},
				footerCellClassList: "font-semibold",
				hidden: section !== "universe",
			},
			{
				// header: t("SCORE"),
				name: "score",
				minWidth: 100,
				header: uuid
					? (headerProps) => (
							<Row {...headerProps}>
								<CustomLabels labelKey={`${uuid}_score1`} fallback={t("SCORE")} />
							</Row>
					  )
					: t("SCORE"),
				content: ({ ticker }, cellProps) => (
					<Row justifyContent="center" {...cellProps} childrenGrow={1}>
						<NullableNumberInput
							onClick={(e) => e.stopPropagation()}
							min={0}
							max={100}
							step={0.01}
							inputAppearance={{ classList: "text-right w-full" }}
							disabled={deleted.has(ticker ?? "-") || disabled}
							size="x-small"
							value={scored.get(ticker ?? "-") ?? null}
							onChange={(newScore) =>
								refs.current.onScoreChange(scored.set(ticker ?? "-", newScore ? Number(newScore.toFixed(2)) : null))
							}
							name="score"
							classList="w-full"
						/>
					</Row>
				),
				footerCellClassList: "font-semibold",
				hidden: section !== "universe" || !hasAccess(user, { requiredService: "CUSTOM_QUALITIES" }),
			},
			{
				name: "actions",
				width: 96,
				header: "",
				align: "end" as const,
				cellClassList,
				content: (row, cellProps) => {
					return (
						<Row {...cellProps} justifyContent="end" gap={8} onClick={(e) => e.stopPropagation()}>
							{row.needsCustomProxy &&
								Boolean(row.delisted) === false &&
								(actions === undefined || actions.includes("edit")) && (
									<CommonItemActions.Edit
										disabled={disabled}
										onClick={() =>
											spawn<void>(
												adaptAnimatedNodeProvider(({ onHidden, resolve, show }) => (
													<InstrumentClassificationDialog
														askForName
														onClose={resolve}
														onHidden={onHidden}
														show={show}
														defaultInstrumentProxy={proxied.get(row.ticker ?? "-") ?? row}
														onSubmitAsync={({ categorization, indices, weights }) => {
															const proxyIndexes = indices.map(
																(props): IndexTicker => ({
																	...props,
																	weight: props.ticker ? weights.get(props.ticker)?.toNumber() : undefined,
																}),
															);
															refs.current.onProxiedChange(
																proxied.set(row.ticker ?? "-", {
																	instrument:
																		categorization.instrumentName ??
																		proxied.get(row.ticker ?? "-")?.instrument ??
																		row.instrument ??
																		"-",
																	assetClass: categorization.macroAssetClass,
																	microAssetClass: categorization.microAssetClass,
																	currency: categorization.instrumentCurrencyExposure,
																	proxies: proxyIndexes,
																} satisfies ProxiedInstrumentData),
															);
														}}
													/>
												)),
											)
										}
										data-qualifier="EditProxiedInstrument/Table/Column(Action)/Edit"
									/>
								)}
							{(actions === undefined || actions.includes("delete")) && Boolean(row.delisted) === false && (
								<CommonItemActions.DeleteRestore
									disabled={disabled}
									deleted={deleted}
									item={row.ticker ?? "-"}
									// wrap to lazily resolve with the latest reference to the closure
									onDeletedChange={(x) => refs.current.onDeletedChange(x)}
									data-qualifier="EditProxiedInstrument/Table/Column(Action)/DeleteRestore"
								/>
							)}
						</Row>
					);
				},
			},
		];
	}, [
		checkboxColumn,
		instrumentColumns.instrument,
		instrumentColumns.alias,
		instrumentColumns.assetClass,
		instrumentColumns.microAssetClass,
		mode,
		section,
		allInstruments,
		uuid,
		t,
		user,
		deleted,
		proxied,
		formatNumber,
		tags,
		tagged,
		disabled,
		refs,
		scored,
		actions,
	]);

	const BatchActionsMap: Record<
		NonUndefined<EditProxiedInstrumentTableProps["actions"]>[number],
		BatchActionsProps["actions"][number]
	> = {
		delete: commonBatchActions.deleteRestore({
			t,
			deleted,
			selection: multiSelectCtx.data.selection,
			onDeletedChange,
		}),
		edit: {
			label: "Edit",
			icon: "Edit",
			onClick: () =>
				spawn<void>(
					adaptAnimatedNodeProvider(({ onHidden, resolve, show }) => (
						<InstrumentClassificationDialog
							askForName={multiSelectCtx.data.selection.size === 1}
							onClose={resolve}
							onHidden={onHidden}
							show={show}
							onSubmitAsync={({ categorization, indices, weights }) => {
								const proxyIndexes = indices.map(
									(props): IndexTicker => ({
										...props,
										weight: props.ticker ? weights.get(props.ticker)?.toNumber() : undefined,
									}),
								);

								onProxiedChange(
									proxied.merge(
										instrumentThatNeedProxy
											.filter(({ ticker }) => multiSelectCtx.data.selection.has(ticker ?? "-"))
											.map(({ ticker, instrument }) => [
												ticker ?? "-",
												{
													instrument: categorization.instrumentName ?? instrument ?? "-",
													assetClass: categorization.macroAssetClass,
													microAssetClass: categorization.microAssetClass,
													currency: categorization.instrumentCurrencyExposure,
													proxies: proxyIndexes,
												} satisfies ProxiedInstrumentData,
											]),
									),
								);
							}}
						/>
					)),
				),
		},
	};

	const tagCrud = useMemo(() => {
		function remove(tag: string) {
			const idsToDelete = tagged
				.toArray()
				.filter(([_id, value]) => value === tag)
				.map(([id]) => id);
			refs.current.onTagChange(tagged.removeAll(idsToDelete));
			setTags((list) => {
				const cloned = [...list];
				const index = list.findIndex((x) => x.value === tag);
				cloned.splice(index, 1);
				return cloned;
			});
		}

		function add(tag: string) {
			setTags((list) => [...list, { value: tag, deletable: true, color: colorGenerator(list.length) }]);
		}

		return { add, remove };
	}, [refs, tagged]);

	return (
		<div>
			<div className="pb-4">
				<div className="flex justify-between">
					<div className="grow max-w-[500px]">
						<TextInput
							leftContent={<Icon icon="Search" />}
							value={query}
							onChangeText={setQuery}
							placeholder="Filter by instrument name, ISIN, asset class or micro asset class"
						/>
					</div>
					{section === "universe" && !disabled && (
						<EditTagButton options={tags} enableDebounce onAdd={tagCrud.add} onDelete={tagCrud.remove} />
					)}
				</div>
			</div>
			<BatchActions
				classList="pb-4"
				total={allInstruments.length}
				selected={multiSelectCtx.data.selection.size}
				data-qualifier="EditProxiedInstrument/Action/DeleteRestore"
				actions={
					disabled
						? []
						: actions
						  ? actions.map((a) => BatchActionsMap[a])
						  : [BatchActionsMap["edit"], BatchActionsMap["delete"]]
				}
			/>
			<div>
				<AutoSortTable
					palette="uniform"
					rows={filtered}
					columns={columns}
					style={{ maxHeight: 492 }}
					noDataText="No instrument found"
					rowClassList={(row, rowIndex) => ({
						...toClassListRecord(rowClassList(row, rowIndex)),
						EditProxiedInstrumentRow: true,
					})}
					rowStyle={({ ticker, needsCustomProxy, delisted }) => ({
						backgroundColor: deleted.has(ticker ?? "-")
							? themeCSSVars.Table_highlightedRowBackgroundColor
							: needsCustomProxy &&
							    !proxied.has(ticker ?? "-") &&
							    !deleted.has(ticker ?? "-") &&
							    mode === "proxy" &&
							    Boolean(delisted) === false
							  ? themeCSSVars.global_palette_warning_100
							  : undefined,
					})}
					onRowClick={(row) => toggle(row.ticker ?? "")}
				/>
			</div>
		</div>
	);
}
