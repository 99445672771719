import type { FC } from "react";
import { useMemo } from "react";
import PositioninGraph from "$root/components/OutlookPositioningGraph";
import { AutoSortHScrollTable, defaultRowHeight, Icon, Row, Text } from "@mdotm/mdotui/components";
import type { IconName, TableColumn } from "@mdotm/mdotui/components";
import { PaletteColors } from "$root/styles/themePalette";
import { builtInSortFnFor } from "$root/utils/collections";
import { InfoTooltip } from "$root/widgets-architecture/layout/WidgetsMapper/InfoTooltip";
import useWidgetsData from "$root/hooks/useWidgetsData";
import { ForEach, overrideClassList } from "@mdotm/mdotui/react-extensions";

type TableVariants = "RETURNS" | "VOLATILITY" | "DIVERSIFICATION";

type OutlookExpectedTableProps = {
	data: Array<TableParams>;
	title: TableVariants;
	className?: string;
	rowClassName?: string;
	sortable?: boolean;
	reverseGraph?: boolean;
};

export type TableParams = {
	dynamics: string;
	dynamicsDescription: string;
	current: number;
	prev?: number;
	tooltip: Array<{ label: string; value: string }>;
	index: number;
};

const TABLE_HEADER: Record<TableVariants, string> = {
	RETURNS: "Historical dynamics",
	VOLATILITY: "Historical dynamics",
	DIVERSIFICATION: "Historical dynamics",
};

const OutlookExpectedTable: FC<OutlookExpectedTableProps> = ({
	data,
	title,
	sortable = false,
	className = "",
	rowClassName = "",
	reverseGraph = false,
}) => {
	const { currentWidgetsData } = useWidgetsData();
	const outLookHeader = TABLE_HEADER[title];
	const indicators: Array<{ icon: IconName; color: string }> = useMemo(
		() => [
			{ icon: "driver-arrow-doubledown", color: reverseGraph ? PaletteColors.GREENY_BLUE : PaletteColors.NEON_RED },
			{ icon: "driver-arrow-singledown", color: reverseGraph ? PaletteColors.GREENY_BLUE : PaletteColors.NEON_RED },
			{ icon: "driver-arrow-center", color: PaletteColors.AZURE },
			{ icon: "driver-arrow-singleup", color: reverseGraph ? PaletteColors.NEON_RED : PaletteColors.GREENY_BLUE },
			{ icon: "driver-arrow-doubleup", color: reverseGraph ? PaletteColors.NEON_RED : PaletteColors.GREENY_BLUE },
		],
		[reverseGraph],
	);

	const columns = useMemo<Array<TableColumn<TableParams>>>(
		() => [
			{
				header: outLookHeader,
				content: ({ dynamics, dynamicsDescription }, cellProps) => (
					<Row justifyContent="space-between" alignItems="center" {...cellProps}>
						<Text as="div" type="Body/M/Book" classList="line-clamp-2">
							{dynamics}
						</Text>
						<InfoTooltip>{dynamicsDescription}</InfoTooltip>
					</Row>
				),
				minWidth: 320,
				name: "dynamics",
				...(sortable ? { sortFn: builtInSortFnFor("dynamics") } : {}),
			},
			{
				name: "graph",
				header: (headerProps) => (
					<Row alignItems="center" justifyContent="space-between" {...headerProps}>
						<ForEach collection={indicators}>
							{({ item: { icon, color } }) => <Icon icon={icon} color={color} size={16} />}
						</ForEach>
					</Row>
				),
				content: (content, cellProps) => (
					<Row
						alignItems="center"
						justifyContent="center"
						{...cellProps}
						style={{ ...cellProps.style, height: defaultRowHeight }}
					>
						<PositioninGraph
							{...content}
							reverseGraph={reverseGraph}
							tooltip={content.tooltip}
							tootlipTitle={content.dynamics}
							lastDashboardUpdate={currentWidgetsData.creation_time}
						/>
					</Row>
				),
				minWidth: 120,
				maxWidth: 180,
			},
		],
		[outLookHeader, sortable, indicators, reverseGraph, currentWidgetsData.creation_time],
	);

	return (
		<AutoSortHScrollTable
			columns={columns}
			rows={data}
			classList={className}
			rowClassList={`OutlookRowsTable ${rowClassName}`}
		/>
	);
};

export default OutlookExpectedTable;
