import type { AclEntityMinInfoEntityTypeEnum, EventInvestmentType } from "$root/api/api-gen";
import { PdfControllerApiFactory, ScoreNamesControllerApiFactory, UserControllerApiFactory } from "$root/api/api-gen";
import { reportPlatformError } from "$root/api/error-reporting";
import { useApiGen } from "$root/api/hooks";
import { DataDisplayOverlayProvider } from "$root/components/DataDisplayOverlay";
import InfiniteLoader from "$root/components/InfiniteLoader";
import PlatformRouter from "$root/components/PlatformRouter";
import { typedUrlForRoute, useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import { usePlatformLayoutOptionsValue } from "$root/components/PlatformRouter/layout-options";
import { useEventBus, useGroupEventBus } from "$root/event-bus";
import { useCustomLabelsState } from "$root/functional-areas/system/CustomLabelsStore";
import type { UserRole } from "$root/functional-areas/user";
import { guestUser, useUserState } from "$root/functional-areas/user";
import useConnectivity from "$root/hooks/useConnectivity";
import { ToastContent, ToastMountPoint, platformToast } from "$root/notification-system/toast";
import { TransientNotification, TransientNotificationProvider } from "$root/notification-system/transient";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { ModeVariants } from "$root/widgets-architecture/contexts/universe";
import { DatePickerInputCtx, StackingContext } from "@mdotm/mdotui/components";
import { SpawnMountPoint } from "@mdotm/mdotui/react-extensions";
import { switchExpr, unpromisify } from "@mdotm/mdotui/utils";
import { format } from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";
import { flushSync } from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { FunctionalAreasProvider } from "./context";
import { instrumentCommentaryGenerationCode } from "$root/functional-areas/portfolio-studio-settings/settings/InstrumentCommentarySetting";
import { PortfolioStudioSettingTabEnum } from "$root/functional-areas/portfolio-studio-settings";
import { downloadFileResponse } from "$root/utils/files";
import { useLocaleFormatters } from "$root/localization/hooks";
import { getLocalizedDateMask } from "$root/localization/formatters";

class MissingTokenError extends Error {}

function App(): JSX.Element {
	const { user, setUser } = useUserState();
	const { setCustomLabelsStatus } = useCustomLabelsState();

	const CustomLabelsApi = useApiGen(ScoreNamesControllerApiFactory);
	const userApi = useApiGen(UserControllerApiFactory);

	useEventBus("investment-update", (e) => console.log('received server side event "investment-update"', e));
	useEventBus("market-update", (e) => console.log('received server side event "market-update"', e));
	useEventBus("user-update", ({ userInfo }) => {
		setUser({
			...user,
			id: userInfo!.id!,
			email: userInfo!.email!,
			availableServices: userInfo?.services || [],
			roles: (userInfo?.roles || []) as UserRole[],
			impersonating: false,
			customFields: userInfo?.customFields,
			name: userInfo?.name,
			surname: userInfo?.surname,
			customerName: userInfo?.customerName ?? "",
			importFormats: userInfo?.importFormats,
			exportFormats: userInfo?.exportFormats,
			importEnabled: userInfo?.importEnabled,
			exportEnabled: userInfo?.exportEnabled,
			automaticImport: userInfo?.importEnabled,
			customerId: userInfo?.customerId,
		});
	});

	// const systemStatusQuery = useQueryNoRefetch(["systemInfo"], {
	// 	retry: 0,
	// 	queryFn: () => {
	// 		//return axiosExtract(systemApi.infoUser());
	// 		return {
	// 			systemMode: "DEFAULT" satisfies SystemMode,
	// 			serverEnv: "Dev",
	// 		} satisfies { systemMode: SystemMode; serverEnv: "Dev" };
	// 	},
	// 	onSuccess({ systemMode, serverEnv }) {
	// 		flushSync(() =>
	// 			setSystemStatus((fetchedSystem) => ({
	// 				...fetchedSystem,
	// 				systemMode,
	// 				serverEnv,
	// 			})),
	// 		);
	// 	},
	// 	onError(err) {
	// 		console.warn(err);
	// 	},
	// });

	// useEventBus("system-update", () => {
	// 	systemStatusQuery.refetch().catch(noop);
	// });

	const userInfoQuery = useQueryNoRefetch(["userInfo", "initial"], {
		retry: 0,
		queryFn: () => {
			if (!user.token) {
				throw new MissingTokenError("missing token");
			}
			return axiosExtract(userApi.infoUser());
		},
		onSuccess({
			services,
			name,
			surname,
			customFields,
			importFormats,
			exportFormats,
			importEnabled,
			exportEnabled,
			id,
			customerId,
		}) {
			flushSync(() =>
				setUser((fetchedUser) => ({
					...fetchedUser,
					id: id!,
					name,
					surname,
					availableServices: services || [],
					customFields,
					importFormats,
					exportFormats,
					importEnabled,
					exportEnabled,
					automaticImport: importEnabled,
					customerId,
				})),
			);
		},
		onError(err) {
			if (!(err instanceof MissingTokenError)) {
				console.error(err);
				setUser(guestUser);
			} else {
				console.warn(err);
			}
		},
	});

	const customLabelQuery = useQueryNoRefetch(["customLabels", user.token], {
		enabled: Boolean(user.token && userInfoQuery.isSuccess),
		queryFn: () => {
			return axiosExtract(CustomLabelsApi.getAllScoreNames());
		},
		onSuccess({ scoreNames }) {
			flushSync(() => setCustomLabelsStatus({ customLabels: scoreNames ?? {} }));
		},
		onError(err) {
			console.warn(err);
		},
	});

	const platformLayoutOptions = usePlatformLayoutOptionsValue();
	const { formatDate, locale, parseDate } = useLocaleFormatters();

	return (
		<>
			{platformLayoutOptions.mode !== "print" && <ToastMountPoint />}
			<DataDisplayOverlayProvider>
				<DatePickerInputCtx.Provider
					value={{
						dateLocalizer: {
							placeholder: getLocalizedDateMask(locale),
							fromLocale: parseDate,
							toLocale: formatDate,
						},
					}}
				>
					<StackingContext.Provider value={{ zIndex: 1300 }}>
						<TransientNotificationProvider>
							<HelmetProvider>
								<FunctionalAreasProvider>
									<EventBusHandler />
									{/* {userInfoQuery.isLoading || systemStatusQuery.isLoading ? ( */}
									{userInfoQuery.isLoading || customLabelQuery.isFetching ? (
										<InfiniteLoader style={{ minHeight: "calc(100vh - 48px)" }} />
									) : (
										<PlatformRouter />
									)}
									<ConnectivityNotification />
									<SpawnMountPoint />
								</FunctionalAreasProvider>
							</HelmetProvider>
						</TransientNotificationProvider>
					</StackingContext.Provider>
				</DatePickerInputCtx.Provider>
			</DataDisplayOverlayProvider>
		</>
	);
}

/**
 * A component that can be used to globally subscribe to events dispatched on
 * the application event bus and react to them.
 */
function EventBusHandler() {
	const { t } = useTranslation();
	const { user, setUser } = useUserState();
	const { push } = useTypedNavigation();
	const [sessionExpired, setSessionExpired] = useState(false);
	const pdfControllerV3Api = useApiGen(PdfControllerApiFactory);

	function routeByInvestment(investment?: EventInvestmentType) {
		if (!investment) {
			return;
		}

		switch (investment) {
			case "PORTFOLIO_IMPORT":
			case "PORTFOLIO_UPDATE":
			case "PORTFOLIO_UPLOAD":
			case "REFERENCE_UPDATE":
			case "INVESTMENT_DRAFT_CONFIGURATION_UPDATE":
				return (uuid: string, proposal?: "true" | "false") =>
					push("PortfolioDetails", { portfolioUid: uuid, proposal });
			case "UNIVERSE_UPDATE":
				return (uuid: string) => push("UniverseDetails", { mode: ModeVariants.review, universeUuid: uuid });
			case "INVESTMENT_REPORT_UPDATE":
			case "NONE":
				return () => undefined;
			default:
				return () => undefined;
		}
	}

	const FunctionaAreaSSE: Record<
		Exclude<EventInvestmentType, "INVESTMENT_DRAFT_CONFIGURATION_UPDATE" | "PORTFOLIO_UPLOAD">,
		{ urlGenerator: (uuid: string) => string; typeLabel: string }
	> = useMemo(
		() => ({
			PORTFOLIO_IMPORT: {
				urlGenerator: (uuid: string) => typedUrlForRoute("PortfolioDetails", { portfolioUid: uuid }),
				typeLabel: "portfolio",
			},
			PORTFOLIO_UPDATE: {
				urlGenerator: (uuid: string) => typedUrlForRoute("PortfolioDetails", { portfolioUid: uuid }),
				typeLabel: "portfolio",
			},
			PORTFOLIO_UPLOAD: {
				urlGenerator: (uuid: string) => typedUrlForRoute("PortfolioDetails", { portfolioUid: uuid }),
				typeLabel: "portfolio",
			},
			UNIVERSE_UPDATE: {
				urlGenerator: (uuid: string) =>
					typedUrlForRoute("UniverseDetails", { mode: ModeVariants.review, universeUuid: uuid }),
				typeLabel: "universe",
			},
			REFERENCE_UPDATE: {
				urlGenerator: (uuid: string) => typedUrlForRoute("PortfolioDetails", { portfolioUid: uuid }),
				typeLabel: "target portfolio",
			},
			INVESTMENT_DRAFT_CONFIGURATION_UPDATE: {
				urlGenerator: (uuid: string) => typedUrlForRoute("PortfolioDetails", { portfolioUid: uuid }),
				typeLabel: "draft",
			},
			NONE: {
				typeLabel: "",
				urlGenerator: () => "/",
			},
			INVESTMENT_REPORT_UPDATE: {
				typeLabel: "",
				urlGenerator: () => "/",
			},
		}),
		[],
	);

	useEventBus("benchmark-update", (e) => {
		if (e.level === "INFO" && e.type === "REFERENCE_UPDATE" && e.status === "CALCULATING") {
			return;
		}

		if (e.level === "INFO" && e.type === "REFERENCE_UPDATE") {
			platformToast({
				children: (
					<ToastContent onClick={() => push("CustomBenchmark", { benchmarkId: e.uuid ?? "" })}>
						Sphere has updated your <strong>{e.name}</strong> benchmark.
					</ToastContent>
				),
				severity: "info",
				autoClose: 15000,
				icon: "Portfolio",
			});
		}
	});

	useEventBus("investment-update", (e) => {
		if ((e && e.type === "INVESTMENT_DRAFT_CONFIGURATION_UPDATE") || e.type === undefined) {
			return;
		}

		if (e.status === "CALCULATING") {
			return;
		}

		if (e.status === "REVIEW") {
			platformToast({
				children: (
					<ToastContent onClick={() => routeByInvestment(e.type)?.(e.uuid!)}>
						The <strong>{e.name}</strong> portfolio need a review. There are some instrument that need to be updated.{" "}
						<u>Click here to see more</u>.
					</ToastContent>
				),
				severity: "info",
				autoClose: 15000,
				icon: "Portfolio",
			});
			return;
		}

		if (e.hasBreachedAlerts && e.status !== "ERROR") {
			platformToast({
				children: (
					<ToastContent onClick={() => routeByInvestment(e.type)?.(e.uuid!, "true")}>
						The <strong>{e.name}</strong> portfolio proposal is ready. Please be aware that there are some conflicting
						constraints in your portfolio. <u>Click here to see more</u>.
					</ToastContent>
				),
				severity: "warning",
				autoClose: 15000,
				icon: "Portfolio",
			});

			return;
		}

		if (e.level === "ERROR") {
			platformToast({
				children: (
					<ToastContent onClick={() => routeByInvestment(e.type)?.(e.uuid!)}>
						The <strong>{e.name}</strong> is on error <u>Click here to see more</u>.
					</ToastContent>
				),
				severity: "error",
				autoClose: 15000,
				icon: "Portfolio",
			});

			return;
		}

		platformToast({
			children: (
				<ToastContent onClick={() => routeByInvestment(e.type)?.(e.uuid!)}>
					Sphere has updated your <strong>{e.name}</strong>{" "}
					{FunctionaAreaSSE[e.type as keyof typeof FunctionaAreaSSE].typeLabel}
				</ToastContent>
			),
			severity: "info",
			autoClose: 15000,
			icon: "Portfolio",
		});
	});

	useEventBus("unauthorized", () => {
		setUser(guestUser);
		if (user.signedIn) {
			setSessionExpired(true);
		}
	});

	useGroupEventBus(
		"commentary-update",
		(payload) => {
			if (payload.length === 1) {
				const [commentary] = payload;

				if (commentary.status === "READY") {
					platformToast({
						children: (
							<ToastContent onClick={() => routeByInvestment("PORTFOLIO_UPDATE")?.(commentary.uuid ?? "")}>
								Commentary of the portfolio: <strong>{commentary.name}</strong> is generated successfully.
							</ToastContent>
						),
						icon: "Portfolio",
						severity: "success",
					});
				}

				if (commentary.status === "ERROR") {
					platformToast({
						children: (
							<ToastContent onClick={() => routeByInvestment("PORTFOLIO_UPDATE")?.(commentary.uuid ?? "")}>
								Commentary of the portfolio: <strong>{commentary.name}</strong> is not generated successfully, try
								again.
							</ToastContent>
						),
						icon: "Portfolio",
						severity: "warning",
					});
				}
			}

			if (payload.length > 1) {
				const succededGeneration = payload.filter((x) => x.status === "READY");
				const failedGeneration = payload.filter((x) => x.status === "ERROR");

				if (succededGeneration.length > 0) {
					platformToast({
						children: `We have successfully generated ${succededGeneration.length} portfolio commentary`,
						icon: "Portfolio",
						severity: "success",
					});
				}

				if (failedGeneration.length > 0) {
					platformToast({
						children: `${failedGeneration.length} portfolio commentary generation have failed. Please try again.`,
						icon: "Portfolio",
						severity: "warning",
					});
				}
			}
		},
		{
			timeout: 10 * 1000,
		},
	);

	useEffect(() => {
		if (user.signedIn) {
			setSessionExpired(false);
		}
	}, [user]);

	const onDownloadGeneratedZip = useCallback(
		async (zipId: string, userName?: string) => {
			try {
				const zip = await axiosExtract(pdfControllerV3Api.downloadReportsById(zipId));
				downloadFileResponse(zip, {
					fileName: `${userName ? `${userName}_` : ""}Reports_${format(new Date(), "MMddyyyy")}.zip`,
				});
			} catch (error) {
				reportPlatformError(error, "ERROR", "portfolio", "unable to download the generated zip");
				throw new Error(String(error));
			}
		},
		[pdfControllerV3Api],
	);

	function generateDescription(portfolios: string[]) {
		if (portfolios.length <= 3) {
			return `Your report for “${portfolios.join(", ")}” is ready`;
		}

		const firstTreePortfolios = portfolios.slice(0, 2);
		const remainingPortfolios = portfolios.slice(2, portfolios.length);
		return `Your report for “${firstTreePortfolios.join(", ")} and ${remainingPortfolios.length} more...” is ready`;
	}

	useEventBus("bulk-report-download", (e) => {
		if (e.level === "INFO" && e.data) {
			platformToast({
				children:
					e.data.errorList && e.data.errorList.length > 0 ? (
						`Report could not be generated for “${e.data.errorList.join(", ")}”`
					) : (
						<ToastContent onClick={unpromisify(() => onDownloadGeneratedZip(e.data?.zipId ?? "", e.data?.userName))}>
							{e.data?.successList && (
								<>
									{generateDescription(e.data.successList)}{" "}
									<strong>
										<u>download</u>
									</strong>
								</>
							)}
						</ToastContent>
					),
				severity: "info",
				autoClose: 15000,
				icon: "Portfolio",
			});
			return;
		}

		platformToast({
			children: "Something went wrong while generating the report",
			severity: "error",
			autoClose: 15000,
			icon: "Portfolio",
		});
	});

	const roleByPermission = {
		editor: "edit",
		viewer: "view",
		owner: "become the owner of",
	} as Record<string, string>;

	const areaByEntity = {
		BENCHMARK: "Benchmark",
		INVESTMENT: "Portfolio",
		MARKET_VIEW: "Market View",
		UNIVERSE: "Universe",
	} satisfies Record<AclEntityMinInfoEntityTypeEnum, string>;

	// "marketView", "portfolio", "benchmark", "universe"
	function generateEntityLink(
		entity: AclEntityMinInfoEntityTypeEnum,
		uuid?: string,
		marketViewType?: string,
		isCustomMarketView?: boolean,
	) {
		switchExpr(entity, {
			INVESTMENT: () => push("PortfolioDetails", { portfolioUid: uuid ?? "" }),
			BENCHMARK: () => push("CustomBenchmark", { benchmarkId: uuid ?? "" }),
			MARKET_VIEW: () =>
				push("MarketViewWorkSpace", {
					type: marketViewType ?? "",
					isCustom: String(isCustomMarketView ?? false),
					uuid: uuid ?? "",
					action: "view",
				}),
			UNIVERSE: () => push("UniverseDetails", { universeUuid: uuid ?? "", mode: "viewer" }),
		});
	}

	function generateObjectPermission(ownerName?: string, permissionLevel?: string) {
		const role = roleByPermission[permissionLevel ?? "-"] ?? "-";

		return `${ownerName} has invited you to ${role}`;
	}

	useEventBus("shared-entity", (e) => {
		if (e.level === "INFO") {
			return platformToast({
				children: (
					<ToastContent
						onClick={() =>
							generateEntityLink(
								e.sharedEntityType as AclEntityMinInfoEntityTypeEnum,
								e.sharedEntityUuid,
								e.marketViewType,
								e.isCustomMarketView,
							)
						}
					>
						{generateObjectPermission(e.grantorName, e.permissionLevel)}
						&nbsp;
						<button type="button" className="underline">
							{e.sharedEntityName ?? "-"}
						</button>
						&nbsp;
						{e.sharedEntityType ? areaByEntity[e.sharedEntityType as AclEntityMinInfoEntityTypeEnum] : "-"}
					</ToastContent>
				),
				severity: "success",
				autoClose: 15000,
				icon: "Portfolio",
			});
		}

		platformToast({
			children: "Something went wrong while sharing the file",
			severity: "error",
			icon: "Portfolio",
		});
	});

	useEventBus("modified-entity", (e) => {
		if (e.level === "INFO") {
			return platformToast({
				children: (
					<ToastContent
						onClick={() =>
							generateEntityLink(
								e.sharedEntityType as AclEntityMinInfoEntityTypeEnum,
								e.sharedEntityUuid,
								e.marketViewType,
								e.isCustomMarketView,
							)
						}
					>
						{`${e.grantorName} has edited`}
						&nbsp;
						<strong>{e.sharedEntityName ?? "-"}</strong>
						&nbsp;
						{e.sharedEntityType ? areaByEntity[e.sharedEntityType as AclEntityMinInfoEntityTypeEnum] : "-"}
					</ToastContent>
				),
				severity: "success",
				autoClose: 15000,
				icon: "Portfolio",
			});
		}

		platformToast({
			children: "Something went wrong while sharing the file",
			severity: "error",
			icon: "Portfolio",
		});
	});

	useEventBus("deleted-entity", (e) => {
		if (e.level === "INFO") {
			return platformToast({
				children: (
					<ToastContent>
						{`${e.grantorName} has deleted`}
						&nbsp;
						<strong>{e.sharedEntityName ?? "-"}</strong>
						&nbsp;
						{e.sharedEntityType ? areaByEntity[e.sharedEntityType as AclEntityMinInfoEntityTypeEnum] : "-"}
					</ToastContent>
				),
				severity: "info",
				autoClose: 15000,
				icon: "Portfolio",
			});
		}

		platformToast({
			children: "Something went wrong while sharing the file",
			severity: "error",
			icon: "Portfolio",
		});
	});

	useEventBus("removed-entity", (e) => {
		if (e.level === "INFO") {
			return platformToast({
				children: (
					<ToastContent
						onClick={() => generateEntityLink(e.sharedEntityType as AclEntityMinInfoEntityTypeEnum, e.sharedEntityUuid)}
					>
						{`${e.grantorName} has removed you from`}
						&nbsp;
						<strong>{e.sharedEntityName ?? "-"}</strong>
						&nbsp;
						{e.sharedEntityType ? areaByEntity[e.sharedEntityType as AclEntityMinInfoEntityTypeEnum] : "-"}
					</ToastContent>
				),
				severity: "info",
				autoClose: 15000,
				icon: "Portfolio",
			});
		}

		platformToast({
			children: "Something went wrong while sharing the file",
			severity: "error",
			icon: "Portfolio",
		});
	});

	useGroupEventBus("instrument-commentary-update", (commentaries) => {
		const succeededCommentaries = commentaries.filter((x) => x.level === "INFO");
		const commentaryServerError = commentaries.filter(
			(x) =>
				x.level === "ERROR" && x.descriptionStatus === instrumentCommentaryGenerationCode.COMMENTARY_GENERATION_ERROR,
		);

		const missingDataError = commentaries.filter(
			(x) => x.level === "ERROR" && x.descriptionStatus === instrumentCommentaryGenerationCode.DATA_NOT_AVAILABLE,
		);

		if (succeededCommentaries.length > 0) {
			const singleCommentaryCopy = "We have generated the description";
			const multipleCommentaryCopy = `${succeededCommentaries.length} instruments description are updated`;
			platformToast({
				children: succeededCommentaries.length === 1 ? singleCommentaryCopy : multipleCommentaryCopy,
				icon: "Portfolio",
				severity: "success",
				onClick: () => push("PortfolioStudioSettings", { tab: PortfolioStudioSettingTabEnum.InstrumentsCustomisation }),
			});
		}

		if (commentaryServerError.length > 0) {
			const singleCommentaryCopy = "Something went wrong, unable to generate the commentary";
			const multipleCommentaryCopy = `${commentaryServerError.length} commentary generation went wrong`;
			platformToast({
				children: (
					<ToastContent
						onClick={() =>
							push("PortfolioStudioSettings", { tab: PortfolioStudioSettingTabEnum.InstrumentsCustomisation })
						}
					>
						{commentaryServerError.length === 1 ? singleCommentaryCopy : multipleCommentaryCopy}
					</ToastContent>
				),
				icon: "Portfolio",
				severity: "error",
			});
		}

		if (missingDataError.length > 0) {
			const singleCommentaryCopy = "The description generation is unavailable for this instrument.";
			const multipleCommentaryCopy = `The description generation is unavailable for ${missingDataError.length} instruments.`;
			platformToast({
				children: (
					<ToastContent
						onClick={() =>
							push("PortfolioStudioSettings", { tab: PortfolioStudioSettingTabEnum.InstrumentsCustomisation })
						}
					>
						{missingDataError.length === 1 ? singleCommentaryCopy : multipleCommentaryCopy}
					</ToastContent>
				),
				icon: "Portfolio",
				severity: "error",
			});
		}
	});

	return (
		<TransientNotification location="overlay" trigger={sessionExpired} variant="error">
			{t("appbar.SESSION_EXPIRED")}
		</TransientNotification>
	);
}

/**
 * Snackbar notification related to lost/reestablished connectivity.
 */
function ConnectivityNotification() {
	const { isOnline } = useConnectivity();
	const { t } = useTranslation();
	return (
		<>
			<TransientNotification pullDown={false} trigger={isOnline} variant="success" dismissible>
				{t("CONNECTION_ESTABLISHED")}
			</TransientNotification>
			<TransientNotification trigger={!isOnline} autoHide={null} variant="error">
				{t("CONNECTION_LOST")}
			</TransientNotification>
		</>
	);
}

export default App;
