import type {
	InvestmentMinInfo,
	InvestmentReferenceListEntry,
	InvestmentStatuses,
	RichAcl,
	UserReferenceColumnOrdering,
	UserReferenceColumnPreference,
	UserReferenceColumnPreferencePreferencesTypeEnum,
} from "$root/api/api-gen";
import { PortfolioStudioPreferencesApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { spawnYesNoDialog } from "$root/components/spawnable/yes-no-dialog";
import type { ColumnMetadata } from "$root/components/tables-extra/CustomizeColumns";
import { CustomizeColumns } from "$root/components/tables-extra/CustomizeColumns";
import { aclByArea, roleByArea } from "$root/functional-areas/acl/checkers/all";
import { validateACLPermissions } from "$root/functional-areas/acl/checkers/shared";
import { useUpdateAccessListControlDialog } from "$root/functional-areas/acl/hook/useUpdateAccessListControlDialog";
import { useUserValue } from "$root/functional-areas/user";
import { platformToast } from "$root/notification-system/toast";
import { actionsColumn } from "$root/ui-lib/interactive-collections/common-table-actions";
import { builtInSortFnFor } from "$root/utils/collections";
import { preventSubmitOnPressEnter } from "$root/utils/experimental";
import { useDebouncedMemo } from "$root/utils/react-extra";
import type { DropdownMenuProps, TableColumn } from "@mdotm/mdotui/components";
import {
	BaseHScrollTable,
	BatchActions,
	Icon,
	TextInput,
	contextMenuHandler,
	sortRows,
	useSelectableTableColumn,
} from "@mdotm/mdotui/components";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { noop, nullary, switchExpr, unpromisify } from "@mdotm/mdotui/utils";
import type { QueryObserverBaseResult } from "@tanstack/react-query";
import { Map } from "immutable";
import type { FC } from "react";
import { useCallback, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { multipleEntityDelete, notifyUser } from "../common";
import { usePortfolioStudioTableSettings } from "../portfolio-studio-table-settings";
import { useReferenceColumn } from "./columns";
import { portfolioEntityManagementActions } from "$root/functional-areas/portfolio/entity-management";
import { benchmarkEntityManagementActions } from "$root/functional-areas/benchmark/entity-management";

type ReferenceListProps = {
	references: InvestmentReferenceListEntry[];
	benchmarks: InvestmentReferenceListEntry[];
	columnsPreferences?: UserReferenceColumnPreference[];
	isVisible: boolean;
	refetch: {
		references: QueryObserverBaseResult<InvestmentReferenceListEntry[]>["refetch"];
		benchmarks: QueryObserverBaseResult<InvestmentReferenceListEntry[]>["refetch"];
		columnsPreferences: QueryObserverBaseResult<UserReferenceColumnOrdering>["refetch"];
	};
	portfolioStudioOuterDiv: HTMLDivElement | null;
};

export type UnifiedMockedReferenceProps = {
	name?: string;
	identifier?: string;
	creationTime?: string;
	modificationTime?: string;
	status?: InvestmentStatuses;
	type: "References" | "Benchmark";
	linkedPortfolio?: InvestmentMinInfo[];
	richAcl?: RichAcl;
	nofPortfolios?: number;
};

function unifyReferenceAndBenchmarksData(
	benchmarks?: InvestmentReferenceListEntry[],
	references?: InvestmentReferenceListEntry[],
): Array<UnifiedMockedReferenceProps> {
	const mapReferences = (references ?? []).map(
		(r): UnifiedMockedReferenceProps => ({
			name: r.name,
			identifier: r.uuid,
			creationTime: r.creationTime,
			modificationTime: r.modificationTime,
			status: r.status,
			type: "References",
			linkedPortfolio: r.referralInvestments,
			richAcl: r.richAcl,
			nofPortfolios: r.nofPortfolios,
		}),
	);

	const mapBenchmarks = (benchmarks ?? []).map(
		(b): UnifiedMockedReferenceProps => ({
			name: b.name,
			identifier: b.uuid,
			creationTime: b.creationTime,
			modificationTime: b.modificationTime,
			status: b.status,
			type: "Benchmark",
			linkedPortfolio: b.referralInvestments,
			richAcl: b.richAcl,
			nofPortfolios: b.nofPortfolios,
		}),
	);

	return [...mapReferences, ...mapBenchmarks].sort(builtInSortFnFor("modificationTime"));
}

const ReferenceList: FC<ReferenceListProps> = ({
	benchmarks,
	references,
	refetch,
	columnsPreferences,
	isVisible,
	portfolioStudioOuterDiv,
}) => {
	const [searchQuery, setSearchQuery] = useState("");

	const [customizeTableModalVisible, setCustomizeTableModalVisible] = useState(false);

	const user = useUserValue();
	const { t } = useTranslation();

	const portfolioStudioPreferencesApi = useApiGen(PortfolioStudioPreferencesApiFactory);
	const { aclSpawn: targetPortfolioSpawn } = useUpdateAccessListControlDialog("INVESTMENT");
	const { aclSpawn: benchmarkSpawn } = useUpdateAccessListControlDialog("BENCHMARK");

	const { value: debouncedLowerCaseSearchQuery } = useDebouncedMemo(
		() => searchQuery.toLocaleLowerCase(),
		[searchQuery],
		{
			debounceInterval: 200,
		},
	);

	const rows = useMemo(() => unifyReferenceAndBenchmarksData(benchmarks, references), [references, benchmarks]);
	const referenceByUid = useMemo(() => Map(rows.map((p) => [p.identifier ?? "", p])), [rows]);
	const filteredRows = useMemo(
		() =>
			rows.filter((item) =>
				[item.name ?? "", item.status ?? ""].some((el) => el.toLowerCase().includes(debouncedLowerCaseSearchQuery)),
			),
		[debouncedLowerCaseSearchQuery, rows],
	);

	const {
		column: checkboxColumn,
		rowClassList,
		multiSelectCtx: tableMultiSelectCtx,
	} = useSelectableTableColumn({
		rows: filteredRows,
		selectBy: (r) => r.identifier!,
	});

	const selection = useMemo(() => tableMultiSelectCtx.data.selection.toArray(), [tableMultiSelectCtx]);
	const { referenceListOrderByName, setReferenceListOrderByName } = usePortfolioStudioTableSettings();
	const visibleColumns = useReferenceColumn(columnsPreferences);

	const getDropdownActions = useCallback(
		(portfolio: UnifiedMockedReferenceProps): DropdownMenuProps<HTMLButtonElement, "">["actions"] => {
			const area = portfolio.type === "Benchmark" ? "benchmark" : ("targetPortfolio" satisfies keyof typeof aclByArea);
			const entityManagementActions =
				portfolio.type === "Benchmark"
					? benchmarkEntityManagementActions(
							user,
							{ ...portfolio, uuid: portfolio.identifier },
							{
								onRename: unpromisify(nullary(refetch.benchmarks)),
								onDuplicate: unpromisify(nullary(refetch.benchmarks)),
								onDelete(uuid) {
									refetch.benchmarks().catch(noop);
									tableMultiSelectCtx.actions.remove(uuid);
								},
							},
					  )
					: portfolioEntityManagementActions(
							user,
							{ ...portfolio, uuid: portfolio.identifier },
							{
								onRename: unpromisify(nullary(refetch.references)),
								onDuplicate: unpromisify(nullary(refetch.references)),
								onDelete(uuid) {
									refetch.references().catch(noop);
									tableMultiSelectCtx.actions.remove(uuid);
								},
							},
					  );

			return [
				aclByArea[area].canDelete(user.id, portfolio.richAcl?.acl ?? []) && {
					icon: "Delete",
					disabled: !entityManagementActions.deleteAsync,
					onClick: unpromisify(entityManagementActions.deleteAsync ?? noop),
					"data-qualifier": "PortfolioStudio/ReferenceList/DropdownMenu/DropdownItem(Delete)",
					label: "Delete",
				},
				{
					icon: "Content-Copy",
					disabled: !entityManagementActions.duplicateAsync,
					onClick: unpromisify(entityManagementActions.duplicateAsync ?? noop),
					"data-qualifier": "PortfolioStudio/ReferenceList/DropdownMenu/DropdownItem(Duplicate)",
					label: "Duplicate",
				},
				aclByArea[area].canRename(user.id, portfolio.richAcl?.acl ?? []) && {
					icon: "Edit",
					disabled: !entityManagementActions.renameAsync,
					onClick: unpromisify(entityManagementActions.renameAsync ?? noop),
					"data-qualifier": "PortfolioStudio/ReferenceList/DropdownMenu/DropdownItem(Rename)",
					label: "Rename",
				},
				switchExpr(portfolio.type, {
					References: () =>
						validateACLPermissions(user.id, portfolio.richAcl?.acl ?? [], roleByArea.targetPortfolio.EDITOR),
					Benchmark: () => validateACLPermissions(user.id, portfolio.richAcl?.acl ?? [], roleByArea.marketView.EDITOR),
				}) && {
					icon: "share",
					onClickAsync: async () => {
						switch (portfolio.type) {
							case "References": {
								await targetPortfolioSpawn(portfolio.name, portfolio.identifier, refetch.references);
								break;
							}
							case "Benchmark": {
								await benchmarkSpawn(portfolio.name, portfolio.identifier, refetch.benchmarks);
								break;
							}
						}
					},
					"data-qualifier": "PortfolioStudio/ReferenceList/DropdownMenu/DropdownItem(Share)",
					label: "Share",
				},
			];
		},
		[benchmarkSpawn, refetch, tableMultiSelectCtx.actions, targetPortfolioSpawn, user],
	);

	const columns = useMemo<TableColumn<UnifiedMockedReferenceProps>[] | null>(
		() =>
			visibleColumns
				? [
						checkboxColumn,
						...visibleColumns,
						actionsColumn({
							onSettingsClick: () => setCustomizeTableModalVisible(true),
							triggerProps: {
								"data-qualifier": "PortfolioStudio/Reference/DropdownMenu",
							},
							dropdownActions: getDropdownActions,
						}),
				  ]
				: null,
		[checkboxColumn, getDropdownActions, visibleColumns],
	);

	const filteredSortedRows = useMemo(
		() => (columns ? sortRows({ rows: filteredRows, columns, orderByArr: referenceListOrderByName }) : filteredRows),
		[columns, filteredRows, referenceListOrderByName],
	);

	const onChangeTableColumn = useCallback(
		async (data: ColumnMetadata<UserReferenceColumnPreferencePreferencesTypeEnum>[]) => {
			try {
				const payload = {
					userReferenceColumnPreferences: data.map((preference) => ({
						enabled: preference.visible,
						preferencesType: preference.id,
					})),
				} satisfies UserReferenceColumnOrdering;
				await portfolioStudioPreferencesApi.setUserReferenceColumnOrderingPreferences(payload);
				await refetch.columnsPreferences({ throwOnError: true });
				platformToast({
					children: "Table Successfully Edited.",
					severity: "success",
					icon: "Settings",
				});
			} catch (error) {
				platformToast({
					children: "Failed to update table",
					severity: "error",
					icon: "Settings",
				});
				throw new Error(String(error));
			} finally {
				setCustomizeTableModalVisible(false);
			}
		},
		[portfolioStudioPreferencesApi, refetch],
	);

	const customizableColumns = (columnsPreferences ?? []).map((c) => ({
		label: c.preferencesType ? t(`TABLE.HEADERS.${c.preferencesType}`) : "-",
		id: c.preferencesType!,
		visible: c.enabled ?? false,
		disabled: c.preferencesType === "NAME",
		hidden: c.preferencesType === "NAME",
	}));

	return (
		<>
			<CustomizeColumns
				show={customizeTableModalVisible}
				onClose={() => setCustomizeTableModalVisible(false)}
				columns={customizableColumns}
				// limit={Math.min(7, customizableColumns?.filter((x) => x.hidden === false).length ?? 1)}
				onSubmitAsync={onChangeTableColumn}
			/>

			<TextInput
				value={searchQuery}
				name="search"
				maxLength={60}
				onChangeText={setSearchQuery}
				placeholder={t("PORTFOLIOS.CONSTRAINTS_TARGETS.PORTFOLIO_LIST")}
				onKeyDown={preventSubmitOnPressEnter}
				style={{ width: 692 }}
				leftContent={<Icon icon="Search" size="1.4em" />}
				classList="mb-4"
			/>

			{columns && (
				<BaseHScrollTable
					onRowClick={(row) => row.identifier && tableMultiSelectCtx.actions.toggle(row.identifier)}
					onRowContextMenu={(row, _index, e) => contextMenuHandler(e, getDropdownActions(row))}
					columns={columns}
					rows={filteredSortedRows}
					rowClassList={rowClassList}
					rowStyle={({ identifier }) => {
						const selected = tableMultiSelectCtx.data.selection?.has(identifier!);
						return selected ? { backgroundColor: themeCSSVars.Table_highlightedRowBackgroundColor } : {};
					}}
					orderBy={referenceListOrderByName}
					// TODO: refactor
					style={{ height: "calc(100dvh - 160px)" }}
					noDataText="No data available"
					pinnedColumns={[
						{ name: "name", side: "left" },
						{ name: "settings-action", side: "right" },
					]}
					onOrderByChange={setReferenceListOrderByName}
				/>
			)}
			{portfolioStudioOuterDiv &&
				createPortal(
					isVisible && tableMultiSelectCtx.data.selection.size > 0 ? (
						<BatchActions
							classList={`sticky bottom-5 !bg-[color:${themeCSSVars.palette_N500}] h-11 px-2 rounded-md shadow-xl shadow-[color:${themeCSSVars.palette_N200}]`}
							selected={tableMultiSelectCtx.data.selection.size}
							total={rows.length}
							palette="quaternary"
							actions={[
								{
									label: "Delete",
									icon: "Delete",
									onClickAsync: () =>
										spawnYesNoDialog({
											header: t("PORTFOLIOS.MODAL.BULK_DELETE.TITLE"),
											yesButton: t("BUTTON.DELETE"),
											noButton: t("BUTTON.CANCEL"),
											children: t("PORTFOLIOS.MODAL.BULK_DELETE.DESCRIPTION", {
												size: tableMultiSelectCtx.data.selection.size,
											}),
											onSubmitAsync: async () => {
												const items = selection.flatMap((uuid) => {
													const reference = referenceByUid.get(uuid);
													return reference ? [reference] : [];
												});
												const response = await multipleEntityDelete({ area: "References", items });
												await refetch.benchmarks({ throwOnError: true });
												await refetch.references({ throwOnError: true });
												tableMultiSelectCtx.actions.reset();
												await notifyUser({ t, succeded: response.succeded, area: "References" });
											},
										})
											.then(noop)
											.catch(noop),
									disabled: tableMultiSelectCtx.data.selection.some((id) => {
										const reference = referenceByUid?.get(id);
										return (
											(reference?.linkedPortfolio ?? []).length > 0 ||
											aclByArea.targetPortfolio.canDelete(user.id, reference?.richAcl?.acl ?? []) === false
										);
									}),
									"data-qualifier": "PortfolioStudio/ReferenceList/BatchAction(Delete)",
								},
							]}
						/>
					) : (
						<></>
					),
					portfolioStudioOuterDiv,
				)}
		</>
	);
};

export default ReferenceList;
