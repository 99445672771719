import type { TableHeadCellProps } from "@mdotm/mdotui/components";
import { Text } from "@mdotm/mdotui/components";
import { overrideClassName, renderNodeOrFn } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import type { CSSProperties } from "react";

export function TinyTableHeadCell(props: TableHeadCellProps): JSX.Element {
	const renderedChildren = renderNodeOrFn(props.children);
	return (
		<div
			style={{ ...props.style, minHeight: 31 }}
			className={overrideClassName(
				{
					"flex items-center": true,
					"justify-start": !props.align || props.align === "start",
					"justify-center": props.align === "middle",
					"justify-end": props.align === "end",
				},
				props.classList,
			)}
		>
			<Text
				color={themeCSSVars.palette_N500}
				type="Body/S/BOLD-UPPERCASE"
				style={{ fontSize: tinyTableHeadCellFontSize }}
				classList={{
					"line-clamp-2": true,
					"text-left": !props.align || props.align === "start",
					"text-right": props.align === "end",
					"text-center": props.align === "middle",
				}}
				title={typeof renderedChildren === "string" ? renderedChildren : undefined}
			>
				{renderedChildren}
			</Text>
		</div>
	);
}

export const tinyTableDataCellFontSize = 10;
export const tinyTableHeadCellFontSize = 7.71;

export function TinyTableDataCell(
	props: TableHeadCellProps & { fontSize?: CSSProperties["fontSize"]; lineClamp?: number },
): JSX.Element {
	const renderedChildren = renderNodeOrFn(props.children);
	return (
		<div
			style={{ ...props.style, minHeight: 31 }}
			className={overrideClassName(
				{
					"flex items-center": true,
					"justify-start": !props.align || props.align === "start",
					"justify-center": props.align === "middle",
					"justify-end": props.align === "end",
				},
				props.classList,
			)}
		>
			<Text
				type="Body/S/Book"
				style={{
					fontSize: props.fontSize ?? tinyTableDataCellFontSize,
					overflow: "hidden",
					display: "-webkit-box",
					WebkitBoxOrient: "vertical",
					WebkitLineClamp: props.lineClamp ?? 2,
				}}
				classList={{
					"text-left": !props.align || props.align === "start",
					"text-right": props.align === "end",
					"text-center": props.align === "middle",
				}}
				title={typeof renderedChildren === "string" ? renderedChildren : undefined}
			>
				{renderedChildren}
			</Text>
		</div>
	);
}
