import { Trans, useTranslation } from "react-i18next";
import InfiniteLoader from "$root/components/InfiniteLoader";
import GraphLegend from "$root/components/GraphLegend";
import { PaletteColors } from "$root/styles/themePalette";
import ColoredRectangle from "$root/components/icons/ColoredRectangle";
import ReturnAnalysisZoomChart from "./ReturnAnalysisZoomChart";
import { useWidgetOptions } from "$root/widgets-architecture/layout/WidgetsMapper/context";
import type { ContextContent } from "$root/utils/react-extra";
import { withContext } from "$root/utils/react-extra";
import { useUnsafeUpdatedRef } from "@mdotm/mdotui/react-extensions";
import { UniverseFiltersContext } from "$root/widgets-architecture/contexts/filters";
import { useApiGen } from "$root/api/hooks";
import { DashboardControllerV3ApiFactory } from "$root/api/api-gen";
import { OutlookControllerV2ApiFactory } from "$root/api/api-gen";
import { InfoTooltip } from "$root/widgets-architecture/layout/WidgetsMapper/InfoTooltip";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { parallelize } from "$root/utils/promise";
import { useEventBus } from "$root/event-bus";
import { noop } from "@mdotm/mdotui/utils";
import { Intercom } from "$root/third-party-integrations/initIntercom";
import { Text, TooltipContent } from "@mdotm/mdotui/components";
import { qualifier } from "$root/utils/qualifiers";

const ReturnAnalysisBlock = (props: ContextContent<typeof UniverseFiltersContext>) => {
	const { firstFilter, secondFilter, thirdFilter } = props;
	const { t } = useTranslation();

	const outlookApi = useApiGen(OutlookControllerV2ApiFactory);
	const outlookApiRef = useUnsafeUpdatedRef(outlookApi);
	const dashboardApi = useApiGen(DashboardControllerV3ApiFactory);
	const dashboardApiRef = useUnsafeUpdatedRef(dashboardApi);

	const { isLoading, data, isError, refetch } = useQueryNoRefetch({
		queryKey: ["ReturnAnalysisBlock", firstFilter, secondFilter, thirdFilter],
		queryFn: async () => {
			const [returnAnalysis, bands] = await parallelize(
				[
					async () => {
						const REGEX = { $regex: `${firstFilter}` };
						let query = REGEX.$regex;
						if (secondFilter) {
							REGEX.$regex = `${firstFilter}_${secondFilter}`;
							query = REGEX.$regex;
						}
						if (thirdFilter) {
							REGEX.$regex = `${firstFilter}_${secondFilter}${
								thirdFilter && thirdFilter !== "ALL_SECTORS" ? `_${thirdFilter}` : ""
							}`;
							query = REGEX.$regex;
						}
						const retAn = await outlookApiRef.current.dashboardControllerGetReturnAnalysis(query);

						const b = {
							dates: retAn.data.dates!.map((x) => new Date(x).getTime()),
							performance: retAn.data.performance!,
							prev_dates: retAn.data.prevDates!.map((x) => new Date(x).getTime()),
							prev_performance: retAn.data.prevPerformance!,
						};
						return b;
					},
					async () => {
						const REGEX = `${firstFilter}_${secondFilter}${
							thirdFilter && thirdFilter !== "ALL_SECTORS" ? `_${thirdFilter}` : ""
						}`;
						const { data: rawBands } = await dashboardApiRef.current.dashboardControllerGetReturnAnalysisBands(REGEX);
						const [bandsDef] = rawBands;
						if (!rawBands[0].regime?.detectedScenario?.[0]?.value) {
							return { bands: [] };
						}
						const ACRegime = rawBands[0].regime!.detectedScenario![0].value;
						let newBands: Array<{
							fromIdx: number;
							toIdx: number;
							color: string;
							value: number;
							regime: 1 | 2 | 3;
						}> = [];

						if (bandsDef?.bands!.length) {
							let prev: [{ idx: number; value: number }, { idx: number; value: number }] | null = null;

							const group = bandsDef.bands.reduce(
								(acc: Array<NonNullable<typeof prev>>, curr: number, idx: number, arr) => {
									if (curr !== 0) {
										const target = arr[idx + 1];
										if (curr === target) {
											if (prev) {
												prev[1] = { idx: idx + 1, value: curr };
											} else {
												prev = [
													{ idx, value: curr },
													{ idx: idx + 1, value: curr },
												];
											}
										} else {
											if (prev) {
												acc.push(prev);
											}
											prev = null;
										}
									}
									return acc;
								},
								[],
							);
							const colors = [
								"eab676",
								PaletteColors.GREENY_BLUE,
								PaletteColors.YELLOW,
								PaletteColors.NEON_RED,
								PaletteColors.TRANQUIL,
							];
							newBands = group.map((x) => {
								return {
									fromIdx: x[0].idx,
									toIdx: x[1].idx,
									color: colors[ACRegime!],
									value: x[0].value,
									regime: ACRegime as 1 | 2 | 3,
								};
							});
							return {
								bandColor: colors[ACRegime!],
								bands: newBands,
							};
						}
						return {
							bandsColor: undefined,
							bands: newBands,
						};
					},
				],
				{ concurrency: 2 },
			);
			return {
				returnAnalysis,
				bandsData: bands,
			};
		},
	});

	useEventBus("market-update", () => {
		refetch().catch(noop);
	});

	useWidgetOptions(
		() => ({
			title: (
				<Text
					type="Body/XL/Bold"
					title={t("RETURN_ANALYSIS.title")}
					classList="truncate"
					data-qualifier={qualifier.widgets.returnAnalysis.name}
				>
					{t("RETURN_ANALYSIS.title")}
				</Text>
			),
			subTitle: t("RETURN_ANALYSIS.subtitle"),
			actionHeader: (
				<InfoTooltip>
					<TooltipContent>
						{t("RETURN_ANALYSIS.tooltipText")}
						<br />
						<br />
						<Trans
							t={t}
							i18nKey="RETURN_ANALYSIS.learnMore"
							components={{
								l: (
									// eslint-disable-next-line jsx-a11y/anchor-is-valid
									<a
										className="underline cursor-pointer font-bold"
										onClick={() => Intercom.showArticle("MarketRegimeAnalysis")}
									>
										Button
									</a>
								),
							}}
						/>
					</TooltipContent>
				</InfoTooltip>
			),
		}),
		[t],
	);

	return (
		<>
			{isError ? (
				t("SOMETHING_WENT_WRONG")
			) : isLoading || !data ? (
				<InfiniteLoader />
			) : (
				<>
					<ReturnAnalysisZoomChart
						data={data.returnAnalysis}
						bands={data.bandsData.bands}
						seriesName={`${t("RETURN_ANALYSIS.AC_PERFORMANCE")}`}
						downloadLabel={`${firstFilter} ${secondFilter} ${thirdFilter}`}
					/>
					<GraphLegend>
						<div className="legend-container return-analysis light more-space book">
							<ColoredRectangle variant="vertical" color={PaletteColors.AZURE} />
							{t("RETURN_ANALYSIS.AC_PERFORMANCE")}
						</div>
						<div className="legend-container return-analysis light more-space book">
							<ColoredRectangle variant="vertical" className="low-opacity" color={data.bandsData.bandsColor} />{" "}
							{t("RETURN_ANALYSIS.POSITIVE_RETURN")}
						</div>
					</GraphLegend>
				</>
			)}
		</>
	);
};

export default withContext(UniverseFiltersContext)(ReturnAnalysisBlock);
