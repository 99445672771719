import type {
	ExposureContributionRequestExposureContributionTypeEnum,
	InvestmentExposureResponse,
	InvestmentListEntry,
	InvestmentSummary,
} from "$root/api/api-gen";
import {
	EditorSaveEditOrReviewRequestPortfolioSavingModeEnum,
	EntityEditorControllerApiFactory,
	InvestmentReportsControllerApiFactory,
	InvestmentsExposureCompareControllerApiFactory,
	PortfolioStudioPreferencesApiFactory,
	ReferenceUniversesControllerApiFactory,
	type ReviewTicker,
} from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { hasAccess } from "$root/components/AuthorizationGuard";
import { ExposureSankeyLikeChart } from "$root/components/ExposureSankeyLikeChart/ExposureSankeyLikeChart";
import { IconWalls } from "$root/components/IconWall";
import { LeavePrompt } from "$root/components/LeavePrompt";
import ReactQueryWrapper from "$root/components/ReactQueryWrapper";
import { platformToast } from "$root/notification-system/toast";
import type { UploadEntity } from "$root/pages/Portfolios/UploadPortfolioPage";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { FormController } from "$root/third-party-integrations/react-hook-form";
import { FormFields } from "$root/ui-lib/form/FormFields";
import { customObjectValuesFn } from "$root/utils/experimental";
import { valueByPath } from "$root/utils/objects";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { zodResolver } from "@hookform/resolvers/zod";
import {
	Banner,
	Button,
	CircularProgressBar,
	Dialog,
	DialogFooter,
	DropdownMenu,
	DropdownMenuActionButton,
	FormField,
	Icon,
	Radio,
	RadioGroup,
	Select,
	SubmitButton,
	Text,
} from "@mdotm/mdotui/components";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import { generateUniqueDOMId, toClassName, useUpdatedRef } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import type { MaybeArray } from "@mdotm/mdotui/utils";
import { alwaysArray, groupBy, unpromisify } from "@mdotm/mdotui/utils";
import equal from "fast-deep-equal";
import { Map, Set } from "immutable";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import type { Control, FormState } from "react-hook-form";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as z from "zod";
import type { CompareDataItem } from "../compare-portfolio/CompareOverlay";
import { CompareOverlay } from "../compare-portfolio/CompareOverlay";
import { EditTagButton } from "../instruments/edit-tags";
import { isIdentifierCodeValid } from "../instruments/indentifier";
import InstrumentEditorTable, { If } from "../instruments/instrumentEditorTable";
import type { EditorCompositionIntruments } from "../instruments/instrumentEditorTable/instrumentEditorColumns";
import { useDebouncedNameUniquenessChecker } from "../named-entities/uniqueness";
import type { UseCompositionBuilderResult } from "../universe/composition";
import AddAssetClassButton from "../upload/Actions/AddAssetClassButton";
import AddPortfolioButton from "../upload/Actions/AddPortfolioButton";
import { OverridePortfolioPayload, spawnOverridePortfolioDialog } from "../upload/Actions/OverridePortfolio";
import UploadInstrumentButton from "../upload/Actions/UploadInstrumentButton";
import { exposureCompareOptions } from "../upload/shared";
import { useUserValue } from "../user";
import { parallelize } from "$root/utils/promise";
import { spawnCopyTemplateDialog } from "../upload/Actions/CopyTemplateDialog";
import { useHistory } from "react-router";

function payloadBuilder(
	data: Omit<EditorCompositionIntruments, "id" | "uploadType"> & {
		id?: string;
		uploadType?: "upload" | "add" | "select" | "cash";
	},
) {
	delete data.id;
	delete data.uploadType;
	return data;
}

export type UploadCompositionSectionProps = {
	uuid?: string;
	instrumentsLimit?: number;
	pathToNotBlock?: Array<string>;
} & (
	| {
			type: "normal";
			hasUniverse: boolean;
			uploadEntity: Extract<UploadEntity, "INVESTMENT">;
			override?: boolean;
	  }
	| {
			type: "enhance" | "optimize";
			uploadEntity: Extract<UploadEntity, "INVESTMENT_ENHANCEMENT">;
	  }
	| {
			uploadEntity: Extract<UploadEntity, "BENCHMARK">;
	  }
	| {
			uploadEntity: Extract<UploadEntity, "TARGET_INVESTMENT">;
	  }
	| {
			uploadEntity: Extract<UploadEntity, "UNIVERSE">;
			submitForm?: {
				isOpen: boolean;
				onCancel?(): void;
				onSubmit?(payload: {
					composition: ReviewTicker[];
					formData: {
						saveMode: keyof typeof SaveModeEnum;
						name: string;
						portfolioSavingMode: EditorSaveEditOrReviewRequestPortfolioSavingModeEnum | undefined;
					};
				}): MaybePromise<void>;
			};
	  }
);

enum SaveModeEnum {
	SAVE = "SAVE",
	SAVE_AS_NEW = "SAVE_AS_NEW",
}

type SubmitFormProps = {
	control: Control<
		{
			saveMode: keyof typeof SaveModeEnum;
			name: string;
			portfolioSavingMode: EditorSaveEditOrReviewRequestPortfolioSavingModeEnum | undefined;
		},
		any
	>;
	formState: FormState<{
		saveMode: keyof typeof SaveModeEnum;
		name: string;
		portfolioSavingMode: EditorSaveEditOrReviewRequestPortfolioSavingModeEnum | undefined;
	}>;
	checkingNameUniqueness: boolean;
	hasPortfolioIncomposition: boolean;
};

function SubmitForm({ control, formState, checkingNameUniqueness, hasPortfolioIncomposition }: SubmitFormProps) {
	const user = useUserValue();
	const observedSaveMode = useWatch({
		control,
		name: "saveMode",
	});

	return (
		<div className="grid gap-2">
			<p>
				Do you want to save the changes in this universe, or want to create a new one with the composition just created
				?
			</p>

			<FormController
				control={control}
				name="saveMode"
				render={({ field: { value, onChange } }) => (
					<RadioGroup value={value} onChange={onChange}>
						<div className="flex flex-row flex-wrap gap-4">
							<Radio value={SaveModeEnum.SAVE}>Save universe</Radio>
							<Radio value={SaveModeEnum.SAVE_AS_NEW}>Save as new</Radio>
						</div>
					</RadioGroup>
				)}
			/>
			{observedSaveMode === "SAVE_AS_NEW" && (
				<FormFields.Text
					control={control}
					formState={formState}
					name="name"
					label="New universe name"
					placeholder="Name"
					rightContent={
						checkingNameUniqueness ? <CircularProgressBar classList="w-3" value="indeterminate" /> : undefined
					}
				/>
			)}
		</div>
	);
}

type EditorActionHeaderProps = {
	onResetInstruments(newInstruments: EditorCompositionIntruments[]): void;
	onAddInstruments(newInstruments: EditorCompositionIntruments[]): void;
	setShowExposureContribution?(visible: boolean): void;
	setMoneyMarket(newMoneyMarket: ReviewTicker): void;
	willPortfolioCompositionBeShrinked: (id: string, ptfUuid?: string, weight?: number) => Promise<void>;
	compositionBuilder: UseCompositionBuilderResult;
	uploadInstruments: EditorCompositionIntruments[];
	tagList?: Array<{ color: string; value: string }>;
	uploadEntity: UploadEntity;
	uuid?: string;
	props: UploadCompositionSectionProps;
	tagCrud: {
		add: (tag: string) => void;
		remove: (tag: string) => void;
		reset(tagList: string[]): void;
	};
};

type EditorActionUnion = "add-row" | "edit-tag" | "copy-template" | "add-assetClass" | "add-portfolio" | "upload";

type EditorActions = {
	options?: Set<EditorActionUnion>;
	addInstrumentDropdownOptions?: Set<EditorActionUnion>;
};

const headerMapButtons: Record<UploadEntity, EditorActions> = {
	INVESTMENT: {
		addInstrumentDropdownOptions: Set(["add-assetClass", "add-portfolio", "upload"]),
	},
	TARGET_INVESTMENT: { addInstrumentDropdownOptions: Set(["add-assetClass"]) },
	BENCHMARK: {
		addInstrumentDropdownOptions: Set(["add-assetClass", "upload"]),
	},
	UNIVERSE: {
		options: Set(["edit-tag"]),
		addInstrumentDropdownOptions: Set(["add-assetClass", "add-portfolio", "upload"]),
	},
	INVESTMENT_ENHANCEMENT: {
		addInstrumentDropdownOptions: Set(["add-assetClass", "add-portfolio"]),
	},
	INVESTMENT_DRAFT: {
		addInstrumentDropdownOptions: Set(["add-assetClass", "add-portfolio", "upload"]),
	},
};

function EditorActionHeader({
	uploadEntity,
	compositionBuilder,
	uploadInstruments,
	tagList,
	uuid,
	props,
	tagCrud,
	onAddInstruments,
	onResetInstruments,
	setShowExposureContribution,
	setMoneyMarket,
	willPortfolioCompositionBeShrinked,
}: EditorActionHeaderProps) {
	const [bubbleSelectAssetClass, setBubbleSelectAssetClass] = useState<{ setShowDialog?(state: boolean): void }>();
	const [bubbleUploadExcel, setBubbleUploadExcel] = useState<{ setShowDialog?(state: boolean): void }>();
	const [bubbleSelectPortfolio, setBubbleSelectPortfolio] = useState<{ setShowDialog?(state: boolean): void }>();

	const addPortfolioCounterRef = useRef(0);
	const editorApi = useApiGen(EntityEditorControllerApiFactory);
	const history = useHistory();

	const currentComposition = useMemo(() => {
		const deleted = compositionBuilder.getDeleted();
		return uploadInstruments
			.filter((instrument) => deleted.has(instrument.id) === false)
			.map((instrument) =>
				payloadBuilder({
					...instrument,
					weight: compositionBuilder.getWeight(instrument.id)?.toNumber(),
					alias: instrument.rowType === "add" ? compositionBuilder.getIdentifier(instrument.id) : instrument.alias,
				}),
			);
	}, [compositionBuilder, uploadInstruments]);

	const validateActionPermission = (values: MaybeArray<EditorActionUnion>, options?: Set<EditorActionUnion>) => {
		const actions = alwaysArray(values);
		return actions.every((action) => options?.has(action));
	};

	const entityButtons = useMemo(() => headerMapButtons[uploadEntity], [uploadEntity]);
	const applyOverrideRef = useUpdatedRef(async (payload: OverridePortfolioPayload) => {
		const [investmentEntry] = payload;

		if (investmentEntry.uuid) {
			const compositionResponse = await axiosExtract(
				editorApi.getEditorEditComposition(investmentEntry.uuid, uploadEntity),
			);

			const composition = compositionResponse.composition?.map(
				(c): EditorCompositionIntruments => ({
					...c,
					id: generateUniqueDOMId(),
					rowType: "select",
					investment: {
						nofInstruments: investmentEntry.nOfInstrument,
					},
				}),
			);

			if (composition) {
				unpromisify(async () => {
					await parallelize(
						composition.flatMap((c) =>
							c.proxyOverwriteType === "PORTFOLIO_MIXED"
								? [() => willPortfolioCompositionBeShrinked(c.id, c.ticker!, c.weight!)]
								: [],
						),
					);
				})();
				onResetInstruments(composition);
			}

			if (compositionResponse.cashTicker) {
				setMoneyMarket(compositionResponse.cashTicker);
			}

			const newSearchParams = new URLSearchParams(history.location.search);
			newSearchParams.delete("override");
			history.replace({ search: newSearchParams.toString() });
		}
	});

	useEffect(() => {
		if (props.uploadEntity === "INVESTMENT" && props.override) {
			console.log("yolo");
			spawnOverridePortfolioDialog({
				uploadEntity: props.uploadEntity,
				uuid: props.uuid,
				onSubmitAsync: applyOverrideRef.current,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [applyOverrideRef, props.uploadEntity, props.uuid]);

	return (
		<div className="flex gap-2">
			{validateActionPermission("edit-tag", entityButtons.options) && (
				<EditTagButton
					data-qualifier="CompositionEditor/HeaderAction/EditTag"
					options={tagList ?? []}
					enableDebounce
					onAdd={tagCrud.add}
					onDelete={tagCrud.remove}
				/>
			)}

			{validateActionPermission("upload", entityButtons.options) && (
				<UploadInstrumentButton
					data-qualifier="CompositionEditor/HeaderAction/Upload"
					uploadEntity={uploadEntity}
					currentComposition={currentComposition}
					onSave={(instruments) => {
						const mapInstruments = instruments.map(
							(i): EditorCompositionIntruments => ({
								...i,
								rowType: "upload",
								weight: i.weight ?? 0,
								id: generateUniqueDOMId(),
							}),
						);
						onResetInstruments(mapInstruments);
					}}
				/>
			)}

			{/* {validateActionPermission("add-row", entityButtons.options) && (
				<Button
					palette="secondary"
					size="small"
					classList="flex gap-2"
					onClick={() => onAddInstruments([{ rowType: "add", weight: 0, id: generateUniqueDOMId(), alias: "" }])}
					data-qualifier="CompositionEditor/HeaderAction/AddRow"
				>
					<Icon icon="Outline1" size={18} />
					Add row
				</Button>
			)} */}

			{validateActionPermission("add-assetClass", entityButtons.options) && (
				<AddAssetClassButton
					filtersMode="multi"
					identifier={uuid}
					uploadEntity={uploadEntity}
					onConfirmSelectionAsync={onAddInstruments}
					onAddCustomInstrument={({ identifier, weight }) => {
						onAddInstruments([{ rowType: "select", weight, id: generateUniqueDOMId(), alias: identifier }]);
						platformToast({
							children: `The instrument “${identifier}” has been added.`,
							icon: "Icon-full-ok",
							severity: "success",
						});
					}}
					selectedInstruments={uploadInstruments.filter(({ ticker }) => ticker).map(({ ticker }) => ticker!)}
					instrumentsInComposition={uploadInstruments}
					placeholder={props.uploadEntity === "INVESTMENT" && props.hasUniverse ? "Select from universe" : undefined}
					data-qualifier="CompositionEditor/HeaderAction/AddAssetClass"
				/>
			)}

			{validateActionPermission("add-portfolio", entityButtons.options) && (
				<AddPortfolioButton
					uuid={uuid}
					uploadEntity={uploadEntity}
					selectedPortfolios={uploadInstruments.filter(({ ticker }) => ticker).map(({ ticker }) => ticker!)}
					onConfirm={(portfolios) => {
						const compositionToAdd = portfolios.map(
							(x): EditorCompositionIntruments => ({
								id: x.domId,
								rowType: "select",
								ticker: x.uuid,
								identifier: "Portfolio",
								instrument: x.name,
								proxyOverwriteType: "PORTFOLIO_MIXED",
								investment: x,
							}),
						);
						onAddInstruments(compositionToAdd);
					}}
				/>
			)}

			{validateActionPermission("add-assetClass", entityButtons.addInstrumentDropdownOptions) && (
				<AddAssetClassButton
					filtersMode="multi"
					identifier={uuid}
					uploadEntity={uploadEntity}
					onConfirmSelectionAsync={onAddInstruments}
					selectedInstruments={uploadInstruments.filter(({ ticker }) => ticker).map(({ ticker }) => ticker!)}
					instrumentsInComposition={uploadInstruments}
					onAddCustomInstrument={({ identifier, weight }) => {
						onAddInstruments([{ rowType: "select", weight, id: generateUniqueDOMId(), alias: identifier }]);
						platformToast({
							children: `The instrument “${identifier}” has been added.`,
							icon: "Icon-full-ok",
							severity: "success",
						});
					}}
					renderCustomButton={function Bubble({ setShowDialog }) {
						useEffect(() => {
							setBubbleSelectAssetClass({ setShowDialog });
						}, [setShowDialog]);
						return <></>;
					}}
				/>
			)}

			{validateActionPermission("upload", entityButtons.addInstrumentDropdownOptions) && (
				<UploadInstrumentButton
					uploadEntity={uploadEntity}
					currentComposition={currentComposition}
					renderCustomButton={function Bubble({ setShowDialog }) {
						useEffect(() => {
							setBubbleUploadExcel({ setShowDialog });
						}, [setShowDialog]);
						return <></>;
					}}
					onSave={(instruments) => {
						const mapInstruments = instruments.map(
							(i): EditorCompositionIntruments => ({
								...i,
								rowType: "select",
								weight: i.weight ?? 0,
								id: generateUniqueDOMId(),
							}),
						);
						onResetInstruments(mapInstruments);
					}}
				/>
			)}

			{validateActionPermission("add-portfolio", entityButtons.addInstrumentDropdownOptions) && (
				<AddPortfolioButton
					uuid={uuid}
					uploadEntity={uploadEntity}
					selectedPortfolios={uploadInstruments.filter(({ ticker }) => ticker).map(({ ticker }) => ticker!)}
					renderCustomButton={function Bubble({ setShowDialog, rows }) {
						useEffect(() => {
							setBubbleSelectPortfolio({ setShowDialog });
						}, [setShowDialog]);
						return <></>;
					}} //TODO: porcaruond rimouviii
					onConfirm={(portfolios) => {
						const compositionToAdd = portfolios.map(
							(x): EditorCompositionIntruments => ({
								id: x.domId,
								rowType: "select",
								ticker: x.uuid,
								identifier: "Portfolio",
								instrument: x.name,
								proxyOverwriteType: "PORTFOLIO_MIXED",
								investment: x,
								weight:  uploadEntity !== "UNIVERSE" ? 10 : undefined,
							}),
						);
						onAddInstruments(compositionToAdd);
						if (addPortfolioCounterRef.current === 0 && uploadEntity !== "UNIVERSE") {
							setShowExposureContribution?.(true);
							addPortfolioCounterRef.current += 1;
						}
					}}
				/>
			)}

			{entityButtons.addInstrumentDropdownOptions && (
				<DropdownMenu
					trigger={(triggerProps) => (
						<Button
							palette="secondary"
							size="small"
							classList="flex gap-2"
							{...triggerProps}
							data-qualifier="CompositionEditor/HeaderAction/DropdownMenu"
						>
							<Icon icon="add-ptf" size={18} />
							Add
						</Button>
					)}
					position="bottom"
					align="endToEnd"
					actions={[
						({ onClose }) =>
							validateActionPermission("add-assetClass", entityButtons.addInstrumentDropdownOptions) && (
								<DropdownMenuActionButton
									onClick={() => {
										bubbleSelectAssetClass?.setShowDialog?.(true);
										onClose();
									}}
									data-qualifier="CompositionEditor/HeaderAction/DropdownMenu(AddInstrument)"
								>
									Add instruments
								</DropdownMenuActionButton>
							),
						({ onClose }) =>
							validateActionPermission("add-portfolio", entityButtons.addInstrumentDropdownOptions) && (
								<DropdownMenuActionButton
									onClick={() => {
										bubbleSelectPortfolio?.setShowDialog?.(true);
										onClose();
									}}
									data-qualifier="CompositionEditor/HeaderAction/DropdownMenu(AddPortfolio)"
								>
									Add portfolio
								</DropdownMenuActionButton>
							),
						({ onClose }) =>
							validateActionPermission("upload", entityButtons.addInstrumentDropdownOptions) && (
								<DropdownMenuActionButton
									onClick={() => {
										bubbleUploadExcel?.setShowDialog?.(true);
										onClose();
									}}
									data-qualifier="CompositionEditor/HeaderAction/DropdownMenu(Upload)"
								>
									Upload composition
								</DropdownMenuActionButton>
							),

						// ({ onClose }) =>
						// 	validateActionPermission("add-row", entityButtons.addInstrumentDropdownOptions) && (
						// 		<DropdownMenuActionButton
						// 			onClick={() => {
						// 				onAddInstruments([{ rowType: "add", weight: 0, id: generateUniqueDOMId(), alias: "" }]);
						// 				onClose();
						// 			}}
						// 			data-qualifier="CompositionEditor/HeaderAction/DropdownMenu(AddRow)"
						// 		>
						// 			Add Row
						// 		</DropdownMenuActionButton>
						// 	),
					]}
				/>
			)}
		</div>
	);
}

const EditCompositionSection = forwardRef<
	{
		disabled: boolean;
		onSubmit(fn: (data: ReviewTicker[]) => Promise<void>): Promise<void>;
		hasPortfolioIncomposition: boolean;
	},
	UploadCompositionSectionProps
>(function _UploadCompositionSection(props, ref): JSX.Element {
	const { uploadEntity, uuid, instrumentsLimit } = props;
	const [showExposureContribution, setShowExposureContribution] = useState(false);
	const [hasComponentReachedTheLimit, setHasComponentReachedLimit] = useState(false);
	const [IsMinNumberInstrumentReached, setIsMinNumberInstrumentReached] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const [compareSelection, setCompareSelection] = useState(Map<string, InvestmentListEntry>());

	const referenceUniversesV4Api = useApiGen(ReferenceUniversesControllerApiFactory);
	const editorApi = useApiGen(EntityEditorControllerApiFactory);
	const investmentsExposureCompareApi = useApiGen(InvestmentsExposureCompareControllerApiFactory);
	const investmentReportApi = useApiGen(InvestmentReportsControllerApiFactory);

	const user = useUserValue();
	const { t } = useTranslation();

	const { checkingNameUniqueness, checkIfNameIsAvailable } = useDebouncedNameUniquenessChecker({
		isNameAvailableApi: (name, opts) => axiosExtract(referenceUniversesV4Api.isUniverseNameAvailable(name, opts)),
	});

	const { control, formState, handleSubmit } = useForm({
		defaultValues: {
			saveMode: "SAVE" as keyof typeof SaveModeEnum,
			name: "" satisfies string,
			portfolioSavingMode: undefined as EditorSaveEditOrReviewRequestPortfolioSavingModeEnum | undefined,
		},
		resolver: zodResolver(
			z.discriminatedUnion("saveMode", [
				z.object({
					saveMode: z.literal(SaveModeEnum.SAVE),
					name: z.string().nullable(),
					portfolioSavingMode: z.nativeEnum(EditorSaveEditOrReviewRequestPortfolioSavingModeEnum).optional(),
				}),
				z.object({
					saveMode: z.literal(SaveModeEnum.SAVE_AS_NEW),
					name: z
						.string()
						.min(1, "Please provide a name for your universe")
						.refine((name) => checkIfNameIsAvailable(name), {
							message: "Name not available",
						}),
					portfolioSavingMode: z.nativeEnum(EditorSaveEditOrReviewRequestPortfolioSavingModeEnum).optional(),
				}),
			]),
		),
	});

	const onSubmitAsync = useCallback(
		async (
			compositionBuilder: UseCompositionBuilderResult,
			uploadInstruments: EditorCompositionIntruments[],
			universeProps: Extract<UploadCompositionSectionProps, { uploadEntity: "UNIVERSE" }>,
			hasPortfolioIncomposition: boolean,
		) => {
			await handleSubmit(async (formData) => {
				const deleted = compositionBuilder.getDeleted();
				const composition = uploadInstruments
					.filter(({ id }) => deleted.has(id) === false)
					.map((instrument) => {
						const tag = compositionBuilder.getTag(instrument.id);
						return payloadBuilder({
							...instrument,
							weight: compositionBuilder.getWeight(instrument.id)?.toNumber(),
							alias: instrument.rowType === "add" ? compositionBuilder.getIdentifier(instrument.id) : instrument.alias,
							tagLabel: tag === "" ? undefined : tag,
							score: compositionBuilder.getScore(instrument.id)?.toNumber(),
						});
					});
				setIsSubmitting(true);
				try {
					await universeProps.submitForm?.onSubmit?.({
						composition,
						formData: {
							...formData,
							portfolioSavingMode: hasPortfolioIncomposition ? "NEST_PORTFOLIOS" : "MIX_INSTRUMENTS",
						},
					});
				} catch (err) {
					platformToast({
						icon: "Icon-full-error",
						children: "Unable to save universe",
						severity: "error",
					});
					throw err;
				} finally {
					setIsSubmitting(false);
				}
			}, console.log)();
		},
		[handleSubmit],
	);

	const [exposureCompareCategory, setExposureCompareCategory] =
		useState<ExposureContributionRequestExposureContributionTypeEnum>("MACRO_ASSET_CLASS");
	const [investmentEntry, setInvestmentEntry] = useState<Array<{ entityUuid: string; composedWeight: number }>>([]);

	const portfoliosWeights = useMemo(
		() => investmentEntry.filter((x) => x.entityUuid !== "INSTRUMENT_WEIGHTS"),
		[investmentEntry],
	);

	const havePortfolioCorrectWeight = portfoliosWeights.every((x) => x.composedWeight < 0 || x.composedWeight > 100);

	const { isFetching, data: exposureCompare } = useQueryNoRefetch(
		["queryExposureOnEditCompare", exposureCompareCategory, investmentEntry, showExposureContribution],
		{
			enabled:
				uploadEntity !== "UNIVERSE" &&
				investmentEntry.length > 0 &&
				showExposureContribution === true &&
				portfoliosWeights.some((x) => x.composedWeight > 0 && x.composedWeight <= 100),
			queryFn: () =>
				axiosExtract(
					investmentsExposureCompareApi.getExposureContribution({
						exposureContributionType: exposureCompareCategory,
						entries: portfoliosWeights.filter((x) => x.entityUuid !== "INSTRUMENT_WEIGHTS" && x.composedWeight > 0),
						instrumentsWeight: investmentEntry.find((x) => x.entityUuid === "INSTRUMENT_WEIGHTS")?.composedWeight ?? 0,
					}),
				),
		},
	);

	const sankeyData = useMemo(() => {
		if (!exposureCompare) {
			return [];
		}
		const { portfolioComposition } = exposureCompare;
		const groupedPortfolioByUuid = groupBy(portfolioComposition ?? [], (x) => x.entityUuid!);

		const investmentComposition = customObjectValuesFn(groupedPortfolioByUuid).flatMap((contributions) => {
			if (!contributions) {
				return [];
			}
			const entry = contributions[0];
			return [
				{
					label: entry.entityName!,
					name: entry.entityUuid!,
					weight: entry.composedWeight!,
					items: contributions.map((x) => ({
						quality: x.quality!,
						weight: x.weight ?? 0,
					})),
				},
			];
		});
		return investmentComposition;
	}, [exposureCompare]);

	const minNumberOfInstrument = useMemo(
		() =>
			hasAccess(user, { requiredService: "NUMBER_OF_INSTRUMENTS_CHECK_BYPASS" }) ||
			uploadEntity === "BENCHMARK" ||
			uploadEntity === "TARGET_INVESTMENT"
				? 1
				: 5,
		[uploadEntity, user],
	);

	const portfolioStudioPreferencesApi = useApiGen(PortfolioStudioPreferencesApiFactory);

	const { data: userColumnPreferences } = useQueryNoRefetch(["loadUserCompositionColumnPreferences"], {
		enabled: uploadEntity === "INVESTMENT" || uploadEntity === "INVESTMENT_ENHANCEMENT",
		queryFn: async () => ({
			columnPreference:
				uploadEntity === "INVESTMENT"
					? (await axiosExtract(portfolioStudioPreferencesApi.getUserCompositionColumnOrderingPreferences()))
							.userCompositionColumnPreferences
					: [],
			enhanceColumnPreference:
				uploadEntity === "INVESTMENT_ENHANCEMENT"
					? (await axiosExtract(portfolioStudioPreferencesApi.getUserEnhancementCompositionColumnOrderingPreferences()))
							.userEnhancementCompositionColumnPreferences
					: [],
		}),
	});

	return (
		<>
			<div
				className={toClassName({
					"flex min-h-[calc(100dvh_-_228px)] h-full": true,
					"space-x-2": showExposureContribution,
				})}
			>
				<div
					className={toClassName({
						"w-full": showExposureContribution === false,
						"w-4/6": showExposureContribution,
						"transition-[width]": true,
					})}
				>
					<ReactQueryWrapper
						enabled={Boolean(uuid)}
						queryKey={["editQueryComposition", uuid]}
						queryFn={async () => {
							if (uuid === undefined) {
								throw new Error("unable to load a valid uuid");
							}
							let portfoliosSummaryMap = Map<string, InvestmentSummary>();
							let portfoliosExposureMap = Map<string, InvestmentExposureResponse>();

							const compositionResponse = await axiosExtract(editorApi.getEditorEditComposition(uuid, uploadEntity));
							if (compositionResponse.composition) {
								await parallelize(
									compositionResponse.composition.flatMap((instrument) => {
										return instrument.proxyOverwriteType === "PORTFOLIO_MIXED" && instrument.ticker
											? [
													async () => {
														const summary = await axiosExtract(
															investmentReportApi.getInvestmentSummary(instrument.ticker!),
														);

														const exposure = await axiosExtract(
															investmentReportApi.getTwoLevelsInvestmentExposure(
																summary.uuid!,
																summary.primaryBenchmarkIdentifier!,
																"MACRO_ASSET_CLASS",
															),
														);

														portfoliosExposureMap = portfoliosExposureMap.set(instrument.ticker!, exposure);
														portfoliosSummaryMap = portfoliosSummaryMap.set(instrument.ticker!, summary);
													},
											  ]
											: [];
									}),
									{ concurrency: 3 },
								);
							}

							const composition = compositionResponse.composition?.map(
								(c): EditorCompositionIntruments => ({
									...c,
									id: generateUniqueDOMId(),
									rowType: "select",
									investment:
										c.proxyOverwriteType === "PORTFOLIO_MIXED" && c.ticker
											? {
													nofInstruments: portfoliosSummaryMap.get(c.ticker)?.nofInstruments,
													macroAssetClassExposure: portfoliosExposureMap.get(c.ticker)?.investmentComposition,
													macroAssetClassExposureEnhanced: portfoliosExposureMap.get(c.ticker)?.enhancementComposition,
													name: portfoliosSummaryMap.get(c.ticker)?.name,
													lastActionNote: portfoliosSummaryMap.get(c.ticker)?.lastActionNote,
													uuid: portfoliosSummaryMap.get(c.ticker)?.uuid,
													action: portfoliosSummaryMap.get(c.ticker)?.action,
											  }
											: undefined,
								}),
							);

							return { composition, cashTicker: compositionResponse.cashTicker };
						}}
					>
						{({ cashTicker, composition: instruments }) => (
							<div className="flex flex-col h-full">
								<div className="rounded bg-white p-4 flex flex-col">
									<If
										condition={
											IsMinNumberInstrumentReached === false &&
											minNumberOfInstrument > 0 &&
											hasAccess(user, { requiredService: "NUMBER_OF_INSTRUMENTS_CHECK_BYPASS" }) === false
										}
									>
										<Banner severity="info" title="Minimum number of instrument" classList="mb-4">
											You need to add at least {minNumberOfInstrument} instruments or a portfolio
										</Banner>
									</If>
									<InstrumentEditorTable
										instruments={instruments ?? []}
										moneyMarket={cashTicker}
										mode="edit"
										entity={uploadEntity}
										compareSelection={compareSelection}
										onCompare={(selection, action) => {
											selection.forEach((x) => {
												const entry = compareSelection.get(x.id);
												const { investment } = x;
												if (entry === undefined && x.proxyOverwriteType === "PORTFOLIO_MIXED" && investment) {
													setCompareSelection((selectionMap) => selectionMap.set(x.id, investment));
												}

												if (entry && action === "remove") {
													setCompareSelection((selectionMap) => selectionMap.delete(x.id));
												}
											});
										}}
										actionHeader={(headerProps) => (
											<div className="flex space-x-2">
												{(uploadEntity === "INVESTMENT" || uploadEntity === "INVESTMENT_ENHANCEMENT") && (
													<Button
														unstyled
														classList="flex space-x-2 items-center"
														onClick={() => setShowExposureContribution((show) => !show)}
														disabled={havePortfolioCorrectWeight}
													>
														<Icon
															icon={showExposureContribution ? "ask-position-rightsmall" : "ask-positionleftsmall"}
															color={havePortfolioCorrectWeight ? themeCSSVars.palette_N400 : themeCSSVars.palette_P400}
															size={16}
														/>
														<Text
															as="span"
															type="Body/M/Bold"
															color={havePortfolioCorrectWeight ? themeCSSVars.palette_N400 : themeCSSVars.palette_P400}
														>
															{showExposureContribution ? "Hide exposure contribution" : "Show exposure contribution"}
														</Text>
													</Button>
												)}
												<EditorActionHeader {...headerProps} uploadEntity={uploadEntity} uuid={uuid} props={props} />
											</div>
										)}
										uuid={uuid}
										userColumnPreferences={userColumnPreferences}
									>
										{function RenderModalBody({ compositionBuilder, uploadInstruments, instrumentNumber }) {
											const isTotalWeightValid = useMemo(
												() => uploadEntity === "UNIVERSE" || compositionBuilder.getTotalWeight().toNumber() === 100,
												[compositionBuilder, uploadInstruments],
											);

											const cleanedComposition = useMemo(() => {
												const instrumentsNotDeleted = compositionBuilder.getComposition({ excludeDeleted: true });

												return {
													list: instrumentsNotDeleted,
													isValid:
														instrumentsNotDeleted.size > 0 &&
														instrumentsNotDeleted.size <= (instrumentsLimit ?? instrumentsNotDeleted.size),
												};
											}, [compositionBuilder, uploadInstruments]); // composition builder reference inhert to memo dependecy, added  uploadInstruments for memo re-trigger

											const brakeDownWeight = useMemo(() => {
												const deleted = compositionBuilder.getDeleted();
												const composition = uploadInstruments.reduce<{ [key: string]: number }>((acc, el) => {
													const isDeleted = deleted.get(el.id);
													if (isDeleted) {
														return acc;
													}

													if (el.proxyOverwriteType === "PORTFOLIO_MIXED" && el.ticker) {
														return {
															...acc,
															[el.ticker]: compositionBuilder.getWeight(el.id)?.toNumber() ?? 0,
														};
													}

													const instrumentsWeight = acc["INSTRUMENT_WEIGHTS"] ?? 0;
													return {
														...acc,
														INSTRUMENT_WEIGHTS:
															instrumentsWeight + (compositionBuilder.getWeight(el.id)?.toNumber() ?? 0),
													};
												}, {});

												return Object.entries(composition).map(([entityUuid, composedWeight]) => ({
													entityUuid,
													composedWeight,
												}));
											}, [compositionBuilder, uploadInstruments]);

											const currentInstrumentsInList = useMemo(() => {
												const deleted = compositionBuilder.getDeleted();
												return uploadInstruments.filter(({ id }) => deleted.has(id) === false);
											}, [compositionBuilder, uploadInstruments]);

											const somePortfolioIncomposition = useMemo(
												() => currentInstrumentsInList.some((x) => x.proxyOverwriteType === "PORTFOLIO_MIXED"),
												[currentInstrumentsInList],
											);

											useEffect(() => {
												setInvestmentEntry((prev) => {
													if (equal(brakeDownWeight, prev)) {
														return prev;
													}

													return brakeDownWeight;
												});
											}, [brakeDownWeight]);

											const minNumberInstrumentReached = useMemo(
												() => instrumentNumber >= minNumberOfInstrument,
												[instrumentNumber],
											);

											useEffect(() => {
												if (hasAccess(user, { requiredService: "NUMBER_OF_INSTRUMENTS_CHECK_BYPASS" }) === false) {
													setHasComponentReachedLimit((prev) => {
														const limitExceeded =
															cleanedComposition.list.size > (instrumentsLimit ?? cleanedComposition.list.size);
														if (prev === limitExceeded) {
															return prev;
														}

														return limitExceeded;
													});
												}
											}, [cleanedComposition.list.size]);

											useEffect(() => {
												setIsMinNumberInstrumentReached(minNumberInstrumentReached);
											}, [minNumberInstrumentReached]);

											const submitData = useMemo(() => {
												const deleted = compositionBuilder.getDeleted();
												return uploadInstruments
													.filter(({ id }) => deleted.has(id) === false)
													.map(
														(instrument): ReviewTicker =>
															payloadBuilder({
																...instrument,
																weight: compositionBuilder.getWeight(instrument.id)?.toNumber(),
																alias:
																	instrument.rowType === "add"
																		? compositionBuilder.getIdentifier(instrument.id)
																		: instrument.alias,
																tagLabel: compositionBuilder.getTag(instrument.id),
																score: compositionBuilder.getScore(instrument.id)?.toNumber(),
															}),
													);
											}, [compositionBuilder, uploadInstruments]);

											useImperativeHandle(
												ref,
												() => {
													return {
														disabled: !isTotalWeightValid || !cleanedComposition.isValid || !minNumberInstrumentReached,
														onSubmit: async function bubbleSubmit(
															fn: (data: ReviewTicker[]) => Promise<void>,
														): Promise<void> {
															await fn(submitData);
														},
														hasPortfolioIncomposition: somePortfolioIncomposition,
													};
												},
												[
													isTotalWeightValid,
													cleanedComposition.isValid,
													submitData,
													minNumberInstrumentReached,
													somePortfolioIncomposition,
												],
											);

											if (props.uploadEntity === "UNIVERSE") {
												return (
													<>
														<Dialog
															header="Save universe"
															show={props.submitForm?.isOpen ?? false}
															onSubmitAsync={() =>
																onSubmitAsync(compositionBuilder, uploadInstruments, props, somePortfolioIncomposition)
															}
															onClose={props.submitForm?.onCancel}
															footer={() => (
																<DialogFooter
																	primaryAction={<SubmitButton>Save</SubmitButton>}
																	neutralAction={
																		<Button palette="tertiary" onClick={props.submitForm?.onCancel}>
																			Cancel
																		</Button>
																	}
																/>
															)}
														>
															<SubmitForm
																control={control}
																formState={formState}
																checkingNameUniqueness={checkingNameUniqueness}
																hasPortfolioIncomposition={somePortfolioIncomposition}
															/>
														</Dialog>
														<LeavePrompt
															when={compositionBuilder.getComposition().toArray().length > 0 && isSubmitting === false}
															title={t("LEAVE_PAGE")}
															pathToNotBlock={["/login", ...(props.pathToNotBlock ?? [])]}
														>
															{t("PORTFOLIOS.CREATE_LEAVE")}
														</LeavePrompt>
													</>
												);
											}

											return (
												<LeavePrompt
													when={compositionBuilder.getComposition().toArray().length > 0 && isSubmitting === false}
													title={t("LEAVE_PAGE")}
													pathToNotBlock={["/login", ...(props.pathToNotBlock ?? [])]}
												>
													{t("PORTFOLIOS.CREATE_LEAVE")}
												</LeavePrompt>
											);
										}}
									</InstrumentEditorTable>
								</div>
							</div>
						)}
					</ReactQueryWrapper>
				</div>
				<div
					className={toClassName({
						"w-0 scale-90": showExposureContribution === false,
						"w-2/6 scale-100": showExposureContribution,
						"min-h-[700px] bg-white rounded overflow-hidden transition-[width,transform] ": true,
					})}
				>
					<div className="h-full py-4 px-2 flex flex-col">
						<div className="mb-2">
							<Select
								value={exposureCompareCategory}
								onChange={setExposureCompareCategory}
								options={exposureCompareOptions}
							/>
						</div>
						{isFetching ? (
							<IconWalls.Loader />
						) : !exposureCompare ? (
							<IconWalls.DataNotAvailable />
						) : (
							<ExposureSankeyLikeChart classList="h-[600px]" aggregateBy="quality" data={sankeyData} />
						)}
					</div>
				</div>
			</div>
			<CompareOverlay
				show={compareSelection.size > 0}
				onClose={() => setCompareSelection(Map())}
				onRemove={(id) => setCompareSelection((selection) => selection.delete(id))}
				compareData={compareSelection.toArray().map(
					([id, investment]): CompareDataItem => ({
						id,
						composition:
							investment?.macroAssetClassExposure?.map((x) => ({
								quality: x.firstQualityLevel,
								weight: x.weight,
							})) ?? [],
						numberOfInstrument: investment.nofInstruments ?? 0,
						portfolioName: investment.name ?? "-",
						uuid: investment.uuid,
						note: investment.lastActionNote,
						action: investment.action,
					}),
				)}
			/>
		</>
	);
});

export default EditCompositionSection;
