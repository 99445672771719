import type { Languages } from "$root/localization/i18n";
import { createContext, useContext } from "react";
import type { UseTranslationResponse } from "react-i18next";
import { useTranslation } from "react-i18next";

export const ReportTranslationCtx = createContext<Languages | null>(null);

export function useReportTranslation(): { t: UseTranslationResponse<"en", undefined>["t"]; language: Languages } {
	const lng = useContext(ReportTranslationCtx);
	if (!lng) {
		throw new Error("missing report language context");
	}
	return { t: useTranslation(undefined, { lng }).t, language: lng };
}
