import { ActionText, Button, CircularProgressBar, ScrollWrapper, Text } from "@mdotm/mdotui/components";
import { FeedbacksControllerApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { FeedbackSectionIconAlt } from "./icons/FeedbackSectionIconAlt";
import { SmartTextArea } from "./inputs/SmartTextArea";
import { useState } from "react";
import { useAsync } from "@mdotm/mdotui/headless";
import { unpromisify } from "@mdotm/mdotui/utils";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import type { FeedbackFaceType } from "./icons/FeedbackFaces";
import { FeedbackFace, FeedbackFaceTypes } from "./icons/FeedbackFaces";
import { SendIcon } from "./icons/SendIcon";
import { ThankYouIcon } from "./icons/ThankYouIcon";

export function SphereAIFeedbackSection(): JSX.Element {
	const feedbackMap = {
		negative: 1,
		neutral: 3,
		positive: 4,
		veryPositive: 5,
	};
	const feedbacksV2Api = useApiGen(FeedbacksControllerApiFactory);
	const [feedbackText, setFeedbackText] = useState("");
	const [selectedFeedbackFaceType, setSelectedFeedbackFaceType] = useState<FeedbackFaceType | null>(null);
	const [showThankYou, setShowThankYou] = useState(false);
	const { run: doSubmit, loading: sending } = useAsync({
		asyncFn: async () => {
			const currentFeedbackText = feedbackText;
			if (!selectedFeedbackFaceType) {
				console.log(`Missing some feedback parameters (electedFeedbackFaceType:${selectedFeedbackFaceType})`);
				return;
			}
			try {
				setFeedbackText("");
				await feedbacksV2Api.addFeedback({
					rating: feedbackMap[selectedFeedbackFaceType],
					message: feedbackText,
					area: "",
				});
				setShowThankYou(true);
			} catch (err) {
				setFeedbackText(currentFeedbackText);
				throw err;
			}
		},
	});

	return (
		<div className="h-full w-full flex flex-col bg-white">
			<ScrollWrapper classList="flex-1">
				<div className="flex flex-col justify-center items-center mt-2">
					<FeedbackSectionIconAlt />
					<Text as="div" type="Body/L/Bold" classList="mb-0.5">
						Feedback
					</Text>
					<Text as="div" classList="max-w-[200px] text-center" type="Body/M/Book">
						Your input will be invaluable to us in building the future version
					</Text>
				</div>
				<div className="pt-6 pb-4">
					<div className="p-4">
						<div className={`p-4 rounded-lg space-y-2 bg-[${themeCSSVars.palette_graph_B50}]`}>
							{!showThankYou ? (
								<>
									<Text type="Body/L/Book" as="div" classList="mb-2 text-center">
										How interesting and useful do you find this function?
									</Text>
									<div className="flex justify-center">
										<div className="flex items-center">
											{FeedbackFaceTypes.map((type) => (
												<Button key={type} unstyled onClick={() => setSelectedFeedbackFaceType(type)}>
													<FeedbackFace type={type} active={selectedFeedbackFaceType === type} />
												</Button>
											))}
										</div>
									</div>
									<hr />
									<Text as="div" classList="mb-1 text-center" type="Body/L/Book">
										What insights would you like to find?
									</Text>
									<div className="relative z-0 min-w-0 flex-grow">
										<SmartTextArea
											onChangeText={setFeedbackText}
											value={feedbackText}
											onEnter={feedbackText.length === 0 ? undefined : unpromisify(() => doSubmit())}
										/>
										<button
											type="button"
											className="absolute bottom-0 right-2 disabled:cursor-default cursor-pointer z-20"
											disabled={feedbackText.length === 0 || sending}
											onClick={unpromisify(() => doSubmit())}
										>
											{!sending ? (
												<div className="relative bottom-1">
													<SendIcon color={sending ? themeCSSVars.palette_N300 : "#4CB09C"} />
												</div>
											) : (
												<CircularProgressBar classList="w-4 relative top-1" value="indeterminate" />
											)}
										</button>
									</div>
								</>
							) : (
								<div className="flex flex-col items-center">
									<div>
										<ThankYouIcon />
									</div>
									<Text as="div" type="Body/L/Book">
										Thank you for your feedback, it is valuable.
									</Text>
									<ActionText onClick={() => setShowThankYou(false)}>Would you like to express another one?</ActionText>
								</div>
							)}
						</div>
					</div>
				</div>
			</ScrollWrapper>
		</div>
	);
}
