import type { StylableProps } from "@mdotm/mdotui/components";
import { Icon, LocalOverlay } from "@mdotm/mdotui/components";
import { toClassListRecord, toClassName, useEventListener, useUnsafeUpdatedRef } from "@mdotm/mdotui/react-extensions";
import type { ReactNode } from "react";
import { useState } from "react";

export type DropzoneAreaProps = {
	accept?: string;
	disabled?: boolean;
	children: ReactNode;
	iconSize?: number;
	childrenWrapperAppearance?: StylableProps;
} & (
	| {
			onChange(files: FileList | null): void;
			multiple: true;
	  }
	| {
			onChange(file: File | null): void;
			multiple?: false;
	  }
) &
	StylableProps;

function wildcardMatch(pattern: string, value: string) {
	const [patternCategory = "*", patternFormat = "*"] = pattern.split("/");
	const [valueCategory = "*", valueFormat = "*"] = value.split(";")[0].split("/");
	return (
		(patternCategory === "*" || patternCategory === valueCategory) &&
		(patternFormat === "*" || patternFormat === valueFormat)
	);
}

export function DropzoneArea({
	accept = "*/*",
	onChange,
	disabled,
	multiple,
	children,
	iconSize = 24,
	classList,
	style,
	childrenWrapperAppearance,
}: DropzoneAreaProps): JSX.Element {
	const refs = useUnsafeUpdatedRef({ onChange, multiple, accept });

	const [dropzoneIsActive, setDropzoneIsActive] = useState(false);
	const [dropzoneEl, setDropzoneEl] = useState<HTMLDivElement | null>(null);

	useEventListener(
		dropzoneEl,
		"dragleave",
		(e) => {
			e.preventDefault();
			setDropzoneIsActive(false);
		},
		{ disabled: disabled || !dropzoneIsActive },
	);

	const dragStartHandler = (e: DragEvent) => {
		e.preventDefault();
		if (e.dataTransfer) {
			const items = Array.from(e.dataTransfer.items ?? []);
			if (
				(refs.current.multiple &&
					(!refs.current.accept || items.every((item) => wildcardMatch(refs.current.accept, item?.type ?? "")))) ||
				(!refs.current.multiple && (!refs.current.accept || wildcardMatch(refs.current.accept, items[0]?.type ?? "")))
			) {
				setDropzoneIsActive(true);
			}
		}
	};

	useEventListener(dropzoneEl, "dragover", dragStartHandler, { disabled });
	useEventListener(dropzoneEl, "drag", dragStartHandler, { disabled });

	useEventListener(
		dropzoneEl,
		"drop",
		(e) => {
			e.preventDefault();
			setDropzoneIsActive(false);

			if (e.dataTransfer) {
				if (refs.current.multiple) {
					(refs.current.onChange as (f: FileList) => void)(e.dataTransfer.files);
				} else if (e.dataTransfer.files?.[0]) {
					(refs.current.onChange as (f: File) => void)(e.dataTransfer.files[0]);
				}
			}
		},
		{ disabled: disabled || !dropzoneIsActive },
	);

	// As a fallback, in case the backdrop remains open when a dragging operation has been cancelled.
	useEventListener(
		dropzoneEl,
		"click",
		() => {
			setDropzoneIsActive(false);
		},
		{ disabled: disabled || !dropzoneIsActive },
	);

	return (
		<div
			style={style}
			className={toClassName({ "relative z-0": true, ...toClassListRecord(classList) })}
			ref={setDropzoneEl}
		>
			<LocalOverlay show={dropzoneIsActive} style={{ zIndex: 10 }} classList="relative pointer-events-none">
				<div style={{ pointerEvents: "none" }}>
					<Icon icon="Upload" classList="animate-bounce" color="white" size={iconSize} />
				</div>
			</LocalOverlay>
			<div
				style={childrenWrapperAppearance?.style}
				className={toClassName({ "relative z-0": true, ...toClassListRecord(childrenWrapperAppearance?.classList) })}
			>
				{children}
			</div>
		</div>
	);
}
