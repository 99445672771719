import { CustomReportEditorContext, updateCustomReportText } from "$root/pages/Report/context";
import { Text } from "@mdotm/mdotui/components";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import type { SyntheticEvent } from "react";
import { useCallback, useContext } from "react";
import type { PrintableComponent } from "../../configuration";

type EndingPageProps = {
	disclaimer?: string;
	mail?: string;
	address?: string;
	website?: string;
};

const placeholder = {
	disclaimer: `The contents of this document are communicated by, and the property of, MDOTM LTD. MDOTM LTD is an appointed
	representative of Thornbridge Investment Management LLP which is authorised and regulated by the Financial
	Conduct Authority (“FCA”). The information and opinions contained in this document are subject to updating
	and verification and may be subject to amendment. No representation, warranty, or undertaking, express or
	limited, is given as to the accuracy or completeness of the information or opinions contained in this
	document by MDOTM LTD or its directors. No liability is accepted by such persons for the accuracy or
	completeness of any information or opinions. As such, no reliance may be placed for any purpose on the
	information and opinions contained in this document. This document is directed at persons who fall within
	the definition of ‘professional clients’ or ‘eligible counterparties’ as defined in the rules of the
	Financial Conduct Authority (“FCA”) of the United Kingdom. The information contained in this document is
	strictly confidential. The value of investments and any income generated may go down as well as up and is
	not guaranteed. Past performance is not necessarily a guide to future performance.`,
	address:
		"MDOTM Ltd | MDOTM Srl\nLondon\n13 Austin Friars\nEC2N 2HE, London\nUnited Kingdom\nMilan\nVia Passione, 8\n20122 Milan\nItaly",
	mail: "info@mdotm.eu",
	website: "www.mdotm.eu",
} satisfies EndingPageProps;

const palette = {
	mdotm: {
		title: themeCSSVars.palette_P800,
		text: themeCSSVars.palette_N700,
		background: themeCSSVars.palette_N0,
	},
	custom: {
		title: themeCSSVars.palette_P800,
		text: themeCSSVars.palette_N700,
		background: themeCSSVars.palette_N0,
	},
};

const EndingPage: PrintableComponent<EndingPageProps, null> = ({ address, disclaimer, mail, website }) => {
	const reportCtx = useContext(CustomReportEditorContext);
	const editable = Boolean(reportCtx);
	const isCustom = editable || Boolean(address || disclaimer || mail || website);

	const theme = isCustom ? "custom" : "mdotm";

	const handleTextChange = useCallback(
		(name: "disclaimer" | "address" | "email" | "website") => (e: SyntheticEvent) => {
			if (reportCtx && e.currentTarget.textContent !== null) {
				updateCustomReportText(reportCtx.update, name, e.currentTarget.textContent);
			}
		},
		[reportCtx],
	);

	return (
		<div
			className="h-[297mm] px-12 py-16 !box-border"
			style={{
				backgroundColor: palette[theme].background,
			}}
		>
			<div className="flex flex-col justify-between h-full">
				<div>
					<Text as="h3" type="Body/XL/Bold" style={{ color: palette[theme].title }} classList="mb-4">
						Disclaimer
					</Text>
					<Text
						as="p"
						type="Body/M/Book"
						style={{ color: palette[theme].text }}
						contentEditable={editable}
						suppressContentEditableWarning
						onBlur={handleTextChange("disclaimer")}
					>
						{disclaimer || placeholder.disclaimer}
					</Text>
				</div>
				<div>
					<Text as="p" type="Body/XL/Bold" style={{ color: palette[theme].title }} classList="mb-6 xl:mb-6">
						Contacts
					</Text>
					<Text
						as="p"
						type="Body/M/Book"
						style={{ color: palette[theme].text }}
						classList="whitespace-pre-line mb-10 xl:mb-10 font-semibold"
						contentEditable={editable}
						suppressContentEditableWarning
						onBlur={handleTextChange("address")}
					>
						{address || placeholder.address}
					</Text>

					<Text as="div" type="Body/M/Book" classList="mb-1">
						<span
							style={{ color: palette[theme].title }}
							contentEditable={editable}
							suppressContentEditableWarning
							onBlur={handleTextChange("website")}
						>
							{website ?? placeholder.website}
						</span>
					</Text>
					<Text as="div" type="Body/M/Book">
						<span
							style={{ color: palette[theme].title }}
							contentEditable={editable}
							suppressContentEditableWarning
							onBlur={handleTextChange("email")}
						>
							{mail ?? placeholder.mail}
						</span>
					</Text>
				</div>
			</div>
		</div>
	);
};

export default EndingPage;
