import { Svg, type SvgProps } from "@mdotm/mdotui/components";
import { type FC } from "react";

const CreateCustomBenchmarkIcon: FC<Omit<SvgProps, "viewBox" | "children">> = ({
	style = {
		width: 73,
		height: 74,
	},
	...props
}) => {
	return (
		<Svg viewBox={{ width: 73, height: 74 }} style={style} {...props}>
			<g fill="#fff" filter="url(#filter0_d_11371_151378)">
				<path d="M18 33h6v24h-6zM28 41h6v16h-6zM39 28h6v29h-6zM49 28h6v29h-6z" />
				<path d="M58.993 56.735c0-.698-.563-1.264-1.257-1.264h-.916V27.635c0-.701-.546-1.27-1.218-1.27h-5.908c-.673 0-1.218 1.27-1.218 1.27v27.836h-2.528V27.517c0-.698-.564-1.265-1.257-1.265h-6.097c-.694 0-1.257 1.265-1.257 1.265V55.47h-1.681V40.7c0-.698-.564-1.265-1.257-1.265h-6.1c-.695 0-1.258.567-1.258 1.265v14.77h-1.685V32.747c0-.698-.564-1.264-1.257-1.264h-6.094c-.694 0-1.257.566-1.257 1.264v22.725h-1.491c-.694 0-1.257.566-1.257 1.264S14.563 58 15.257 58h42.487c.694 0 1.257-.567 1.257-1.265zm-36.148-1.264h-3.584V34.01h3.584zm10.3 0h-3.588V41.967h3.587zm10.294 0h-3.583V28.784h3.583zm7.607-26.575h3.584v26.575h-3.584z" />
			</g>
			<path
				stroke="#C3C9D5"
				strokeLinecap="round"
				strokeWidth="2"
				d="M57.993 56.735v.066a.26.26 0 0 1-.25.199H15.258a.26.26 0 0 1-.257-.265c0-.151.121-.264.257-.264h2.49V32.746c0-.151.122-.264.258-.264h6.094c.135 0 .257.113.257.264v23.725h3.685V40.7c0-.152.122-.265.257-.265h6.1c.136 0 .258.113.258.265v15.77h3.681V27.756a3 3 0 0 1 .18-.287c.074-.102.139-.174.187-.216h5.987c.135 0 .257.113.257.265V56.47h4.528V27.865c.047-.088.108-.194.178-.295q.1-.142.168-.205h5.78c.08 0 .218.081.218.27v28.836h1.916c.136 0 .257.113.257.264ZM38.746 27.22l-.004.003zm11.125.105-.005.003zM22.845 56.47h1V33.01h-5.584v23.46h4.584Zm10.3 0h1V40.967h-5.588v15.504h4.587Zm10.294 0h1V27.784h-5.583v28.687h4.583Zm7.607-28.575h-1v28.575h5.584V27.896h-4.584Z"
			/>
			<path
				stroke="#4CB09C"
				strokeWidth="2"
				d="M48.354 9.563h.71l.234-.67A2.81 2.81 0 0 1 51.945 7c1.539 0 2.803 1.26 2.803 2.826s-1.267 2.83-2.803 2.83a2.815 2.815 0 0 1-2.647-1.895l-.234-.67h-4.537l-.235.67a2.81 2.81 0 0 1-2.646 1.896c-.122 0-.236-.009-.35-.025l-.712-.099-.321.642-6.97 13.931-.302.606.45.506c.445.501.717 1.16.717 1.88 0 1.56-1.266 2.826-2.808 2.826-1.54 0-2.803-1.264-2.803-2.827q0-.368.089-.71l.173-.684-.585-.392-4.322-2.904-.593-.399-.57.433c-.47.358-1.054.569-1.684.569-1.538 0-2.803-1.262-2.803-2.827h-.004c.005-1.563 1.27-2.823 2.803-2.823 1.537 0 2.804 1.264 2.804 2.83q-.001.365-.088.702l-.176.684.587.394 4.326 2.904.593.398.57-.432a2.76 2.76 0 0 1 2.143-.525l.737.133.335-.67 6.925-13.844.315-.63-.487-.509a2.84 2.84 0 0 1-.792-1.968C38.843 8.265 40.108 7 41.646 7c1.212 0 2.26.787 2.647 1.893l.234.67h3.827ZM18.761 23.19a2.295 2.295 0 0 0 2.29 2.298c1.27 0 2.29-1.034 2.29-2.298a2.3 2.3 0 0 0-2.29-2.303 2.3 2.3 0 0 0-2.29 2.303Zm10.295 6.914a2.295 2.295 0 0 0 2.29 2.298 2.298 2.298 0 0 0 0-4.596 2.295 2.295 0 0 0-2.29 2.298Zm10.3-20.274a2.3 2.3 0 0 0 2.29 2.302 2.3 2.3 0 0 0 2.29-2.302 2.295 2.295 0 0 0-2.29-2.299 2.295 2.295 0 0 0-2.29 2.299Zm10.295 0a2.3 2.3 0 0 0 2.29 2.302 2.3 2.3 0 0 0 2.29-2.302 2.295 2.295 0 0 0-2.29-2.299 2.295 2.295 0 0 0-2.29 2.299Z"
			/>
			<path
				fill="#C3C9D5"
				d="M5.43 5.702A1.332 1.332 0 0 1 7.403 4.23a1.33 1.33 0 0 1 .455 1.821 1.328 1.328 0 0 1-2.428-.348"
			/>
			<path
				fill="#C3C9D5"
				fillRule="evenodd"
				d="M11 13v-1a1 1 0 1 1 2 0v1h1a1 1 0 1 1 0 2h-1v1a1 1 0 1 1-2 0v-1h-1a1 1 0 1 1 0-2h1M6 51v-1a1 1 0 1 1 2 0v1h1a1 1 0 1 1 0 2H8v1a1 1 0 1 1-2 0v-1H5a1 1 0 1 1 0-2h1M63 19v-1a1 1 0 1 1 2 0v1h1a1 1 0 1 1 0 2h-1v1a1 1 0 1 1-2 0v-1h-1a1 1 0 1 1 0-2h1"
				clipRule="evenodd"
			/>
			<defs>
				<filter
					id="filter0_d_11371_151378"
					width="73.001"
					height="59.748"
					x="0"
					y="14.252"
					colorInterpolationFilters="sRGB"
					filterUnits="userSpaceOnUse"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
					<feOffset dy="2" />
					<feGaussianBlur stdDeviation="7" />
					<feColorMatrix values="0 0 0 0 0.184314 0 0 0 0 0.207843 0 0 0 0 0.254902 0 0 0 0.2 0" />
					<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_11371_151378" />
					<feBlend in="SourceGraphic" in2="effect1_dropShadow_11371_151378" result="shape" />
				</filter>
			</defs>
		</Svg>
	);
};

export default CreateCustomBenchmarkIcon;
