import type { InvestmentListEntry, MarketViewListEntry, ReferenceUniverseListEntry } from "$root/api/api-gen";
import {
	BenchmarksControllerApiFactory,
	InvestmentControllerV4ApiFactory,
	MarketViewControllerApiFactory,
	ReferenceUniversesControllerApiFactory,
} from "$root/api/api-gen";
import { getApiGen } from "$root/api/factory";
import { parallelize } from "$root/utils/promise";
import { match } from "ts-pattern";
import type { UnifiedMockedReferenceProps } from "../ReferenceList";
import { platformToast } from "$root/notification-system/toast";
import { PortfolioStudioTab } from "..";

type MultipleEntityDelte =
	| {
			area: "Portfolios";
			items: Array<InvestmentListEntry>;
	  }
	| {
			area: "References";
			items: Array<UnifiedMockedReferenceProps>;
	  }
	| {
			area: "Universes";
			items: Array<ReferenceUniverseListEntry>;
	  }
	| {
			area: "MarketViews";
			items: Array<MarketViewListEntry>;
	  };

const getDeleteApiByArea = () => ({
	Portfolios: getApiGen(InvestmentControllerV4ApiFactory).deleteInvestment,
	Universes: getApiGen(ReferenceUniversesControllerApiFactory).deleteUniverse,
	MarketViews: getApiGen(MarketViewControllerApiFactory).deleteMarketView,
	References: {
		References: getApiGen(InvestmentControllerV4ApiFactory).deleteInvestment,
		Benchmark: getApiGen(BenchmarksControllerApiFactory).deleteBenchmark,
	},
});

function errorToast(message: string) {
	platformToast({
		children: message,
		severity: "error",
		icon: "Portfolio",
	});
}
type ResponseStacK = { name?: string };

export function notifyUser(params: {
	succeded: Array<ResponseStacK>;
	area: PortfolioStudioTab;
	t: (...params: any) => string;
}): void {
	if (params.succeded.length > 1) {
		const translationKey = match(params)
			.with({ area: "Portfolios" }, () => "PUSH_NOTIFICATION.MULTI_PORTFOLIO_DELETED_TITLE_SUCCESS")
			.with({ area: "References" }, () => "PUSH_NOTIFICATION.MULTI_REFERENCES_DELETED_TITLE_SUCCESS")
			.with({ area: "Universes" }, () => "PUSH_NOTIFICATION.MULTI_UNIVERSE_DELETED_TITLE_SUCCESS")
			.with({ area: "MarketViews" }, () => "PUSH_NOTIFICATION.MULTI_MARKET_DELETED_TITLE_SUCCESS")
			.exhaustive();

		platformToast({
			children: params.t(translationKey, { count: params.succeded.length }),
			severity: "success",
			icon: params.area === "MarketViews" ? "News-category-Market-view-aligment" : "Portfolio",
		});
	}

	if (params.succeded.length === 1) {
		const deletedItem = params.succeded[0];

		const feedback = match(params)
			.with({ area: "Portfolios" }, () => `Successfully deleted your portfolio “${deletedItem.name ?? "-"}”`)
			.with({ area: "References" }, () => `Successfully deleted your reference “${deletedItem.name ?? "-"}”`)
			.with({ area: "Universes" }, () => `Successfully deleted your universe “${deletedItem.name ?? "-"}”`)
			.with({ area: "MarketViews" }, () => `Successfully deleted your market view “${deletedItem.name ?? "-"}”`)
			.exhaustive();

		platformToast({
			children: feedback,
			severity: "success",
			icon: params.area === "MarketViews" ? "News-category-Market-view-aligment" : "Portfolio",
		});
	}
}

export async function multipleEntityDelete(params: MultipleEntityDelte): Promise<{
	succeded: ResponseStacK[];
	failed: ResponseStacK[];
}> {
	const succeded: Array<ResponseStacK> = [];
	const failed: Array<ResponseStacK> = [];
	const deleteApiByArea = getDeleteApiByArea();
	const stack = match(params)
		.with({ area: "Portfolios" }, ({ items, area }) => {
			return items.map(
				({ uuid, name }) =>
					() =>
						deleteApiByArea[area](uuid!)
							.then(() => succeded.push({ name }))
							.catch(() => {
								errorToast(`Failed to delete your portfolio “${name ?? "-"}”`);
								failed.push({ name });
							}),
			);
		})
		.with({ area: "References" }, ({ area, items }) => {
			return items.map(
				({ name, identifier, type }) =>
					() =>
						deleteApiByArea[area][type](identifier!)
							.then(() => succeded.push({ name }))
							.catch(() => {
								errorToast(
									type === "Benchmark"
										? `Failed to delete your custom benchmark “${name ?? "-"}”`
										: `Failed to delete your portfolio target “${name ?? "-"}”`,
								);
								failed.push({ name });
							}),
			);
		})
		.with({ area: "MarketViews" }, ({ area, items }) => {
			return items.map(
				({ uuid, name }) =>
					() =>
						deleteApiByArea[area](uuid!)
							.then(() => succeded.push({ name }))
							.catch(() => {
								errorToast(`Failed to delete your market view “${name ?? "-"}”`);
								failed.push({ name });
							}),
			);
		})
		.with({ area: "Universes" }, ({ area, items }) => {
			return items.map(
				({ uuid, name }) =>
					() =>
						deleteApiByArea[area](uuid!)
							.then(() => succeded.push({ name }))
							.catch(() => {
								errorToast(`Failed to delete your universe “${name ?? "-"}”`);
								failed.push({ name });
							}),
			);
		})
		.exhaustive();

	await parallelize(stack, { concurrency: 32 });
	return { succeded, failed };
}
