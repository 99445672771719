import type { NullableNumberInputProps } from "@mdotm/mdotui/components";
import { Icon, NullableNumberInput } from "@mdotm/mdotui/components";

export type PercentageInputProps = NullableNumberInputProps;

// TODO(refactor): wherever possible, use this instead of the more generic NullableNumberInput
// as this PercentageInput already provides common properties.
export function PercentageInput(props: PercentageInputProps): JSX.Element {
	return (
		<NullableNumberInput
			placeholder="0"
			rightContent={<Icon icon="Percentile" />}
			min={0}
			max={100}
			step={0.01}
			{...props}
		/>
	);
}
