import type { InvestmentCommentaryResponseStatusEnum, InvestmentSummary } from "$root/api/api-gen";
import {
	CommentaryTemplateControllerApiFactory,
	InvestmentControllerV4ApiFactory,
	InvestmentEnhancementControllerV4ApiFactory,
	InvestmentEnhancementReportsControllerApiFactory,
	InvestmentReportsControllerApiFactory,
} from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import AuthorizationGuard from "$root/components/AuthorizationGuard";
import { ButtonWithSelect } from "$root/components/buttons/ButtonWithSelect";
import FakeAiLoader from "$root/components/FakeAiLoader";
import {
	animationProgressState,
	getAnimationProgressById,
	simulateAnimationProgress,
} from "$root/components/FakeAiLoader/atom";
import type { MarkdownRendererProps } from "$root/components/MarkdownRenderer/MarkdownRenderer";
import { MarkdownRenderer } from "$root/components/MarkdownRenderer/MarkdownRenderer";
import { typedUrlForRoute, useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import { useEventBus, waitForEvent } from "$root/event-bus";
import { aclByArea } from "$root/functional-areas/acl/checkers/all";
import { PortfolioStudioSettingTabEnum } from "$root/functional-areas/portfolio-studio-settings";
import { useLocaleFormatters } from "$root/localization/hooks";
import { TransientNotification } from "$root/notification-system/transient";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { objMatchFn } from "$root/utils/objects";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { Card } from "$root/widgets-architecture/layout/Card";
import type { Option } from "@mdotm/mdotui/components";
import { ActionText, Button, CircularProgressBar, Icon, Text } from "@mdotm/mdotui/components";
import { AbortError } from "@mdotm/mdotui/headless";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { noop, unpromisify } from "@mdotm/mdotui/utils";
import EventEmitter from "eventemitter3";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { PortfolioQueryWidgetBase, WidgetStatus } from "./PortfolioWidgetStatus";
import { diffrenceISODate } from "$root/utils/dates";
import { START_DATE_CREATE_PROPOSAL_COMMENTARY } from "$root/utils/const";
import { IconWalls } from "$root/components/IconWall";
import { platformToast } from "$root/notification-system/toast";

export type PortfolioCommentaryProps = {
	portfolio: InvestmentSummary;
	enhanced: boolean;
	reportExcutionCounter: number;
};

export function PortfolioCommentary({
	portfolio,
	enhanced,
	reportExcutionCounter,
}: PortfolioCommentaryProps): JSX.Element {
	const { lastActionDate } = portfolio ?? {};
	const { formatDateTime } = useLocaleFormatters();
	//animation atom progress
	const commentaryGenerationProgress = getAnimationProgressById(portfolio?.uuid ?? "-");
	const { setAnimationProgress } = animationProgressState();

	const [commentaryGenerationAbortController, setCommentaryGenerationAbortController] = useState(new AbortController());
	const [commentaryGenerationDoneEmitter, setCommentaryGenerationDoneEmitter] = useState(new EventEmitter<"done">());

	const [commentaryGenerationStatus, setCommentaryGenerationStatus] =
		useState<null | InvestmentCommentaryResponseStatusEnum>(null);

	const resetEvents = useCallback(() => {
		setCommentaryGenerationDoneEmitter(new EventEmitter<"done">());
		setCommentaryGenerationAbortController(new AbortController());
	}, []);

	const thinkingBoxAnimation = useCallback(async () => {
		commentaryGenerationAbortController.abort(new AbortError("done"));
		let cleanup = noop;
		await new Promise((resolve) => {
			commentaryGenerationDoneEmitter.addListener("done", resolve);
			cleanup = () => commentaryGenerationDoneEmitter.removeListener("done", resolve);
		});
		cleanup();
	}, [commentaryGenerationAbortController, commentaryGenerationDoneEmitter]);

	const enhanceInvestmentReportApi = useApiGen(InvestmentEnhancementReportsControllerApiFactory);
	const investmentReportApi = useApiGen(InvestmentReportsControllerApiFactory);
	const investmentV4Api = useApiGen(InvestmentControllerV4ApiFactory);
	const investmentEnhancementV4Api = useApiGen(InvestmentEnhancementControllerV4ApiFactory);
	const commentaryTemplateApi = useApiGen(CommentaryTemplateControllerApiFactory);

	const commentaryQuery = useQueryNoRefetch(["portfolioCommentaryPage", portfolio, enhanced, reportExcutionCounter], {
		enabled: Boolean(portfolio),
		queryFn: async () => {
			const commentary = await axiosExtract(
				enhanced
					? enhanceInvestmentReportApi.getCommentaries1(portfolio?.uuid ?? "")
					: investmentReportApi.getCommentaries(portfolio?.uuid ?? ""),
			);

			if (commentary.status === "CALCULATING" && !commentary.commentary) {
				return {
					data: undefined,
					widgetStatus: WidgetStatus.CALCULATING as const,
				};
			}
			const templates = await axiosExtract(commentaryTemplateApi.getTemplateList());
			if (!commentary.commentary) {
				return {
					data: { ...commentary, templates },
					widgetStatus: WidgetStatus.EMPTY as const,
				};
			}

			return {
				data: { ...commentary, templates },
				widgetStatus: WidgetStatus.READY as const,
			};
		},
		onError: (e) => console.warn(e),
	});

	const { data: rq } = commentaryQuery;
	const { data: commentaryResponse } = rq ?? {};

	const options = useMemo(
		(): Array<Option<string>> =>
			commentaryResponse?.templates?.flatMap((x) =>
				x.name && x.uuid && x.visible ? [{ label: x.name, value: x.uuid }] : [],
			) ?? [],
		[commentaryResponse?.templates],
	);

	// const selectedTemplate = useMemo(
	// 	() => options.find((x) => x.value === commentaryResponse?.template?.uuid),
	// 	[options, commentaryResponse?.template?.uuid],
	// );

	const timeoutIdRef = useRef<ReturnType<typeof setTimeout> | null>(null);

	useEventBus("commentary-update", {
		filter: objMatchFn({ uuid: portfolio?.uuid }),
		listener: (x) => {
			timeoutIdRef.current = setTimeout(
				unpromisify(async () => {
					if (commentaryGenerationStatus === "CALCULATING") {
						await thinkingBoxAnimation();
						setCommentaryGenerationStatus("COMPLETED");
						resetEvents();
					}

					if (commentaryGenerationProgress) {
						setAnimationProgress((animations) => animations.filter((animation) => animation.id !== portfolio?.uuid));
					}
					await commentaryQuery.refetch();
				}),
				3000,
			);
		},
	});

	useEffect(() => {
		const uuid = portfolio?.uuid;
		if (commentaryGenerationProgress !== undefined && uuid && commentaryQuery.data) {
			if (commentaryResponse?.status && commentaryResponse.status !== "CALCULATING") {
				setAnimationProgress((animations) => animations.filter((animation) => animation.id !== uuid));

				return;
			}

			if (commentaryResponse?.status === "CALCULATING") {
				unpromisify(async () => {
					try {
						setCommentaryGenerationStatus("CALCULATING");
						await waitForEvent("commentary-update", {
							filter: objMatchFn({ uuid }),
							signal: AbortSignal.timeout(60000),
						});
					} catch (error) {
						await thinkingBoxAnimation();
						const { data } = await commentaryQuery.refetch();
						if (data?.data?.status === "ERROR") {
							setCommentaryGenerationStatus("ERROR");
						} else {
							setCommentaryGenerationStatus(null);
						}
						resetEvents();
						throw error;
					} finally {
						setAnimationProgress((animations) => animations.filter((animation) => animation.id !== uuid));
					}
				})();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [commentaryResponse?.status]);

	useEffect(
		() => () => {
			if (timeoutIdRef.current !== null) {
				clearTimeout(timeoutIdRef.current);
			}
		},
		[],
	);

	const generateCommentary = useCallback(
		async (templateUuid: string) => {
			try {
				setCommentaryGenerationStatus("CALCULATING");
				let sanitaizedTemplateUuid = templateUuid;
				const templates = commentaryResponse?.templates;
				if (templates && !templates.find((x) => x.uuid === templateUuid)) {
					sanitaizedTemplateUuid = templates.find((x) => x.name === "Standard Template")?.uuid ?? templateUuid;
				}

				if (enhanced) {
					investmentEnhancementV4Api
						.createCommentaryForEnhancementWithTemplate(portfolio!.uuid!, sanitaizedTemplateUuid)
						.catch(noop);
				} else {
					investmentV4Api.createCommentaryFromTemplateUUID(portfolio.uuid!, sanitaizedTemplateUuid).catch(noop);
				}

				await waitForEvent("commentary-update", {
					filter: objMatchFn({ uuid: portfolio.uuid ?? "" }),
					signal: AbortSignal.timeout(60000),
				});
			} catch (error) {
				if (commentaryGenerationAbortController.signal.aborted) {
					resetEvents();
				} else {
					await thinkingBoxAnimation();
				}
				const { data } = await commentaryQuery.refetch();
				if (data?.data?.status === "ERROR") {
					setCommentaryGenerationStatus("ERROR");
				}
				throw error;
			}
		},
		[
			commentaryGenerationAbortController.signal.aborted,
			commentaryQuery,
			commentaryResponse?.templates,
			enhanced,
			investmentEnhancementV4Api,
			investmentV4Api,
			portfolio,
			resetEvents,
			thinkingBoxAnimation,
		],
	);

	const canUserGenerateNewCommentary = useMemo(() => {
		if (enhanced) {
			return diffrenceISODate(portfolio?.lastActionDate, START_DATE_CREATE_PROPOSAL_COMMENTARY) > 0;
		}

		return true;
	}, [enhanced, portfolio?.lastActionDate]);

	const generateCommentaryAction = useMemo(() => {
		if (commentaryResponse && portfolio) {
			return (
				<AuthorizationGuard
					permissionChecker={aclByArea.portfolio.canEditComposition}
					acl={portfolio?.richAcl?.acl ?? []}
				>
					{canUserGenerateNewCommentary && (
						<ButtonWithSelect
							options={options}
							value={commentaryResponse?.template?.uuid}
							disabled={
								portfolio?.status === "CALCULATING" ||
								portfolio?.status === "ERROR" ||
								commentaryResponse.status === "CALCULATING" ||
								commentaryGenerationStatus === "CALCULATING"
							}
							palette="secondary"
							size="small"
							onClick={generateCommentary}
							enableSearch
						>
							Generate
						</ButtonWithSelect>
					)}
				</AuthorizationGuard>
			);
		} else {
			return <></>;
		}
	}, [
		canUserGenerateNewCommentary,
		commentaryGenerationStatus,
		commentaryResponse,
		generateCommentary,
		options,
		portfolio,
	]);

	const defaultFakeLoader = useMemo(
		() =>
			commentaryGenerationProgress
				? simulateAnimationProgress(
						commentaryGenerationProgress.date,
						commentaryGenerationProgress.progress,
						commentaryGenerationProgress.step,
						commentaryGenerationProgress.step !== "preparing" ? 0.175 : 0.02,
				  )
				: undefined,
		[commentaryGenerationProgress],
	);

	const reportUrl = useMemo(
		() =>
			commentaryQuery.data?.data?.template?.uuid && portfolio.uuid
				? typedUrlForRoute("Report", {
						templateId: "storyfolio",
						objectId: portfolio.uuid,
						enhanced: enhanced ? "true" : undefined,
				  })
				: undefined,
		[portfolio.uuid, commentaryQuery.data?.data?.template?.uuid, enhanced],
	);

	const { push } = useTypedNavigation();

	return (
		<>
			<TransientNotification
				variant="warning"
				dismissible={false}
				location="in-page"
				autoHide={null}
				// TODO: based on API response
				trigger={false}
			>
				Some of the descriptions for the instruments contained in this portfolio are expired.{" "}
				<Text color="inherit" type="Body/M/Bold">
					Customize your
				</Text>{" "}
				<ActionText
					color="inherit"
					type="Body/M/Bold"
					href={typedUrlForRoute("PortfolioStudioSettings", {
						tab: PortfolioStudioSettingTabEnum.InstrumentsCustomisation,
					})}
				>
					instrument descriptions
				</ActionText>
			</TransientNotification>
			<Card removeDefaultPaddings classList="min-h-[calc(100dvh_-_340px)]">
				{commentaryGenerationStatus === "CALCULATING" && (
					<FakeAiLoader
						signal={commentaryGenerationAbortController.signal}
						onDone={() => commentaryGenerationDoneEmitter.emit("done")}
						defaultValue={defaultFakeLoader}
						persist={(progress, step) => {
							const uuid = portfolio?.uuid;
							if (uuid) {
								setAnimationProgress((prevAnimationProgress) => {
									const indexOfAnimation = prevAnimationProgress.findIndex((animation) => animation.id === uuid);

									if (indexOfAnimation > -1) {
										prevAnimationProgress[indexOfAnimation].progress = progress;
										prevAnimationProgress[indexOfAnimation].step = step;
										prevAnimationProgress[indexOfAnimation].date = new Date();
										return prevAnimationProgress;
									}

									return [...prevAnimationProgress, { id: uuid, progress, step, date: new Date() }];
								});
							}
						}}
					/>
				)}
				<PortfolioQueryWidgetBase
					query={commentaryQuery}
					errorFallback="Generative AI commentary is not available at the moment. It will be generated soon!"
					iconWalls={{
						empty: IconWalls.PortfolioCommentaryEmpty({
							onGenerate: async (templateId) => {
								if (templateId) {
									await generateCommentary(templateId);
									return;
								}

								platformToast({
									children: "Unable to generate portfolio commentary",
									severity: "error",
									icon: "Ask-ai",
								});
							},
							generateOption: options,
							defaultValue: commentaryQuery.data?.data?.template?.uuid,
						}),
					}}
				>
					{({ commentary }, { isFetching }) => (
						<>
							<div
								className="flex py-2 px-4 border-b justify-between"
								style={{ borderColor: themeCSSVars.palette_N200 }}
							>
								<div className="flex items-center">
									<Text type="Body/M/Bold" color={themeCSSVars.palette_N500}>
										Template: {commentaryResponse?.template?.name}
									</Text>
								</div>
								<div className="flex gap-2 items-center">
									<Button
										size="small"
										palette="tertiary"
										onClick={() =>
											push("Storyfolio/Details", {
												uuid:
													options.find((x) => x.value === commentaryResponse?.template?.uuid)?.value ??
													options.find((x) => x.label === "Standard Template")?.value ??
													commentaryResponse?.template?.uuid ??
													"",
											})
										}
									>
										Edit template
									</Button>
									{generateCommentaryAction}
								</div>
							</div>

							<div className="flex flex-col items-center mt-7 pb-4">
								<div className="flex flex-col max-w-[946px]">
									<div className="flex justify-end">
										<div className="flex gap-4">
											{commentaryResponse?.commentaryDate && (
												<div className="flex space-x-1 items-center">
													<Icon icon="Clock" color={themeCSSVars.palette_N300} size={16} />
													<Text type="Body/S/Book" as="p">
														Generated on&nbsp;
														<span>{formatDateTime(commentaryResponse?.commentaryDate)}</span>
													</Text>
												</div>
											)}
										</div>
									</div>

									<div className="px-4">
										{isFetching ? (
											<div className="h-80 flex">
												<CircularProgressBar value="indeterminate" classList="m-auto" />
											</div>
										) : (
											<MarkdownRenderer componentOverrides={markdownOverrides}>{commentary ?? ""}</MarkdownRenderer>
										)}
									</div>
								</div>
							</div>
						</>
					)}
				</PortfolioQueryWidgetBase>
			</Card>
		</>
	);
}

const markdownOverrides: MarkdownRendererProps["componentOverrides"] = {
	h1: ({ node: _node, ...props }) => (
		<h1 className="text-[18px] font-bold pb-2 pt-2 " {...props}>
			{props.children}
		</h1>
	),
	h2: ({ node: _node, ...props }) => (
		<h2 className="text-[18px] font-bold pb-2 pt-2" {...props}>
			{props.children}
		</h2>
	),
	h3: ({ node: _node, ...props }) => (
		<h3 className="text-[16px] font-bold pb-2 pt-2" {...props}>
			{props.children}
		</h3>
	),
	ul: ({ node: _node, ...props }) => <ul className="list-disc pl-4 text-[16px]" {...props} />,
	ol: ({ node: _node, ...props }) => <ul className="list-decimal pl-4 text-[16px]" {...props} />,
	li: ({ node: _node, ...props }) => <li className="text-[16px]" {...props} />,
	p: ({ node: _node, ...props }) => <div className="text text-[16px] pb-2" {...props} />,
	blockquote: ({ node: _node, ...props }) => (
		<blockquote className="bg-sky-100 px-2 py-1 rounded text-[16px]" {...props} />
	),
	table: ({ node: _node, ...props }) => <table className="w-full border-collapse" {...props} />,
	thead: ({ node: _node, ...props }) => <thead {...props} />,
	tr: ({ node: _node, ...props }) => (
		<tr className={`even:bg-[#F7F8F9] border-b border-b-[color:${themeCSSVars.palette_N100}]`} {...props} />
	),
	td: ({ node: _node, ...props }) => <td className="text-left p-4 first:w-2/4" {...props} />,
	th: ({ node: _node, ...props }) => (
		<th className="text-left px-4 py-2 !font-bold !text-[12px] !uppercase text-[#667085]" {...props} />
	),
	tbody: ({ node: _node, ...props }) => <tbody {...props} />,
	code: ({ node: _node, ...props }) => <span className="text-[16px]" {...props} />,
	pre: ({ node: _node, ...props }) => <i className="text-[16px]" {...props} />,
};
