import Composition from "../components/portfolio/Composition";
import { builtInSortFnFor } from "@mdotm/mdotui/utils";
import { match } from "ts-pattern";
import type { ComponentAndPropsPair } from ".";
import { type ComponentAndPropsPairPartial, type PrintableComponent } from ".";
import OutlookTables, { getOutlookTableProps } from "../components/market/OutlookTables";
import Box from "../components/mock/Box";
import AssetClassOverview from "../components/portfolio/AssetClassOverview";
import Summary from "../components/portfolio/Summary";
import type { CustomReportDataUnion, ReportProps, UnionMapData } from "./hooks/useExtractReports";
import ExposureMixed, {
	exposureMixedChartHeight,
	exposureMixedEntriesToSplittableProps,
} from "../components/portfolio/ExposureMixed";
import {
	chunkExposureListItems,
	Exposure,
	exposureChartHeight,
	exposureEntriesToSplittableProps,
} from "$root/functional-areas/reports/components/Exposure";
import Commentary, { getCommentaryProps } from "$root/functional-areas/reports/components/Commentary";
import StartingPage from "../components/pdf/StartingPage";
import EndingPage from "../components/pdf/EndingPage";

export type UnionMapDataKeys = keyof UnionMapData;
function jsxHelper<
	T extends [ComponentAndPropsPairPartial<object, unknown>, ...ComponentAndPropsPairPartial<object, unknown>[]],
>(params: {
	[K in keyof T]: T[K] & {
		component: T[K] extends ComponentAndPropsPairPartial<infer TCommon, infer TSplittable>
			? PrintableComponent<TCommon, TSplittable>
			: never;
	};
}): T {
	return params.map((item) => ({
		...item,
		component: (props: any) => {
			const C = item.component;
			return <C {...props} />;
		},
	})) as unknown as T;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const templateMaps = (props: CustomReportDataUnion & { layout: "portrait" | "landscape" }) => {
	const withCoverAndDisclaimer = (
		report: ReportProps,
		componentAndPropsList: Array<ComponentAndPropsPair<any, any>>,
	) => {
		return jsxHelper([
			{
				component: StartingPage,
				commonProps: {
					customData: report,
					title: report.title,
					subtitle: report.subtitle,
				},
				splittableProps: [],
				hideHeader: true,
				hideFooter: true,
			},
			...componentAndPropsList,
			{
				component: EndingPage,
				commonProps: {
					address: report.address,
					mail: report.email,
					disclaimer: report.disclaimer,
					website: report.website,
				},
				splittableProps: [],
				startsFromBlankPage: true,
				hideHeader: true,
				hideFooter: true,
			},
		]);
	};
	return match(props)
		.with({ template: "portfolio-reference" }, ({ payload: data }) => {
			const componentAndPropsList = jsxHelper([
				{
					component: Summary,
					commonProps: { data },
					splittableProps: [],
				},

				{
					component: Composition,
					commonProps: { data },
					splittableProps: data.investmentComposition.composition?.sort(builtInSortFnFor("weight")).reverse() ?? [],
				},
				{
					component: Exposure,
					commonProps: {
						comparison: "MACRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY" as const,
						composition: data.exposure.macroVsGeo.investmentComposition ?? [],
					},
					splittableProps: chunkExposureListItems(
						exposureEntriesToSplittableProps(data.exposure.macroVsGeo.investmentComposition ?? []),
						exposureChartHeight,
					),
					startsFromBlankPage: !!data.exposure.macroVsGeo.investmentComposition?.length,
				},
				{
					component: Exposure,
					commonProps: {
						comparison: "MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS" as const,
						composition: data.exposure.macroVsMicro.investmentComposition ?? [],
					},
					splittableProps: chunkExposureListItems(
						exposureEntriesToSplittableProps(data.exposure.macroVsGeo.investmentComposition ?? []),
						exposureChartHeight,
					),
					startsFromBlankPage: !!data.exposure.macroVsMicro.investmentComposition?.length,
				},
			]);

			return { componentAndPropsList: withCoverAndDisclaimer(data.report, componentAndPropsList) };
		})
		.with({ template: "custom-report" }, ({ payload: data }) => {
			const componentAndPropsList = jsxHelper([
				{
					component: AssetClassOverview,
					commonProps: { data },
					splittableProps: [],
				},
				{
					component: OutlookTables,
					commonProps: { data, type: "doubleColumns" as const },
					splittableProps: getOutlookTableProps(data),
				},
			]);

			return { componentAndPropsList: withCoverAndDisclaimer(data.report, componentAndPropsList) };
		})
		.with({ template: "mixed-portfolio" }, ({ payload: data }) => {
			const componentAndPropsList = data.sections.flatMap((section) => {
				const all = exposureMixedEntriesToSplittableProps(section.exposureCompare.portfolioComposition ?? []);
				return jsxHelper([
					{
						component: ExposureMixed,
						commonProps: { data: section },
						splittableProps: chunkExposureListItems(all, section.supportsChart ? exposureMixedChartHeight : 0),
					},
				]);
			});
			return { componentAndPropsList: withCoverAndDisclaimer(data.report, componentAndPropsList) };
		})
		.with({ template: "mocked" }, ({ payload: data }) => {
			const componentAndPropsList = jsxHelper([
				{
					component: Box,
					commonProps: { data },
					splittableProps: data.list,
				},
			]);

			return { componentAndPropsList: withCoverAndDisclaimer(data.report, componentAndPropsList) };
		})
		.with({ template: "storyfolio" }, ({ payload: data }) => {
			const componentAndPropsList = jsxHelper([
				{
					component: Commentary,
					commonProps: {
						commentary: data.commentary,
						config: {
							detailed: true,
							summary: true,
						},
					},
					splittableProps: getCommentaryProps({
						...data.commentary,
					}),
				},
			]);

			return { componentAndPropsList: withCoverAndDisclaimer(data.report, componentAndPropsList) };
		})
		.exhaustive() as unknown as {
		componentAndPropsList: [ComponentAndPropsPair<object, unknown>, ...ComponentAndPropsPair<object, unknown>[]];
	}; //TODO : why does the return doesn't match
};

// satisfies { [K in keyof UnionMapData]: (props: CustomReportBodyData<K>) => unknown };
