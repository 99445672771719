const cacheByKey = new Map<string, { expiresAt: number; content: unknown }>();

export function withCache<T, TParams extends [unknown, ...unknown[]] | [] = []>(
	fn: (...params: TParams) => Promise<T>,
	opts: { cacheTime?: number; cacheKey: (...params: TParams) => string },
): (...params: TParams) => Promise<T> {
	return async (...params) => {
		const cacheKey = opts.cacheKey(...params);
		const cache = cacheByKey.get(cacheKey) as { expiresAt: number; content: T } | undefined;

		if (!cache || cache.expiresAt < Date.now()) {
			const content = await fn(...params);
			cacheByKey.set(cacheKey, { expiresAt: Date.now() + (opts.cacheTime ?? 5 * 60 * 1000), content });
			return content;
		}

		return cache.content;
	};
}
