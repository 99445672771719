export const qualifier = {
	storyfolio: {
		studio: {
			newTemplate: "Storyfolio/Studio/NewTemplate",
			duplicate: "Storyfolio/Studio/Duplicate",
			rename: "Storyfolio/Studio/Rename",
			delete: "Storyfolio/Studio/Delete",
			bulkDelete: "Storyfolio/Studio/BatchAction(Delete)",
			search: "Storyfolio/Studio/Search",
		},
		details: {
			cancel: "Storyfolio/Details/Cancel",
			save: "Storyfolio/Details/Save",
			expandContext: "Storyfolio/Details/Style/ExpandContext",
			contextValue: "Storyfolio/Details/Style/Context/Content",
			selectLanguage: "Storyfolio/Details/Style/SelectLanguage",
			languageOption: function language(lng: string): string {
				return `Storyfolio/Details/Style/SelectLanguage/Language(${lng})`;
			},
			selectTone: "Storyfolio/Details/Style/SelectTone",
			toneOption: function tone(tn: string): string {
				return `Storyfolio/Details/Style/SelectTone/Tone(${tn})`;
			},
			selectModule: "Storyfolio/Details/Structure/SelectModule",
			moduleOption: function module(md: string): string {
				return `Storyfolio/Details/Structure/SelectModule/Module(${md})`;
			},
			contentValue: "Storyfolio/Details/Structure/Content",
			selectPortfolio: "Storyfolio/Details/Preview/SelectPortfolio",
			generatePreview: "Storyfolio/Details/Preview/Generate",
		},
		modules: {
			portfolioDynamics: {
				relevanceThreshold: "Storyfolio/Module/PortfolioDynamics/RelevanceThreshold",
			},
			portfolioComposition: {
				commentBenchmark: "Storyfolio/Module/PortfolioComposition/CommentBenchmark",
				commentAssetClassConstrainnts: "Storyfolio/Module/PortfolioComposition/CommentAssetClassConstraints",
				highlightMainInstrumentMacroAssetClass:
					"Storyfolio/Module/PortfolioComposition/HighlightMainInstrumentMacroAssetClass",
				relevanceThresholdForMicroAcCommented:
					"Storyfolio/Module/PortfolioComposition/RelevanceThresholdForMicroAcCommented",
			},
			instrumentInsights: {
				maxNumOfAddedInstruments: "Storyfolio/Module/InstumentInsights/MaxNumOfAddedInstruments",
				maxNumOfAddedInstrumentsThreshold: "Storyfolio/Module/InstumentInsights/MaxNumOfAddedInstrumentsThreshold",
				maxNumOfDeletedInstruments: "Storyfolio/Module/InstumentInsights/MaxNumOfDeletedInstruments",
				maxNumOfDeletedInstrumentsThreshold: "Storyfolio/Module/InstumentInsights/MaxNumOfDeletedInstrumentsThreshold",
				maxNumOfPositiveDeltaInstruments: "Storyfolio/Module/InstumentInsights/MaxNumOfPositiveDeltaInstruments",
				maxNumOfPositiveDeltaInstrumentsThreshold:
					"Storyfolio/Module/InstumentInsights/MaxNumOfPositiveDeltaInstrumentsThreshold",
				maxNumOfNegativeDeltaInstruments: "Storyfolio/Module/InstumentInsights/MaxNumOfNegativeDeltaInstruments",
				maxNumOfNegativeDeltaInstrumentsThreshold:
					"Storyfolio/Module/InstumentInsights/MaxNumOfNegativeDeltaInstrumentsThreshold",
				instrumentsReturns: "Storyfolio/Module/InstumentInsights/InstrumentsReturns",
			},
			portfolioMetrics: {
				exAnteVar: "Storyfolio/Module/PortfolioMetrics/Metric/ExAnteVar",
				exAnteAnnualizedVolatility: "Storyfolio/Module/PortfolioMetrics/Metric/ExAnteAnnualizedVolatility",
				exAnteDiversificationRatio: "Storyfolio/Module/PortfolioMetrics/Metric/ExAnteDiversificationRatio",
				exAnteVolatilityContribution: "Storyfolio/Module/PortfolioMetrics/Metric/ExAnteVolatilityContribution",
				riskBudgetOnVarVolatility: "Storyfolio/Module/PortfolioMetrics/Metric/RiskBudgetOnVarVolatility",
				riskBudgetTrackingError: "Storyfolio/Module/PortfolioMetrics/Metric/RiskBudgetTrackingError",
				riskFactors: "Storyfolio/Module/PortfolioMetrics/Metric/RiskFactors",
				trackingError: "Storyfolio/Module/PortfolioMetrics/Metric/TrackingError",
				varVolatilityRiskConstraint: "Storyfolio/Module/PortfolioMetrics/Metric/VarVolatilityRiskConstraint",
			},
		},
		component: {
			sideHeaderContent: {
				submit: "Storyfolio/Module/SideHeaderContent/Submit",
				cancel: "Storyfolio/Module/SideHeaderContent/Cancel",
			},
			moduleStyling: {
				summarize: "Storyfolio/Module/ModuleStyling/Summarize",
				extended: "Storyfolio/Module/ModuleStyling/Extended",
				tableFormat: "Storyfolio/Module/ModuleStyling/TableFormat",
				customPrompt: "Storyfolio/Module/ModuleStyling/CustmoPrompt",
				content: "Storyfolio/Module/ModuleStyling/Content",
			},
		},
	},
	lexical: {
		pillNode: {
			node: function node(n: string): string {
				return `Lexical/PillNode/Node(${n})`;
			},
		},
	},
	widgets: {
		portfolioCommentary: {
			name: "Widget/PortfolioCommentary",
			timeStamp: "Widget/PortfolioCommentary/TimeStamp",
			templateName: "Widget/PortfolioCommentary/TemplateName",
			generate: "Widget/PortfolioCommentary/GenerateComment",
			selectTemplate: "Widget/PortfolioCommentary/SelectTemplate",
		},
		portfolioPerformance: {
			name: "Widget/PortfolioPerformance",
		},
		portfolioMonitoring: {
			name: "Widget/PortfolioMonitoring",
		},
		portfolioPerformanceMetrics: {
			name: "Widget/PortfolioPerformanceMetrics",
		},
		portfolioExAnteMetric: {
			name: "Widget/PortfolioExAnteMetric",
		},
		portfolioExposure: {
			name: "Widget/PortfolioExposure",
		},
		portfolioAttribution: {
			name: "Widget/PortfolioAttribution",
		},
		portfolioContributionVolatility: {
			name: "Widget/PortfolioContributionVolatility",
		},
		portfolioExAnteContributionVolatility: {
			name: "Widget/PortfolioExAnteContributionVolatility",
		},
		portfolioFactorExposure: {
			name: "Widget/PortfolioFactorExposure",
		},
		portfolioExposureCompare: {
			name: "Widget/PortfolioExposureCompare",
		},
		portfolioHistory: {
			name: "Widget/PortfolioHistory",
		},
		assetClassOveriview: {
			name: "Widget/AssetClassOveriview",
		},
		returnAnalysis: {
			name: "Widget/ReturnAnalysis",
		},
		factorExposure: {
			name: "Widget/FactorExposure",
		},
		marketCommentary: {
			name: "Widget/MarketCommentary",
		},
		assetClassOverview: {
			name: "Widget/AssetClassOverview",
		},
		outlookOverview: {
			name: "Widget/OutlookOverview",
		},
		portfolioListOverview: {
			name: "Widget/PortfolioListOverview",
		},
		positioning: {
			name: "Widget/Positioning",
		},
		forecast: {
			name: "Widget/Forecast",
		},
	},
	component: {
		hierarchicalMultiSelect: {
			name: "Component/HierarchicalMultiSelect",
		},
		modalWithFiler: {
			search: "Component/ModalWithFilers/Search",
			submit: "Component/ModalWithFilers/Submit",
			cancel: "Component/ModalWithFilers/Cancel",
		},
	},
	outlook: {
		focus: {
			summary: "Outlook/Focus/Summary",
			positioning: "Outlook/Focus/Positioning",
			macroDynamics: "Outlook/Focus/MacroDynamics",
		},
	},
};
