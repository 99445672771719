import {
	ReviewTickerProxyOverwriteTypeEnum,
	type InvestmentSummary,
	type ReviewTicker,
	type UserCompositionColumnPreference,
	type UserEnhancementCompositionColumnPreference,
} from "$root/api/api-gen";
import { hasAccess } from "$root/components/AuthorizationGuard";
import CustomLabels from "$root/components/CustomLabels";
import { InfoDelta } from "$root/components/InfoDelta";
import { MarkdownRenderer } from "$root/components/MarkdownRenderer/MarkdownRenderer";
import { typedUrlForRoute } from "$root/components/PlatformRouter/RoutesDef";
import { TagBadge } from "$root/components/tags/TagBadge";
import { labelToTag } from "$root/components/tags/shared";
import type { PortfolioAlert } from "$root/functional-areas/portfolio/alerts";
import { useUserValue } from "$root/functional-areas/user";
import { useLocaleFormatters } from "$root/localization/hooks";
import type { TableColumn } from "@mdotm/mdotui/components";
import { ActionText, AutoTooltip, TableDataCell, TableHeadCell, TooltipContent } from "@mdotm/mdotui/components";
import { overrideClassName } from "@mdotm/mdotui/react-extensions";
import { builtInSortFnFor } from "@mdotm/mdotui/utils";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";
import { PortfolioDetailsTabs } from "..";

function InstrumentNameBlock(props: { row: ReviewTicker; alerts: PortfolioAlert[] }) {
	const { t } = useTranslation();
	const { instrument, tickerId, descriptionCreator, description } = props.row;
	return (
		<AutoTooltip
			align="startToStart"
			disabled={!description}
			trigger={({ innerRef }) => (
				<div className="flex flex-row items-center flex-nowrap w-full pr-4" ref={innerRef}>
					<div
						className={overrideClassName("whitespace-nowrap text-ellipsis overflow-hidden", {
							underline: Boolean(description),
						})}
					>
						{instrument ?? ""}
					</div>
					{props.alerts.some(
						(a) =>
							(a.type === "InstrumentsChangesAlertCheck" || a.type === "InstrumentsChangesChildAlertCheck") &&
							a.value.tickerId === tickerId,
					) && (
						<span
							className="block rounded-full px-1 ml-1 py-0.5 bg-[#00AEEF] text-white uppercase"
							style={{ fontSize: 8 }}
						>
							{t("UPDATED")}
						</span>
					)}
				</div>
			)}
		>
			{descriptionCreator === "SPHERE" ? (
				<TooltipContent>
					<MarkdownRenderer>{description ?? ""}</MarkdownRenderer>
				</TooltipContent>
			) : (
				description
			)}
		</AutoTooltip>
	);
}

function PortfolioNameBlock(props: { row: ReviewTicker }) {
	const { instrument, ticker } = props.row;
	return (
		<div className="flex flex-row items-center flex-nowrap w-full pr-4">
			<ActionText
				classList="inline-flex items-center gap-1"
				onClick={() =>
					window.open(
						typedUrlForRoute("PortfolioDetails", { portfolioUid: ticker ?? "", tab: PortfolioDetailsTabs.COMPOSITION }),
						"_blank",
					)
				}
			>
				<span className="font-[weight:500] truncate items-center">{instrument ?? "-"}</span>
				<svg
					width="12"
					height="12"
					viewBox="0 0 12 12"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					className="shrink-0"
				>
					<path
						d="M8 1.5H10.5V4"
						stroke="currentColor"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M7 5L10.5 1.5"
						stroke="currentColor"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M8.5 7.4375V9.625C8.5 10.1084 8.10844 10.5 7.625 10.5H2.375C1.89156 10.5 1.5 10.1084 1.5 9.625V4.375C1.5 3.89156 1.89156 3.5 2.375 3.5H4.5625"
						stroke="currentColor"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</ActionText>
		</div>
	);
}

export function useCompositionColumn(
	preferences: UserCompositionColumnPreference[] | UserEnhancementCompositionColumnPreference[],
	portfolio: InvestmentSummary,
	rows: ReviewTicker[],
	alerts: PortfolioAlert[],
) {
	const { t } = useTranslation();
	const user = useUserValue();
	const formatters = useLocaleFormatters();
	const { formatNumber } = formatters;

	const tags = useMemo(
		() =>
			Array.from(new Set(rows.map(({ tagLabel }) => tagLabel)))
				.filter((label) => label)
				.map((label, _i, set) => labelToTag({ label: label! }, set as string[])),
		[rows],
	);

	const columns = useMemo(
		() =>
			preferences.map(
				(preference) =>
					({
						// header: t(`TABLE.HEADERS.${c.preferenceType}`),
						hidden: preference.enabled === false,
						...match(preference)
							.with(
								{ preferenceType: "INSTRUMENT_NAME" },
								(): TableColumn<ReviewTicker, string> => ({
									name: "instrument",
									header: "Name",
									content: (row, cellProps) => {
										return (
											<TableDataCell {...cellProps}>
												{row.proxyOverwriteType === "PORTFOLIO_MIXED" ? (
													<PortfolioNameBlock row={row} />
												) : (
													<InstrumentNameBlock alerts={alerts} row={row} />
												)}
											</TableDataCell>
										);
									},
									minWidth: 460,
									maxWidth: 560,
									sortFn: builtInSortFnFor("instrument"),
								}),
							)
							.with(
								{ preferenceType: "IDENTIFIER" },
								(): TableColumn<ReviewTicker, string> => ({
									name: "identifier",
									header: "Identifier",
									content: ({ identifier, proxyOverwriteType }) =>
										proxyOverwriteType === "PORTFOLIO_MIXED" ? "Portfolio" : identifier ? identifier : "",
									width: 128,
									sortFn: builtInSortFnFor("identifier"),
								}),
							)
							.with(
								{ preferenceType: "ASSET_CLASS" },
								(): TableColumn<ReviewTicker, string> => ({
									name: "assetClass",
									header: "Asset Class",
									content: ({ assetClass, proxyOverwriteType }) =>
										proxyOverwriteType === "PORTFOLIO_MIXED" ? "" : assetClass ? assetClass : "",
									width: 128,
									sortFn: builtInSortFnFor("assetClass"),
								}),
							)
							.with(
								{ preferenceType: "SCORE" },
								(): TableColumn<ReviewTicker, string> => ({
									header: (props) => (
										<TableHeadCell {...props}>
											{portfolio?.scoreIdentifier ? (
												<CustomLabels
													labelKey={portfolio?.scoreIdentifier}
													fallback={t("SCORE")}
													mode="view"
													isEditable={false}
												/>
											) : (
												t("SCORE")
											)}
										</TableHeadCell>
									),
									content: ({ score }) => (score ? formatNumber(score) : ""),
									name: "score",
									width: 128,
									hidden: preference.enabled === false || !hasAccess(user, { requiredService: "CUSTOM_QUALITIES" }),
									sortFn: builtInSortFnFor("score"),
								}),
							)
							.with(
								{ preferenceType: "MICRO_ASSET_CLASS" },
								(): TableColumn<ReviewTicker, string> => ({
									name: "microAssetClass",
									header: "Micro Asset Class",
									width: 240,
									content: ({ microAssetClass, proxyOverwriteType }) =>
										proxyOverwriteType === "PORTFOLIO_MIXED" ? "" : microAssetClass ? microAssetClass : "",
									sortFn: builtInSortFnFor("microAssetClass"),
								}),
							)
							.with(
								{ preferenceType: "WEIGHT" },
								(): TableColumn<ReviewTicker, string> => ({
									name: "weight",
									header: "Weight",
									content: (row) => `${formatNumber(row.weight ?? 0)}%`,
									width: 104,
									sortFn: builtInSortFnFor("weight"),
									align: "end",
								}),
							)
							.with(
								{ preferenceType: "CURRENT_WEIGHT" },
								(): TableColumn<ReviewTicker, string> => ({
									name: "currentWeight",
									header: "Current Weight",
									content: (row) => `${formatNumber(row.previousWeight ?? 0)}%`,
									width: 134,
									sortFn: builtInSortFnFor("previousWeight"),
									align: "end",
									cellClassList: "tabular-nums",
								}),
							)
							.with(
								{ preferenceType: "ENHANCED_WEIGHT" },
								(): TableColumn<ReviewTicker, string> => ({
									name: "enhancedWeight",
									header: "Enhanced Weight",
									content: (row) => `${formatNumber(row.weight ?? 0)}%`,
									width: 104,
									sortFn: builtInSortFnFor("weight"),
									align: "end",
									cellClassList: "tabular-nums",
								}),
							)
							.with(
								{ preferenceType: "DIFFERENCE" },
								(): TableColumn<ReviewTicker, string> => ({
									name: "difference",
									header: "difference",
									content: (instrument, cellProps) => {
										const deltaWeight = Number((instrument.weight ?? 0) - (instrument.previousWeight ?? 0)).toFixed(2);
										return (
											<TableDataCell {...cellProps}>
												<InfoDelta diff={Number(deltaWeight) ?? 0} enh={instrument.weight ?? 0} />
											</TableDataCell>
										);
									},
									align: "end",
									width: 140,
									cellClassList: "tabular-nums",
									sortFn: (rowa, rowb) => {
										const deltaA = (rowa.weight ?? 0) - (rowa.previousWeight ?? 0);
										const deltaB = (rowb.weight ?? 0) - (rowb.previousWeight ?? 0);

										if (deltaA > deltaB) {
											return 1;
										}

										if (deltaA < deltaB) {
											return -1;
										}

										return 0;
									},
								}),
							)
							.with(
								{
									preferenceType: "TAG",
								},
								(): TableColumn<ReviewTicker, string> => ({
									name: "tag",
									header: "Tag",
									content: ({ tagLabel }, cellProps) => {
										const currentTag = tags?.find((item) => item.name === tagLabel);
										if (currentTag === undefined) {
											return "";
										}

										return (
											<TableDataCell {...cellProps}>
												<TagBadge color={currentTag.color}>{currentTag.name}</TagBadge>
											</TableDataCell>
										);
									},
									minWidth: 140,
									maxWidth: 320,
									sortFn: builtInSortFnFor("tagLabel"),
								}),
							)
							.otherwise(() => ({})),
					}) as TableColumn<ReviewTicker, string>,
			),
		[formatNumber, portfolio?.scoreIdentifier, preferences, t, tags, user, alerts],
	);

	return columns;
}
