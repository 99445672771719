import { useLocaleFormatters } from "$root/localization/hooks";
import { sumArrayLike } from "$root/utils/collections";
import { Text, type StylableProps } from "@mdotm/mdotui/components";
import { toClassListRecord, toClassName, ForEach } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";

export type RiskProbabilityBarProps = (
	| {
			mode: "single";
			risk: MainRiskCategory;
			weight: number;
			risks?: undefined;
			/** @default true */
			showCaption?: boolean;
	  }
	| {
			mode: "multi";
			risks: Array<{ risk: MainRiskCategory; weight: number }>;
			risk?: undefined;
			weight?: undefined;
			showCaption?: undefined;
	  }
) & {
	/** defaults to 100 in single mode or sum(risks[number].weight) in multi mode */
	maxWeight?: number;
} & StylableProps;

export type MainRiskCategory = "High" | "Medium" | "Low";

const colorByRisk: Record<MainRiskCategory, { foreground: string; background: string }> = {
	Low: { foreground: themeCSSVars.palette_P500, background: themeCSSVars.palette_P100 },
	Medium: { foreground: themeCSSVars.palette_A500, background: themeCSSVars.palette_A100 },
	High: { foreground: themeCSSVars.palette_D500, background: themeCSSVars.palette_D100 },
};
const labelByRisk: Record<MainRiskCategory, string> = {
	Low: "Low risk",
	Medium: "Medium risk",
	High: "High risk",
};

export function RiskProbabilityBar({
	style,
	classList,
	mode,
	risk,
	weight,
	risks,
	maxWeight: propsMaxWeight,
	showCaption = true,
}: RiskProbabilityBarProps): JSX.Element {
	const { formatNumber } = useLocaleFormatters();
	const maxWeight = propsMaxWeight ?? (mode === "single" ? 100 : sumArrayLike(risks, (r) => r.weight));
	return (
		<div
			className={toClassName({
				...toClassListRecord(classList),
			})}
			style={style}
		>
			{mode === "single" && showCaption && (
				<div className="flex justify-between">
					<Text color={themeCSSVars.palette_N400} type="Body/S/Book" as="div">
						{labelByRisk[risk]}
					</Text>
					<Text type="Body/S/Bold" as="div">
						{formatNumber(weight)}%
					</Text>
				</div>
			)}
			<div
				className="relative z-0 rounded-full overflow-hidden border"
				style={{
					borderColor: themeCSSVars.palette_N50,
				}}
			>
				<div
					className="relative z-0 w-full h-1"
					style={{
						backgroundColor: mode === "multi" ? themeCSSVars.palette_N100 : colorByRisk[risk].background,
					}}
				/>
				{mode === "single" ? (
					<div
						className="absolute z-10 left-0 inset-y-0"
						style={{
							width: `${(weight * 100) / maxWeight}%`,
							background: colorByRisk[risk].foreground,
						}}
					/>
				) : (
					(() => {
						let sumOfPreviousWeights = 0;
						return (
							<ForEach collection={risks}>
								{({ item }) => {
									const left = `${(sumOfPreviousWeights * 100) / maxWeight}%`;
									sumOfPreviousWeights += item.weight;
									return (
										<div
											className="absolute z-10 inset-y-0"
											style={{
												left,
												width: `${(item.weight * 100) / maxWeight}%`,
												background: colorByRisk[item.risk].foreground,
											}}
										/>
									);
								}}
							</ForEach>
						);
					})()
				)}
			</div>
		</div>
	);
}
