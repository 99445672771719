import type { IUser } from "$root/functional-areas/user";

const IntercomContentsMap = {
	articles: {
		marketRegimeAnalysysTool: 7969565,
		GeographicAndSectorIndicators: 8001000,
		MarketRegimeAnalysis: 7169731,
		globalRiskMap: 10006138,
	},
};

let mounted = false;
const initIntercom = (): void => {
	if (mounted) {
		return;
	}
	mounted = true;
	(window as any).intercomSettings = {
		api_base: "https://api-iam.intercom.io",
		app_id: "dczh015u",
	};

	const intercomScript = document.createElement("script");
	intercomScript.text = `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/dczh015u';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`;

	document.head.appendChild(intercomScript);
};

export default initIntercom;

export const initIntercomCUser = (user: IUser): void => {
	(window as any).Intercom?.("boot", {
		app_id: "dczh015u",
		email: user.email,
		user_id: (user.customFields as { intercomId?: string } | undefined)?.intercomId,
		//created_at: 1234567890,
	});
};
export const disposeIntercomUser = (): void => {
	(window as any).Intercom?.("shutdown");
};

export const Intercom = {
	showMessage: (): void => (window as any).Intercom?.("show"),
	showHelp: (): void => (window as any).Intercom?.("showSpace", "help"),
	showArticle: (article: keyof (typeof IntercomContentsMap)["articles"]): void =>
		(window as any).Intercom?.("showArticle", IntercomContentsMap.articles[article]),
	update: (): void => (window as any).Intercom?.("update"),
};
