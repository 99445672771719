import type {
	EntityManagementBaseDialogCommon,
	EntityManagementBaseDialogProps,
	SpawnEntityManagementBaseDialogParams,
} from "./_base-dialog";
import { EntityManagementBaseDialog, spawnEntityManagementBaseDialog } from "./_base-dialog";

export type CreateDialogCommon<ID> = Omit<EntityManagementBaseDialogCommon<ID>, "currentName">;
export type CreateDialogProps<ID> = Omit<EntityManagementBaseDialogProps<ID>, "currentName">;
export type SpawnCreateDialogParams<ID> = Omit<SpawnEntityManagementBaseDialogParams<ID>, "currentName">;

function withDefaults<ID, T extends SpawnCreateDialogParams<ID> | CreateDialogProps<ID>>(
	params: T,
): EntityManagementBaseDialogCommon<ID> & T {
	return {
		header: params.header ?? (params.entityType ? `Create ${params.entityType}` : "Create"),
		inputLabel: params.inputLabel ?? "Name",
		size: "large",
		...params,
	};
}

export function spawnCreateDialog<ID>(params: SpawnCreateDialogParams<ID>): Promise<ID> {
	return spawnEntityManagementBaseDialog(withDefaults(params));
}

export function CreateDialog<ID>(props: CreateDialogProps<ID>): JSX.Element {
	return <EntityManagementBaseDialog {...withDefaults(props)} />;
}
