import { EventControllerApiFactory } from "$root/api/api-gen";
import { makeAuthController } from "$root/api/factory";
import env from "$root/env";
import { unpromisify } from "@mdotm/mdotui/utils";
import type { ServerEventPayloadMap } from "./bus";
import { eventBus } from "./bus";

export function initServerEvents({ authToken }: { authToken?: string }): {
	close(): void;
} {
	const isAuthenticated = authToken;
	const timeBetweenRetry = isAuthenticated ? 10000 : 300000;
	let source: EventSource | null = null;
	let abortController: AbortController | null = null;

	// TODO: if data needs some mapping/processing before sending it to the event bus, you can add it in these functions.
	// Record<keyof ServerEventPayloadMap, (e: MessageEvent) => void>
	const forwarders: { [key in keyof ServerEventPayloadMap]: (e: MessageEvent) => void } = {
		"investment-update": (e) => eventBus.emit("investment-update", JSON.parse(e.data)),
		"market-update": (e) => eventBus.emit("market-update", JSON.parse(e.data)),
		"investment-draft-configuration-update": (e) =>
			eventBus.emit("investment-draft-configuration-update", JSON.parse(e.data)),
		"benchmark-update": (e) => eventBus.emit("benchmark-update", JSON.parse(e.data)),
		"system-update": (e) => eventBus.emit("system-update", JSON.parse(e.data)),
		"user-update": (e) => eventBus.emit("user-update", JSON.parse(e.data)),
		"bulk-report-download": (e) => eventBus.emit("bulk-report-download", JSON.parse(e.data)),
		"investment-bulk-enhance-update": (e) => eventBus.emit("investment-bulk-enhance-update", JSON.parse(e.data)),
		"shared-entity": (e) => eventBus.emit("shared-entity", JSON.parse(e.data)),
		"deleted-entity": (e) => eventBus.emit("deleted-entity", JSON.parse(e.data)),
		"removed-entity": (e) => eventBus.emit("removed-entity", JSON.parse(e.data)),
		"modified-entity": (e) => eventBus.emit("modified-entity", JSON.parse(e.data)),
		"commentary-update": (e) => eventBus.emit("commentary-update", JSON.parse(e.data)),
		"investment-report-update": (e) => eventBus.emit("investment-report-update", JSON.parse(e.data)),
		"instrument-commentary-update": (e) => eventBus.emit("instrument-commentary-update", JSON.parse(e.data)),
		"investment-import-update": (e) => eventBus.emit("investment-import-update", JSON.parse(e.data)),
	};

	function close() {
		if (restartTimeoutId !== null) {
			clearTimeout(restartTimeoutId);
			restartTimeoutId = null;
		}
		if (source) {
			// We shouldn't need this, but the BE would leave the EventSource
			// pending otherwise, so this is a temporary (?) fix.
			abortController?.abort(new Error("abort pending open request"));
			abortController = null;

			source.removeEventListener("open", handleOpen);
			source.removeEventListener("error", handleError);
			for (const eventName of Object.keys(forwarders) as (keyof ServerEventPayloadMap)[]) {
				source.removeEventListener(eventName, forwarders[eventName]);
			}
			source.close();
			source = null;
		}
	}

	function start() {
		if (isAuthenticated) {
			source = isAuthenticated
				? new EventSource(`${env.apiOrigin}/v1/event/register?token=${authToken}`)
				: new EventSource(`${env.apiOrigin}/v1/ext-event/register`);

			source.addEventListener("open", handleOpen);
			for (const eventName of Object.keys(forwarders) as (keyof ServerEventPayloadMap)[]) {
				source.addEventListener(eventName, forwarders[eventName]);
			}

			source.addEventListener("error", handleError);

			// We shouldn't need this, but the BE would leave the EventSource
			// pending otherwise, so this is a temporary (?) fix. (2/2)
			const newAbortController = new AbortController();
			abortController = newAbortController;
			setTimeout(
				unpromisify(async () => {
					await fetch(`${env.apiOrigin}/v1/event/send/open-channel`, {
						method: "POST",
						signal: newAbortController.signal,
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${authToken}`,
						},
						body: JSON.stringify({
							level: "INFO",
							type: "NONE",
							userId: "",
							uuid: "",
							name: "",
							status: "",
						}),
					});
				}),
				1000,
			);
		}
	}

	function restart() {
		close();
		start();
	}

	let restartTimeoutId: ReturnType<typeof setTimeout> | null = null;

	function handleOpen() {
		console.log("Ready to receive server-side events");
	}

	function handleError(e: EventSourceEventMap["error"]) {
		console.error("Server Events error: ", e);
		let needRetry = true;
		if (restartTimeoutId !== null) {
			clearTimeout(restartTimeoutId);
			restartTimeoutId = null;
		}
		// Add an api call to check the Token Validity and force logout
		if (isAuthenticated) {
			const apiCheckCall = makeAuthController({ controllerFactory: EventControllerApiFactory, token: authToken });
			const tokenCheck = (async () => {
				try {
					const check = await apiCheckCall.verify();
					return check;
				} catch (err) {
					needRetry = false;
					console.warn("check failed, Logging Out", err);
				}
			})();
			console.log("Token Check", tokenCheck);
		}
		if (needRetry) {
			restartTimeoutId = setTimeout(() => {
				restart();
			}, timeBetweenRetry); // Restart after a defined time
		}
	}

	restart();
	return {
		close,
	};
}
