import type {
	InvestmentBulkBulkStaticConfigurationControllerV1Api,
	InvestmentBulkEnhancementConfigurationControllerV4Api,
	InvestmentCreationConfigurationControllerV4Api,
	InvestmentDraftConfigurationControllerV4Api,
	InvestmentEnhancementConfigurationControllerV4Api,
	MarketScenario,
	MarketViewListEntry,
	MarketViewSettings,
} from "$root/api/api-gen";
import {
	InvestmentBulkEnhancementConfigurationControllerV4ApiFactory,
	InvestmentCreationConfigurationControllerV4ApiFactory,
	InvestmentDraftConfigurationControllerV4ApiFactory,
	InvestmentEnhancementConfigurationControllerV4ApiFactory,
	InvestmentsStaticConfigurationControllerApiFactory,
	MarketScenarioIds,
	MarketViewControllerApiFactory,
	MarketViewType,
	MarketViewSettingsActiveMarketViewTypesEnum,
	PortfolioStudioPreferencesApiFactory,
	InvestmentBulkBulkStaticConfigurationControllerV1ApiFactory,
} from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { useQueryNoRefetch } from "$root/utils/react-query";
import type { ModalProps, TableColumn } from "@mdotm/mdotui/components";
import { AutoSortTable, useSelectableTableColumn } from "@mdotm/mdotui/components";
import { Button, Dialog, DialogFooter, Icon, SubmitButton, TextInput } from "@mdotm/mdotui/components";
import { noop } from "@mdotm/mdotui/utils";
import { useCallback, useEffect, useMemo, useState } from "react";
import type { EditPortfolioV4Props } from "../EditPortfolio";
import { StepBase } from "../StepBase";
import { useStepSync } from "../hooks";
import { responseToStepMapper } from "../requests";
import { responseToStepMapper as responseToStepMapperMulti } from "../requests-multi";

import { ReactQueryWrapperBase } from "$root/components/ReactQueryWrapper";
import { MarketViewPositioning } from "$root/functional-areas/market-view/MarketViewPositioning";
import { MarketViewProbabilities } from "$root/functional-areas/market-view/MarketViewProbabilities";
import { useLocaleFormatters } from "$root/localization/hooks";
import { checkUndefined, sortingFn } from "$root/pages/PortfoliosStudio";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { trackMixPanelEvent } from "$root/third-party-integrations/initMixPanel";
import RenderingGuard from "$root/ui-lib/renderingGuard";
import { useSearchable } from "@mdotm/mdotui/headless";
import { Switch, adaptAnimatedNodeProvider, overrideClassName, spawn } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { differenceInDays } from "date-fns";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";
import { applyDeltaToSelectedMarketView, removeDeltaFromSelectedMarketView } from "../../MarketView/utilsV2";
import type { EditPortfolioMultiV4Props } from "../EditPortfolioMulti";
import type { ConstraintAdder } from "../constraints/AddConstraintSection";
import { AddConstraintSection } from "../constraints/AddConstraintSection";
import { type EditPortfolioV4StepPayloadMap, type StepPropsFor } from "../shared";
import type { StepMultiPropsFor } from "../shared-multi";
import { IconWalls } from "$root/components/IconWall";

export function getMarketViewStepData(
	createApi: Omit<InvestmentCreationConfigurationControllerV4Api, "basePath" | "axios">,
	enhanceApi: Omit<InvestmentEnhancementConfigurationControllerV4Api, "basePath" | "axios">,
	draftApi: Omit<InvestmentDraftConfigurationControllerV4Api, "basePath" | "axios">,
	area: EditPortfolioV4Props["area"],
): Promise<EditPortfolioV4StepPayloadMap["marketView"]> {
	return (
		!area.portfolioUid
			? createApi.getCreationConfigurationMarketView()
			: area.name === "draft"
			  ? draftApi.getDraftConfigurationMarketView(area.portfolioUid)
			  : enhanceApi.getEnhancementConfigurationMarketView(area.portfolioUid)
	).then(({ data }) => responseToStepMapper.marketView(data, area));
}

export function getBulkMarketViewStepData(
	bulkApi: Omit<InvestmentBulkEnhancementConfigurationControllerV4Api, "basePath" | "axios">,
	bulkSettingsApi: Omit<InvestmentBulkBulkStaticConfigurationControllerV1Api, "basePath" | "axios">,
	area: EditPortfolioMultiV4Props["area"],
): Promise<EditPortfolioV4StepPayloadMap["marketView"]> {
	return (
		area.name === "create-bulk-proposal"
			? bulkApi.getEnhancementConfigurationMarketView1(area.proposalUuid)
			: bulkSettingsApi.getEnhancementConfigurationMarketView2(area.bulkUid)
	).then(({ data }) => responseToStepMapperMulti.marketView(data, area));
}

/**
 * ID associated with a custom market view created on the step 06
 */
const customMarketViewId = "CUSTOM_PORTFOLIO_MARKET_VIEW";

/**
 * ID associated with a custom market view  created on the step 06 abd used to create a portfolio or a portfolio proposal.
 */
const currentMarketViewId = "CURRENT_PORTFOLIO";

export function Step06_MarketView({
	context,
	stepMetadata,
	stepData,
	onStepDataChange,
	onStepError,
	toggleDirty,
}: StepPropsFor<"marketView"> | StepMultiPropsFor<"marketView">): JSX.Element {
	//TODO: does the market view can goes in calculating and if yes,
	// How the component should behave ?
	const [item, setItem] = useState<{
		id: MarketViewType | null;
		isCustom?: boolean;
		action?: MarketViewSettingsActiveMarketViewTypesEnum | "load";
		name?: string;
	} | null>(null);
	const [isDirty, setIsDirty] = useState(false);
	const [loadingFlag, setLoadingFlag] = useState(0); // TODO: review this flag and remove it

	const { area } = context;
	const edit = area.editable && area.edit;

	const [currentScenarioId, setCurrentScenarioId] = useState<string | null>(null);
	const [marketScenario, setMarketScenario] = useState<MarketScenario | null>(null);
	const [originalScenario, setOriginalScenario] = useState<MarketScenario | null>(null);

	useEffect(() => {
		noop(currentScenarioId, marketScenario); // track
	}, [currentScenarioId, marketScenario]);

	const creationApi = useApiGen(InvestmentCreationConfigurationControllerV4ApiFactory);
	const draftApi = useApiGen(InvestmentDraftConfigurationControllerV4ApiFactory);
	const enhancementApi = useApiGen(InvestmentEnhancementConfigurationControllerV4ApiFactory);
	const staticController = useApiGen(InvestmentsStaticConfigurationControllerApiFactory);
	const marketViewApi = useApiGen(MarketViewControllerApiFactory);
	const bulkEnhancementApi = useApiGen(InvestmentBulkEnhancementConfigurationControllerV4ApiFactory);
	const investmentBulkBulkStaticV1Api = useApiGen(InvestmentBulkBulkStaticConfigurationControllerV1ApiFactory);
	const portfolioStudioPreferenceApi = useApiGen(PortfolioStudioPreferencesApiFactory);

	const { data: defaultScenario, isLoading: isLoadingDefaultScenario } = useQueryNoRefetch({
		queryKey: ["market-views-default-scenario"],
		queryFn: () =>
			axiosExtract(
				marketViewApi.getMarketViewScenario(
					MarketScenarioIds.SphereForecastPositioningOneMonth,
					undefined,
					false,
					false,
				),
			),
	});

	const selectableMarketViewQuery = useQueryNoRefetch({
		enabled: isLoadingDefaultScenario === false,
		queryKey: ["selectable-market-views", context.area],
		queryFn: () =>
			match(context)
				.with({ area: { name: "create-bulk-proposal" } }, () =>
					axiosExtract(bulkEnhancementApi.getEnhancementConfigurationSelectableMarketViews1()),
				)
				.with({ area: { name: "edit-bulk-settings" } }, (x) =>
					axiosExtract(investmentBulkBulkStaticV1Api.getEnhancementConfigurationSelectableMarketViews2()),
				)
				.with({ area: { name: "enhance" } }, (x) =>
					axiosExtract(enhancementApi.getEnhancementConfigurationSelectableMarketViews(x.area.portfolioUid)),
				)
				.with({ area: { name: "settings-enhanced" } }, (x) =>
					axiosExtract(staticController.getStaticConfigurationSelectableMarketViews(x.area.portfolioUid)),
				)
				.with({ area: { name: "settings-current" } }, (x) =>
					axiosExtract(staticController.getStaticConfigurationSelectableMarketViews(x.area.portfolioUid)),
				)
				.with({ area: { name: "create" } }, () =>
					axiosExtract(creationApi.getCreationConfigurationSelectableMarketViews()),
				)
				.with({ area: { name: "draft" } }, (x) =>
					axiosExtract(draftApi.getDraftConfigurationSelectableMarketViews(x.area.portfolioUid)),
				)
				.exhaustive(),
	});

	const { data: marketViewSettings } = useQueryNoRefetch(["getMarketViewTempateConfig"], {
		queryFn: async () => ({
			setting: await axiosExtract(marketViewApi.getMarketViewUserSettings()),
			alias: await axiosExtract(portfolioStudioPreferenceApi.getMarketViewAssetClassAliases()),
		}),
	});

	// <Updates for calculating>
	useEffect(() => {
		setCurrentScenarioId(stepData.scenarioIdentifier ?? null);
	}, [stepData.scenarioIdentifier]);

	useEffect(() => {
		if (stepData.scenarioData) {
			const regimes = {
				lower: stepData.scenarioData?.regimeUserProbability?.a ?? 0,
				upper:
					(stepData.scenarioData?.regimeUserProbability?.a ?? 0) +
					(stepData.scenarioData?.regimeUserProbability?.b ?? 0),
			};

			const appliedAssetClasses = {
				...stepData.scenarioData,
				flexibleExpectedReturnsVolatility: {
					assetClasses: applyDeltaToSelectedMarketView(
						{
							a: regimes.lower,
							b: regimes.upper - regimes.lower,
							c: 100 - regimes.upper,
						},
						stepData.scenarioData.flexibleExpectedReturnsVolatility?.assetClasses,
					), // indicators.regimeUserProbability!
				},
			} satisfies MarketScenario;

			setMarketScenario(appliedAssetClasses);
			setOriginalScenario(stepData.scenarioData);
		}
	}, [stepData.scenarioData]);

	useEffect(() => {
		setItem({
			id: stepData.scenarioData?.marketViewType ?? null,
		});
	}, [stepData.scenarioData]);
	// </Updates for calculating>

	const isCalculating = stepMetadata.processing;
	const hasCustomMvTemplate = useMemo(
		() =>
			marketViewSettings?.setting.activeMarketViewTypes?.includes(MarketViewSettingsActiveMarketViewTypesEnum.Custom),
		[marketViewSettings?.setting.activeMarketViewTypes],
	);

	const constraintAdders = useMemo<ConstraintAdder<MarketViewSettingsActiveMarketViewTypesEnum | "load">[]>(
		() => [
			{
				maxOfType: 1,
				label: "Load Market view",
				type: "load",
				disabled: isCalculating || item?.id !== null || selectableMarketViewQuery.isLoading,
				limitTooltip: selectableMarketViewQuery.isLoading
					? "Sphere is retrieving the market view configuration"
					: "You have already set a MarketView",
			},
			...(marketViewSettings?.setting.activeMarketViewTypes?.includes(
				MarketViewSettingsActiveMarketViewTypesEnum.ExpectedReturnsVolatility,
			)
				? [
						{
							maxOfType: 1,
							label: "Expected returns",
							type: MarketViewSettingsActiveMarketViewTypesEnum.ExpectedReturnsVolatility,
							disabled: selectableMarketViewQuery.isLoading || isCalculating || item?.id !== null,
							limitTooltip: selectableMarketViewQuery.isLoading
								? "Sphere is retrieving the market view configuration"
								: "You have already set a MarketView",
						},
				  ]
				: []),
			...(marketViewSettings?.setting.activeMarketViewTypes?.includes(
				MarketViewSettingsActiveMarketViewTypesEnum.PositioningIndicators,
			)
				? [
						{
							maxOfType: 1,
							label: "Under/over weight",
							type: MarketViewSettingsActiveMarketViewTypesEnum.PositioningIndicators,
							disabled: selectableMarketViewQuery.isLoading || isCalculating || item?.id !== null,
							limitTooltip: selectableMarketViewQuery.isLoading
								? "Sphere is retrieving the market view configuration"
								: "You have already set a MarketView",
						},
				  ]
				: []),
			...(hasCustomMvTemplate &&
			marketViewSettings?.setting.customMarketViewType &&
			(selectableMarketViewQuery.data?.customDefaultScenarios?.length ?? 0) > 0
				? [
						{
							maxOfType: 1,
							label: marketViewSettings?.setting.customMarketViewName ?? "Custom market view",
							type: MarketViewSettingsActiveMarketViewTypesEnum.Custom,
							disabled: selectableMarketViewQuery.isLoading || isCalculating || item?.id !== null,
							limitTooltip: selectableMarketViewQuery.isLoading
								? "Sphere is retrieving the market view configuration"
								: "You have already set a MarketView",
						},
				  ]
				: []),
		],
		[
			hasCustomMvTemplate,
			isCalculating,
			item?.id,
			marketViewSettings?.setting.activeMarketViewTypes,
			marketViewSettings?.setting.customMarketViewName,
			marketViewSettings?.setting.customMarketViewType,
			selectableMarketViewQuery.data?.customDefaultScenarios?.length,
			selectableMarketViewQuery.isLoading,
		],
	);

	const isSubmitDisabled = useMemo(() => isCalculating, [isCalculating]);

	useStepSync({ reset: (prevData) => prevData, toggleDirty, isDirty, stepData });
	const handleSubmit = useCallback(async () => {
		try {
			if (isSubmitDisabled) {
				throw new Error("cannot submit the current step"); // TODO: what should we tell the user, exactly?
			}

			if (!originalScenario || !marketScenario || !currentScenarioId) {
				onStepDataChange(
					await context.saveHandlers.marketView({
						scenarioData: undefined,
						scenarioIdentifier: undefined,
						custom: undefined,
						name: undefined,
					}),
					{ skipMetadataUpdate: true, persist: true },
				);
				setIsDirty(false);
				setLoadingFlag(0);
				if (context.area.name !== "create-bulk-proposal" && context.area.name !== "edit-bulk-settings") {
					trackMixPanelEvent("Portfolio-Draft", {
						Type: context.area.name === "create" ? "creation" : "enhance",
						UID: context.area.portfolioUid ?? "missing uuid",
						Step: "market-view",
						Action: "save",
						ScenarioIdentifier: currentScenarioId ?? "not selected",
						RegimeUserProbability: marketScenario?.regimeProbabilities,
						RegimeDefaultProbability: marketScenario?.regimeUserProbability,
					});
				}
				return;
			}

			if (
				context.area.name === "create" ||
				context.area.name === "enhance" ||
				context.area.name === "draft" ||
				context.area.name === "create-bulk-proposal"
			) {
				const { flexibleExpectedReturnsVolatility } = removeDeltaFromSelectedMarketView(
					marketScenario.flexibleExpectedReturnsVolatility!.assetClasses!,
					originalScenario.flexibleExpectedReturnsVolatility!.assetClasses!,
					marketScenario.regimeUserProbability!,
				);

				onStepDataChange(
					await context.saveHandlers.marketView({
						scenarioData:
							item?.id === "POSITIONING_INDICATORS"
								? marketScenario
								: {
										...marketScenario,
										flexibleExpectedReturnsVolatility: { assetClasses: flexibleExpectedReturnsVolatility },
								  },
						scenarioIdentifier: currentScenarioId,
						custom: item?.isCustom,
						name: item?.name,
					}),
					{ skipMetadataUpdate: true, persist: true },
				);
				setIsDirty(false);
				setLoadingFlag(0);

				if (context.area.name !== "create-bulk-proposal") {
					trackMixPanelEvent("Portfolio-Draft", {
						Type: context.area.name === "create" ? "creation" : "enhance",
						UID: context.area.portfolioUid,
						Step: "market-view",
						Action: "save",
						ScenarioIdentifier: currentScenarioId ?? "not selected",
						RegimeUserProbability: marketScenario?.regimeProbabilities,
						RegimeDefaultProbability: marketScenario?.regimeUserProbability,
					});
				}

				return;
			}

			if (!originalScenario || !marketScenario || !currentScenarioId) {
				onStepDataChange(
					await context.saveHandlers.marketView({
						scenarioData: {
							assetClasses: undefined,
							editable: undefined,
							flexibleExpectedReturnsVolatility: undefined,
							forecastHorizon: undefined,
							historicalInvestmentHorizon: undefined,
							marketViewType: undefined,
							positioningIndicators: undefined,
							regimeProbabilities: undefined,
							regimeUserProbability: undefined,
							yield: undefined,
						},
						scenarioIdentifier: undefined,
						custom: undefined,
						name: undefined,
					}),
					{ skipMetadataUpdate: true, persist: true },
				);
				setIsDirty(false);
				setLoadingFlag(0);
			} else {
				const { flexibleExpectedReturnsVolatility } = removeDeltaFromSelectedMarketView(
					marketScenario.flexibleExpectedReturnsVolatility!.assetClasses!,
					originalScenario.flexibleExpectedReturnsVolatility!.assetClasses!,
					marketScenario.regimeUserProbability!,
				);
				onStepDataChange(
					await context.saveHandlers.marketView({
						scenarioData:
							item?.id === "POSITIONING_INDICATORS"
								? marketScenario
								: {
										...marketScenario,
										flexibleExpectedReturnsVolatility: { assetClasses: flexibleExpectedReturnsVolatility },
								  },
						scenarioIdentifier: currentScenarioId,
						custom: item?.isCustom,
						name: item?.name,
					}),
					{ skipMetadataUpdate: true, persist: true },
				);
				setIsDirty(false);
				setLoadingFlag(0);
			}
		} catch (err) {
			onStepError();
			console.error("cannot save market view step", err);
			throw err;
		}
	}, [
		isSubmitDisabled,
		originalScenario,
		marketScenario,
		currentScenarioId,
		context.area.name,
		context.saveHandlers,
		onStepDataChange,
		item?.id,
		item?.isCustom,
		item?.name,
		onStepError,
	]);
	useEffect(() => {
		context.submittersRef.current.marketView = handleSubmit;
	});

	const { formatDate } = useLocaleFormatters();

	return (
		<StepBase title="Market View" optional={stepMetadata.optional} onSubmitAsync={handleSubmit}>
			<p className="mb-4" data-qualifier="portfolioWizard/marketView/hint">
				In this step, you can tailor your portfolio to align with a specific market outlook. Load an existing market
				view, choose from Sphere&lsquo;s view, or customize it with your insights.
			</p>
			<RenderingGuard
				type="condition"
				when={
					context.area.name === "create" ||
					context.area.name === "enhance" ||
					context.area.name === "create-bulk-proposal" ||
					edit
				}
			>
				<AddConstraintSection
					constraintAdders={constraintAdders}
					constraintList={[]}
					onAdd={(type) => {
						if (type === "load") {
							spawn<string>(
								adaptAnimatedNodeProvider(({ resolve, show, onHidden, reject }) => (
									<LoadMarketViewModal
										userScenarios={selectableMarketViewQuery.data?.userScenarios ?? []}
										onSubmit={resolve}
										onClose={() => reject("user cancelled")}
										show={show}
										onAnimationStateChange={(state) => state === "hidden" && onHidden()}
										marketViewSetting={marketViewSettings?.setting}
									/>
								)),
							)
								.promise.then((customScenarioId) => {
									const customScenario = selectableMarketViewQuery.data?.userScenarios?.find(
										(x) => x.uuid === customScenarioId,
									);
									if (!customScenario) {
										throw new Error("invalid scenario id");
									}

									setItem({
										id: customScenario.type!,
										isCustom: customScenario.custom,
										action: "load",
										name: customScenario?.name,
									});
									setCurrentScenarioId(customScenarioId);
									setIsDirty(true);
								}, noop)
								.catch(console.warn);
						}

						if (type === "POSITIONING_INDICATORS") {
							const defaultScenarioId = selectableMarketViewQuery.data?.positioningDefaultScenarios?.[0].id;
							setItem({ id: type, isCustom: false, action: "POSITIONING_INDICATORS" });

							if (defaultScenarioId) {
								setCurrentScenarioId(defaultScenarioId);
							}
							setIsDirty(true);
						}

						if (type === "EXPECTED_RETURNS_VOLATILITY") {
							const defaultScenarioId = selectableMarketViewQuery.data?.returnsVolatilityDefaultScenarios?.[0].id;
							setItem({ id: type, isCustom: false, action: "EXPECTED_RETURNS_VOLATILITY" });
							if (defaultScenarioId) {
								setCurrentScenarioId(defaultScenarioId);
							}
							setIsDirty(true);
						}

						if (type === "CUSTOM") {
							const defaultScenarioId = selectableMarketViewQuery.data?.customDefaultScenarios?.[0].id;
							setItem({
								id: marketViewSettings?.setting.customMarketViewType ?? null,
								isCustom: true,
								action: "CUSTOM",
							});
							if (defaultScenarioId) {
								setCurrentScenarioId(defaultScenarioId);
							}
							setIsDirty(true);
						}
					}}
					customLabel="Select how you want to indicate your Market View"
				/>
			</RenderingGuard>
			{!item ||
			!item.id ||
			((context.area.name === "settings-current" || context.area.name === "settings-enhanced" ? !edit : false) &&
				!currentScenarioId) ? (
				<div className="h-[50dvh]">
					<IconWalls.ConstraintNotSet constraintName="market view" />
				</div>
			) : (
				<div className="relative">
					{(context.area.name === "create" ||
						context.area.name === "enhance" ||
						context.area.name === "create-bulk-proposal" ||
						edit) && (
						<button
							data-qualifier="portfolioWizard/marketView/delete"
							type="button"
							className="absolute top-5 right-2 z-10"
							onClick={(e) => {
								setIsDirty(true);
								setItem({ id: null, isCustom: false });
								setMarketScenario(null);
								setCurrentScenarioId(null);
								e.preventDefault();
								e.stopPropagation();
							}}
						>
							<Icon icon="Delete" size={21} color={themeCSSVars.Button_bg_primary} />
						</button>
					)}
					{item && item.id && (
						<ReactQueryWrapperBase query={selectableMarketViewQuery}>
							{(selectableMarketViews) => (
								<Switch
									case={item.id!}
									match={{
										[MarketViewType.PositioningIndicators]: () => {
											const customScenario = selectableMarketViews?.userScenarios?.find(
												(x) => x.uuid === currentScenarioId,
											);

											return (
												<MarketViewPositioning
													classList="!py-0"
													mode={(() => {
														if (
															context.area.name === "create" ||
															context.area.name === "draft" ||
															context.area.name === "enhance" ||
															context.area.name === "create-bulk-proposal" ||
															context.area.name === "edit-bulk-settings"
														) {
															if (
																marketScenario &&
																marketScenario.editable &&
																customScenario === undefined &&
																currentScenarioId !== currentMarketViewId
															) {
																return "edit";
															}
														}

														if (context.area.name === "settings-current") {
															if (edit && marketScenario?.editable && currentScenarioId !== customMarketViewId) {
																return "edit";
															}
														}

														return "view";
													})()}
													marketScenarioProvider={async (scenarioId) => {
														if (scenarioId === stepData.scenarioIdentifier && stepData.scenarioData) {
															return stepData.scenarioData;
														}

														let loadedScenario = await match(context)
															.with({ area: { name: "create-bulk-proposal" } }, (ctx) =>
																axiosExtract(
																	bulkEnhancementApi.loadEnhancementConfigurationMarketView1(
																		ctx.area.proposalUuid,
																		scenarioId,
																		item.isCustom,
																	),
																),
															)
															.with({ area: { name: "edit-bulk-settings" } }, (ctx) =>
																axiosExtract(
																	investmentBulkBulkStaticV1Api.loadStaticConfigurationMarketView1(
																		ctx.area.bulkUid,
																		scenarioId,
																		item.isCustom,
																	),
																),
															)
															.with({ area: { name: "enhance" } }, (ctx) =>
																axiosExtract(
																	enhancementApi.loadEnhancementConfigurationMarketView(
																		ctx.area.portfolioUid,
																		scenarioId,
																		item.isCustom,
																	),
																),
															)
															.with({ area: { name: "settings-enhanced" } }, (ctx) =>
																axiosExtract(
																	staticController.loadStaticConfigurationMarketView(
																		ctx.area.portfolioUid,
																		scenarioId,
																		item.isCustom,
																	),
																),
															)
															.with({ area: { name: "settings-current" } }, (ctx) =>
																axiosExtract(
																	staticController.loadStaticConfigurationMarketView(
																		ctx.area.portfolioUid,
																		scenarioId,
																		item.isCustom,
																	),
																),
															)
															.with({ area: { name: "create" } }, () =>
																axiosExtract(
																	creationApi.loadCreationConfigurationMarketView(scenarioId, item.isCustom),
																),
															)
															.with({ area: { name: "draft" } }, (ctx) =>
																axiosExtract(
																	draftApi.loadDraftConfigurationMarketView(
																		ctx.area.portfolioUid,
																		scenarioId,
																		item.isCustom,
																	),
																),
															)
															.exhaustive();

														if (currentScenarioId !== currentMarketViewId && item.action !== "load") {
															loadedScenario = {
																...loadedScenario,
																positioningIndicators: {
																	...loadedScenario.positioningIndicators,
																	positioningIndicators: item.isCustom
																		? loadedScenario.positioningIndicators?.positioningIndicators
																		: loadedScenario.positioningIndicators?.positioningIndicators?.map((indicator) => {
																				const mapped = {
																					...indicator,
																					mdotmCommentary:
																						defaultScenario?.positioningIndicators?.positioningIndicators?.find(
																							(x) => x.microAssetClass === indicator.microAssetClass,
																						)?.commentary,
																					mdotmPositioning:
																						defaultScenario?.positioningIndicators?.positioningIndicators?.find(
																							(x) => x.microAssetClass === indicator.microAssetClass,
																						)?.defaultPositioning,
																				};

																				if (currentScenarioId !== MarketScenarioIds.SphereForecastPositioningOneMonth) {
																					mapped.commentary = "";
																				}

																				if (
																					currentScenarioId !== MarketScenarioIds.UserForecastPositioningOneMonth &&
																					currentScenarioId !== MarketScenarioIds.SphereForecastPositioningOneMonth
																				) {
																					mapped.mdotmCommentary = undefined;
																					mapped.mdotmPositioning = undefined;
																				}

																				return mapped;
																		  }),
																},
															};
														}

														// setIsDirty(true);
														setOriginalScenario(loadedScenario);
														return loadedScenario; /* TODO: creationDate is missing from API */
													}}
													marketScenario={marketScenario}
													onMarketScenarioChange={(scenario) => {
														setLoadingFlag((flag) => {
															const nextFlag = flag + 1;
															if (flag > 0) {
																setIsDirty(true);
															}

															return nextFlag;
														});

														setMarketScenario(scenario);
													}}
													scenarioOptions={
														customScenario
															? [{ label: customScenario.name ?? "-", value: customScenario.uuid ?? "-" }]
															: item.isCustom
															  ? selectableMarketViews.customDefaultScenarios?.map((x, i) => ({
																		label: x.label ?? `Untitled ${i}`,
																		value: x.id ?? `untitled_${i}`,
															    })) ?? []
															  : selectableMarketViews?.positioningDefaultScenarios
																		?.map((x, i) => ({
																			label: x.label ?? `Untitled ${i}`,
																			value: x.id ?? `untitled_${i}`,
																		}))
																		.concat(
																			stepData.scenarioData?.marketViewType === "POSITIONING_INDICATORS" &&
																				stepData.scenarioIdentifier === customMarketViewId
																				? [
																						{
																							label: `Market view ${formatDate(
																								stepData.scenarioData?.historicalInvestmentHorizon?.from,
																							)}`,
																							value: stepData.scenarioIdentifier,
																						},
																				  ]
																				: [],
																		) ?? []
													}
													selectedScenarioId={currentScenarioId}
													onSelectedScenarioChange={setCurrentScenarioId}
													canReadCommentary={marketViewSettings?.setting.commentaryEditorVisible}
													alias={marketViewSettings?.alias.assetClassAliases}
													marketViewSettings={item.isCustom ? marketViewSettings?.setting : undefined}
													hideOutlookToopltip={
														item.isCustom ||
														!(
															currentScenarioId === MarketScenarioIds.UserForecastPositioningOneMonth ||
															currentScenarioId === MarketScenarioIds.SphereForecastPositioningOneMonth
														)
													}
												/>
											);
										},
										[MarketViewType.ExpectedReturnsVolatility]: () => {
											const customScenario = selectableMarketViews?.userScenarios?.find(
												(x) => x.uuid === currentScenarioId,
											);
											console.log({ customScenario, currentScenarioId });
											return (
												<MarketViewProbabilities
													mode={(() => {
														if (
															context.area.name === "create" ||
															context.area.name === "draft" ||
															context.area.name === "enhance" ||
															context.area.name === "create-bulk-proposal" ||
															context.area.name === "edit-bulk-settings"
														) {
															if (
																marketScenario &&
																marketScenario.editable &&
																customScenario === undefined &&
																currentScenarioId !== currentMarketViewId
															) {
																return "edit";
															}
														}

														if (context.area.name === "settings-current") {
															if (edit && marketScenario?.editable && currentScenarioId !== customMarketViewId) {
																return "edit";
															}
														}

														return "view";
													})()}
													marketScenarioProvider={async (scenarioId) => {
														if (scenarioId === stepData.scenarioIdentifier && stepData.scenarioData) {
															return stepData.scenarioData;
														}

														const response = await match(context)
															.with({ area: { name: "create-bulk-proposal" } }, (ctx) =>
																axiosExtract(
																	bulkEnhancementApi.loadEnhancementConfigurationMarketView1(
																		ctx.area.proposalUuid!,
																		scenarioId,
																		item.isCustom,
																	),
																),
															)
															.with({ area: { name: "edit-bulk-settings" } }, (ctx) =>
																axiosExtract(
																	investmentBulkBulkStaticV1Api.loadStaticConfigurationMarketView1(
																		ctx.area.bulkUid!,
																		scenarioId,
																		item.isCustom,
																	),
																),
															)
															.with({ area: { name: "enhance" } }, (ctx) =>
																axiosExtract(
																	enhancementApi.loadEnhancementConfigurationMarketView(
																		ctx.area.portfolioUid,
																		scenarioId,
																		item.isCustom,
																	),
																),
															)
															.with({ area: { name: "settings-enhanced" } }, (ctx) =>
																axiosExtract(
																	staticController.loadStaticConfigurationMarketView(
																		ctx.area.portfolioUid!,
																		scenarioId,
																		item.isCustom,
																	),
																),
															)
															.with({ area: { name: "settings-current" } }, (ctx) =>
																axiosExtract(
																	staticController.loadStaticConfigurationMarketView(
																		ctx.area.portfolioUid!,
																		scenarioId,
																		item.isCustom,
																	),
																),
															)
															.with({ area: { name: "create" } }, () =>
																axiosExtract(
																	creationApi.loadCreationConfigurationMarketView(scenarioId, item.isCustom),
																),
															)
															.with({ area: { name: "draft" } }, (ctx) =>
																axiosExtract(
																	draftApi.loadDraftConfigurationMarketView(
																		ctx.area.portfolioUid!,
																		scenarioId,
																		item.isCustom,
																	),
																),
															)
															.exhaustive();

														// setIsDirty(true);
														setOriginalScenario(response);
														return response;
													}}
													marketScenario={marketScenario}
													onMarketScenarioChange={(scenario) => {
														setLoadingFlag((flag) => {
															const nextFlag = flag + 1;
															if (flag > 0) {
																setIsDirty(true);
															}

															return nextFlag;
														});
														setMarketScenario(scenario);
													}}
													scenarioOptions={
														customScenario
															? [{ label: customScenario.name ?? "-", value: customScenario.uuid ?? "-" }]
															: item.isCustom
															  ? selectableMarketViews.customDefaultScenarios?.map((x, i) => ({
																		label: x.label ?? `Untitled ${i}`,
																		value: x.id ?? `untitled_${i}`,
															    })) ?? []
															  : selectableMarketViews?.returnsVolatilityDefaultScenarios
																		?.map((x, i) => ({
																			label: x.label ?? `Untitled ${i}`,
																			value: x.id ?? `untitled_${i}`,
																		}))
																		.concat(
																			stepData.scenarioData?.marketViewType === "EXPECTED_RETURNS_VOLATILITY" &&
																				stepData.scenarioIdentifier === customMarketViewId
																				? [
																						{
																							label: `Market view ${formatDate(
																								stepData.scenarioData?.historicalInvestmentHorizon?.from,
																							)}`,
																							value: stepData.scenarioIdentifier,
																						},
																				  ]
																				: [],
																		) ?? []
													}
													selectedScenarioId={currentScenarioId}
													onSelectedScenarioChange={setCurrentScenarioId}
													canReadCommentary={marketViewSettings?.setting.commentaryEditorVisible}
													alias={marketViewSettings?.alias.assetClassAliases}
													marketViewSettings={item.isCustom ? marketViewSettings?.setting : undefined}
													hidePositioning={
														selectableMarketViews?.userScenarios?.some((x) => x.uuid === currentScenarioId) ||
														currentScenarioId === customMarketViewId
													}
												/>
											);
										},
									}}
								/>
							)}
						</ReactQueryWrapperBase>
					)}
				</div>
			)}
		</StepBase>
	);
}

function LoadMarketViewModal(props: {
	userScenarios: MarketViewListEntry[];
	onSubmit?(uuid: string): void;
	show: boolean;
	onClose: () => void;
	onAnimationStateChange?: ModalProps["onAnimationStateChange"];
	marketViewSetting?: MarketViewSettings;
}) {
	const { t } = useTranslation();
	const { formatDate } = useLocaleFormatters();
	const searchable = useSearchable({
		collection: props.userScenarios,
		matchFn: (row, q) => JSON.stringify(row).toLowerCase().includes(q.toLowerCase()),
	});

	const {
		column: checkBoxColumn,
		rowClassList,
		toggle,
		multiSelectCtx,
	} = useSelectableTableColumn({
		rows: searchable.filtered,
		selectBy: ({ uuid }) => uuid ?? "",
		mode: "radio",
	});

	const columns = useMemo<TableColumn<MarketViewListEntry>[]>(
		() => [
			checkBoxColumn,
			{
				name: "name",
				header: t("TABLE.HEADERS.NAME"),
				contentTextType: "Body/M/Bold",
				content: (row) => row.name,
			},
			{
				name: "type",
				header: t("TABLE.HEADERS.TYPE"),
				content: (row) => {
					if (row.custom) {
						return props.marketViewSetting?.customMarketViewName ?? "-";
					}
					return row.type ? t(row.type) : "-";
				},
			},
			{
				name: "creationDate",
				header: t("TABLE.HEADERS.CREATION"),
				content: (row) => (row.creationDate ? formatDate(new Date(row.creationDate)) : "-"),
			},
			{
				name: "forecastHorizon",
				header: t("TABLE.HEADERS.FORECAST_HORIZON"),
				content: (row) => (row.forecastHorizon ? t(`INVESTMENT_HORIZON.${row.forecastHorizon}`) : "-"),
			},
			{
				name: "expiration",
				header: t("TABLE.HEADERS.EXPIRATION"),
				content: (row, cellProps) => {
					if (!row.endDate) {
						return "-";
					}
					const expirationDate = new Date(row.endDate);
					if (differenceInDays(new Date(), expirationDate) > 0) {
						return (
							<div
								className={overrideClassName(cellProps.classList, "flex items-center justify-start")}
								style={cellProps.style}
							>
								<div
									className={`bg-[${themeCSSVars.palette_W50}] flex items-center gap-2 py-1 px-2 text-[${themeCSSVars.palette_W500}] rounded-full`}
								>
									<Icon icon="Icon-full-alert" color={themeCSSVars.palette_W500} size={16} />
									<p className="font-semibold">{formatDate(expirationDate)}</p>
								</div>
							</div>
						);
					}

					return formatDate(expirationDate);
				},
				sortFn: ({ endDate: a }, { endDate: b }) => {
					const rowA = new Date(checkUndefined<string>(a, "")).getTime();
					const rowB = new Date(checkUndefined<string>(b, "")).getTime();
					return sortingFn<number>({ rowA, rowB });
				},
			},
		],
		[checkBoxColumn, formatDate, props.marketViewSetting?.customMarketViewName, t],
	);

	return (
		<>
			<Dialog
				onAnimationStateChange={props.onAnimationStateChange}
				size="xxlarge"
				show={props.show}
				onClose={() => props.onClose()}
				onSubmitAsync={() => {
					props.onSubmit?.(multiSelectCtx.data.selection.first());
				}}
				header="Select existing market view"
				footer={
					<DialogFooter
						primaryAction={
							<SubmitButton
								data-qualifier="portfolioWizard/marketView/loadMarketView"
								disabled={multiSelectCtx.data.selection.size === 0}
							>
								Load MarketView
							</SubmitButton>
						}
						neutralAction={
							<Button palette="tertiary" onClick={() => props.onClose()}>
								Cancel
							</Button>
						}
					/>
				}
			>
				<div className="mb-4">
					<TextInput
						data-qualifier="portfolioWizard/marketView/filterByName"
						placeholder="Filter by name"
						classList="max-w-[480px]"
						value={searchable.query}
						onChangeText={searchable.setQuery}
					/>
				</div>
				<AutoSortTable
					data-qualifier="portfolioWizard/marketView/filteredEntries"
					rows={searchable.filtered}
					rowClassList={rowClassList}
					columns={columns}
					classList="max-h-[410px]"
					onRowClick={({ uuid }) => toggle(uuid ?? "")}
					noDataText="no scenario available"
				/>
			</Dialog>
		</>
	);
}
