import { useMemo } from "react";
import type { CommonSetUpReportProps, UnionMapData } from "../../configuration/hooks/useExtractReports";
import { customObjectEntriesFn } from "$root/utils/experimental";
import { Text } from "@mdotm/mdotui/components";
import ChartStepperLined from "$root/ui-lib/charts/ChartStepperLined";
import { useTranslation } from "react-i18next";
import useWidgetsData from "$root/hooks/useWidgetsData";
import type { PrintableProps } from "../../configuration";
import { Card } from "../Card";
import { tinyTableDataCellFontSize } from "../table/tiny-table";

type AssetClassOverviewProps = CommonSetUpReportProps<UnionMapData["custom-report"]>;

const AssetClassOverview = ({ data }: PrintableProps<{ data: AssetClassOverviewProps }, number>): JSX.Element => {
	const { assetClassIndicators } = data;
	const { t } = useTranslation();
	const { currentWidgetsData } = useWidgetsData();

	const assetClassOverviewMap = useMemo(() => {
		return {
			equity_indicators: assetClassIndicators.equityIndicators,
			fixed_income_indicators: assetClassIndicators.fixedIncomeIndicators,
			commodities_indicators: assetClassIndicators.commoditiesIndicators,
		};
	}, [
		assetClassIndicators.commoditiesIndicators,
		assetClassIndicators.equityIndicators,
		assetClassIndicators.fixedIncomeIndicators,
	]);

	return (
		<Card title="Asset Class Overview">
			{assetClassOverviewMap && (
				<div className="grid grid-cols-3 gap-6">
					{customObjectEntriesFn(assetClassOverviewMap).map(([indicatorKey, indicatorValue], i) =>
						(() => {
							const { currentValue, prevValue } = indicatorValue ?? {};
							if (currentValue?.value === undefined || indicatorKey === undefined) {
								return <></>;
							}
							const sanitizePrevValue = prevValue?.value === currentValue.value ? undefined : prevValue?.value;
							const indicatorStrings = t(`OUTLOOK.indicators.${indicatorKey}`, { returnObjects: true });
							const labels = indicatorStrings.labels;
							return (
								<div key={i} className="grow">
									<Text type="Body/M/Bold" classList="mb-2">
										{indicatorStrings.title}
									</Text>
									<div className="h-20 flex items-center">
										<ChartStepperLined
											columnNumber={5}
											labelFontSize={tinyTableDataCellFontSize}
											current={{
												value: currentValue.value - 1,
												tooltip: [
													{
														label: t("CURRENT", { returnObjects: false }),
														value: indicatorStrings.tooltips[currentValue.value - 1],
														data: currentValue?.data,
													},
												],
											}}
											previous={{
												value: sanitizePrevValue ? sanitizePrevValue - 1 : sanitizePrevValue,
												tooltip: sanitizePrevValue
													? [
															{
																label: t("PREVIOUS"),
																value: indicatorStrings.tooltips[sanitizePrevValue - 1],
																data: prevValue?.data,
															},
													  ]
													: [],
											}}
											labels={labels}
											line="dotted"
											lastDashboardUpdate={currentWidgetsData.creation_time}
										/>
									</div>
								</div>
							);
						})(),
					)}
				</div>
			)}
		</Card>
	);
};

export default AssetClassOverview;
