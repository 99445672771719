// TODO: add sphere default template in frontend

import { FormFields } from "$root/ui-lib/form/FormFields";
import {
	AsyncButton,
	Banner,
	Button,
	Checkbox,
	Collapsible,
	DefaultCollapsibleHeader,
	DraggableList,
	Text,
} from "@mdotm/mdotui/components";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import { ForEach, toClassName } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { useState, type ReactNode } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import type { ReportTemplateVariant } from "./report-latest";
import { itemToLabelMap, type ReportTemplateItemFor, type ReportTemplateItemMap } from "./report-latest";
import { typedObjectKeys } from "$root/utils/objects";
import { Map } from "immutable";
import ReactQueryWrapper from "$root/components/ReactQueryWrapper";
import { CommentaryTemplateControllerApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { axiosExtract } from "$root/third-party-integrations/axios";

export type SetConfigurationReportTemplateHelper<K extends keyof ReportTemplateItemMap> = {
	templateVariant: ReportTemplateVariant;
	configuration: ReportTemplateItemFor<K>;
	onChange(newConfiguration: ReportTemplateItemFor<K>): void;
	onCancel(): void;
};

export function SideHeaderContent(props: {
	title: string;
	onCancel?(): void;
	onSubmit(): MaybePromise<void>;
}): JSX.Element {
	const { t } = useTranslation();
	return (
		<div className={`flex items-center border-b border-solid border-b-[color:${themeCSSVars.palette_N100}] p-4`}>
			{/* <Icon icon="share" classList="mr-1" size={22} color={themeCSSVars.palette_N700} /> */}
			<Text type="Body/XL/Bold" as="p" classList="grow h-fit line-clamp-2">
				{props.title}
			</Text>
			{/* <Button unstyled>
					<Icon icon="ask-positionleftsmall" color={themeCSSVars.palette_N700} size={18} />
				</Button> */}
			<div className="flex justify-end py-2 px-2 space-x-2 bg-white">
				<Button palette="tertiary" onClick={props.onCancel}>
					{t("BUTTON.CANCEL")}
				</Button>
				<AsyncButton palette="primary" onClickAsync={props.onSubmit}>
					{t("BUTTON.SAVE")}
				</AsyncButton>
			</div>
		</div>
	);
}

export function SideBarContent(props: {
	title: string;
	onSubmit(): MaybePromise<void>;
	onCancel(): void;
	children: ReactNode;
}): JSX.Element {
	// bg-[color:${themeCSSVars.palette_N20}]
	return (
		<div className="grow bg-white overflow-y-auto pb-6">
			<SideHeaderContent title={props.title} onSubmit={props.onSubmit} onCancel={props.onCancel} />
			<div className={`px-4 pt-4 border-t border-solid border-t-[color:${themeCSSVars.palette_N100}]`}>
				{props.children}
			</div>
		</div>
	);
}

export function Summary(props: SetConfigurationReportTemplateHelper<"summary">): JSX.Element {
	const { id, kind, ...opts } = props.configuration;
	const { t } = useTranslation();
	const { control, handleSubmit, formState } = useForm({
		defaultValues: opts,
	});

	return (
		<SideBarContent
			title={itemToLabelMap[kind]}
			onSubmit={async () => {
				await handleSubmit((payload) => props.onChange({ id, kind, ...payload }))();
			}}
			onCancel={props.onCancel}
		>
			<div>
				<p className="mb-4 font-semibold">Select the elements you want to display</p>
				<ForEach collection={typedObjectKeys(opts)}>
					{({ item: key }) => (
						<div>
							<FormFields.Checkbox control={control} formState={formState} name={key} classList="mb-1">
								{t(`PORTFOLIO_SUMMARY.${key}`)}
							</FormFields.Checkbox>
						</div>
					)}
				</ForEach>
			</div>
		</SideBarContent>
	);
}

export function Disclaimer(props: SetConfigurationReportTemplateHelper<"disclaimer">): JSX.Element {
	const { kind, id, ...opts } = props.configuration;
	const { control, handleSubmit, formState, watch } = useForm({
		defaultValues: opts,
	});

	return (
		<SideBarContent
			title={itemToLabelMap[kind]}
			onSubmit={async () => {
				await handleSubmit((payload) => props.onChange({ id, kind, ...payload }))();
			}}
			onCancel={props.onCancel}
		>
			<p className="mb-4 font-semibold">Customize the title you want to display</p>
			<FormFields.Checkbox control={control} formState={formState} name="content.enabled" classList="mb-2">
				Custom Text
			</FormFields.Checkbox>

			<FormFields.Text
				control={control}
				formState={formState}
				label="Title"
				name="content.title"
				classList="mb-2"
				disabled={!watch("content.enabled")}
			/>

			<FormFields.TextArea
				control={control}
				formState={formState}
				label="Text"
				name="content.description"
				classList="mb-4 [&>textarea]:resize-none"
				disabled={!watch("content.enabled")}
				rows={5}
			/>

			<FormFields.Checkbox control={control} formState={formState} name="contact.enabled" classList="mb-2">
				Contacts
			</FormFields.Checkbox>
			<FormFields.TextArea
				control={control}
				formState={formState}
				label=""
				name="contact.description"
				classList="mb-2 [&>textarea]:resize-none"
				disabled={!watch("contact.enabled")}
				rows={5}
			/>
		</SideBarContent>
	);
}

export function Cover(props: SetConfigurationReportTemplateHelper<"cover">): JSX.Element {
	const { kind, id, ...opts } = props.configuration;
	const { control, handleSubmit, formState } = useForm({
		defaultValues: opts,
	});

	return (
		<SideBarContent
			title={itemToLabelMap[kind]}
			onSubmit={async () => {
				await handleSubmit((payload) => props.onChange({ id, kind, ...payload }))();
			}}
			onCancel={props.onCancel}
		>
			<p className="mb-4 font-semibold">Customize the title you want to display</p>
			<FormFields.Text label="Title" name="title" control={control} formState={formState} />
		</SideBarContent>
	);
}

export function PortfolioMetrics(props: SetConfigurationReportTemplateHelper<"portfolioMetrics">): JSX.Element {
	const { kind, id, ...opts } = props.configuration;
	const { t } = useTranslation();
	const { control, handleSubmit, formState, watch } = useForm({
		defaultValues: opts,
	});

	const volatilityOpts = watch("volatility");
	const selectedVolatilityOpts = Object.values(volatilityOpts).filter((opt) => opt);

	const efficiencyRatioOpts = watch("efficiencyRatio");
	const selectedEfficiencyRatioOpts = Object.values(efficiencyRatioOpts).filter((opt) => opt);

	const maxDrawdownOpts = watch("maxDrawdown");
	const selectedMaxDrawdownOpts = Object.values(maxDrawdownOpts).filter((opt) => opt);

	const performanceOpts = watch("performance");
	const selectedPerformanceOpts = Object.values(performanceOpts).filter((opt) => opt);

	const sortinoOpts = watch("sortino");
	const selectedSortinoOpts = Object.values(sortinoOpts).filter((opt) => opt);

	function PortfolioMetricsCollapsibleContent(contentProps: { children: ReactNode }): JSX.Element {
		return <div className="p-2">{contentProps.children}</div>;
	}

	function selectionToLabel(list: Array<any>) {
		return list.length > 0 ? `(${list.length})` : "";
	}

	return (
		<SideBarContent
			title={itemToLabelMap[kind]}
			onSubmit={async () => {
				await handleSubmit((payload) => props.onChange({ id, kind, ...payload }))();
			}}
			onCancel={props.onCancel}
		>
			<p className="mb-4 font-semibold">Select the elements you want to display</p>
			<div className="flex flex-col space-y-2">
				{/* volatility */}
				<Collapsible
					header={(params) => (
						<DefaultCollapsibleHeader {...params}>
							Volatility {selectionToLabel(selectedVolatilityOpts)}
						</DefaultCollapsibleHeader>
					)}
				>
					<PortfolioMetricsCollapsibleContent>
						<ForEach collection={typedObjectKeys(volatilityOpts)}>
							{({ item: key }) => (
								<div>
									<FormFields.Checkbox
										control={control}
										formState={formState}
										name={`volatility.${key}`}
										classList="mb-1"
									>
										{t(`PERFORMANCE_METRICS.METRICS.FULL.${key}`)}
									</FormFields.Checkbox>
								</div>
							)}
						</ForEach>
					</PortfolioMetricsCollapsibleContent>
				</Collapsible>

				{/* Efficiency ratio */}
				<Collapsible
					header={(params) => (
						<DefaultCollapsibleHeader {...params}>
							Efficiency ratio {selectionToLabel(selectedEfficiencyRatioOpts)}
						</DefaultCollapsibleHeader>
					)}
				>
					<PortfolioMetricsCollapsibleContent>
						<ForEach collection={typedObjectKeys(efficiencyRatioOpts)}>
							{({ item: key }) => (
								<div>
									<FormFields.Checkbox
										control={control}
										formState={formState}
										name={`efficiencyRatio.${key}`}
										classList="mb-1"
									>
										{t(`PERFORMANCE_METRICS.METRICS.FULL.${key}`)}
									</FormFields.Checkbox>
								</div>
							)}
						</ForEach>
					</PortfolioMetricsCollapsibleContent>
				</Collapsible>

				{/* Max drawdown ratio */}
				<Collapsible
					header={(params) => (
						<DefaultCollapsibleHeader {...params}>
							Max drawdown {selectionToLabel(selectedMaxDrawdownOpts)}
						</DefaultCollapsibleHeader>
					)}
				>
					<PortfolioMetricsCollapsibleContent>
						<ForEach collection={typedObjectKeys(maxDrawdownOpts)}>
							{({ item: key }) => (
								<div>
									<FormFields.Checkbox
										control={control}
										formState={formState}
										name={`maxDrawdown.${key}`}
										classList="mb-1"
									>
										{t(`PERFORMANCE_METRICS.METRICS.FULL.${key}`)}
									</FormFields.Checkbox>
								</div>
							)}
						</ForEach>
					</PortfolioMetricsCollapsibleContent>
				</Collapsible>

				{/* Performance */}
				<Collapsible
					header={(params) => (
						<DefaultCollapsibleHeader {...params}>
							Performance {selectionToLabel(selectedPerformanceOpts)}
						</DefaultCollapsibleHeader>
					)}
				>
					<PortfolioMetricsCollapsibleContent>
						<ForEach collection={typedObjectKeys(performanceOpts)}>
							{({ item: key }) => (
								<div>
									<FormFields.Checkbox
										control={control}
										formState={formState}
										name={`performance.${key}`}
										classList="mb-1"
									>
										{t(`PERFORMANCE_METRICS.METRICS.FULL.${key}`)}
									</FormFields.Checkbox>
								</div>
							)}
						</ForEach>
					</PortfolioMetricsCollapsibleContent>
				</Collapsible>

				{/* Sortino */}
				<Collapsible
					header={(params) => (
						<DefaultCollapsibleHeader {...params}>
							Sortino {selectionToLabel(selectedSortinoOpts)}
						</DefaultCollapsibleHeader>
					)}
				>
					<PortfolioMetricsCollapsibleContent>
						<ForEach collection={typedObjectKeys(sortinoOpts)}>
							{({ item: key }) => (
								<div>
									<FormFields.Checkbox control={control} formState={formState} name={`sortino.${key}`} classList="mb-1">
										{t(`PERFORMANCE_METRICS.METRICS.FULL.${key}`)}
									</FormFields.Checkbox>
								</div>
							)}
						</ForEach>
					</PortfolioMetricsCollapsibleContent>
				</Collapsible>
			</div>
			<div className={`mt-4 pt-4 -mx-4 border-t border-solid border-t-[color:${themeCSSVars.palette_N100}]`}>
				<div className="px-4">
					<p className="mb-2 font-semibold">Compare</p>
					<FormFields.Checkbox control={control} formState={formState} name="benchmark">
						Show benchmark
					</FormFields.Checkbox>
				</div>
			</div>
		</SideBarContent>
	);
}

export function ExAntePortfolioMetrics(
	props: SetConfigurationReportTemplateHelper<"exAntePortfolioMetrics">,
): JSX.Element {
	const { kind, id, ...opts } = props.configuration;
	const { t } = useTranslation();
	const { control, handleSubmit, formState, watch } = useForm({
		defaultValues: opts,
	});

	const volatilityOpts = watch("volatility");
	const selectedVolatilityOpts = Object.values(volatilityOpts).filter((opt) => opt);

	const diversificationRatioOpts = watch("diversificationRatio");
	const selectedDiversificationRatioOpts = Object.values(diversificationRatioOpts).filter((opt) => opt);

	const efficiencyRatioOpts = watch("efficiencyRatio");
	const selectedEfficiencyRatioOpts = Object.values(efficiencyRatioOpts).filter((opt) => opt);

	const maxDrawdownOpts = watch("maxDrawdown");
	const selectedMaxDrawdownOpts = Object.values(maxDrawdownOpts).filter((opt) => opt);

	const historicalVarOpts = watch("historicalVar");
	const selectedHistoricalVarOpts = Object.values(historicalVarOpts).filter((opt) => opt);

	const returnOpts = watch("return");
	const selectedReturnOpts = Object.values(returnOpts).filter((opt) => opt);

	const trackingErrorOpts = watch("trackingError");
	const selectedTrackingErrorOpts = Object.values(trackingErrorOpts).filter((opt) => opt);

	const riskConstraintOpts = watch("riskConstraint");
	const selectedRiskConstraintOpts = Object.values(riskConstraintOpts).filter((opt) => opt);

	function PortfolioMetricsCollapsibleContent(contentProps: { children: ReactNode }): JSX.Element {
		return <div className="p-2">{contentProps.children}</div>;
	}

	function selectionToLabel(list: Array<any>) {
		return list.length > 0 ? `(${list.length})` : "";
	}

	const exanteMetricList = [
		{
			name: "volatility",
			label: `Volatility ${selectionToLabel(selectedVolatilityOpts)}`,
			options: typedObjectKeys(volatilityOpts),
		} as const,
		{
			name: "diversificationRatio",
			label: `Diversification ratio ${selectionToLabel(selectedDiversificationRatioOpts)}`,
			options: typedObjectKeys(diversificationRatioOpts),
		} as const,
		{
			name: "efficiencyRatio",
			label: `Efficiency ratio ${selectionToLabel(selectedEfficiencyRatioOpts)}`,
			options: typedObjectKeys(efficiencyRatioOpts),
		} as const,
		{
			name: "maxDrawdown",
			label: `Max drawdown ${selectionToLabel(selectedMaxDrawdownOpts)}`,
			options: typedObjectKeys(maxDrawdownOpts),
		} as const,
		{
			name: "historicalVar",
			label: `Historical Var ${selectionToLabel(selectedHistoricalVarOpts)}`,
			options: typedObjectKeys(historicalVarOpts),
		} as const,
		{
			name: "return",
			label: `Return ${selectionToLabel(selectedReturnOpts)}`,
			options: typedObjectKeys(returnOpts),
		} as const,
		{
			name: "trackingError",
			label: `Tracking error ${selectionToLabel(selectedTrackingErrorOpts)}`,
			options: typedObjectKeys(trackingErrorOpts),
		} as const,
		{
			name: "riskConstraint",
			label: `Risk constraint ${selectionToLabel(selectedRiskConstraintOpts)}`,
			options: typedObjectKeys(riskConstraintOpts),
		} as const,
	];

	const { grouped, instances } = exanteMetricList.reduce<{
		grouped: Array<(typeof exanteMetricList)[number]>;
		instances: Array<(typeof exanteMetricList)[number]>;
	}>(
		(acc, el) => {
			if (el.options.length === 1) {
				acc.instances.push(el);
			}

			if (el.options.length > 1) {
				acc.grouped.push(el);
			}

			return acc;
		},
		{ grouped: [], instances: [] },
	);

	return (
		<SideBarContent
			title={itemToLabelMap[kind]}
			onSubmit={async () => {
				await handleSubmit((payload) => props.onChange({ id, kind, ...payload }))();
			}}
			onCancel={props.onCancel}
		>
			<p className="mb-4 font-semibold">Select the elements you want to display</p>
			<ForEach collection={instances}>
				{({ item }) => (
					<ForEach collection={item.options}>
						{({ item: key }) => (
							<div className="mb-1.5">
								<FormFields.Checkbox control={control} formState={formState} name={`${item.name}.${key}` as any}>
									{t(`EXANTE_METRICS.METRICS.${key}`)}
								</FormFields.Checkbox>
							</div>
						)}
					</ForEach>
				)}
			</ForEach>

			{grouped.length > 0 && <div className="mt-4" />}

			<ForEach collection={grouped}>
				{({ item }) => (
					<div className="mb-1.5">
						<Collapsible
							header={(params) => <DefaultCollapsibleHeader {...params}>{item.label}</DefaultCollapsibleHeader>}
						>
							<PortfolioMetricsCollapsibleContent>
								<ForEach collection={item.options}>
									{({ item: key }) => (
										<div>
											<FormFields.Checkbox
												control={control}
												formState={formState}
												name={`${item.name}.${key}` as any}
												classList="mb-px"
											>
												{t(`EXANTE_METRICS.METRICS.${key}`)}
											</FormFields.Checkbox>
										</div>
									)}
								</ForEach>
							</PortfolioMetricsCollapsibleContent>
						</Collapsible>
					</div>
				)}
			</ForEach>
			<div className={`mt-4 pt-4 -mx-4 border-t border-solid border-t-[color:${themeCSSVars.palette_N100}]`}>
				<div className="px-4">
					<p className="mb-2 font-semibold">Compare</p>
					<FormFields.Checkbox control={control} formState={formState} name="benchmark">
						Show benchmark
					</FormFields.Checkbox>
				</div>
			</div>
		</SideBarContent>
	);
}

export function FreeText(props: SetConfigurationReportTemplateHelper<"freeText">): JSX.Element {
	const { kind, id, ...opts } = props.configuration;
	const { control, handleSubmit, formState } = useForm({
		defaultValues: opts,
	});

	return (
		<SideBarContent
			title={itemToLabelMap[kind]}
			onSubmit={async () => {
				await handleSubmit((payload) => props.onChange({ id, kind, ...payload }))();
			}}
			onCancel={props.onCancel}
		>
			<p className="mb-4 font-semibold">Customize the description you want to display</p>
			<FormFields.TextArea label="Description" name="content" control={control} formState={formState} />
		</SideBarContent>
	);
}

export function Commentary(props: SetConfigurationReportTemplateHelper<"commentary">): JSX.Element {
	const { kind, id, templateUuid, ...opts } = props.configuration;
	const { control, handleSubmit, formState, getValues, setValue, watch } = useForm({
		defaultValues: { templateUuid, ...opts },
	});

	const commentaryTemplateApi = useApiGen(CommentaryTemplateControllerApiFactory);

	return (
		<SideBarContent
			title={itemToLabelMap[kind]}
			onSubmit={async () => {
				await handleSubmit((payload) =>
					props.onChange({
						id,
						kind,
						...payload,
					}),
				)();
			}}
			onCancel={props.onCancel}
		>
			<div className="flex flex-col gap-4">
				<p className="mb-4 font-semibold">Select the elements you want to display</p>
				<ReactQueryWrapper
					queryKey={["getCommentaryTemplateList"]}
					queryFn={async () => {
						const templates = await axiosExtract(commentaryTemplateApi.getTemplateList());
						return (
							templates.flatMap((x) => (x.name && x.uuid && x.visible ? [{ label: x.name, value: x.uuid }] : [])) ?? []
						);
					}}
					onSuccess={(opt) => {
						if (getValues("templateUuid") === undefined) {
							setValue("templateUuid", opt.find((x) => x.label === "Standard Template")?.value);
						}
					}}
				>
					{(options) => (
						<FormFields.Select
							control={control}
							formState={formState}
							name="templateUuid"
							label="Select template"
							options={options}
							listboxAppearance={{ classList: "max-h-[328px]" }}
						/>
					)}
				</ReactQueryWrapper>
				<FormFields.Checkbox control={control} formState={formState} name="useTemplateLanguage">
					Use template language
				</FormFields.Checkbox>
				<hr />
				<div className="flex flex-col gap-4">
					<FormFields.Text
						disabled={watch("useTemplateNameAsTitle")}
						control={control}
						formState={formState}
						name="title"
						label="Title"
					/>
					<FormFields.Checkbox control={control} formState={formState} name="useTemplateNameAsTitle">
						Use template name as title
					</FormFields.Checkbox>
				</div>
			</div>
		</SideBarContent>
	);
}

export function Performance(props: SetConfigurationReportTemplateHelper<"performance">): JSX.Element {
	const { kind, id, ...opts } = props.configuration;
	const { control, handleSubmit, formState } = useForm({
		defaultValues: opts,
	});

	const { t } = useTranslation();

	return (
		<SideBarContent
			title={itemToLabelMap[kind]}
			onSubmit={async () => {
				await handleSubmit((payload) => props.onChange({ id, kind, ...payload }))();
			}}
			onCancel={props.onCancel}
		>
			<div>
				<p className="mb-4 font-semibold">Select the elements you want to display</p>
				<ForEach collection={typedObjectKeys(opts)}>
					{({ item: key }) => (
						<div>
							<FormFields.Checkbox control={control} formState={formState} name={key} classList="mb-1">
								{t(`REPORT_COMPONENTS.PERFORMANCE.${key}`)}
							</FormFields.Checkbox>
						</div>
					)}
				</ForEach>
			</div>
		</SideBarContent>
	);
}

export function Composition(props: SetConfigurationReportTemplateHelper<"composition">): JSX.Element {
	const { kind, id, ...opts } = props.configuration;
	const { t } = useTranslation();
	const { control, handleSubmit, formState, watch, setValue } = useForm({
		defaultValues: {
			currentColumnPreferences: props.templateVariant === "current" ? opts.currentColumnPreferences : [],
			proposalColumnPreferences: props.templateVariant === "proposal" ? opts.proposalColumnPreferences : [],
		},
	});

	const [selectedColumnPreferences, setSelectedColumnPreferences] = useState(
		Map(
			(props.templateVariant === "current" ? watch("currentColumnPreferences") : watch("proposalColumnPreferences"))
				.filter((x) => x.enabled)
				.map((x) => [x.column, x]),
		),
	);

	return (
		<SideBarContent
			title={itemToLabelMap[kind]}
			onSubmit={async () => {
				await handleSubmit((payload) => {
					const reorderedPayload = {
						currentColumnPreferences:
							props.templateVariant === "current"
								? ([
										...Array.from(selectedColumnPreferences.values()),
										...payload.currentColumnPreferences.filter((x) => !selectedColumnPreferences.has(x.column)),
								  ] as any)
								: payload.currentColumnPreferences,
						proposalColumnPreferences:
							props.templateVariant === "proposal"
								? ([
										...Array.from(selectedColumnPreferences.values() as any),
										...payload.proposalColumnPreferences.filter((x) => !selectedColumnPreferences.has(x.column)),
								  ] as any)
								: payload.proposalColumnPreferences,
					} satisfies typeof opts;

					props.onChange({ id, kind, ...reorderedPayload });
				})();
			}}
			onCancel={props.onCancel}
		>
			<div>
				<p className="mb-4 font-semibold">Select the elements you want to display</p>

				<ForEach
					collection={
						props.templateVariant === "current" ? opts.currentColumnPreferences : opts.proposalColumnPreferences
					}
				>
					{({ item: { column }, index }) => (
						<div>
							<FormFields.Checkbox
								control={control}
								formState={formState}
								name={
									props.templateVariant === "current"
										? `currentColumnPreferences.${index}.enabled`
										: `proposalColumnPreferences.${index}.enabled`
								}
								classList="mb-1"
								onChange={(enabled) => {
									setValue(
										props.templateVariant === "current"
											? `currentColumnPreferences.${index}.enabled`
											: `proposalColumnPreferences.${index}.enabled`,
										enabled,
									);

									setSelectedColumnPreferences((prev) => {
										const columnExist = prev.has(column);
										if (columnExist && !enabled) {
											return prev.remove(column);
										}
										if (!columnExist && enabled) {
											return prev.set(column, { column, enabled });
										}
										return prev;
									});
								}}
							>
								{t(`TABLE.HEADERS.${column}`)}
							</FormFields.Checkbox>
						</div>
					)}
				</ForEach>
				<p className="my-4 font-semibold">Choose the order</p>
				<DraggableList
					items={Array.from(selectedColumnPreferences.values())}
					itemKey={(item) => item.column}
					onReorder={(item) => setSelectedColumnPreferences(Map(item.map((x) => [x.column, { ...x }])))}
				>
					{({ dragHandleProps, isDragging, draggableProps, item, innerRef }) => (
						<div
							style={{ borderColor: themeCSSVars.palette_N50 }}
							className={toClassName({
								"relative flex flex-row items-center min-w-0 bg-white transition-shadow border-b gap-2 px-2 h-8": true,
								"shadow-lg": isDragging,
								"border-t": isDragging,
							})}
							{...draggableProps}
							ref={innerRef}
						>
							<div {...dragHandleProps} className={toClassName({ invisible: !draggableProps || !dragHandleProps })}>
								<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M6.04181 3.2944L7.96096 1.37506C8.01677 1.31944 8.10704 1.31944 8.16286 1.37506L10.082 3.2944C10.1228 3.3352 10.1349 3.3966 10.113 3.44991C10.0909 3.50323 10.0389 3.53807 9.98115 3.53807H6.14267C6.08493 3.53807 6.03277 3.50323 6.01083 3.44991C5.98888 3.3966 6.00101 3.3352 6.04181 3.2944ZM8.16305 14.8297C8.10724 14.8853 8.01697 14.8853 7.96115 14.8297L6.04181 12.9103C6.00101 12.8695 5.98888 12.8081 6.01083 12.7548C6.03296 12.7015 6.08512 12.6667 6.14286 12.6667H9.98134C10.0391 12.6667 10.091 12.7015 10.1132 12.7548C10.1351 12.8081 10.123 12.8695 10.0822 12.9103L8.16305 14.8297ZM14.3446 6.00001H1.97822C1.62186 6.00001 1.33337 6.29415 1.33337 6.65052C1.33337 7.00688 1.33337 7.30102 1.97822 7.30102H14.3333C14.701 7.30102 14.9895 7.01254 14.9895 6.65052C14.9895 6.28849 14.701 6.00001 14.3446 6.00001ZM1.97822 9.33334H14.3446C14.701 9.33334 14.9895 9.62183 14.9895 9.98385C14.9895 10.3459 14.701 10.6344 14.3333 10.6344H1.97822C1.33337 10.6344 1.33337 10.3402 1.33337 9.98385C1.33337 9.62748 1.62186 9.33334 1.97822 9.33334Z"
										fill="#8792AB"
									/>
								</svg>
							</div>
							<Text type="Body/M/Book">{t(`TABLE.HEADERS.${item.column}`)}</Text>
						</div>
					)}
				</DraggableList>
			</div>
		</SideBarContent>
	);
}

export function PerformanceAttribution(
	props: SetConfigurationReportTemplateHelper<"performanceAttribution">,
): JSX.Element {
	const { kind, id, ...opts } = props.configuration;

	const { t } = useTranslation();
	const { control, handleSubmit, formState } = useForm({
		defaultValues: opts,
	});

	return (
		<SideBarContent
			title={itemToLabelMap[kind]}
			onSubmit={async () => {
				await handleSubmit((payload) => props.onChange({ id, kind, ...payload }))();
			}}
			onCancel={props.onCancel}
		>
			<div>
				<p className="mb-4 font-semibold">Select the elements you want to display</p>
				<ForEach collection={typedObjectKeys(opts)}>
					{({ item: key }) => (
						<div>
							<FormFields.Checkbox control={control} formState={formState} name={key} classList="mb-1">
								{t(`FORECAST_HORIZON.${key}`)}
							</FormFields.Checkbox>
						</div>
					)}
				</ForEach>
			</div>
		</SideBarContent>
	);
}

export function PerformanceAndVolatilityContribution(
	props: SetConfigurationReportTemplateHelper<"performanceAndVolatilityContribution">,
): JSX.Element {
	const { kind, id, ...opts } = props.configuration;

	const { t } = useTranslation();
	const { control, handleSubmit, formState } = useForm({
		defaultValues: opts,
	});

	return (
		<SideBarContent
			title={itemToLabelMap[kind]}
			onSubmit={async () => {
				await handleSubmit((payload) => props.onChange({ id, kind, ...payload }))();
			}}
			onCancel={props.onCancel}
		>
			<div className="mb-4">
				<FormFields.Checkbox control={control} formState={formState} name="filterSignificant" classList="mb-1">
					Significant values only
				</FormFields.Checkbox>
			</div>

			<div>
				<p className="mb-4 font-semibold">Select the elements you want to display</p>
				<ForEach collection={typedObjectKeys(opts.versus)}>
					{({ item: key }) => (
						<div>
							<FormFields.Checkbox control={control} formState={formState} name={`versus.${key}`} classList="mb-1">
								{t(`VERSUS.${key}`)}
							</FormFields.Checkbox>
						</div>
					)}
				</ForEach>
			</div>
		</SideBarContent>
	);
}

export function ExAnteContributionVolatility(
	props: SetConfigurationReportTemplateHelper<"exAnteContributionVolatility">,
): JSX.Element {
	const { kind, id, ...opts } = props.configuration;

	const { t } = useTranslation();
	const { control, handleSubmit, formState } = useForm({
		defaultValues: opts,
	});

	return (
		<SideBarContent
			title={itemToLabelMap[kind]}
			onSubmit={async () => {
				await handleSubmit((payload) => props.onChange({ id, kind, ...payload }))();
			}}
			onCancel={props.onCancel}
		>
			<div className="mb-4">
				<FormFields.Checkbox control={control} formState={formState} name="filterSignificant" classList="mb-1">
					Significant values only
				</FormFields.Checkbox>
			</div>

			<div>
				<p className="mb-4 font-semibold">Select the elements you want to display</p>
				<ForEach collection={typedObjectKeys(opts.versus)}>
					{({ item: key }) => (
						<div>
							<FormFields.Checkbox control={control} formState={formState} name={`versus.${key}`} classList="mb-1">
								{t(`VERSUS.${key}`)}
							</FormFields.Checkbox>
						</div>
					)}
				</ForEach>
			</div>
		</SideBarContent>
	);
}

export function FactorExposure(props: SetConfigurationReportTemplateHelper<"factorExposure">): JSX.Element {
	const { kind, id, ...opts } = props.configuration;

	const { control, handleSubmit, formState } = useForm({
		defaultValues: opts,
	});

	const { t } = useTranslation();

	return (
		<SideBarContent
			title={itemToLabelMap[kind]}
			onSubmit={async () => {
				await handleSubmit((payload) => props.onChange({ id, kind, ...payload }))();
			}}
			onCancel={props.onCancel}
		>
			<div>
				<p className="mb-4 font-semibold">Select the elements you want to display</p>
				<ForEach collection={typedObjectKeys(opts)}>
					{({ item: key }) => (
						<div>
							<FormFields.Checkbox control={control} formState={formState} name={key} classList="mb-1">
								{t(`REPORT_COMPONENTS.FACTORSEXPOSURE.${key}`)}
							</FormFields.Checkbox>
						</div>
					)}
				</ForEach>
			</div>
		</SideBarContent>
	);
}

export function Exposure(props: SetConfigurationReportTemplateHelper<"exposure">): JSX.Element {
	const { kind, id, versus } = props.configuration;
	const { t } = useTranslation();
	const { control, handleSubmit, formState } = useForm({
		defaultValues: versus,
	});

	return (
		<SideBarContent
			title={itemToLabelMap[kind]}
			onSubmit={async () => {
				await handleSubmit((payload) => props.onChange({ id, kind, versus: payload }))();
			}}
			onCancel={props.onCancel}
		>
			<div>
				<p className="mb-4 font-semibold">Select the elements you want to display</p>
				<ForEach collection={typedObjectKeys(versus)}>
					{({ item: key }) => (
						<div>
							<FormFields.Checkbox control={control} formState={formState} name={key} classList="mb-1">
								{t(`VERSUS.${key}`)}
							</FormFields.Checkbox>
						</div>
					)}
				</ForEach>
			</div>
		</SideBarContent>
	);
}
