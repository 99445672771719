type MarketDataVariants = "dashboard" | "outlook";
type OutlookMarketDataVariants = "LOW" | "MID_LOW" | "NEUTRAL" | "MID_HIGH" | "HIGH";
type DashboardMarketDataVariants = "STRONG_UNDERWEIGHT" | "UNDERWEIGHT" | "NEUTRAL" | "OVERWEIGHT" | "S_OVERWEIGHT";

type PositioningChooser<T extends MarketDataVariants> = {
	dashboard: DashboardMarketDataVariants;
	outlook: OutlookMarketDataVariants;
}[T];

export const POSITIONING: {
	dashboard: { [key: number]: PositioningChooser<"dashboard"> };
	outlook: { [key: number]: PositioningChooser<"outlook"> };
} = {
	outlook: {
		1: "LOW",
		2: "MID_LOW",
		3: "NEUTRAL",
		4: "MID_HIGH",
		5: "HIGH",
	},
	dashboard: {
		1: "STRONG_UNDERWEIGHT",
		2: "UNDERWEIGHT",
		3: "NEUTRAL",
		4: "OVERWEIGHT",
		5: "S_OVERWEIGHT",
	},
};

//not typesafe
export function handlePreviousMarketData<T extends MarketDataVariants>(
	marketSection: T,
	translation: (position: PositioningChooser<T>) => string,
	current: { value: number; data?: string },
	previous?: { value?: number; data?: string },
): { previous: number | undefined; tooltip: { label: string; value: string; data?: string | undefined }[] } {
	const sanitaizedPrevValue = previous?.value === current.value ? undefined : previous?.value;
	const currentI18n = POSITIONING[marketSection][current.value];
	const tooltip: Array<{ label: string; value: string; data?: string }> = [
		{ label: "Current", value: translation(currentI18n as PositioningChooser<T>), data: current?.data },
	];

	if (sanitaizedPrevValue) {
		const previousI18n = POSITIONING[marketSection][sanitaizedPrevValue];
		tooltip.push({
			label: "Previous",
			value: translation(previousI18n as PositioningChooser<T>),
			data: previous?.data,
		});
	}

	return { previous: sanitaizedPrevValue, tooltip };
}
