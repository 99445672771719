import type { CommentaryTemplateModel } from "$root/api/api-gen";
import { CommentaryTemplateControllerApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import ReactQueryWrapper from "$root/components/ReactQueryWrapper";
import { spawnCreateDialog } from "$root/components/spawnable/entity-management/create-dialog";
import StoryfolioInner from "$root/functional-areas/storyfolio";
import { stableDefaultConfiguration } from "$root/functional-areas/storyfolio/common";
import { platformToast } from "$root/notification-system/toast";
import { axiosExtract } from "$root/third-party-integrations/axios";
import type { Abortable } from "$root/utils/promise";
import { noop } from "@mdotm/mdotui/utils";
import { useParams } from "react-router";

function StoryfolioDetails(props: { uuid?: string }): JSX.Element {
	const { uuid } = useParams<{ uuid: string }>();
	const { push, replace } = useTypedNavigation();
	const commentaryTemplateApi = useApiGen(CommentaryTemplateControllerApiFactory);

	async function createStoryfolioTemplate(template: CommentaryTemplateModel, name?: string) {
		try {
			const mode = template.default ? "create" : "update";
			if (mode === "create") {
				await commentaryTemplateApi.createTemplate({ ...template, name: name ?? template.name, default: false });
			}

			if (mode === "update") {
				await commentaryTemplateApi.updateTemplate(template.uuid!, {
					...template,
					name: name ?? template.name,
					default: false,
				});
			}

			platformToast({
				children:
					mode === "create" ? "Commentary template created successfully" : "Commentary template updated successfully",
				icon: "Ask-ai",
				severity: "success",
			});
			push("Storyfolio/Studio", {});
		} catch (error) {
			platformToast({
				children: "Something went wrong please, try later",
				icon: "Ask-ai",
				severity: "error",
			});
			throw error;
		}
	}

	function checkIfNameIsAvailable(name: string, opts?: Abortable) {
		return axiosExtract(commentaryTemplateApi.isInvestmentNameAvailable1(name, opts));
	}
	return (
		<ReactQueryWrapper
			queryKey={["loadStoryfolioData", uuid]}
			queryFn={async () => {
				const list = await axiosExtract(commentaryTemplateApi.getTemplateList());
				return { template: await axiosExtract(commentaryTemplateApi.getTemplate(uuid!)), list };
			}}
		>
			{({ list, template }) => (
				<StoryfolioInner
					template={{
						...template,
						instrumentInsightsBlock: stableDefaultConfiguration.instrumentInsightsBlock(
							template.instrumentInsightsBlock,
						),
					}}
					list={list}
					onSubmit={async (editedTemplate) => {
						if (template.default) {
							await spawnCreateDialog({
								entityType: "template",
								onSubmitAsync: ({ param: name }) => createStoryfolioTemplate(editedTemplate, name),
								checkIfNameIsAvailable,
								placeholder: editedTemplate.name ?? "Untitled template",
								header: "Rename Template",
							}).catch(noop);
							return;
						}

						await createStoryfolioTemplate(editedTemplate);
					}}
					onChangeTemplate={(uuid) => replace("Storyfolio/Details", { uuid: uuid! })}
				/>
			)}
		</ReactQueryWrapper>
	);
}

export default StoryfolioDetails;
