import type { InvestmentStatuses, UserReferenceColumnPreference } from "$root/api/api-gen";
import { typedUrlForRoute } from "$root/components/PlatformRouter/RoutesDef";
import { useLocaleFormatters } from "$root/localization/hooks";
import type { TableColumn } from "@mdotm/mdotui/components";
import { ActionText, Icon, TableDataCell, Text } from "@mdotm/mdotui/components";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { builtInSort, builtInSortFnFor, switchExpr } from "@mdotm/mdotui/utils";
import { t } from "i18next";
import { useMemo } from "react";
import { match } from "ts-pattern";
import type { UnifiedMockedReferenceProps } from ".";
import { statusIconMap } from "..";
import { LinkedPortfolioCounterWithTooltip } from "../shared";
import { useUserValue } from "$root/functional-areas/user";
import { aclToUsersListEntries } from "$root/functional-areas/acl/checkers/all";
import UserShareIcon from "$root/functional-areas/acl/UserShareIcon";
import { overrideClassName } from "@mdotm/mdotui/react-extensions";
import { sharingWithColumn } from "$root/functional-areas/acl/table-columns";

const mapReferenceLabels: Record<NonNullable<UnifiedMockedReferenceProps["type"]>, string> = {
	Benchmark: "Custom benchmark",
	References: "Target portfolio",
};

const mapReferenceUrl: Record<NonNullable<UnifiedMockedReferenceProps["type"]>, (identifier: string) => string> = {
	Benchmark: (identifier) => typedUrlForRoute("CustomBenchmark", { benchmarkId: identifier }),
	References: (identifier) => typedUrlForRoute("PortfolioDetails", { portfolioUid: identifier }),
};

export function useReferenceColumn(
	columnsPreferences?: UserReferenceColumnPreference[],
): TableColumn<UnifiedMockedReferenceProps>[] | null {
	const user = useUserValue();
	const formatters = useLocaleFormatters();
	const { formatDate } = formatters;

	const columns = useMemo(
		() =>
			columnsPreferences
				? columnsPreferences.map(
						(columnMeta) =>
							({
								header: columnMeta.preferencesType ? t(`TABLE.HEADERS.${columnMeta.preferencesType}`) : "-",
								hidden: columnMeta.enabled === false,
								content: () => null,
								...match(columnMeta)
									.with(
										{ preferencesType: "NAME" },
										(): Omit<TableColumn<UnifiedMockedReferenceProps>, "header"> => ({
											content: ({ name, identifier, type, richAcl }, props) => (
												<div style={props.style} className={overrideClassName(props.classList, "items-center flex")}>
													<ActionText
														classList="line-clamp-2 mr-1"
														color={themeCSSVars.palette_N900}
														href={mapReferenceUrl[type](identifier ?? "")}
														title={name}
														data-qualifier="PortfolioStudio/ReferenceList/Table/Column(Name)"
													>
														{name}
													</ActionText>
													<UserShareIcon
														entity={type === "References" ? "INVESTMENT" : "BENCHMARK"}
														userId={user.id}
														permission={richAcl?.currentUserPermissions}
														color={themeCSSVars.palette_N300}
														acl={richAcl?.acl}
													/>
												</div>
											),
											sortFn: builtInSortFnFor("name"),
											name: "name",
											width: 320,
										}),
									)
									.with(
										{ preferencesType: "SHARING_VIEWS" },
										(): Omit<TableColumn<UnifiedMockedReferenceProps>, "header"> =>
											sharingWithColumn({
												users: ({ richAcl, type }) =>
													aclToUsersListEntries(
														switchExpr(type, {
															Benchmark: () => "benchmark" as const,
															References: () => "targetPortfolio" as const,
														}),
														richAcl?.acl ?? [],
													),
											}),
									)
									.with(
										{ preferencesType: "INCEPTION_DATE" },
										(): Omit<TableColumn<UnifiedMockedReferenceProps>, "header"> => ({
											content: ({ creationTime }) => {
												const sanitizedCreationTime = creationTime ? formatDate(new Date(creationTime)) : "-";
												return sanitizedCreationTime;
											},
											name: "creation",
											sortFn: ({ creationTime: a }, { creationTime: b }) => {
												const rowA = new Date(a ?? "").getTime();
												const rowB = new Date(b ?? "").getTime();
												return rowA > rowB ? 1 : rowA < rowB ? -1 : 0;
											},
											cellClassList: "tabular-nums",
											align: "end",
											minWidth: 128,
										}),
									)
									.with(
										{ preferencesType: "LAST_STATUS_UPDATE" },
										(): Omit<TableColumn<UnifiedMockedReferenceProps>, "header"> => ({
											content: ({ modificationTime }) => {
												const sanitizedModificationTime = modificationTime
													? formatDate(new Date(modificationTime))
													: "-";
												return sanitizedModificationTime;
											},
											sortFn: ({ modificationTime: a }, { modificationTime: b }) => {
												const rowA = new Date(a ?? "").getTime();
												const rowB = new Date(b ?? "").getTime();
												return rowA > rowB ? 1 : rowA < rowB ? -1 : 0;
											},
											name: "lastUpdate",
											cellClassList: "tabular-nums",
											align: "end",
											minWidth: 92,
										}),
									)
									.with(
										{ preferencesType: "STATUS" },
										(): Omit<TableColumn<UnifiedMockedReferenceProps>, "header"> => ({
											content: ({ status }, props) => {
												const sanitizedStatus = status ? statusIconMap[status as InvestmentStatuses] : "-";
												if (sanitizedStatus === "-") {
													return (
														<TableDataCell
															{...props}
															data-qualifier="PortfolioStudio/ReferenceList/Table/Column(Status)"
														>
															{sanitizedStatus}
														</TableDataCell>
													);
												}
												return (
													<div
														style={props.style}
														onClick={props.onClick}
														className={overrideClassName(props.classList, "flex items-center")}
														title={sanitizedStatus.title}
														data-qualifier="PortfolioStudio/ReferenceList/Table/Column(Status)"
													>
														<Icon
															classList="mr-1"
															icon={sanitizedStatus.icon}
															color={themeCSSVars.palette_N800}
															size={sanitizedStatus.size}
														/>
														<Text type="Body/M/Book" classList="line-clamp-2">
															{sanitizedStatus.title}
														</Text>
													</div>
												);
											},
											sortFn: builtInSortFnFor("status"),
											name: "status",
											minWidth: 128,
										}),
									)
									.with(
										{ preferencesType: "TYPE" },
										(): Omit<TableColumn<UnifiedMockedReferenceProps>, "header"> => ({
											content: ({ type }) => mapReferenceLabels[type],
											sortFn: builtInSortFnFor("type"),
											name: "type",
											minWidth: 128,
										}),
									)
									.with(
										{ preferencesType: "LINKED_PORTFOLIOS" },
										(): Omit<TableColumn<UnifiedMockedReferenceProps>, "header"> => ({
											name: "linkedPtfs",
											content: ({ linkedPortfolio, type, nofPortfolios }, props) => (
												<div
													style={props.style}
													className={overrideClassName("flex items-center justify-end", props.classList)}
												>
													<LinkedPortfolioCounterWithTooltip
														type={type!}
														portfolios={linkedPortfolio}
														othersCount={(nofPortfolios ?? 0) - (linkedPortfolio?.length ?? 0)}
													/>
												</div>
											),
											width: 128,
											sortFn: (a, b) => builtInSort(a.linkedPortfolio?.length ?? 0, b.linkedPortfolio?.length ?? 0),
										}),
									)
									.otherwise(() => ({})),
							}) as unknown as TableColumn<UnifiedMockedReferenceProps>,
				  )
				: null,
		[columnsPreferences, formatDate, user.id],
	);

	return columns;
}
