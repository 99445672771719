import type {
	AllocationConstraintsResponse,
	EventInvestmentDraftUpdate,
	EventLevel,
	InvestableUniverseResponse,
	MarketViewResponse,
	RiskConstraintsResponse,
	StrategyConstraintsResponse,
} from "$root/api/api-gen";
import {
	InvestmentCreationConfigurationControllerV4ApiFactory,
	InvestmentDraftConfigurationControllerV4ApiFactory,
	InvestmentEnhancementConfigurationControllerV4ApiFactory,
	InvestmentsEnhancementStaticConfigurationControllerApiFactory,
	InvestmentsStaticConfigurationControllerApiFactory,
	StepAvailabilityStatus,
} from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { IconWalls } from "$root/components/IconWall";
import type { StepToUpdate } from "$root/event-bus";
import { useEventBus } from "$root/event-bus";
import { platformToast } from "$root/notification-system/toast";
import type { TransientNotificationVariant } from "$root/notification-system/transient";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { trackMixPanelEvent } from "$root/third-party-integrations/initMixPanel";
import { UnreachableError } from "$root/utils/errors";
import type { SetterOrUpdater } from "$root/utils/functions";
import { objectMap } from "$root/utils/objects";
import { parallelize } from "$root/utils/promise";
import type { ContextContent } from "$root/utils/react-extra";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { useHashState } from "$root/utils/react-router-extra";
import { ProgressBar } from "@mdotm/mdotui/components";
import type { NodeOrFn } from "@mdotm/mdotui/react-extensions";
import { renderNodeOrFn, useUnsafeUpdatedRef } from "@mdotm/mdotui/react-extensions";
import { maybeCall, noop, switchExpr, unpromisify } from "@mdotm/mdotui/utils";
import type { QueryObserverResult } from "@tanstack/react-query";
import type { MutableRefObject } from "react";
import { createContext, useCallback, useMemo, useRef, useState } from "react";
import { flushSync } from "react-dom";
import type { Id } from "react-toastify";
import { match } from "ts-pattern";
import type { MultiStepFormV2Props, StepMetadata } from "../../MultiStepFormV2/MultiStepFormV2";
import { MultiStepFormV2 } from "../../MultiStepFormV2/MultiStepFormV2";
import { Step01_MainInfo, getMainInfoStepData } from "./Steps/01-MainInfo";
import { Step02_InvestableUniverse, getInvestableUniverseStepData } from "./Steps/02-InvestableUniverse";
import { Step03_PortfolioConstraints, getPortfolioConstraintsStepData } from "./Steps/03-PortfolioConstraints";
import { Step04_RiskConstraints, getRiskConstraintsStepData } from "./Steps/04-RiskConstraints";
import { Step05_PortfolioStrategy, getPortfolioStrategyStepData } from "./Steps/05-PortfolioStrategy";
import { Step06_MarketView, getMarketViewStepData } from "./Steps/06-MarketView";
import { makeStepToRequestMapper, responseToStepMapper } from "./requests";
import type { EditPortfolioV4StepName, EditPortfolioV4StepPayloadMap, defaultEditPortfolioV4StepData } from "./shared";
import {
	editPortfolioV4InitialStepsMetadataByMode,
	editPortfolioV4OrderedSteps,
	makeUnsavedDataHandler,
} from "./shared";
import type { MapKeys } from "$root/utils/collections";

export type EditPortfolioAreaType = "create" | "settings-current" | "settings-enhanced" | "enhance" | "draft";

export type SubmittersRef = MutableRefObject<Partial<Record<keyof EditPortfolioV4StepPayloadMap, () => Promise<void>>>>;

export type EditPortfolioV4Props = {
	saveHandlers: {
		[K in keyof EditPortfolioV4StepPayloadMap]: (
			data: EditPortfolioV4StepPayloadMap[K],
		) => Promise<EditPortfolioV4StepPayloadMap[K]>;
	};
	submittersRef: SubmittersRef;
	area: {
		editable: boolean;
		edit: boolean;
	} & (
		| { name: "create"; portfolioUid?: undefined }
		| {
				name: "settings-current" | "settings-enhanced" | "enhance" | "draft";
				portfolioUid: string;
		  }
	);
	currentStep: EditPortfolioV4StepName;
	setCurrentStep(stepName: EditPortfolioV4StepName): void;
	stepsMetadata: { [K in EditPortfolioV4StepName]: StepMetadata };
	setStepsMetadata: SetterOrUpdater<{ [K in EditPortfolioV4StepName]: StepMetadata }>;
	stepsData: EditPortfolioV4StepPayloadMap;
	/** Retrieve the latest stepsData */
	// eslint-disable-next-line react/no-unused-prop-types
	getStepsData(): EditPortfolioV4StepPayloadMap;
	getStepsMetadata(): { [K in EditPortfolioV4StepName]: StepMetadata };
	setStepsData(stepsData: EditPortfolioV4StepPayloadMap | null): void;
	refetch(): Promise<unknown>;
	refetchConfiguration(): Promise<unknown>;
	isDraftPresent: boolean;
};

export type MultiStepPortfolioContext = {
	area: EditPortfolioV4Props["area"];
	saveHandlers: EditPortfolioV4Props["saveHandlers"];
	submittersRef: EditPortfolioV4Props["submittersRef"];
};

const TypedMultiStepper = (
	props: MultiStepFormV2Props<typeof defaultEditPortfolioV4StepData, MultiStepPortfolioContext>,
) => {
	return (
		<MultiStepFormV2
			unsavedDataHandler={makeUnsavedDataHandler({
				currentStep: props.currentStep,
				setStepsMetadata: props.setStepsMetadata,
				submittersRef: props.context!.submittersRef,
			})}
			{...props}
		/>
	);
};

const EditPortfolioV4Context = createContext<
	| null
	| ({ canSubmit: boolean } & Omit<EditPortfolioV4Props, "stepsData"> & {
				refetch: () => Promise<QueryObserverResult<any>>;
			} & (
				| {
						stepsData: EditPortfolioV4Props["stepsData"];
						isLoading: false;
						isError: false;
				  }
				| {
						stepsData: EditPortfolioV4Props["stepsData"] | null;
						isLoading: true;
						isError: false;
				  }
				| {
						stepsData: null;
						isLoading: false;
						isError: true;
				  }
			))
>(null);

export type EditPortfolioV4ContextType = NonNullable<ContextContent<typeof EditPortfolioV4Context>>;

const serverStepToInterface = {
	MAIN_INFO: "mainInfo",
	INVESTABLE_UNIVERSE: "investableUniverse",
	ALLOCATION_CONSTRAINTS: "portfolioConstraints",
	RISK_CONSTRAINTS: "riskConstraints",
	STRATEGY_CONSTRAINTS: "portfolioStrategy",
	MARKET_VIEW: "marketView",
} satisfies Record<StepToUpdate, EditPortfolioV4StepName>;

export function EditPortfolioV4ContextProvider({
	area,
	children,
}: {
	children: NodeOrFn<EditPortfolioV4ContextType>;
	area:
		| { name: "create"; portfolioUid?: undefined; editable: boolean; edit: boolean }
		| {
				name: "settings-current" | "settings-enhanced" | "enhance" | "draft";
				portfolioUid: string;
				editable: boolean;
				edit: boolean;
		  };
}): JSX.Element {
	const defaultStepsMetadata = editPortfolioV4InitialStepsMetadataByMode[area.name];
	const [editedStepsMetadata, _setEditedStepsMetadata] = useState<null | EditPortfolioV4Props["stepsMetadata"]>(null);
	const defaultStepsMetadataRef = useUnsafeUpdatedRef(defaultStepsMetadata);
	const stepToUpdatedRef = useRef<Array<StepToUpdate> | undefined>(undefined);

	const stepsMetadata = editedStepsMetadata ?? defaultStepsMetadata;
	const stepsMetadataRef = useRef(stepsMetadata);
	const setEditedStepsMetadata = useCallback<SetterOrUpdater<EditPortfolioV4Props["stepsMetadata"]>>(
		(x) => {
			_setEditedStepsMetadata((prev) => {
				const next = maybeCall(x, prev ?? defaultStepsMetadataRef.current);
				stepsMetadataRef.current = next;
				return next;
			});
		},
		[defaultStepsMetadataRef],
	);

	const [nullableCurrentStep, _setCurrentStep] = useHashState({
		allowedValues: editPortfolioV4OrderedSteps,
	});
	const nullableCurrentStepRef = useRef<EditPortfolioV4StepName | null>(nullableCurrentStep);
	const setCurrentStep = useCallback<typeof _setCurrentStep>(
		(v) => {
			nullableCurrentStepRef.current = v;
			_setCurrentStep(v);
		},
		[_setCurrentStep],
	);
	const currentStep = nullableCurrentStep ?? (area.name === "enhance" ? "investableUniverse" : "mainInfo");

	const canSubmit = useMemo(() => {
		const metadata = Object.values(stepsMetadata);
		// Disable if
		// - there is a non-optional step that hasn't been completed
		// - there is a step containing errors
		// - there is a step asynchronously validating on the server
		// - there is a step marked as dirty
		return !metadata.some(
			(m) => (!m.optional && !m.completed) || m.hasErrors || m.processing || m.persisting, //removed m.dirty
		);
	}, [stepsMetadata]);

	const [stepsData, _setStepsData] = useState<EditPortfolioV4Props["stepsData"] | null>(null); // null while loading
	const stepsDataRef = useRef(stepsData);
	const setStepsData = useCallback((data: typeof stepsData) => {
		stepsDataRef.current = data;
		_setStepsData(data);
	}, []);

	const enhanceApi = useApiGen(InvestmentEnhancementConfigurationControllerV4ApiFactory);
	const createApi = useApiGen(InvestmentCreationConfigurationControllerV4ApiFactory);
	const draftApi = useApiGen(InvestmentDraftConfigurationControllerV4ApiFactory);
	const staticController = useApiGen(InvestmentsStaticConfigurationControllerApiFactory);
	const enhancementStaticController = useApiGen(InvestmentsEnhancementStaticConfigurationControllerApiFactory);

	const [isResetting, setIsResetting] = useState(area.name === "create" || area.name === "enhance");
	const initialConfigurationQuery = useQueryNoRefetch(["getInitialConfigurationInfo"], {
		enabled: area.name === "create" || area.name === "enhance",
		queryFn: async () => {
			let data;
			if (area.name === "enhance") {
				data = await axiosExtract(enhanceApi.getDraftStatus(area.portfolioUid));
			} else {
				data = await axiosExtract(createApi.getDraftStatus2());
			}

			return data;
		},
		onSuccess: (response) => {
			if (response.draftPresent && area.name === "create") {
				//implicit reset draft of a creation when mount
				createApi
					.createDraftCreationConfiguration()
					.then(() => {
						trackMixPanelEvent("Portfolio-Draft", {
							Type: area?.portfolioUid ? "enhance" : "creation",
							UID: area?.portfolioUid,
							Step: "global",
							Action: "reset",
						});
						setCurrentStep("mainInfo");
					})
					.catch(noop)
					.finally(() => setIsResetting(false));
			} else {
				setIsResetting(false);
			}
		},
		onError: () => setIsResetting(false),
	});

	const initialStepsDataAndMetadataQuery = useQueryNoRefetch({
		queryKey: ["stepsData", area.portfolioUid, area.name, isResetting],
		enabled: isResetting === false,
		queryFn: async () => {
			const stepData =
				area.name === "create" || area.name === "enhance" || area.name === "draft"
					? await parallelize(
							{
								mainInfo: () => getMainInfoStepData(createApi, enhanceApi, draftApi, area),
								investableUniverse: () => getInvestableUniverseStepData(createApi, enhanceApi, draftApi, area),
								portfolioConstraints: () => getPortfolioConstraintsStepData(createApi, enhanceApi, draftApi, area),
								riskConstraints: () => getRiskConstraintsStepData(createApi, enhanceApi, draftApi, area),
								portfolioStrategy: () => getPortfolioStrategyStepData(createApi, enhanceApi, draftApi, area),
								marketView: () => getMarketViewStepData(createApi, enhanceApi, draftApi, area),
							},
							{
								concurrency: 3,
							},
					  )
					: await (area.name === "settings-enhanced"
							? enhancementStaticController.getStaticEnhancementConfiguration(area.portfolioUid)
							: staticController.getStaticConfiguration(area.portfolioUid)
					  ).then(({ data }) => ({
							mainInfo: responseToStepMapper.mainInfo(data.mainInfo ?? {}, area),
							investableUniverse: responseToStepMapper.investableUniverse(data.investableUniverse ?? {}, area),
							portfolioConstraints: responseToStepMapper.portfolioConstraints(data.allocationConstraints ?? {}, area),
							riskConstraints: responseToStepMapper.riskConstraints(data.riskConstraints ?? {}, area),
							portfolioStrategy: responseToStepMapper.portfolioStrategy(data.strategyConstraints ?? {}, area),
							marketView: responseToStepMapper.marketView(data.marketView ?? {}, area),
					  }));

			const { availabilityMap } = await match(area)
				.with({ name: "create" }, () => axiosExtract(createApi.getCreationConfigurationStepsAvailability()))
				.with({ name: "draft" }, (x) => axiosExtract(draftApi.getDraftConfigurationStepsAvailability(x.portfolioUid)))
				.with({ name: "settings-enhanced" }, (x) =>
					axiosExtract(enhancementStaticController.getStaticEnhancementConfiguration(x.portfolioUid)),
				)
				.with({ name: "settings-current" }, (x) =>
					axiosExtract(staticController.getStaticConfiguration(x.portfolioUid)),
				)
				.with({ name: "enhance" }, (x) =>
					axiosExtract(enhanceApi.getEnhancementConfigurationStepsAvailability(x.portfolioUid)),
				)
				.exhaustive();

			return {
				stepData,
				stepsMetadata: mergeStepsMetadata(editedStepsMetadata ?? defaultStepsMetadata, availabilityMap),
			};
		},
		onSuccess: (response) => {
			const stepsToUpdate = stepToUpdatedRef.current;
			if (stepsToUpdate?.length !== undefined && stepsToUpdate.length > 0) {
				const currentStepData = stepsDataRef.current;
				const currentStepmetadata = stepsMetadata;

				const newStepData = stepsToUpdate?.reduce(
					(acc, stepToUpdate) => {
						const step = serverStepToInterface[stepToUpdate];

						return {
							...acc,
							[step]: response.stepData?.[step],
						};
					},
					{ ...(currentStepData ?? response.stepData) },
				);

				const newStepMetadata = stepsToUpdate?.reduce(
					(acc, stepToUpdate) => {
						const step = serverStepToInterface[stepToUpdate];

						return {
							...acc,
							[step]: response.stepsMetadata?.[step],
						};
					},
					{ ...currentStepmetadata },
				);

				setStepsData(newStepData);
				setEditedStepsMetadata(newStepMetadata);

				stepToUpdatedRef.current = undefined;
			} else {
				setStepsData(response.stepData);
				setEditedStepsMetadata(response.stepsMetadata);
			}

			if (
				nullableCurrentStep === null &&
				response.stepsMetadata.mainInfo.completed &&
				response.stepsMetadata.investableUniverse.completed &&
				area.name === "enhance"
			) {
				setCurrentStep("marketView");
			}
		},
	});

	const refetch = useUnsafeUpdatedRef({
		initialConfiguration: initialConfigurationQuery.refetch,
		initialStepMetadata: initialStepsDataAndMetadataQuery.refetch,
	});

	const solveWithAiStep = new Map([
		["ALLOCATION_CONSTRAINTS", "portfolioConstraints"],
		["RISK_CONSTRAINTS", "riskConstraints"],
	] as const);

	const lastOpenNotification = useRef<{ id: Id; level: string } | null>(null);
	useEventBus(
		"investment-draft-configuration-update",
		unpromisify(async (data: EventInvestmentDraftUpdate) => {
			// await initialStepsDataAndMetadataQuery.refetch().catch(noop);
			await refetch.current.initialStepMetadata().catch(noop);

			stepToUpdatedRef.current = data.stepsToUpdate;

			const firstSolvedWithAiStep = data.stepsToUpdate?.find((step) => solveWithAiStep.get(step as any)) as MapKeys<
				typeof solveWithAiStep
			>;
			const firstSolvedWithAiStepHash = firstSolvedWithAiStep && solveWithAiStep.get(firstSolvedWithAiStep);

			//FIXME: Add notification Message
			const ErrorMap: Record<EventLevel, { variant: TransientNotificationVariant; message: string }> = {
				ERROR: {
					variant: "error",
					message:
						"Oops! An error occurred while saving your selection. Please, try again or contact support for assistance",
				},
				INFO: { variant: "info", message: "Draft correctly updated and saved" },
				WARN: {
					variant: firstSolvedWithAiStepHash ? "info" : "warning",
					message: firstSolvedWithAiStepHash
						? "Review the feasibility adjustments before proceeding"
						: "Error: Unfeasible constraints found. Please, review your inputs",
				},
			};

			// skip second banner if the previous one is still open
			if (
				(!lastOpenNotification.current || lastOpenNotification.current.level !== data.level) &&
				area.name !== "settings-current" &&
				area.name !== "settings-enhanced" &&
				data.level !== "INFO"
			) {
				lastOpenNotification.current = {
					// FIXME: Check this solution
					id: platformToast({
						children: ErrorMap[data.level as EventLevel].message,
						severity: ErrorMap[data.level as EventLevel].variant,
						icon: "Portfolio",
						autoClose: 3000,
						onClick: firstSolvedWithAiStepHash ? () => setCurrentStep(firstSolvedWithAiStepHash) : undefined,
						dismissible: false,
						onClose: () => (lastOpenNotification.current = null),
					}),
					level: data.level as EventLevel,
				};
			}
		}),
	);

	const mapper = useMemo(() => makeStepToRequestMapper(area.portfolioUid), [area.portfolioUid]);

	const mergeStepMetadata = useCallback(
		//dirty: false  if we get here, it means that the user successfully saved the step
		// ask be to sse based on the step
		(metadata: StepMetadata, availabilityStatus: StepAvailabilityStatus, solveWithAi?: boolean) => ({
			...metadata,
			completed: availabilityStatus === StepAvailabilityStatus.Ready, // !empty
			hasErrors: availabilityStatus === StepAvailabilityStatus.ReviewRequired,
			processing: availabilityStatus === StepAvailabilityStatus.Calculating,
			solveWithAi: solveWithAi ?? false,
		}),
		[],
	);

	const mergeStepsMetadata = useCallback(
		(
			curStepsMetadata: EditPortfolioV4Props["stepsMetadata"],
			availabilityStatusMap?: Partial<
				Record<
					| "ALLOCATION_CONSTRAINTS"
					| "INVESTABLE_UNIVERSE"
					| "MAIN_INFO"
					| "MARKET_VIEW"
					| "RISK_CONSTRAINTS"
					| "STRATEGY_CONSTRAINTS",
					StepAvailabilityStatus
				>
			>,
		) => {
			if (!availabilityStatusMap) {
				return curStepsMetadata;
			}
			const next = { ...curStepsMetadata };
			next.mainInfo = !availabilityStatusMap.MAIN_INFO
				? next.mainInfo
				: mergeStepMetadata(next.mainInfo, availabilityStatusMap.MAIN_INFO);
			next.investableUniverse = !availabilityStatusMap.INVESTABLE_UNIVERSE
				? next.investableUniverse
				: mergeStepMetadata(next.investableUniverse, availabilityStatusMap.INVESTABLE_UNIVERSE);
			next.portfolioConstraints = !availabilityStatusMap.ALLOCATION_CONSTRAINTS
				? next.portfolioConstraints
				: mergeStepMetadata(
						next.portfolioConstraints,
						availabilityStatusMap.ALLOCATION_CONSTRAINTS,
						availabilityStatusMap.ALLOCATION_CONSTRAINTS === "REVIEW_REQUIRED",
				  );
			next.riskConstraints = !availabilityStatusMap.RISK_CONSTRAINTS
				? next.riskConstraints
				: mergeStepMetadata(
						next.riskConstraints,
						availabilityStatusMap.RISK_CONSTRAINTS,
						availabilityStatusMap.RISK_CONSTRAINTS === "REVIEW_REQUIRED",
				  );
			next.portfolioStrategy = !availabilityStatusMap.STRATEGY_CONSTRAINTS
				? next.portfolioStrategy
				: mergeStepMetadata(next.portfolioStrategy, availabilityStatusMap.STRATEGY_CONSTRAINTS);
			next.marketView = !availabilityStatusMap.MARKET_VIEW
				? next.marketView
				: mergeStepMetadata(next.marketView, availabilityStatusMap.MARKET_VIEW);
			return next;
		},
		[mergeStepMetadata],
	);

	const saveHandlers = useMemo<EditPortfolioV4ContextType["saveHandlers"]>(
		() =>
			objectMap(
				{
					mainInfo: async (values) => {
						if (area.name === "create") {
							const response = (await createApi.setCreationConfigurationMainInfo(mapper.mainInfo(values))).data;
							flushSync(() =>
								setEditedStepsMetadata((metadata) =>
									mergeStepsMetadata(metadata ?? defaultStepsMetadata, response.stepsAvailability),
								),
							);
							return responseToStepMapper.mainInfo(response, area);
						} else if (area.name === "settings-current" || area.name === "settings-enhanced") {
							return values;
							// const response = (await staticController.setStaticConfigurationMainInfo(mapper.mainInfo(values))).data;
							// flushSync(() =>
							// 	setEditedStepsMetadata((metadata) =>
							// 		mergeStepsMetadata(metadata ?? defaultStepsMetadata, response.stepsAvailability),
							// 	),
							// );
							// return responseToStepMapper.mainInfo(response, area);
						} else if (area.name === "draft") {
							const response = (await draftApi.setDraftConfigurationMainInfo(mapper.mainInfo(values))).data;
							flushSync(() =>
								setEditedStepsMetadata((metadata) =>
									mergeStepsMetadata(metadata ?? defaultStepsMetadata, response.stepsAvailability),
								),
							);
						} else if (area.name === "enhance") {
							const response = (await enhanceApi.setEnhancementConfigurationMainInfo(mapper.mainInfo(values))).data;
							flushSync(() =>
								setEditedStepsMetadata((metadata) =>
									mergeStepsMetadata(metadata ?? defaultStepsMetadata, response.stepsAvailability),
								),
							);
							await refetch.current.initialConfiguration(); // refetch configuration
						} else {
							throw new UnreachableError();
						}
						// await investmentApi.renameInvestment(area.portfolioUid, values.name);
						return values;
					},
					investableUniverse: async (values) => {
						let response: InvestableUniverseResponse;
						if (area.name === "create") {
							response = (await createApi.setCreationConfigurationInvestableUniverse(mapper.investableUniverse(values)))
								.data;
						} else if (area.name === "settings-current" || area.name === "settings-enhanced") {
							return values;
							// response = (
							// 	await staticController.setStaticConfigurationInvestableUniverse(mapper.investableUniverse(values))
							// ).data;
						} else if (area.name === "enhance") {
							response = (
								await enhanceApi.setEnhancementConfigurationInvestableUniverse(mapper.investableUniverse(values))
							).data;
							await refetch.current.initialConfiguration(); // refetch configuration
						} else if (area.name === "draft") {
							response = (await draftApi.setDraftConfigurationInvestableUniverse(mapper.investableUniverse(values)))
								.data;
						} else {
							throw new UnreachableError();
						}
						flushSync(() =>
							setEditedStepsMetadata((metadata) =>
								mergeStepsMetadata(metadata ?? defaultStepsMetadata, response.stepsAvailability),
							),
						);
						return responseToStepMapper.investableUniverse(response, area);
					},
					portfolioConstraints: async (values) => {
						let response: AllocationConstraintsResponse;
						if (area.name === "create") {
							response = (
								await createApi.setCreationConfigurationAllocationConstraints(mapper.portfolioConstraints(values))
							).data;
						} else if (area.name === "settings-current" || area.name === "settings-enhanced") {
							return values;
							// response = (
							// 	await staticController.setStaticConfigurationAllocationConstraints(mapper.portfolioConstraints(values))
							// ).data;
						} else if (area.name === "enhance") {
							response = (
								await enhanceApi.setEnhancementConfigurationAllocationConstraints(mapper.portfolioConstraints(values))
							).data;
							await refetch.current.initialConfiguration(); // refetch configuration
						} else if (area.name === "draft") {
							response = (
								await draftApi.setDraftConfigurationAllocationConstraints(mapper.portfolioConstraints(values))
							).data;
						} else {
							throw new UnreachableError();
						}

						flushSync(() =>
							setEditedStepsMetadata((metadata) =>
								mergeStepsMetadata(metadata ?? defaultStepsMetadata, response.stepsAvailability),
							),
						);
						return responseToStepMapper.portfolioConstraints(response, area);
					},
					riskConstraints: async (values) => {
						let response: RiskConstraintsResponse;
						if (area.name === "create") {
							response = (await createApi.setCreationConfigurationRiskConstraints(mapper.riskConstraints(values))).data;
						} else if (area.name === "settings-current" || area.name === "settings-enhanced") {
							return values;
							// response = (await staticController.setStaticConfigurationRiskConstraints(mapper.riskConstraints(values)))
							// 	.data;
						} else if (area.name === "enhance") {
							response = (await enhanceApi.setEnhancementConfigurationRiskConstraints(mapper.riskConstraints(values)))
								.data;
							await refetch.current.initialConfiguration(); // refetch configuration
						} else if (area.name === "draft") {
							response = (await draftApi.setDraftConfigurationRiskConstraints(mapper.riskConstraints(values))).data;
						} else {
							throw new UnreachableError();
						}
						flushSync(() =>
							setEditedStepsMetadata((metadata) =>
								mergeStepsMetadata(metadata ?? defaultStepsMetadata, response.stepsAvailability),
							),
						);
						return responseToStepMapper.riskConstraints(response, area);
					},
					portfolioStrategy: async (values) => {
						let response: StrategyConstraintsResponse;
						if (area.name === "create") {
							response = (await createApi.setCreationConfigurationStrategyConstraints(mapper.portfolioStrategy(values)))
								.data;
						} else if (area.name === "settings-current" || area.name === "settings-enhanced") {
							return values;
							// response = (
							// 	await staticController.setStaticConfigurationStrategyConstraints(mapper.portfolioStrategy(values))
							// ).data;
						} else if (area.name === "enhance") {
							response = (
								await enhanceApi.setEnhancementConfigurationStrategyConstraints(mapper.portfolioStrategy(values))
							).data;
							await refetch.current.initialConfiguration(); // refetch configuration
						} else if (area.name === "draft") {
							response = (await draftApi.setDraftConfigurationStrategyConstraints(mapper.portfolioStrategy(values)))
								.data;
						} else {
							throw new UnreachableError();
						}
						flushSync(() =>
							setEditedStepsMetadata((metadata) =>
								mergeStepsMetadata(metadata ?? defaultStepsMetadata, response.stepsAvailability),
							),
						);
						return responseToStepMapper.portfolioStrategy(response, area);
					},
					marketView: async (values) => {
						let response: MarketViewResponse;
						if (area.name === "create") {
							response = (await createApi.setCreationConfigurationMarketView(mapper.marketView(values))).data;
						} else if (area.name === "settings-current" || area.name === "settings-enhanced") {
							return values;
							// response = (await staticController.setStaticConfigurationMarketView(mapper.marketView(values))).data;
						} else if (area.name === "enhance") {
							response = (await enhanceApi.setEnhancementConfigurationMarketView(mapper.marketView(values))).data;
							await refetch.current.initialConfiguration(); // refetch configuration
						} else if (area.name === "draft") {
							response = (await draftApi.setDraftConfigurationMarketView(mapper.marketView(values))).data;
						} else {
							throw new UnreachableError();
						}
						flushSync(() =>
							setEditedStepsMetadata((metadata) =>
								mergeStepsMetadata(metadata ?? defaultStepsMetadata, response.stepsAvailability),
							),
						);
						return responseToStepMapper.marketView(response, area);
					},
				} satisfies EditPortfolioV4ContextType["saveHandlers"],
				(fn, _key) => async (values: any) => {
					const result = await fn(values);
					return result;
				},
			) as EditPortfolioV4ContextType["saveHandlers"],
		[
			area,
			createApi,
			mapper,
			setEditedStepsMetadata,
			mergeStepsMetadata,
			defaultStepsMetadata,
			draftApi,
			enhanceApi,
			refetch,
		],
	);

	const submittersRef: SubmittersRef = useRef({});

	const contextValue = useMemo<EditPortfolioV4ContextType>(
		() => ({
			saveHandlers,
			submittersRef,
			currentStep,
			setCurrentStep,
			setStepsMetadata: setEditedStepsMetadata,
			stepsMetadata:
				editedStepsMetadata ?? initialStepsDataAndMetadataQuery.data?.stepsMetadata ?? defaultStepsMetadata,
			canSubmit,
			setStepsData,
			getStepsData: () => stepsDataRef.current! /* assuming we only access the field after stepsData is loaded */,
			getStepsMetadata: () => stepsMetadataRef.current,
			area,
			refetch: refetch.current.initialStepMetadata,
			refetchConfiguration: refetch.current.initialConfiguration,
			...(initialStepsDataAndMetadataQuery.isError || initialConfigurationQuery.isError
				? {
						stepsData: null,
						isError: true,
						isLoading: false,
				  }
				: initialStepsDataAndMetadataQuery.isLoading
				  ? {
							stepsData,
							isError: false,
							isLoading: true,
				    }
				  : {
							stepsData: stepsData ?? initialStepsDataAndMetadataQuery.data.stepData,
							isError: false,
							isLoading: false,
				    }),
			isDraftPresent: initialConfigurationQuery.data?.draftPresent ?? false,
		}),
		[
			saveHandlers,
			currentStep,
			setCurrentStep,
			setEditedStepsMetadata,
			editedStepsMetadata,
			initialStepsDataAndMetadataQuery.data?.stepsMetadata,
			initialStepsDataAndMetadataQuery.data?.stepData,
			initialStepsDataAndMetadataQuery.isError,
			initialStepsDataAndMetadataQuery.isLoading,
			defaultStepsMetadata,
			canSubmit,
			setStepsData,
			area,
			refetch,
			initialConfigurationQuery.isError,
			initialConfigurationQuery.data?.draftPresent,
			stepsData,
		],
	);

	return (
		<EditPortfolioV4Context.Provider value={contextValue}>
			{renderNodeOrFn(children, contextValue)}
		</EditPortfolioV4Context.Provider>
	);
}

export function EditPortfolioV4InContext(): JSX.Element {
	const investmentConfigurationApi = useApiGen(InvestmentCreationConfigurationControllerV4ApiFactory);
	const investmentEnhanceConfigurationApi = useApiGen(InvestmentEnhancementConfigurationControllerV4ApiFactory);

	const onStartNew = useCallback(
		async (
			refetch?: EditPortfolioV4Props["refetch"],
			setStepsData?: EditPortfolioV4Props["setStepsData"],
			area?: EditPortfolioV4Props["area"],
		) => {
			try {
				if (area?.name === "enhance") {
					await investmentEnhanceConfigurationApi.createDraftEnhancementConfiguration(area.portfolioUid);
				}

				if (area?.name === "create") {
					await investmentConfigurationApi.createDraftCreationConfiguration();
				}
				trackMixPanelEvent("Portfolio-Draft", {
					Type: area?.portfolioUid ? "enhance" : "creation",
					UID: area?.portfolioUid,
					Step: "global",
					Action: "reset",
				});
				setStepsData?.(null);
				await refetch?.();
			} catch (error) {
				console.error(error); //TODO: handle error
			}
		},
		[investmentConfigurationApi, investmentEnhanceConfigurationApi],
	);

	return (
		<EditPortfolioV4Context.Consumer>
			{(data) => (
				<>
					{!data || data.isError ? (
						<div className="h-[70dvh] w-full">
							<IconWalls.WizardError onAsyncClear={() => onStartNew(data?.refetch, data?.setStepsData, data?.area)} />
						</div>
					) : data.isLoading ? (
						<ProgressBar value="indeterminate" />
					) : (
						<EditPortfolioV4 {...data} />
					)}
				</>
			)}
		</EditPortfolioV4Context.Consumer>
	);
}

export function EditPortfolioV4({
	saveHandlers,
	submittersRef,
	area,
	currentStep,
	setCurrentStep,
	setStepsData,
	setStepsMetadata,
	stepsData,
	stepsMetadata,
}: EditPortfolioV4Props): JSX.Element {
	return (
		<TypedMultiStepper
			context={{ saveHandlers, area, submittersRef }}
			currentStep={currentStep}
			setCurrentStep={setCurrentStep}
			stepsMetadata={stepsMetadata}
			setStepsMetadata={setStepsMetadata}
			stepsData={stepsData}
			setStepsData={setStepsData}
			mode={switchExpr(area.name, {
				create: () => "new" as const,
				enhance: () => "edit" as const,
				draft: () => "new" as const,
				"settings-current": () => "view" as const,
				"settings-enhanced": () => "view" as const,
			})}
			orderedStepNames={editPortfolioV4OrderedSteps}
			componentsByStep={{
				mainInfo: Step01_MainInfo,
				investableUniverse: Step02_InvestableUniverse,
				portfolioConstraints: Step03_PortfolioConstraints,
				riskConstraints: Step04_RiskConstraints,
				portfolioStrategy: Step05_PortfolioStrategy,
				marketView: Step06_MarketView,
			}}
			key={String(area.edit)} // reload steps to reset react hook form when change edit settings
		/>
	);
}
