import type { InvestmentStatuses, RichAccessControl } from "$root/api/api-gen";
import { InvestmentControllerV4ApiFactory } from "$root/api/api-gen";
import { reportPlatformError } from "$root/api/error-reporting";
import { getApiGen } from "$root/api/factory";
import type { EntityManagementActions } from "$root/components/spawnable/entity-management/actions";
import { spawnDeleteDialog } from "$root/components/spawnable/entity-management/delete-dialog";
import { spawnDuplicateDialog } from "$root/components/spawnable/entity-management/duplicate-dialog";
import { spawnRenameDialog } from "$root/components/spawnable/entity-management/rename-dialog";
import { platformToast } from "$root/notification-system/toast";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { t } from "i18next";
import { aclByArea } from "../acl/checkers/all";
import { useUserValue } from "../user";
import { useMemo } from "react";
import { trackMixPanelEvent } from "$root/third-party-integrations/initMixPanel";

export function usePortfolioEntityManagementActions(
	portfolio:
		| {
				name?: string;
				uuid?: string;
				richAcl?: { acl?: RichAccessControl[] };
				reference?: boolean;
				linkedPortfolios?: number;
				status?: InvestmentStatuses;
				usagesAsNestedPortfolios?: unknown[];
		  }
		| null
		| undefined,
	callbacks?: {
		onRename?(uuid: string): void;
		onDelete?(uuid: string): void;
		onDuplicate?(uuid: string): void;
	},
): Partial<Omit<EntityManagementActions, "createAsync">> {
	const user = useUserValue();
	return useMemo(
		() => (!portfolio ? {} : portfolioEntityManagementActions(user, portfolio, callbacks)),
		[callbacks, portfolio, user],
	);
}

export function portfolioEntityManagementActions(
	user: { id: string },
	portfolio: {
		name?: string;
		uuid?: string;
		richAcl?: { acl?: RichAccessControl[] };
		reference?: boolean;
		linkedPortfolios?: number;
		status?: InvestmentStatuses;
		usagesAsNestedPortfolios?: unknown[];
	},
	callbacks?: {
		onRename?(uuid: string): void;
		onDelete?(uuid: string): void;
		onDuplicate?(uuid: string): void;
	},
): Partial<Omit<EntityManagementActions, "createAsync">> {
	const api = () => getApiGen(InvestmentControllerV4ApiFactory); // accessor for lazy evaluation
	return {
		deleteAsync:
			!aclByArea.portfolio.canDelete(user.id, portfolio?.richAcl?.acl ?? []) ||
			(portfolio.reference && (portfolio.linkedPortfolios ?? 0) > 0) ||
			(portfolio.usagesAsNestedPortfolios?.length ?? 0) > 0
				? undefined
				: () =>
						spawnDeleteDialog({
							entityName: portfolio?.name ?? "",
							entityType: "portfolio",
							onDeleteAsync: async () => {
								try {
									await api().deleteInvestment(portfolio.uuid ?? "");

									trackMixPanelEvent("Portfolio", {
										Type: "Delete",
										ID: portfolio.uuid,
										Name: portfolio.name,
									});

									platformToast({
										children: t("PORTFOLIOS.DELETE_OK_MESSAGE", { name: portfolio.name }),
										severity: "success",
										icon: "Portfolio",
									});
									callbacks?.onDelete?.(portfolio.uuid ?? "");
								} catch (err) {
									reportPlatformError(
										err,
										"ERROR",
										"portfolio",
										`delete ${portfolio.reference ? "reference " : ""}investment "${portfolio.uuid}"`,
									);
									platformToast({
										children: t("SOMETHING_WENT_WRONG"),
										severity: "error",
										icon: "Portfolio",
									});
									throw err;
								}
							},
						}),
		duplicateAsync:
			!portfolio.status || invalidStatus.includes(portfolio.status)
				? undefined
				: () =>
						spawnDuplicateDialog({
							entityType: "portfolio",
							originalName: portfolio?.name ?? "",
							checkIfNameIsAvailable: (name, opts) => axiosExtract(api().isInvestmentNameAvailable(name, opts)),
							onSubmitAsync: async ({ param: name }) => {
								try {
									const uuid = (await axiosExtract(api().duplicateInvestment(portfolio.uuid ?? "", name))).uuid ?? "";

									platformToast({
										children: t("PORTFOLIOS.DUPLICATE_OK_MESSAGE", { name }),
										severity: "success",
										icon: "Portfolio",
									});
									callbacks?.onDuplicate?.(uuid);

									return uuid;
								} catch (err) {
									reportPlatformError(
										err,
										"ERROR",
										"portfolio",
										`duplicate ${portfolio.reference ? "reference " : ""}investment "${portfolio.uuid}"`,
									);
									platformToast({
										children: t("SOMETHING_WENT_WRONG"),
										severity: "error",
										icon: "Portfolio",
									});
									throw err;
								}
							},
						}),
		renameAsync: !aclByArea.portfolio.canRename(user.id, portfolio?.richAcl?.acl ?? [])
			? undefined
			: () =>
					spawnRenameDialog({
						entityType: "portfolio",
						currentName: portfolio?.name ?? "",
						checkIfNameIsAvailable: (name, opts) => axiosExtract(api().isInvestmentNameAvailable(name, opts)),
						onSubmitAsync: async ({ param: name }) => {
							try {
								await api().renameInvestment(portfolio.uuid ?? "", name);

								platformToast({
									children: t("PORTFOLIOS.RENAME_OK_MESSAGE", { name }),
									severity: "success",
									icon: "Portfolio",
								});
								callbacks?.onRename?.(name);

								return name;
							} catch (err) {
								reportPlatformError(
									err,
									"ERROR",
									"portfolio",
									`rename ${portfolio.reference ? "reference " : ""}investment "${portfolio.uuid}"`,
								);
								platformToast({
									children: t("SOMETHING_WENT_WRONG"),
									severity: "error",
									icon: "Portfolio",
								});
								throw err;
							}
						},
					}),
	};
}

const invalidStatus: Array<InvestmentStatuses> = [
	"ERROR",
	"REVIEW",
	"CALCULATING",
	"RETRIEVING_DATA",
	"PROPOSAL_READY",
];
