import type { ExposureContributionResponse, ExposureContributionResponseEntry } from "$root/api/api-gen";
import { exposureCategoryInfo } from "$root/widgets-architecture/widgets/ExposureEvolve";
import { ForEach } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { groupBy, objectMap } from "@mdotm/mdotui/utils";
import type { PrintableProps } from "../../configuration";
import type { CommonSetUpReportProps, UnionMapData } from "../../configuration/hooks/useExtractReports";
import { Card } from "../Card";
import type { ExposureSankeyLikeChartProps } from "$root/components/ExposureSankeyLikeChart/ExposureSankeyLikeChart";
import { ExposureSankeyLikeChart } from "$root/components/ExposureSankeyLikeChart/ExposureSankeyLikeChart";
import { customObjectValuesFn } from "$root/utils/experimental";
import { sumArrayLike } from "$root/utils/collections";
import {
	CompositionList,
	exposureAvailableHeightForItemSlotsByChartHeight,
	exposureChartSectionHeight,
} from "$root/functional-areas/reports/components/Exposure";

type ExposureMixedProps = CommonSetUpReportProps<UnionMapData["mixed-portfolio"]>;

export function exposureMixedEntriesToSplittableProps(
	composition: ExposureContributionResponseEntry[],
): Array<
	| { type: "title"; label: string; weight: number; groupName: string }
	| { type: "entry"; label: string; weight: number; groupName: string; netLong: boolean }
	| { type: "footer"; label: string; groupName: string }
> {
	const groups: string[] = [];
	for (const { quality } of composition) {
		if (!groups.includes(quality!)) {
			groups.push(quality!);
		}
	}

	return composition.length === 0
		? []
		: Array.from(
				Object.values(
					objectMap(
						groupBy(composition, (entry) => entry.quality!),
						(entries) => [
							{
								type: "title",
								label: entries?.at(0)?.quality ?? "",
								groupName: entries?.at(0)?.quality ?? "",
								weight: sumArrayLike(entries ?? [], (x) => (x.composedWeight! * x.weight!) / 100),
							} as const,
							...(entries ?? []).map(
								(x) =>
									({
										type: "entry",
										label: x.entityName ?? "",
										weight: (x.composedWeight! * x.weight!) / 100,
										netLong: true,
										groupName: entries?.at(0)?.quality ?? "",
									}) as const,
							),
							{
								type: "footer",
								label: "",
								groupName: entries?.at(0)?.quality ?? "",
							} as const,
						],
					),
				),
		  ).flat();
}

export function mixedExposureCompositionToSankeyLikeProps(
	exposureCompare: ExposureContributionResponse,
): ExposureSankeyLikeChartProps<any>["data"] {
	const { portfolioComposition } = exposureCompare;
	const groupedPortfolioByUuid = groupBy(portfolioComposition ?? [], (x) => x.entityUuid!);

	const investmentComposition = customObjectValuesFn(groupedPortfolioByUuid).flatMap((contributions) => {
		if (!contributions) {
			return [];
		}
		const entry = contributions[0];
		return [
			{
				label: entry.entityName!,
				name: entry.entityUuid!,
				weight: entry.composedWeight!,
				items: contributions.map((x) => ({
					quality: x.quality!,
					weight: x.weight ?? 0,
				})),
			},
		];
	});
	return investmentComposition;
}

const ExposureMixed = ({
	data,
	list,
	firstRender,
}: PrintableProps<
	{
		data: ExposureMixedProps["sections"][number];
	},
	Array<
		| { type: "title"; label: string; groupName: string; weight: number }
		| { type: "entry"; label: string; weight: number; groupName: string; netLong: boolean }
		| { type: "footer"; label: string; groupName: string }
	>
>): JSX.Element => {
	if (list.length === 0) {
		return <></>;
	}
	return (
		<>
			{firstRender && data.supportsChart && (
				<Card style={{ marginBottom: 0 }} title={`Exposure - ${exposureCategoryInfo[data.exposureCategory].label}`}>
					<div className="border-t border-b-2 pt-5 mb-4" style={{ borderColor: themeCSSVars.palette_N300 }}>
						<ExposureSankeyLikeChart
							style={{
								height: exposureMixedChartHeight - 20 /* pt-5 */ - 16 /* mb-4 */ - 60 /* card extra spacing */,
							}}
							aggregateBy="quality"
							data={mixedExposureCompositionToSankeyLikeProps(data.exposureCompare)}
						/>
					</div>
				</Card>
			)}
			<ForEach collection={list}>
				{({ item: subList }) => (
					<CompositionList
						availableHeight={exposureAvailableHeightForItemSlotsByChartHeight(
							firstRender ? exposureChartSectionHeight(exposureMixedChartHeight) : 0,
							"portrait",
						)}
						list={subList}
					/>
				)}
			</ForEach>
		</>
	);
};

export default ExposureMixed;

export const exposureMixedChartHeight = 880; // TODO: handle landscape?
