import type { AclEntityMinInfo, RichAccessControl } from "$root/api/api-gen";
import { spawnYesNoDialog } from "$root/components/spawnable/yes-no-dialog";
import { DropdownMenuTrigger } from "$root/components/DropdownMenuTrigger";
import { ForEach } from "$root/utils/react-extra";
import type { DialogProps, InnerRefProps, StylableProps } from "@mdotm/mdotui/components";
import {
	Banner,
	Button,
	Controller,
	Dialog,
	DialogFooter,
	DialogHeader,
	DropdownMenu,
	DropdownMenuActionButton,
	FloatingContent,
	ProgressBar,
	ScrollWrapper,
	SubmitButton,
	Text,
	TextInput,
	useListbox,
} from "@mdotm/mdotui/components";
import { useAsync, type MaybePromise } from "@mdotm/mdotui/headless";
import type { NodeOrFn } from "@mdotm/mdotui/react-extensions";
import {
	propagateRef,
	renderNodeOrFn,
	toClassListRecord,
	toClassName,
	useDebouncedMemo,
	useSize,
	useUnsafeUpdatedRef,
} from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { debugValue, noop, stableEmptyArray } from "@mdotm/mdotui/utils";
import type { KeyboardEvent } from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { UserCircle, type UserCircleProps } from "./UserCircle";
import type { Areas } from "./checkers/all";
import { roleByArea } from "./checkers/all";
import { validateACLPermissions } from "./checkers/shared";
import { getRolePermissionByArea } from "./shared";

export type ShareDialogProps = {
	objectName: string;
	show: boolean;
	onClose(): void;
	ownerId: string;
	currentUserId: string;
	acl: RichAccessControl[];
	onSubmitAsync(ownerId: string, acl: RichAccessControl[]): MaybePromise<void>;
	usersProvider(query: string): MaybePromise<RichAccessControl[]>;
	disclaimerProvider?: NodeOrFn<Array<AclEntityMinInfo>>;
	aclLinkedEntities: Array<AclEntityMinInfo>;
	area: Areas;
	onAnimationStateChange?: DialogProps["onAnimationStateChange"];
};

export function ShareDialog({
	objectName,
	ownerId: propsOwnerId,
	currentUserId,
	acl: propsAcl,
	show,
	onClose,
	onSubmitAsync,
	usersProvider,
	disclaimerProvider,
	aclLinkedEntities,
	area,
	onAnimationStateChange,
}: ShareDialogProps): JSX.Element {
	const value = useMemo(() => ({ ownerId: propsOwnerId, acl: propsAcl }), [propsOwnerId, propsAcl]);
	const isOwner = validateACLPermissions(currentUserId, propsAcl, roleByArea[area].OWNER);
	return (
		<Controller value={value}>
			{({ value: { ownerId, acl }, onChange: setOwnerAndACL }) => (
				<Dialog
					size="large"
					show={show}
					onAnimationStateChange={onAnimationStateChange}
					onClose={onClose}
					onSubmitAsync={async () => {
						await onSubmitAsync(ownerId, acl);
						onClose();
					}}
					header={`Share "${objectName}"`}
					footer={
						<DialogFooter
							primaryAction={<SubmitButton data-qualifier="ShareDialog/Action(Submit)">Done</SubmitButton>}
							neutralAction={
								<Button palette="tertiary" onClick={() => onClose()} data-qualifier="ShareDialog/Action(Cancel)">
									Cancel
								</Button>
							}
						/>
					}
				>
					{currentUserId === propsOwnerId /*  ||
						propsAcl.some(
							(originalAcl) => originalAcl.user.id === currentUserId && originalAcl.permissions.includes("write"),
						) */ && (
						<div className="mb-4">
							<UserSearchAutocomplete
								usersProvider={usersProvider}
								ignoreUserIds={acl.flatMap((entry) => (entry.userId ? [entry.userId] : []))}
								onAdd={(users) =>
									setOwnerAndACL({
										ownerId,
										acl: acl.concat(
											users.map((user) => ({
												...user,
												permissions: roleByArea[area].VIEWER,
											})),
										),
									})
								}
							/>
						</div>
					)}

					<div className="mb-2">
						<Text type="Body/M/Bold">Person with access</Text>
					</div>

					<ScrollWrapper classList="max-h-[250px]">
						<div className="space-y-4">
							<ForEach collection={acl} keyProvider={(x) => x.userId ?? "-"}>
								{({ item }) => (
									<div className="flex items-center gap-2">
										<ACLEntryPreview
											palette={ownerId === item.userId ? "light" : "dark"}
											isCurrentUser={item.userId === currentUserId}
											user={item}
											classList="grow"
										/>
										<div>
											{ownerId === item.userId ? (
												<DropdownMenu
													trigger={(forward) => (
														<DropdownMenuTrigger
															{...forward}
															disabled
															data-qualifier={`ShareDialog/User(${item.email})/Permission`}
														>
															<span className="inline-block min-w-[60px] text-left">Owner</span>
														</DropdownMenuTrigger>
													)}
													actions={[]}
												/>
											) : (
												<DropdownMenu
													trigger={(forward) => (
														<DropdownMenuTrigger
															{...forward}
															disabled={
																forward.disabled ||
																validateACLPermissions(item.userId!, [item], roleByArea[area].VIEWER) === false
															}
															data-qualifier={`ShareDialog/User(${item.email})/Permission`}
														>
															<span className="inline-block min-w-[60px] text-left">
																{/* TODO: change this permission */}
																{validateACLPermissions(item.userId!, [item], roleByArea[area].EDITOR)
																	? "Editor"
																	: "Viewer"}
															</span>
														</DropdownMenuTrigger>
													)}
													actions={[
														({ onClose: onDropdownClose }) => (
															<DropdownMenuActionButton
																onClick={() => {
																	setOwnerAndACL({
																		ownerId,
																		acl: acl.map((entry) =>
																			entry.userId === item.userId
																				? {
																						...entry,
																						permissions: getRolePermissionByArea(area, "EDITOR"),
																				  }
																				: entry,
																		),
																	});
																	onDropdownClose();
																}}
																data-qualifier={`ShareDialog/User(${item.email})/Permission(Editor)`}
																icon="Edit"
															>
																Editor
															</DropdownMenuActionButton>
														),
														({ onClose: onDropdownClose }) => (
															<DropdownMenuActionButton
																onClickAsync={async () => {
																	onDropdownClose();

																	if (item.userId === currentUserId) {
																		const shouldContinue = await spawnYesNoDialog({
																			header: "Update the permission?",
																			children:
																				"This will modify your authorization. You will no longer be able to share.",
																			noButton: "Cancel",
																			yesButton: "Yes, proceed",
																		});
																		if (!shouldContinue) {
																			return;
																		}
																	}

																	setOwnerAndACL({
																		ownerId,
																		acl: acl.map((entry) =>
																			entry.userId === item.userId
																				? {
																						...entry,
																						permissions: getRolePermissionByArea(area, "VIEWER"),
																				  }
																				: entry,
																		),
																	});
																}}
																data-qualifier={`ShareDialog/User(${item.email})/Permission(Viewer)`}
																icon="show"
															>
																Viewer
															</DropdownMenuActionButton>
														),
														{
															group: { id: isOwner ? "access-and-ownership" : "" },
															children: ({ onClose: onDropdownClose }) =>
																isOwner && (
																	<DropdownMenuActionButton
																		onClickAsync={async () => {
																			onDropdownClose();
																			const shouldContinue = await spawnYesNoDialog({
																				header: "Transfer ownership?",
																				children: "Your permissions will become of editor.",
																				noButton: "Cancel",
																				yesButton: "Yes, proceed",
																			});
																			if (!shouldContinue) {
																				return;
																			}
																			setOwnerAndACL(
																				debugValue({
																					ownerId: item.userId!,
																					acl: acl.map((entry) =>
																						entry.userId === item.userId
																							? {
																									...entry,
																									permissions: getRolePermissionByArea(area, "OWNER"),
																							  }
																							: entry,
																					),
																				}),
																			);
																		}}
																		data-qualifier={`ShareDialog/User(${item.email})/Permission(Transfer ownership)`}
																	>
																		Transfer ownership
																	</DropdownMenuActionButton>
																),
														},
														{
															group: { id: "access-and-ownership" },
															children: ({ onClose: onDropdownClose }) => (
																<DropdownMenuActionButton
																	onClick={() => {
																		setOwnerAndACL({
																			ownerId,
																			acl: acl.filter((entry) => entry.userId !== item.userId),
																		});
																		onDropdownClose();
																	}}
																	data-qualifier={`ShareDialog/User(${item.email})/Permission(Remove access)`}
																>
																	Remove access
																</DropdownMenuActionButton>
															),
														},
													]}
												/>
											)}
										</div>
									</div>
								)}
							</ForEach>
						</div>
					</ScrollWrapper>

					{disclaimerProvider && (
						<>
							<div className="border-b my-4" style={{ borderColor: themeCSSVars.palette_N200 }} />
							{renderNodeOrFn(disclaimerProvider, aclLinkedEntities)}
						</>
					)}
				</Dialog>
			)}
		</Controller>
	);
}

type ACLEntryPreviewProps = {
	palette: UserCircleProps["palette"];
	user?: RichAccessControl;
	isCurrentUser?: boolean;
} & StylableProps;

function ACLEntryPreview({
	palette,
	user,
	isCurrentUser = false,
	classList,
	style,
}: ACLEntryPreviewProps): JSX.Element {
	return (
		<div
			className={toClassName({ "flex items-center gap-2 min-w-0": true, ...toClassListRecord(classList) })}
			style={style}
		>
			<div className="grow-0">
				{user ? (
					<UserCircle variant="name" palette={palette} firstName={user.name ?? "-"} lastName={user.surname ?? "-"} />
				) : (
					<UserCircle variant="add" palette={palette} />
				)}
			</div>
			<div className="grow min-w-0">
				{user ? (
					<>
						<Text as="div" type="Body/L/Bold" classList="truncate">
							{user.name ?? ""} {user.surname} {isCurrentUser ? "(you)" : ""}
						</Text>
						<Text as="div" type="Body/M/Book" classList="truncate">
							{user.email}
						</Text>
					</>
				) : (
					<Text as="div" type="Body/L/Bold" classList="truncate">
						Add all
					</Text>
				)}
			</div>
		</div>
	);
}

export type UserSearchAutocompleteProps = {
	usersProvider(query: string): MaybePromise<RichAccessControl[]>;
	ignoreUserIds?: string[];
	onAdd(users: RichAccessControl[]): void;
};

export function UserSearchAutocomplete({
	usersProvider,
	ignoreUserIds = stableEmptyArray,
	onAdd,
}: UserSearchAutocompleteProps): JSX.Element {
	const [query, setQuery] = useState("");
	const { value: debouncedQuery } = useDebouncedMemo(() => query, [query], { debounceInterval: 400 });
	const [open, setOpen] = useState(false);

	const {
		loading,
		error,
		result: fetchedUsers,
		run: fetchUsers,
	} = useAsync<RichAccessControl[], string>({
		asyncFn: ({ param }) => usersProvider(param),
	});
	const filteredUsers = useMemo(
		() => fetchedUsers?.filter((user) => ignoreUserIds.includes(user.userId ?? "-") === false) ?? stableEmptyArray,
		[fetchedUsers, ignoreUserIds],
	);

	const runRef = useUnsafeUpdatedRef(fetchUsers);
	useEffect(() => {
		runRef.current(debouncedQuery).catch(noop);
	}, [debouncedQuery, runRef]);

	const [scrollAreaRef, setScrollAreaRef] = useState<HTMLElement | null>(null);
	const handleSelectOutlined = useCallback(
		(i: number) => {
			const liElement = scrollAreaRef?.querySelector(`[data-listbox-index="${i}"]`) as HTMLLIElement;
			if (!liElement) {
				return;
			}
			(liElement.children[0] as HTMLElement | undefined)?.click();
		},
		[scrollAreaRef],
	);

	const [outlinedItemIndex, setOutlinedItemIndex] = useState<number | null>(null);
	const { handleKeyDown: handleKeyDownBase } = useListbox({
		disabled: !open,
		onOutlinedSelected: handleSelectOutlined,
		onOutlinedItemIndexChange: setOutlinedItemIndex,
		outlinedItemIndex,
		interceptCharacters: false,
		options: useMemo(() => new Array((filteredUsers?.length ?? 0) + 1).fill(0), [filteredUsers?.length]),
		pageSize: 3,
	});
	const handleKeyDown = useCallback(
		(e: KeyboardEvent) => {
			if (e.key === "Enter") {
				e.preventDefault();
			}
			handleKeyDownBase(e);
		},
		[handleKeyDownBase],
	);
	const scrollAreaSize = useSize(scrollAreaRef);
	useEffect(() => {
		if (!scrollAreaRef || outlinedItemIndex === null || !scrollAreaSize) {
			return;
		}
		const liElement = scrollAreaRef.querySelector(`[data-listbox-index="${outlinedItemIndex}"]`) as HTMLLIElement;
		if (!liElement) {
			return;
		}
		scrollAreaRef.scrollTop = Math.max(0, liElement.offsetTop - scrollAreaSize.height / 2);
	}, [scrollAreaSize, outlinedItemIndex, scrollAreaRef]);

	const textInputRef = useRef<HTMLInputElement | null>(null);

	const triggerMemo = useCallback(
		({ innerRef }: InnerRefProps<HTMLInputElement>) => {
			const forwardRef = (el: HTMLInputElement | null) => {
				textInputRef.current = el;
				propagateRef(el, innerRef);
			};
			return (
				<TextInput
					placeholder="Search for a member to add"
					innerRef={forwardRef}
					value={query}
					onChangeText={(v) => {
						if (query !== v) {
							setQuery(v);
							setOpen(true);
						}
					}}
					data-qualifier="ShareDialog/SearchAutocomplete"
					onKeyDown={handleKeyDown}
					onFocus={() => setOpen(true)}
				/>
			);
		},
		[handleKeyDown, query],
	);

	return (
		<FloatingContent
			position="bottom"
			focusOnOpen={false}
			open={open && (query.length > 0 || filteredUsers.length > 0)}
			onClose={() => {
				if (textInputRef.current !== document.activeElement) {
					setOpen(false);
				}
			}}
			onClickAway={() => {
				if (textInputRef.current !== document.activeElement) {
					setOpen(false);
				}
			}}
			trigger={triggerMemo}
		>
			{({ triggerBox }) => (
				<div className="shadow-[0px_8px_32px_rgba(0,0,0,0.16)] rounded bg-white flex flex-col flex-1 min-h-0 py-1">
					<ScrollWrapper
						innerRef={setScrollAreaRef}
						direction="vertical"
						style={{
							width: triggerBox.width,
						}}
						classList={{
							"min-h-0 max-h-[300px] sm:max-w-[620px] max-w-[90vw]": true,
						}}
					>
						<div className="py-1">
							{error ? (
								"Si è verificato un errore"
							) : loading || !filteredUsers ? (
								<ProgressBar value="indeterminate" />
							) : filteredUsers.length === 0 ? (
								query.length > 0 && (
									<Banner severity="error">I cannot find any colleagues with this email address.</Banner>
								)
							) : (
								<>
									<button
										data-listbox-index={0}
										aria-current={outlinedItemIndex === 0}
										type="button"
										className="flex w-full text-left py-2 px-2 hover:bg-[#EDF7F5] aria-[current=true]:bg-[#EFF0F3] cursor-pointer"
										onClick={() => {
											onAdd(filteredUsers);
											setQuery("");
											textInputRef.current?.blur();
											setOpen(false);
										}}
										data-qualifier="ShareDialog/UserList/AddAllUser"
									>
										<ACLEntryPreview palette="dashed" classList="grow" />
									</button>

									<ForEach collection={filteredUsers}>
										{({ item, index }) => (
											<button
												data-listbox-index={index + 1}
												aria-current={index + 1 === outlinedItemIndex}
												type="button"
												data-qualifier={`ShareDialog/UserList/User(${item.email})`}
												className="flex w-full text-left py-2 px-2 hover:bg-[#EDF7F5] aria-[current=true]:bg-[#EFF0F3] cursor-pointer"
												onClick={() => {
													onAdd([item]);
													setQuery("");
													textInputRef.current?.blur();
													setOpen(false);
												}}
											>
												<ACLEntryPreview palette="dark" classList="grow" user={item} />
											</button>
										)}
									</ForEach>
								</>
							)}
						</div>
					</ScrollWrapper>
				</div>
			)}
		</FloatingContent>
	);
}
