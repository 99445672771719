import {
	Button,
	Dialog,
	DialogFooter,
	DialogHeader,
	FloatingContent,
	Icon,
	ProgressBar,
	SubmitButton,
	TextInput,
	LoadingButton,
} from "@mdotm/mdotui/components";
import { toClassName } from "$root/utils/react-dom-extra";
import { useDebouncedMemo } from "$root/utils/react-extra";
import { useUnsafeUpdatedRef } from "@mdotm/mdotui/react-extensions";
import { yupResolver } from "@hookform/resolvers/yup";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { TagBadge } from "./TagBadge";
import type { Tag } from "./type";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { defaultTagLabels, defaultTags, labelToTag } from "./shared";
import type { DataAttributesProps, IconName } from "@mdotm/mdotui/components";
import { themeCSSVars } from "@mdotm/mdotui/themes";

export type InstrumentTagSelectProps = {
	universeTags: Array<Tag>;
	disabled?: boolean;
	addDefaultTags?: boolean;
} & (SelectorInstrumentsProps | EditorInstrumentsProps) &
	DataAttributesProps;

type EditorInstrumentsProps = {
	mode: "editor";
	variant?: "inherit" | "linear";
	editTagButtonClassName?: string;
	icon?: {
		name: IconName;
		size: number | string;
	};
	onAddClick(tag: string): Promise<void> | void;
	onDeleteConfirm(tag: string): Promise<void> | void;
};

type SelectorInstrumentsProps = {
	mode: "selector";
	variant?: "inherit" | "linear";
	editTagButtonClassName?: string;
	icon?: {
		name: IconName;
		size: number | string;
	};
	selectedTagId: string | null;
	onTagClick?(id: string): void;
	onTagCancel?(id: string): void;
};

const maxTagNameLength = 30;
const tagNameValidationRegex = /^[a-zA-Z0-9_ ]{2,30}$/g;

function _InstrumentTagSelect(props: InstrumentTagSelectProps): JSX.Element {
	const {
		mode,
		universeTags,
		variant = "inherit",
		editTagButtonClassName = "",
		icon = { name: "Edit", size: "1.2em" },
		disabled,
		addDefaultTags,
		...dataAttrs
	} = props;

	const allTags = useMemo(() => {
		const mapTagsId = universeTags.concat(addDefaultTags ? defaultTags : []).map(({ id }) => id);
		const setOfTags = new Set<string>(mapTagsId);
		return Array.from(setOfTags).map((el) =>
			labelToTag({ label: el, disabled: universeTags.find(({ id }) => id === el)?.disabled }, Array.from(setOfTags)),
		);
	}, [addDefaultTags, universeTags]);

	const [open, setOpen] = useState(false);
	const [deleteTagId, setDeleteTagId] = useState<string | null>(null);

	const handleDeleteAsync = useCallback(async () => {
		try {
			if (mode === "editor" && deleteTagId) {
				await props.onDeleteConfirm?.(deleteTagId);
			}
			// TODO: toast?
		} catch (err) {
			// TODO: toast?
		} finally {
			setDeleteTagId(null);
		}
	}, [deleteTagId, mode, props]);

	const { t } = useTranslation();

	const selectedTag = useMemo(() => {
		if (mode === "selector") {
			return allTags.find(({ id }) => id === props.selectedTagId) ?? null;
		}
		return null;
	}, [mode, props, allTags]);

	const toggleOpen = useCallback(() => {
		setOpen((o) => !o);
	}, []);

	const onClick = useCallback(
		(isDefault: boolean, id: string) => {
			if (mode === "selector") {
				toggleOpen();
				return props.onTagClick?.(id);
			}
			if (mode === "editor" && !isDefault) {
				return setDeleteTagId(id);
			}
		},
		[mode, props, toggleOpen],
	);

	const [defaultValues] = useState(() => ({
		query: "",
	}));

	const onSubmit = useCallback(
		async ({ query }: { query?: string }) => {
			try {
				if (props.mode === "editor") {
					await props.onAddClick(query?.trim() ?? "");
				}
			} catch (err) {
				// TODO: show error (notification?)
			}
		},
		[props],
	);

	const searchOrAddTagForm = useForm({
		mode: "onChange",
		defaultValues,
		resolver: useMemo(
			() =>
				yupResolver(
					Yup.object({
						query: Yup.string().optional().matches(tagNameValidationRegex, { excludeEmptyString: true }),
					}),
				),
			[],
		),
	});

	const searchOrAddTagFormRef = useUnsafeUpdatedRef(searchOrAddTagForm);

	useEffect(() => {
		if (!open) {
			searchOrAddTagFormRef.current.reset();
		}
		return () => {
			// eslint-disable-next-line react-hooks/exhaustive-deps
			searchOrAddTagFormRef.current.reset();
		};
	}, [open, searchOrAddTagFormRef]);

	const query = searchOrAddTagForm.watch("query") ?? "";

	const { value: debouncedSearchString, state: debouncedSearchStringState } = useDebouncedMemo(() => query, [query]);
	const searchResults = useMemo(
		() => allTags.filter(({ name }) => name.toLowerCase().includes(debouncedSearchString.toLowerCase().trimEnd())),
		[allTags, debouncedSearchString],
	);

	return (
		<>
			<FloatingContent
				{...dataAttrs}
				classList="bg-white pl-3 shadow-xl rounded-lg pt-3 border"
				relativePositionOffsets={{
					bottom: 8,
				}}
				onClickAway={toggleOpen}
				open={open}
				position="bottom"
				align="startToStart"
				trigger={({ innerRef }) =>
					mode === "editor" ? (
						<Button
							palette="primary"
							type="button"
							innerRef={innerRef}
							onClick={toggleOpen}
							classList={editTagButtonClassName}
							data-qualifier="instrumentTagSelect/edit"
						>
							<Icon icon={icon.name} color="currentColor" size={icon.size} />
							&nbsp;{t("TAGS.EDIT")}
						</Button>
					) : variant === "linear" ? (
						<span ref={innerRef}>
							<TagBadge color="white" className="!p-0 !block">
								{selectedTag === null ? (
									<button
										data-qualifier="instrumentTagSelect/assign"
										type="button"
										onClick={toggleOpen}
										className={toClassName({
											"underline font-semibold text-[#2f3541]": true,
										})}
									>
										{t("TAGS.ASSIGN")}
									</button>
								) : (
									<div className="flex items-center gap-2">
										<button data-qualifier="instrumentTagSelect/open" type="button" onClick={toggleOpen}>
											{selectedTag.name}
										</button>
										<button
											data-qualifier="instrumentTagSelect/close"
											type="button"
											onClick={() => props.onTagCancel?.(selectedTag.id)}
										>
											<Icon icon="Close" color="white" />
										</button>
									</div>
								)}
							</TagBadge>
						</span>
					) : (
						<span ref={innerRef} className="block">
							<TagBadge
								className={toClassName({
									"border-[#A0A7B6] border": selectedTag === null,
									"": selectedTag !== null,
									[editTagButtonClassName]: Boolean(editTagButtonClassName),
									"w-full": Boolean(selectedTag),
									block: true,
								})}
								color={selectedTag === null ? "white" : selectedTag.color}
								style={{
									color: selectedTag === null ? "#4C566C" : "white",
								}}
							>
								{selectedTag === null ? (
									<button
										data-qualifier="instrumentTagSelect/open"
										type="button"
										onClick={toggleOpen}
										disabled={disabled}
										className="disabled:opacity-60 flex flex-wrap items-center"
									>
										<span className="max-w-[80px] mr-2">{t("TAGS.ASSIGN")}</span>
										<span>
											<Icon icon="Outline1" color="currentColor" />
										</span>
									</button>
								) : (
									<div className="flex items-center relative pr-3">
										<button
											data-qualifier="instrumentTagSelect/open"
											type="button"
											onClick={toggleOpen}
											disabled={disabled}
											title={selectedTag.name}
											className="truncate"
										>
											{selectedTag.name}
										</button>
										<button
											data-qualifier="instrumentTagSelect/close"
											className="disabled:opacity-60 absolute right-0"
											type="button"
											disabled={disabled}
											onClick={() => props.onTagCancel?.(selectedTag.id)}
										>
											<Icon icon="Close" color="white" />
										</button>
									</div>
								)}
							</TagBadge>
						</span>
					)
				}
			>
				<div className="flex flex-row pb-1">
					<form
						noValidate
						className="flex items-center pr-2"
						onSubmit={(e) => {
							e.stopPropagation();
							// eslint-disable-next-line @typescript-eslint/no-misused-promises
							searchOrAddTagForm.handleSubmit(onSubmit)(e).catch(console.error);
						}}
					>
						<Controller
							control={searchOrAddTagForm.control}
							name="query"
							render={({ field: { name, onBlur, onChange, value, ref } }) => (
								<TextInput
									data-qualifier="instrumentTagSelect/search"
									innerRef={ref}
									name={name}
									onChangeText={onChange}
									onBlur={onBlur}
									value={value ?? ""}
									placeholder={t("TAGS.SEARCH_PLACEHOLDER")}
									classList="flex-grow"
									inputAppearance={{
										classList: "border-none",
									}}
									maxLength={maxTagNameLength}
									invalid={Boolean(searchOrAddTagForm.formState.errors.query)}
								/>
							)}
						/>
						{mode === "editor" && (
							<LoadingButton
								data-qualifier="instrumentTagSelect/add"
								size="small"
								palette="primary"
								loading={searchOrAddTagForm.formState.isSubmitting}
								disabled={
									!searchOrAddTagForm.formState.isValid ||
									debouncedSearchStringState === "debouncing" ||
									searchResults.length > 0
								}
								type="submit"
							>
								{t("TAGS.ADD")}
							</LoadingButton>
						)}
					</form>
				</div>
				<div className="h-px border-b border-[#BFC4CE] -ml-3" />
				<div className="max-h-96 overflow-y-auto custom-scrollbar-y relative">
					{searchOrAddTagForm.formState.errors.query ? (
						<div className="py-2">
							{/* TODO: translate */}
							<p className="mb-2 text-red-600">
								<strong>Tags can only include</strong>
							</p>
							<ul>
								<li>
									digits <span className="rounded bg-slate-200 px-1">0-9</span>
								</li>
								<li>
									letters <span className="rounded bg-slate-200 px-1">a-z</span>
								</li>
								<li>2 - 30 total characters</li>
							</ul>
						</div>
					) : (
						<div className="max-h-52 pb-3 overflow-y-auto">
							{searchResults.length === 0 && debouncedSearchStringState === "idle" && (
								<div data-qualifier="instrumentTagSelect/noResult" className="italic text-center py-2">
									{t("TAGS.NO_RESULT")}
								</div>
							)}
							<div
								className="absolute left-0 right-0 top-0 h-1 transition-opacity"
								style={{
									opacity: debouncedSearchStringState === "debouncing" ? 1 : 0,
								}}
							>
								{debouncedSearchStringState === "debouncing" && <ProgressBar value="indeterminate" />}
							</div>
							{searchResults.map(({ name, id, color, disabled: tagDisabled }) => {
								const isDefault = (defaultTagLabels as string[]).includes(name) ?? false;
								const sanitizedMode = isDefault ? "default" : mode;
								const renderIcon = {
									editor: <Icon icon="Close" color="currentColor" />,
									selector: null,
									default: null,
								};

								return (
									<div key={id} className="pt-4">
										<button
											data-qualifier={`instrumentTagSelect/tag(${id})`}
											onClick={() => onClick(isDefault, id)}
											type="button"
											disabled={tagDisabled}
										>
											<TagBadge
												color={tagDisabled ? themeCSSVars.global_palette_neutral_400 : color}
												className="flex items-center gap-2"
											>
												<span>{name}</span>
												{renderIcon[sanitizedMode] && <span>{renderIcon[sanitizedMode]}</span>}
											</TagBadge>
										</button>
									</div>
								);
							})}
						</div>
					)}
				</div>
			</FloatingContent>

			{mode === "editor" && (
				<Dialog
					data-qualifier="instrumentTagSelect/deleteDialog"
					show={deleteTagId !== null}
					onClose={() => setDeleteTagId(null)}
					header={t("TAGS.DELETE_MODAL_TITLE")}
					onSubmitAsync={async () => {
						await handleDeleteAsync();
					}}
					footer={
						<DialogFooter
							primaryAction={
								<SubmitButton data-qualifier="instrumentTagSelect/deleteDialog/submit">{t("TAGS.DELETE")}</SubmitButton>
							}
							neutralAction={
								<Button
									palette="tertiary"
									data-qualifier="instrumentTagSelect/deleteDialog/cancel"
									onClick={() => setDeleteTagId(null)}
								>
									{t("CANCEL")}
								</Button>
							}
						/>
					}
				>
					<Trans
						i18nKey="INSTRUMENT_TAGS.DELETE_MESSAGE"
						values={{ tag: deleteTagId }}
						components={{ strong: <strong /> }}
					/>
				</Dialog>
			)}
		</>
	);
}

export const InstrumentTagSelect = memo<InstrumentTagSelectProps>(_InstrumentTagSelect) as typeof _InstrumentTagSelect;
