import type {
	EventBulkDownload,
	EventInstrumentCommentaryUpdate,
	EventInvestmentDraftUpdate,
	EventInvestmentType,
	EventInvestmentUpdate,
	EventLevel,
	EventMarketUpdate,
	EventReportUpdate,
	EventSharedEntity,
	EventTopic,
	EventUserUpdate,
} from "$root/api/api-gen";
import { AbortError } from "@mdotm/mdotui/headless";
import EventEmitter from "eventemitter3";
import { useEffect, useRef, useState } from "react";
import { useUnsafeUpdatedRef } from "@mdotm/mdotui/react-extensions";
import type { TypedEventEmitter } from "./helper-types";
import { useDebounced } from "@mdotm/mdotui/react-extensions";

export type StepToUpdate =
	| "MAIN_INFO"
	| "ALLOCATION_CONSTRAINTS"
	| "RISK_CONSTRAINTS"
	| "INVESTABLE_UNIVERSE"
	| "STRATEGY_CONSTRAINTS"
	| "MARKET_VIEW";

export type ServerEventPayloadMap = {
	[EventTopic.InvestmentUpdate]: EventInvestmentUpdate;
	[EventTopic.MarketUpdate]: EventMarketUpdate;
	[EventTopic.InvestmentDraftConfigurationUpdate]: EventInvestmentDraftUpdate;
	[EventTopic.BenchmarkUpdate]: {
		// TODO: migrate to EventUpdateBenchmark once the definition matches its usage
		level?: EventLevel;
		type?: EventInvestmentType;
		uuid?: string;
		name?: string;
		status?: string;
	};
	[EventTopic.UserUpdate]: EventUserUpdate;
	[EventTopic.BulkReportDownload]: EventBulkDownload;
	[EventTopic.InvestmentBulkEnhanceUpdate]: EventInvestmentDraftUpdate;
	[EventTopic.SharedEntity]: EventSharedEntity;
	[EventTopic.ModifiedEntity]: EventSharedEntity;
	[EventTopic.RemovedEntity]: EventSharedEntity;
	[EventTopic.DeletedEntity]: EventSharedEntity;
	[EventTopic.CommentaryUpdate]: Omit<EventInvestmentUpdate, "hasBreachedAlerts">;
	[EventTopic.InvestmentReportUpdate]: EventReportUpdate;
	[EventTopic.InstrumentCommentaryUpdate]: EventInstrumentCommentaryUpdate;
	[EventTopic.InvestmentImportUpdate]: unknown;
	"system-update": unknown;
};

/**
 * This type defines global app events which can be emitted on the
 * event bus.
 *
 * Each key corresponds to an event name, while each corresponding type
 * corresponds to the payload for that event.
 *
 * Example:
 *
 * ```ts
 * export type EventPayloadMap = { myEvent: { myPayloadContent: string } };
 * eventBus.on("myEvent", ({ myPayloadContent }) => console.log(myPayloadContent));
 * eventBus.emit("myEvent", { myPayloadContent: "example" });
 * ```
 */
export type EventPayloadMap = {
	unauthorized: void;
	printNextComponent: { name: string; height: number };
} & ServerEventPayloadMap;

/**
 * App event bus, used to listen and dispatch events that are not specific to a specific context, e.g.
 * expired authentication/authorization token.
 */
export const eventBus = new EventEmitter<EventPayloadMap>() as unknown as TypedEventEmitter<EventPayloadMap>;

/**
 * Attach to an event on the event bus.
 *
 * @param eventName name of the event.
 * @param listener a callback that will be called with the event payload.
 */
export function useEventBus<K extends keyof EventPayloadMap>(
	eventName: K,
	listener: (payload: EventPayloadMap[K]) => void,
): void;

/**
 * Attach to an event on the event bus.
 *
 * @param eventName name of the event.
 * @param config an object containing the callback and a filter function.
 */
export function useEventBus<K extends keyof EventPayloadMap>(
	eventName: K,
	config: { filter?(payload: EventPayloadMap[K]): boolean; listener(payload: EventPayloadMap[K]): void },
): void;

/**
 * Attach to an event on the event bus.
 *
 * @param eventName name of the event.
 * @param listenerOrConfig a callback that will be called with the event payload or an object containing the callback and a filter function.
 */
export function useEventBus<K extends keyof EventPayloadMap>(
	eventName: K,
	listenerOrConfig:
		| ((payload: EventPayloadMap[K]) => void)
		| { filter?(payload: EventPayloadMap[K]): boolean; listener(payload: EventPayloadMap[K]): void },
): void;

export function useEventBus<K extends keyof EventPayloadMap>(
	eventName: K,
	listenerOrConfig:
		| ((payload: EventPayloadMap[K]) => void)
		| { filter?(payload: EventPayloadMap[K]): boolean; listener(payload: EventPayloadMap[K]): void },
): void {
	const listenerOrConfigRef = useUnsafeUpdatedRef(listenerOrConfig);

	useEffect(() => {
		function wrappedListener(payload: EventPayloadMap[K]) {
			if (typeof listenerOrConfigRef.current === "function") {
				listenerOrConfigRef.current(payload);
			} else if (!listenerOrConfigRef.current.filter || listenerOrConfigRef.current.filter(payload)) {
				listenerOrConfigRef.current.listener(payload);
			}
		}

		eventBus.on(eventName, wrappedListener);

		return () => {
			eventBus.off(eventName, wrappedListener);
		};
	}, [eventName, listenerOrConfigRef]);
}

/**
 *
 * @param eventName name of the event
 * @param listener listener callback with the event payload
 * @param opts set timeout to queue the list
 */
export function useGroupEventBus<K extends keyof EventPayloadMap>(
	eventName: K,
	listener: (payload: Array<EventPayloadMap[K]>) => unknown,
	opts?: {
		/** @default 5000 */
		timeout?: number;
		filter?(payload: EventPayloadMap[K]): boolean;
	},
): void {
	const { timeout = 5000 } = opts ?? {};
	const eventPayloadListRef = useRef<Array<EventPayloadMap[K]>>([]);

	const { invoke } = useDebounced(
		() => {
			listener(eventPayloadListRef.current);
			eventPayloadListRef.current = [];
		},
		{ debounceInterval: timeout },
	);

	const [dispatch] = useState(() => (payload: EventPayloadMap[K]) => {
		eventPayloadListRef.current = [...eventPayloadListRef.current, payload];
		invoke();
	});

	useEventBus(eventName, {
		listener: dispatch,
		filter: opts?.filter,
	});
}

/**
 *
 * @param eventName name of the event
 * @param opts.filter filter callback with the event payload
 * @param opts.signal recive a {@link AbortSignal} to resolve the promise
 * @returns Promise
 */
export function waitForEvent<K extends keyof EventPayloadMap>(
	eventName: K,
	opts?: {
		filter?: (payload: EventPayloadMap[K]) => boolean;
		signal?: AbortSignal;
	},
): Promise<EventPayloadMap[K]> {
	return new Promise<EventPayloadMap[K]>((resolve, reject) => {
		if (opts?.signal?.aborted) {
			reject(opts.signal.reason ?? new AbortError());
			return;
		}
		const cleanup = () => {
			opts?.signal?.removeEventListener("abort", abortHandler);
			eventBus.off(eventName, resolveHandler);
		};
		const abortHandler = () => {
			cleanup();
			reject(opts?.signal?.reason ?? new AbortError());
		};

		const resolveHandler = (payload: EventPayloadMap[K]) => {
			if (!opts?.filter || opts.filter(payload)) {
				cleanup();
				resolve(payload);
			}
		};
		opts?.signal?.addEventListener("abort", abortHandler);
		eventBus.on(eventName, resolveHandler);
	});
}
