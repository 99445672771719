import { Card } from "../Card";
import type { PageHeaderFooterProps } from "../../configuration";
import { Text } from "@mdotm/mdotui/components";
import { themeCSSVars } from "@mdotm/mdotui/themes";

const Header = (props: PageHeaderFooterProps & { title: string; subtitle?: string }): JSX.Element => {
	const { pageIndex, title, totalPages, subtitle } = props;

	/* First page is the cover, second page is "page one" */
	return pageIndex === 1 ? (
		<Card>
			<div className="flex mt-16 items-start justify-between gap-4">
				<div className="min-w-0">
					<Text as="div" type="Title/M/Bold" classList="truncate" color={themeCSSVars.palette_N700}>
						{title}
					</Text>
					{subtitle && (
						<Text as="h4" type="Body/M/Book" color={themeCSSVars.palette_N700}>
							{subtitle}
						</Text>
					)}
				</div>
				<Text as="h4" type="Body/M/Book" color={themeCSSVars.palette_N700} classList="whitespace-nowrap">
					{/* totalPages includes the cover */}
					Pag {pageIndex}/{totalPages - 1}
				</Text>
			</div>
		</Card>
	) : (
		<div className="flex items-center justify-between px-12 mt-16">
			<div>
				<Text color={themeCSSVars.palette_N700} type="Body/M/Bold">
					{title}
				</Text>
				<Text color={themeCSSVars.palette_N700} type="Body/M/Book" classList="mx-2">
					|
				</Text>
				<Text color={themeCSSVars.palette_N700} type="Body/M/Book">
					{subtitle}
				</Text>
			</div>
			<Text as="div" type="Body/M/Book" color={themeCSSVars.palette_N700} classList="whitespace-nowrap">
				{/* totalPages includes the cover */}
				Pag {pageIndex}/{totalPages - 1}
			</Text>
		</div>
	);
};

export default Header;
