import { Redirect } from "react-router";
import { useUserValue } from "$root/functional-areas/user";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect } from "react";
import * as Yup from "yup";
import { useUnsafeUpdatedRef } from "@mdotm/mdotui/react-extensions";
import { Form, FormField, Icon, LinkButton, PasswordInput, SubmitButton, TextInput } from "@mdotm/mdotui/components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { exclude } from "@mdotm/mdotui/utils";
import { reportPlatformError } from "$root/api/error-reporting";
import { FormController } from "$root/third-party-integrations/react-hook-form";
import { platformToast } from "$root/notification-system/toast";
import { login } from "$root/functional-areas/user/auth";

export type LoginProps = {
	from?: string;
	atubm?: string;
};

export function Login({ from: redirectTo, atubm }: LoginProps): JSX.Element {
	const { t } = useTranslation();
	const user = useUserValue();

	const handleLogin = useCallback(
		async (credentials: { email: string; password: string } | { token: string }) => {
			try {
				await login(credentials);
			} catch (err) {
				reportPlatformError(
					err,
					"ERROR",
					"user",
					`login with username/token "${"email" in credentials ? credentials.email : credentials.token}"`,
				);
				platformToast({
					children: t("SOMETHING_WENT_WRONG"),
					severity: "error",
					icon: "Top-menu-user",
				});
				throw err;
			}
		},
		[t],
	);

	const handleLoginRef = useUnsafeUpdatedRef(handleLogin);

	useEffect(() => {
		if (atubm) {
			handleLoginRef.current({ token: atubm }).catch(console.warn);
		}
	}, [atubm, handleLoginRef]);

	const { formState, control, handleSubmit } = useForm({
		defaultValues: {
			email: "",
			password: "",
		},
		resolver: yupResolver(
			Yup.object({
				email: Yup.string().email(t("login.INVALID_FIELDS_NOT")).required(t("login.INVALID_FIELDS_NOT")),
				password: Yup.string().required(t("login.INVALID_FIELDS_NOT")),
			}),
		),
	});

	return user.signedIn ? (
		<Redirect to={redirectTo || "/"} />
	) : (
		<div className="flex flex-col items-center pt-12">
			<div>
				<div className="text-white text-center text-3xl drop-shadow mb-4">
					<h1>
						{t("login.WELCOME_1")}
						<br />
						{t("login.WELCOME_2")}
					</h1>
				</div>
				<div className="px-20 py-6 bg-white shadow rounded">
					<div className="mb-4">
						<h2 className="text-xl text-center">{t("login.LOGIN")}</h2>
					</div>

					<Form noValidate onSubmitAsync={() => handleSubmit(handleLogin)()} classList="space-y-4 w-[15.625rem]">
						<FormField label={t("login.USERNAME_LABEL")} classList="w-full">
							{({ id, invalid }) => (
								<FormController
									control={control}
									name="email"
									render={({ field: { onChange, ref, ...controllerProps } }) => (
										<TextInput
											required
											autoComplete="email"
											placeholder={t("login.USERNAME_PLACEHOLDER")}
											id={id}
											invalid={invalid}
											rightContent={<Icon icon="Top-menu-user" />}
											onChangeText={onChange}
											innerRef={ref}
											data-qualifier="login-email"
											{...controllerProps}
										/>
									)}
								/>
							)}
						</FormField>
						<FormField
							label={t("login.PASSWORD_LABEL")}
							error={formState.errors.email?.message ?? formState.errors.password?.message}
							classList="w-full"
						>
							{({ id, invalid }) => (
								<FormController
									control={control}
									name="password"
									render={({ field }) => (
										<PasswordInput
											required
											autoComplete="password"
											placeholder={t("login.PASSWORD_PLACEHOLDER")}
											id={id}
											invalid={invalid}
											data-qualifier="login-password"
											{...exclude(field, ["ref"])}
										/>
									)}
								/>
							)}
						</FormField>
						<div className="flex flex-col items-center pt-4 space-y-4">
							<SubmitButton data-qualifier="login-submit">{t("login.LOGIN")}</SubmitButton>
							<div>
								<LinkButton
									data-qualifier="login-forgotPassword"
									href="/forgot_password"
									palette="primary"
									classList="font-bold"
									size="x-small"
								>
									{t("login.FORGOT_PASSWORD")}
								</LinkButton>
							</div>
						</div>
					</Form>
				</div>
			</div>
		</div>
	);
}

export default Login;
