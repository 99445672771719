import type { PlatformErrorAreas } from "$root/api/error-reporting";
import { reportPlatformError } from "$root/api/error-reporting";
import type { DataAttributesProps, IconName } from "@mdotm/mdotui/components";
import { Button, DropdownMenu, Icon } from "@mdotm/mdotui/components";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import { useDataAttrs } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";

export type PageMoreActionsMenuAction = {
	label: string;
	icon: IconName;
	disabled?: boolean;
	group?: string | { id: string; label: string };
} & (
	| { onClick?: undefined; onClickAsync: () => MaybePromise<void> }
	| { onClick: () => void; onClickAsync?: undefined }
) &
	DataAttributesProps;

export function PageMoreActionsMenu(props: {
	actions: Array<PageMoreActionsMenuAction | null | undefined | false>;
	area: PlatformErrorAreas;
}): JSX.Element {
	const [dataAttrs] = useDataAttrs(props);
	return (
		<DropdownMenu
			actions={(props.actions.filter(Boolean) as Array<PageMoreActionsMenuAction>).map(
				({ label, onClick, onClickAsync, ...forward }) => ({
					label,
					onClickAsync: async () => {
						try {
							if (onClickAsync) {
								await onClickAsync();
							} else {
								onClick();
							}
						} catch (err) {
							reportPlatformError(err, "ERROR", props.area, `Click "${label}"`);
							throw err;
						}
					},
					...forward,
				}),
			)}
			trigger={({ open, ...forwardProps }) => (
				<Button
					palette="tertiary"
					size="small"
					aria-expanded={open}
					classList="!px-0"
					{...forwardProps}
					data-qualifier={`PageMoreActionsMenu(${props.area})/Button`}
				>
					<Icon icon="Vertical" size={24} color={themeCSSVars.palette_N400} />
				</Button>
			)}
			data-qualifier={`PageMoreActionsMenu(${props.area})`}
			{...dataAttrs}
		/>
	);
}
