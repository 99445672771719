import { toClassName } from "$root/utils/react-dom-extra";
import { Text, type InnerRefProps, type StylableProps } from "@mdotm/mdotui/components";
import type { NodeOrFn } from "@mdotm/mdotui/react-extensions";
import { renderNodeOrFn, toClassListRecord } from "@mdotm/mdotui/react-extensions";
import { useMemo } from "react";
import type React from "react";

export type CardProps = StylableProps &
	InnerRefProps<HTMLDivElement> & {
		title?: NodeOrFn;
		subTitle?: NodeOrFn;
		children: NodeOrFn;
		onMouseDown?: React.MouseEventHandler<HTMLDivElement>;
		onMouseUp?: React.MouseEventHandler<HTMLDivElement>;
		onTouchEnd?: React.TouchEventHandler<HTMLDivElement>;
		footer?: NodeOrFn;
		actionHeader?: NodeOrFn;
		// TODO: consider creating a CardBase which only provides the shadow, background and border radius, so that the composition is always additive.
		removeDefaultPaddings?: boolean;
		id?: string;
	};

export function Card({
	title,
	subTitle,
	children,
	footer,
	actionHeader,
	style,
	classList,
	innerRef,
	removeDefaultPaddings = false,
	id,
	...handlers
}: CardProps): JSX.Element {
	const renderedTitle = useMemo(() => {
		if (!title) {
			return undefined;
		}
		const rendered = renderNodeOrFn(title);
		if (typeof rendered === "string") {
			return (
				<Text type="Body/XL/Bold" title={rendered} classList="truncate">
					{rendered}
				</Text>
			);
		}
		return rendered;
	}, [title]);
	return (
		<div
			className={toClassName({
				"rounded bg-white flex flex-col": true,
				"px-4 pb-4": !removeDefaultPaddings,
				...toClassListRecord(classList),
			})}
			style={style}
			ref={innerRef}
			id={id}
			{...handlers}
		>
			{renderedTitle || actionHeader ? (
				<div className="flex items-center justify-between py-[10px] Header overflow-hidden">
					{renderedTitle}
					<div>{renderNodeOrFn(actionHeader)}</div>
				</div>
			) : (
				!removeDefaultPaddings && <div className="pt-3" />
			)}
			<div className="min-h-0 flex flex-col flex-1">
				{subTitle && <div className="text-[14px] pb-1.5">{renderNodeOrFn(subTitle)}</div>}
				<div className="min-h-0 flex flex-col flex-1">{renderNodeOrFn(children)}</div>
			</div>
			{footer && <div>{renderNodeOrFn(footer)}</div>}
		</div>
	);
}
