import { type InvestmentListEntry } from "$root/api/api-gen";
import type { CompareDataItem } from "$root/functional-areas/compare-portfolio/CompareOverlay";
import { customObjectEntriesFn } from "$root/utils/experimental";
import type { DialogProps, TableColumn } from "@mdotm/mdotui/components";
import { AutoSortTable, Button, Checkbox, Dialog, DialogFooter, Row, SubmitButton } from "@mdotm/mdotui/components";
import type { SpawnResult } from "@mdotm/mdotui/react-extensions";
import { adaptAnimatedNodeProvider, spawn } from "@mdotm/mdotui/react-extensions";
import { builtInSortFnFor, groupBy } from "@mdotm/mdotui/utils";
import equal from "fast-deep-equal";
import { Map } from "immutable";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export type DefaultSelectionProps = { current: boolean; enhance: boolean };
const defaultSelection = { current: false, enhance: false } satisfies DefaultSelectionProps;

type CompareDialogProps = {
	investments: Array<InvestmentListEntry>;
	comparedInvestments: Array<CompareDataItem>;
	show: boolean;
	onClose?(): void;
	onSubmit?(selectedInvestments: Map<string, DefaultSelectionProps>): void;
	onAnimationStateChange?: DialogProps["onAnimationStateChange"];
};

const CompareDialog = (props: CompareDialogProps): JSX.Element => {
	const { t } = useTranslation();
	const [selectedInvestments, setSelectedInvestment] = useState<Map<string, DefaultSelectionProps>>(Map());

	useEffect(() => {
		if (props.show) {
			const groupCompared = groupBy(props.comparedInvestments, (x) => x.uuid!);
			const comparedInvestment = Map<string, DefaultSelectionProps>(
				customObjectEntriesFn(groupCompared).map(([uuid, comparedItem]): [string, DefaultSelectionProps] => [
					uuid,
					{
						current: comparedItem?.some((x) => !x.enhanced) ?? false,
						enhance: comparedItem?.some((x) => x.enhanced) ?? false,
					},
				]),
			);

			setSelectedInvestment((prevInvestments) => {
				const comparedInvestmentToList = comparedInvestment.toArray();
				if (equal(prevInvestments, comparedInvestmentToList)) {
					return prevInvestments;
				}

				return comparedInvestment;
			});
		}
	}, [props.comparedInvestments, props.show]);

	const getInvestment = useCallback(
		(uuid?: string) => {
			if (!uuid) {
				return;
			}

			return selectedInvestments.get(uuid);
		},
		[selectedInvestments],
	);

	const onUpdatePreference = useCallback((opt: keyof DefaultSelectionProps, uuid?: string) => {
		return (selected: boolean) => {
			setSelectedInvestment((selections) => {
				if (!uuid) {
					return selections;
				}

				const investment = selections.get(uuid);
				if (investment === undefined) {
					return selections.set(uuid, { ...defaultSelection, [opt]: selected });
				}

				return selections.set(uuid, { ...investment, [opt]: selected });
			});
		};
	}, []);

	// TODO: implement checkbox in header like we have in the TemplateChooserDialog
	const columns = useMemo<TableColumn<InvestmentListEntry, string>[]>(
		() => [
			{
				header: t("TABLE.HEADERS.NAME"),
				content: (row) => row.name,
				sortFn: builtInSortFnFor("name"),
				name: "name",
				minWidth: 200,
			},
			{
				header: t("TABLE.HEADERS.CURRENT"),
				content: (row, cellProps) => (
					<Row {...cellProps} alignItems="center">
						<Checkbox
							checked={getInvestment(row.uuid)?.current ?? false}
							onChange={onUpdatePreference("current", row.uuid)}
						/>
					</Row>
				),
				name: "current",
				width: 96,
			},
			{
				header: t("TABLE.HEADERS.PROPOSAL"),
				content: (row, cellProps) => (
					<Row {...cellProps} alignItems="center">
						<Checkbox
							checked={getInvestment(row.uuid)?.enhance ?? false}
							onChange={onUpdatePreference("enhance", row.uuid)}
						/>
					</Row>
				),
				name: "proposal",
				width: 96,
			},
		],
		[getInvestment, t, onUpdatePreference],
	);

	return (
		<Dialog
			show={props.show}
			onClose={props.onClose}
			header="Compare portolios"
			onSubmitAsync={() => props.onSubmit?.(selectedInvestments)}
			onAnimationStateChange={props.onAnimationStateChange}
			footer={({ loading }) => (
				<DialogFooter
					primaryAction={<SubmitButton palette="primary">Proceed</SubmitButton>}
					neutralAction={
						<Button disabled={loading} onClick={props.onClose}>
							Cancel
						</Button>
					}
				/>
			)}
			size="large"
		>
			<div>
				<p className="mb-4">
					The following portfolios have proposals ready. Please choose which you&apos;d like to compare: the portfolio,
					the proposal, or both.
				</p>
				<AutoSortTable
					rows={props.investments.filter(({ status }) => status === "PROPOSAL_READY")}
					columns={columns}
					style={{ maxHeight: 380 }}
				/>
			</div>
		</Dialog>
	);
};

type SpawnCompareDialogProps = Omit<CompareDialogProps, "show" | "onClose">;
export function spawnCompareDialogProps(props: SpawnCompareDialogProps): SpawnResult<void> {
	return spawn<void>(
		adaptAnimatedNodeProvider(({ show, resolve, onHidden }) => (
			<CompareDialog
				{...props}
				show={show}
				onAnimationStateChange={(state) => state === "hidden" && onHidden()}
				onClose={() => resolve()}
				onSubmit={(instruments) => {
					props.onSubmit?.(instruments);
					resolve();
				}}
			/>
		)),
	);
}

export default CompareDialog;
