import type { StylableProps } from "@mdotm/mdotui/components";
import { ComputedSizeContainer, Svg } from "@mdotm/mdotui/components";
import { ForEach } from "@mdotm/mdotui/react-extensions";
import { mapBetweenRanges } from "@mdotm/mdotui/utils";

type BarGraphProps = {
	/** @default "100%" */
	width?: string | number;
	/** @default "auto" */
	height?: string | number;
	bars: number[];
	scale: {
		min: number;
		max: number;
	};
} & StylableProps;

export const BarGraph: React.FC<BarGraphProps> = ({
	width: propsWidth,
	height: propsHeight,
	bars,
	scale,
	classList,
	style,
}) => {
	return (
		<ComputedSizeContainer
			classList={classList}
			style={{
				width: propsWidth ?? "100%",
				height: propsHeight ?? "auto",
				...style,
			}}
		>
			{({ height, width }) => {
				const originAxisHeight = 8;
				const barHeight = (height - originAxisHeight) / bars.length;
				return (
					<Svg classList="min-w-0" viewBox={{ width, height }}>
						<ForEach collection={bars}>
							{({ item: value, index: i }) => {
								const isPositive = value >= 0;
								const rectWidth = isPositive
									? mapBetweenRanges(value, 0, scale.max, 0, width / 2)
									: mapBetweenRanges(value, scale.min, 0, 0, width / 2);
								return (
									<rect
										fill={i % 2 === 0 ? "rgb(6 182 212)" : "rgb(100 116 139)"}
										x={width / 2 + (isPositive ? 0 : -rectWidth)}
										y={i * barHeight + originAxisHeight / 2}
										width={rectWidth}
										height={barHeight}
									/>
								);
							}}
						</ForEach>
						<rect x={width / 2 - 0.5} y={0} width={1} height={height} fill="rgb(228 228 231)" />
					</Svg>
				);
			}}
		</ComputedSizeContainer>
	);
};
